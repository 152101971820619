import { ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { DatePipe } from '@angular/common';
import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { findIndex, isEmpty, uniqBy } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { AgGridHelper } from '../../components/ag-grid/ag-grid-helper';
import { gridConvertSort } from '../../components/ag-grid/ag-grid.utils';
import { AuthService } from '../../core/services/auth.service';
import { OctService } from '../../core/services/oct.service';
import { FilterItem } from '../../data/filters/filter-item';
import { HotlineFilterRaw } from '../../data/filters/hotlineFilterRaw';
import { HotlineDataRaw } from '../../data/hotlineDataRaw';
import { RouteHistoryService } from '../../shared/route-history.service';
import { LotsFilterSvc } from '../flux/lots/lots-filter.service';
import { GroupUsersService } from '../users/groupUsers.service';
import { UsersService } from '../users/users.service';
import { HotlinePreviewComponent } from './hotline-subcomposant/hotline-preview.component';
import { HotlineService } from './hotline.service';
import { HotMotifsService } from './hotMotifs.service';
import { ConvertDateFormat } from '../../core/utilities/convert-date-format.service';
import { GridDataRaw } from '../../data/gridDataRaw';
import { StorageService } from '../../shared/storage-service';
import {ExportModalComponent, ExportMode} from "../export-modal/export-modal.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import { saveAs } from 'file-saver';
import {TransformService} from "../../core/services/transform.service";
import {ExportState, State} from "../../shared/model/storage.model";

@Component({
	selector: 'careweb-app-hotline',
	templateUrl: './hotline.component.html',
	styleUrls: ['./hotline.component.scss']
})
export class HotlineComponent implements OnInit, OnDestroy {
	params: any;
	order;
	showFilter = false;
	initHidden = true;
	gridHelper: AgGridHelper;
	filters = new HotlineFilterRaw();
	datePipe: DatePipe;
	currentOct;
	filterList: FilterItem[];
	intervenants = [];
	motifs = [];
	groupeUsers = [];
	octs = [];
	agents = [];
	retour: any;
	numeroPsvalided: Boolean = false;
	referenceExportId = '';
	hotlineList = [];
	interventionStatut: any = [
		{ id: 0, statut: 'Indifférent' },
		{ id: 1, statut: 'En cours' },
		{ id: 2, statut: 'Transférée' },
		{ id: 3, statut: 'Clôturée' }
	];
	hotlineDataRaw: HotlineDataRaw;
	showDeleteInterventionModal: Boolean = false;
	@ViewChild(HotlinePreviewComponent, { static: true })
	hotlinePreviewComponent: HotlinePreviewComponent;
	paramsRows: any;
	interventionToDelete;
	gridDataRaw: GridDataRaw;
	rowCount: number;
	private openedModal: BsModalRef;
	export: boolean = false;
	exportState: ExportState;
	deleteHistory: boolean = true;
	deleteHistoryYes: boolean = true;
	deleteHistoryNo: boolean = false;
	constructor(
		private hotlineSvc: HotlineService,
		private octSvc: OctService,
		private usersService: UsersService,
		private authSvc: AuthService,
		private hotMotifsService: HotMotifsService,
		private gUService: GroupUsersService,
		private router: Router,
		private lotsFilterSvc: LotsFilterSvc,
		private toastr: ToastrService,
		private routeHistory: RouteHistoryService,
		private convertFormatDate: ConvertDateFormat,
		private storageService: StorageService,
		private modalService: BsModalService,
		private transformService: TransformService
	) {
		//Get grid size from session if already stored
		const sessionPageSize = this.storageService.get('hotPaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;

		this.gridHelper = new AgGridHelper('hot', this.gridDataRaw.size, 10);
		this.hotlineDataRaw = new HotlineDataRaw();
		this.convertFormatDate = new ConvertDateFormat();
	}

	ngOnInit() {
		const parsedFilter = JSON.parse(sessionStorage.getItem('hotlineFilters'));

		if (!isEmpty(parsedFilter)) {
			this.filters = Object.assign({}, parsedFilter);
			// Convert String dates to Dates format
			this.filters.dateCreation ? (this.filters.dateCreation = new Date(parsedFilter.dateCreation)) : '';
			this.filters.dateDebutIntervention
				? (this.filters.dateDebutIntervention = new Date(parsedFilter.dateDebutIntervention))
				: '';
		}

		// if coming from details should not get numeroPs from localstorage
		if (this.routeHistory?.getPreviousUrl()?.indexOf('hotline-edit') === -1) {
			if (!isEmpty(localStorage.getItem('numeroPs'))) {
				this.filters.numeroPs = localStorage.getItem('numeroPs');
			}
			sessionStorage['hotCurrentPage'] = null;
		}

		this.currentOct = JSON.parse(localStorage.getItem('octCurrent'));
		if (isNullOrUndefined(this.currentOct)) {
			this.octSvc.currentOctSubject.subscribe((value) => {
				this.currentOct = value;
			});
		}

		// Init date pipe
		this.datePipe = new DatePipe('fr-FR');
		this.gridHelper.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				suppressMenu: true
			},
			domLayout: 'autoHeight',
			rowHeight: 85,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRaw.size,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			enableCellTextSelection: true,
			onGridReady: (params) => this.onGridReady(params),
			 onGridSizeChanged: () => this.onGridHotTypeSizeChanged(),
			onColumnMoved: (params) => this.onColumnMoved(params)
		};
	}

	onGridHotTypeSizeChanged() {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	onGridReady(params) {
		const ctx = this;
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.hotlineDataRaw.withReference = true;
		this.initSorting();
		this.restoreGrid();
		this.setColumnOrder(window.innerWidth);
		const parsedFilter = JSON.parse(sessionStorage.getItem('hotlineFilters'));
		ctx.usersService.getAdminAndHotlineUsersList(ctx.currentOct?.idOct).subscribe((data) => {
			ctx.getIntervenants(data);
			ctx.getAgents(data);
		});

		ctx.hotMotifsService.getHotlineList().subscribe((data) => {
			ctx.getMotifsLists(data);
		});

		ctx.gUService.getGroupUserList().subscribe((data) => {
			ctx.getGroupeUsers(data);
		});

		if (!isEmpty(parsedFilter) || !isEmpty(this.filters.numeroPs)) {
			this.launchSearch();
		} else {
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
					ctx.hotlineSvc
						.getHotLineFilteredListFiltre(
							{
								page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
								size: ctx.gridHelper.gridApi.paginationGetPageSize(),
								idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
								sorts
							},
							ctx.hotlineDataRaw
						)
						.subscribe(
							(data) => {
								ctx.rowCount = data.totalElements;
								ctx.hotlineList = data.content;
								ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
								const pageN = Number.parseInt(sessionStorage.getItem('hotCurrentPage'));
								this.gridHelper.gridApi.sizeColumnsToFit();
								if (
									!isNullOrUndefined(pageN) &&
									pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
									pageN > 0
								) {
									ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
								}
								sessionStorage['hotCurrentPage'] = null;
							},
							() => paramsRows.fail()
						);
				}
			};
			this.gridHelper.gridApi.setServerSideDatasource(dataSource);
			this.gridHelper.gridApi.sizeColumnsToFit();
		}
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		let width = event.target.innerWidth;
		this.setColumnOrder(width);
	}
	setColumnOrder(width:any) {
		const columnDefs = this.getColumnDefs();
		if(width<=1600){
			const columnApi = this.gridHelper.gridColumnApi;
			const optionsHotlineIndex = columnDefs.findIndex(column => column.field === 'optionsHotline');
			const optionsRemoveIndex = columnDefs.findIndex(column => column.field === 'optionsRemove');
			if(optionsHotlineIndex !== -1 && optionsRemoveIndex !== -1){
				columnApi.moveColumn('optionsHotline', 0);
				columnApi.moveColumn('optionsRemove', 1);
			}
		}else{
			this.gridHelper.gridApi.setColumnDefs(columnDefs);
			this.gridHelper.gridApi.sizeColumnsToFit();
		}
	}
	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.gridHelper.paginationPageSize;
	}

	private initSorting() {
		const sort = [
			{
				colId: 'dateDebutIntervention',
				sort: 'desc',
				sortIndex: 0
			}
		];
		return this.gridHelper.applyColumnState(sort);
	}

	toggleFilterDisplay() {
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
		}
	}

	agInit(params: any): void {
		this.params = params;
		this.order = params.data;
	}

	resetGridState() {
		this.gridHelper.resetGridState();
		this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
		this.initSorting();
	}

	refresh(): boolean {
		return false;
	}

	resetFilter() {
		this.lotsFilterSvc.reset();
	}

	initFilter() {
		// Liste des filtres
		this.resetFilter();
		this.updateFilterList(this.filters);
	}

	resetAllFilter(): void {
		this.initFilter();
		this.filters = new HotlineFilterRaw();
		this.launchSearch();
		this.lotsFilterSvc.reset();
	}

	launchSearch(): void {
		const ctx = this;
		this.hotlineDataRaw = new HotlineDataRaw();
		this.hotlineDataRaw.agent = this.filters.agent?.id;
		this.hotlineDataRaw.numeroPs = this.filters.numeroPs;
		this.hotlineDataRaw.withReference = true;
		if (this.filters.numeroPs) {
			ctx.numeroPsvalided = true;
		} else {
			ctx.numeroPsvalided = false;
		}

		this.hotlineDataRaw.groupe = this.filters.groupe?.id;
		this.hotlineDataRaw.reference = this.filters.reference ? this.filters.reference.trim() : null;
		this.hotlineDataRaw.reference = this.filters.reference || null;
		this.hotlineDataRaw.statut = this.filters.statut;
		this.hotlineDataRaw.intervenant = this.filters.intervenant?.id;
		this.hotlineDataRaw.motif = this.filters.motif;

		this.hotlineDataRaw.dateCreation = this.convertFormatDate.getFormatDateFr(this.filters.dateCreation);
		this.hotlineDataRaw.dateDebutIntervention = this.convertFormatDate.getFormatDateFr(
			this.filters.dateDebutIntervention
		);

		ctx.hotMotifsService.getHotlineList().subscribe((data) => {
			ctx.getMotifsLists(data);
			this.updateFilterList(this.filters);
		});

		Object.keys(this.hotlineDataRaw).forEach((key) => {
			if (isNullOrUndefined(this.hotlineDataRaw[key]) || this.hotlineDataRaw[key] == '') {
				delete this.hotlineDataRaw[key];
			}
		});
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
				ctx.hotlineSvc
					.getHotLineFilteredListFiltre(
						{
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							idOct: ctx.currentOct ? ctx.currentOct.idOct : '',
							sorts
						},
						ctx.hotlineDataRaw
					)
					.subscribe(
						(data) => {
							ctx.rowCount = data.totalElements;
							ctx.hotlineList = data.content;
							ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
							paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
							const pageN = Number.parseInt(sessionStorage.getItem('hotCurrentPage'));
							if (
								!isNullOrUndefined(pageN) &&
								pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
								pageN > 0
							) {
								ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
							}
							sessionStorage['hotCurrentPage'] = null;
						},
						(err) => paramsRows.fail()
					);
			}
		};

		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		this.gridHelper.gridApi.sizeColumnsToFit();

		sessionStorage['hotlineFilters'] = JSON.stringify(this.filters);
	}

	updateFilterList(filters) {
		const ctx = this;
		ctx.filterList = [];
		Object.keys(filters).forEach((key) => {
			if (filters[key] !== null && filters[key] !== '' && filters[key] !== undefined) {
				ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
			}
		});
		// Remove duplicated ids
		ctx.filterList = uniqBy(ctx.filterList, 'id');
		// filter null
		ctx.filterList = ctx.filterList.filter((item) => {
			return item.id !== null;
		});
	}

	exportXlsx() {
		const exportableColumns = this.gridHelper.gridColumnApi.getAllDisplayedColumns()
			.filter(column => column.getColDef().headerName != "")
			.map(column => column.getColDef().field);
		if (this.rowCount > 0 && this.rowCount <= 1000) {
			this.exportToExcel(exportableColumns);
		} else if (this.rowCount > 1000) {
			this.openedModal = this.modalService.show(ExportModalComponent,  { initialState: {
					nbrElement: this.rowCount
				}, class: 'modal-lg'});
			this.openedModal.content.exportEvent.subscribe((e: any) => {
				if (e.action === ExportMode.EXPORT) {
					// this.exportFileXlsx();
					this.exportToExcel(exportableColumns);
				}
				if (e.action === ExportMode.EMAIL) {
					// this.exportFileXlsx();
					this.exportByEmail(exportableColumns);
				}
			});
		}
	}
	exportToExcel(columns) {
		this.export = true;
		this.exportState = {
			message : "Vous allez exporter <b>" + this.transformService.transform(this.rowCount) + " ligne(s)</b>, veuillez patienter quelques instants.",
			state: State.INPROGRESS
		}
		const sorts = gridConvertSort(this.gridHelper.gridSortModel(),[]);
		this.hotlineSvc.exportHotline(
			{
				page : this.gridHelper.gridApi.paginationGetCurrentPage(),
				size : this.gridHelper.gridApi.paginationGetPageSize(),
				export : false,
				sorts
			},
			this.hotlineDataRaw,
			columns
		).subscribe((response) => {
			saveAs(response.file, response.fileName);
			this.exportState = {
				message : "Votre fichier a été téléchargé avec succès.",
				state: State.SUCCESS
			}
		},() => {
			this.exportState = {
				message : "Le téléchargement de votre fichier a rencontré un problème.",
				state: State.FAILED
			}
		})
	}

	exportByEmail(columns) {
		const sorts = gridConvertSort(this.gridHelper.gridSortModel(),[]);
		this.hotlineSvc.exportHotlineByEmail(
			{
				page : this.gridHelper.gridApi.paginationGetCurrentPage(),
				size : this.gridHelper.gridApi.paginationGetPageSize(),
				export: false,
				sorts
			},
			this.hotlineDataRaw,
			columns
		).subscribe((response) => {

		})
	}
	closeAlert() {
		this.export = false;
	}
	rowGroupCallback(params) {
		return params.node.key;
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	private getColumnDefs(): ColDef[] {
		return [
			{
				headerName: 'Référence',
				field: 'reference',
				minWidth: 100,
				maxWidth: 100,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true,
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Initiateur',
				field: 'intervenant',
				minWidth: 80,
				sortable: true,
				onCellClicked: (params) => this.onCellClicked(params),
				resizable: true,
				cellStyle: {
					'white-space': 'normal',
					'display': 'grid',
					'overflow': 'hidden',
					'word-break': 'break-word'
				}
			},
			{
				headerName: 'Service destinataire',
				field: 'service',
				minWidth: 150,
				sortable: true,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Date de création',
				field: 'dateCreation',
				minWidth: 150,
				valueFormatter: this.formatDate,
				lockVisible: true,
				sortable: true,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: "Type d'intervention",
				field: 'intervention',
				minWidth: 150,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true,
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'N° de PS',
				field: 'numeroPs',
				minWidth: 100,
				maxWidth: 150,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				sortable: true,
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Motif',
				field: 'motif',
				sortable: true,
				minWidth: 80,
				onCellClicked: (params) => this.onCellClicked(params),
				resizable: true,
				cellStyle: {
					'white-space': 'normal',
					'display': 'grid',
					'overflow': 'hidden',
					'word-break': 'break-word'
				}
			},
			{
				headerName: 'Statut',
				field: 'statut',
				cellRenderer: this.getStatut,
				minWidth: 80,
				maxWidth: 100,
				lockVisible: true,
				sortable: true,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: "Date d'action",
				field: 'dateDebutIntervention',
				minWidth: 150,
				valueFormatter: this.formatDate,
				lockVisible: true,
				sortable: true,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.onCellClicked(params)
			},
			{
				headerName: 'Commentaire',
				field: 'commentaire',
				minWidth: 200,
				lockVisible: true,
				onCellClicked: (params) => this.onCellClicked(params),
				cellRenderer: HotlinePreviewComponent,
				cellStyle: {
					'white-space': 'normal',
					'display': 'grid',
					'overflow': 'hidden',
					'word-break': 'break-word'
				},
				resizable: true
			},
			{
				headerName: 'Agent assigné',
				field: 'agent',
				sortable: true,
				minWidth: 80,
				onCellClicked: (params) => this.onCellClicked(params),
				resizable: true,
				cellStyle: {
					'white-space': 'normal',
					'display': 'grid',
					'overflow': 'hidden',
					'word-break': 'break-word'
				}
			},
			{
				headerName: '',
				field: 'optionsRemove',
				minWidth: 50,
				maxWidth: 50,
				cellRenderer: HotlinePreviewComponent,
				cellRendererParams: {
					redirect: 'hotlineRemove',
					type: 'hotlineIntervention'
				},
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				onCellClicked: (params) => this.showDeleteModal(params)
			},
			{
				headerName: '',
				field: 'optionsHotline',
				minWidth: 50,
				maxWidth: 50,
				onCellClicked: (params) => this.onCellClicked(params),
				cellRenderer: HotlinePreviewComponent,
				cellRendererParams: {
					redirect: 'hotlineEdit'
				},
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent()
			}
		];
	}

	onCellClicked(params) {
		if (!this.gridHelper.isCellTextSelected()) {
			this.router.navigate(['/hotline-edit', params.data.idIntervention]);
		}
	}

	getMotif = (params): any => {
		try {
			if (params.data.reference.motifs) {
				let nom = '';
				if (params.data.reference.motifs[0].libelle) {
					nom = params.data.reference.motifs[0].libelle;
				}
				return nom;
			}
		} catch (error) { }
	};

	getUser = (params): any => {
		return params.data && params.data.reference.ps.psUsers[0].nom ? params.data.reference.ps.psUsers[0].nom : '';
	};

	getStatut = (params): any => {
		if (params.data) {
			let statut: string;
			switch (params.data.statut) {
				case 1: {
					statut = 'En cours';
					break;
				}
				case 2: {
					statut = 'Transférée';
					break;
				}
				case 3: {
					statut = 'Clôturée';
					break;
				}
			}
			return statut;
		}
	};

	formatDate = (params): string => {
		if (params.value !== null && params.value !== 'Invalid Date' && params.value) {
			return this.datePipe.transform(params.value, 'short');
		} else {
			return params.value;
		}
	};

	formatDateForTags = (params): string => {
		if (params.value !== null && params.value != 'Invalid Date') {
			return this.datePipe.transform(params.value, 'dd/MM/yyyy');
		}
	};

	translateFilterLabelValue(key, value) {
		let translatedKey = key;
		let formattedValue = value;
		let formattedKey = key;
		const ctx = this;

		if (key === 'numeroPs') {
			translatedKey = 'N° de PS';
		} else if (key === 'reference') {
			translatedKey = "Référence de l'intervention";
		} else if (key === 'motif') {
			translatedKey = "Motif de l'intervention";
			this.getMotifsValue(formattedValue);
			formattedValue = this.retour;
			/* 		} else if (key === 'N°PS') {
			translatedKey = 'N° de fSE supérieur à'; */
		} else if (key === 'dateCreation') {
			translatedKey = 'Intervention créée le';
			formattedValue = ctx.formatDateForTags({ value: value });
		} else if (key === 'dateDebutIntervention') {
			translatedKey = "Date d'action le";
			formattedValue = ctx.formatDateForTags({ value: value });
		} else if (key === 'statut') {
			translatedKey = "Statut de l'intervention";
			this.getStatutValue(formattedValue);
			formattedValue = this.retour;
		} else if (key === 'groupe') {
			translatedKey = 'Service du destinataire de';
			formattedValue = this.getGroupeUsersValue(formattedValue);
		} else if (key === 'agent') {
			translatedKey = 'Agent assigné';
			formattedValue = this.getHotlineValue(formattedValue);
		} else if (key === 'intervenant') {
			translatedKey = "Initiateur de l'intervention";
			formattedValue = this.getIntervenantValue(formattedValue);
		}

		return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
	}

	removeFilter(id) {
		const ctx = this;
		delete ctx.filters[id];
		let currentFilterList = ctx.filterList;
		const index = findIndex(currentFilterList, (filt) => filt.id === id);
		currentFilterList.splice(index, 1);
		ctx.filterList = currentFilterList;
		this.fillFilterObjectFromTagsOnly();
		ctx.launchSearch();
	}

	fillFilterObjectFromTagsOnly() {
		this.filters = new HotlineFilterRaw();
		this.filterList.forEach(filter => {
			this.filters[filter.id] = filter.value2;
		});
	}

	getMotifsLists = (data: any) => {
		if (data) {
			data.forEach((element) => {
				const motif = {
					id: element.idMotifs,
					name: element.libelle.toUpperCase()
				};
				this.motifs.push(motif);
			});
		}
	};

	getMotifsValue = (data: any): String => {
		if (data) {
			this.motifs.forEach((element) => {
				if (element.id === data) {
					this.retour = element.name;
				}
			});
		}
		return this.retour;
	};

	getIntervenants = (data: any) => {
		if (data) {
			data.forEach((element) => {
				const intervenant = {
					id: element.idUser,
					name: `${element.prenom ? element.prenom : ''} ${element.nom ? element.nom.toUpperCase() : ''}`
				};
				this.intervenants.push(intervenant);
			});
			if(this.intervenants && this.intervenants.length && this.filters && this.filters.intervenant && this.filters.intervenant.id){
				let index=this.intervenants.findIndex(i=>i.id===this.filters.intervenant.id)
				this.filters.intervenant=this.intervenants[index]
			}
		}
	};

	getIntervenantValue = (data: any): String => {
		if (data) {
		return data.name
		}
	};

	getGroupeUsers = (data: any) => {
		if (data) {
			data.forEach((element) => {
				const groupe = {
					id: element.idGroupe,
					name: element.libelleLong.toUpperCase()
				};
				this.groupeUsers.push(groupe);
			});
			if(this.groupeUsers && this.groupeUsers.length && this.filters && this.filters.groupe && this.filters.groupe.id){
				let index=this.groupeUsers.findIndex(i=>i.id===this.filters.groupe.id)
				this.filters.groupe=this.groupeUsers[index]
			}
		}
	};

	getGroupeUsersValue = (data: any): String => {
		if (data) {
			return data.name
		}		
	};

	getAgents = (data: any) => {
		if (data) {
			data.forEach((element) => {
				if (element.idTypeUser == 3) {
					const agent = {
						id: element.idUser,
						value: `${element.prenom ? element.prenom : ''} ${element.nom ? element.nom.toUpperCase() : ''}`
					};
					this.agents.push(agent);					
				}
			});
			if(this.agents && this.agents.length && this.filters && this.filters.agent && this.filters.agent.id){
				let index=this.agents.findIndex(a=>a.id===this.filters.agent.id)
				this.filters.agent=this.agents[index]
			}			
		}
	};

	getHotlineValue = (data: any): String => {
		if (data) {
			return data.value
		}
	};

	getStatutValue = (data: any): String => {
		if (data != null) {
			this.interventionStatut.forEach((element) => {
				if (element.id === data) {
					this.retour = element.statut;
				}
			});
		}
		return this.retour;
	};

	ngOnDestroy() {
		sessionStorage['hotlineFilters'] = JSON.stringify(this.filters);
		sessionStorage['hotCurrentPage'] = this.gridHelper.gridApi.paginationGetCurrentPage();
		this.gridHelper.saveGridStatePrefix();
	}

	showDeleteModal(params: any) {
		this.showDeleteInterventionModal = true;
		this.interventionToDelete = params.data;
	}

	cancelDelete() {
		this.deleteHistory=true
		this.showDeleteInterventionModal = false;
	}

	deleteIntervention() {
		const ctx = this;
		this.hotlineDataRaw.withReference = true;
		this.hotlineSvc.deleteIntervention(this.interventionToDelete.idIntervention,this.deleteHistory?this.interventionToDelete.reference:-1).subscribe((data) => {
			ctx.toastr.success('Intervention supprimée avec succès.');
			this.showDeleteInterventionModal = false;
			// @TODO: to optimise !!
			this.launchSearch();
		});
	}

	onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		this.launchSearch();
		this.gridHelper.saveGridStatePrefix();
	}

	onColumnMoved(params: any): void {
		this.gridHelper.saveGridColumnState();
	}

	onKeyDown(event) {
		this.launchSearch();
	}
	onYesChange(event: any) {
		if (event.target.checked) {
		  this.deleteHistoryNo = false;
		  this.deleteHistory = true;
		} else {
		  this.deleteHistory = false;
		}
	  }
	  
	  onNoChange(event: any) {
		if (event.target.checked) {
		  this.deleteHistoryYes = false;
		  this.deleteHistory = false;
		} else {
		  this.deleteHistory = false;
		}
	  }
}
