import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DictRuleRappService } from '../dictRuleRapp.service';
import { DictRappkeyword } from "../../../data/dictRappMotCle";
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'careweb-dict-rule-cpam-modal',
    templateUrl: './dict-rule-cpam-modal.component.html',
    styleUrls: ['./dict-rule-cpam-modal.component.scss']
})
export class DictRuleCpamModalComponent implements OnInit {
    @Input() title: string;
    @Input() dictRuleCpam: any;
    @Input() isDeleteModale: any;
    dictRuleCpamForm: UntypedFormGroup;
    isDictRuleFormSubmitted = false;
    dictRappkeyword: DictRappkeyword = {};
    oldRuleKeyLabel = '';
    oldRuleKeyLabelVirement1 = '';
    bankTransactions = [];
    showConfirmAlert = false;

    constructor(private modalRef: BsModalRef, private toastr: ToastrService, private fb: UntypedFormBuilder, private dictRuleRapp: DictRuleRappService) { }

    ngOnInit() {
        this.initSolderForm();
        if (this.dictRuleCpam && this.dictRuleCpam.occurrenceOrg && this.dictRuleCpam.occurrenceVrt) {
            this.dictRuleRapp.getMetadataSuggestion(this.dictRuleCpam.idDictRapp).subscribe((bankTransactions) => {
                this.bankTransactions = bankTransactions
            });
        }
    }

    initSolderForm() {
        this.dictRuleCpamForm = this.fb.group({
            'motCleLabelVrt': [this.dictRuleCpam ? this.dictRuleCpam.motCleLabelVrt : '', [Validators.required]],
            'motCleLabelV1': [this.dictRuleCpam ? this.dictRuleCpam.motCleLabelV1 : '', [Validators.required]],
        });
    }

    closeModal() {
        if (this.oldRuleKeyLabel !== '' || this.oldRuleKeyLabelVirement1 !== '') {
            this.dictRappkeyword = {};
        }
        this.modalRef.hide();
    }

    onSubmit() {
        this.isDictRuleFormSubmitted = true;
        if (this.dictRuleCpamForm.invalid) {
            return;
        }
        this.dictRappkeyword.motCleLabelVrt = this.dictRuleCpamForm.get('motCleLabelVrt').value;
        this.dictRappkeyword.motCleLabelV1 = this.dictRuleCpamForm.get('motCleLabelV1').value;
        if (this.dictRuleCpam) {
            //update 
            this.dictRappkeyword.idDictRapp = this.dictRuleCpam.idDictRapp;
            if (this.dictRappkeyword.motCleLabelVrt !== this.dictRuleCpam.motCleLabelVrt || this.dictRappkeyword.motCleLabelV1 !== this.dictRuleCpam.motCleLabelV1 || !this.dictRuleCpam.enabled) {
                this.dictRuleRapp.isRuleCpamExists(this.dictRappkeyword.motCleLabelVrt, this.dictRappkeyword.motCleLabelV1).subscribe(isRuleExists => {
                    this.checkRuleExistesUpdate(isRuleExists);
                });
            } else {
                this.modalRef.content.dictRappkeyword = this.dictRappkeyword;
                this.closeModal();
            }
        } else {
            //ajout
            this.dictRuleRapp.isRuleCpamExists(this.dictRappkeyword.motCleLabelVrt, this.dictRappkeyword.motCleLabelV1).subscribe(isRuleExists => {
                this.checkRuleExistes(isRuleExists);
            });
        }

        if (this.isDeleteModale) {
            this.modalRef.content.isToDelete = true;
            this.closeModal();
        }
    }

    checkRuleExistes(rule) {
        switch (rule) {
            case 0:
                this.oldRuleKeyLabel = this.dictRappkeyword.motCleLabelVrt?.valueOf() || '';
                this.modalRef.content.dictRappkeyword = this.dictRappkeyword;
                this.oldRuleKeyLabelVirement1 = '';
                break;
            case 1:
                this.oldRuleKeyLabelVirement1 = this.dictRappkeyword.motCleLabelV1?.valueOf() || '';
                this.modalRef.content.dictRappkeyword = this.dictRappkeyword;
                this.oldRuleKeyLabel = '';
                break;
            case 2:
                this.oldRuleKeyLabel = this.dictRappkeyword.motCleLabelVrt?.valueOf() || '';
                this.oldRuleKeyLabelVirement1 = this.dictRappkeyword.motCleLabelV1?.valueOf() || '';
                break;
            default:
                this.modalRef.content.dictRappkeyword = this.dictRappkeyword;
                this.oldRuleKeyLabel = '';
                this.oldRuleKeyLabelVirement1 = '';
                this.closeModal();

        }
    }

    checkRuleExistesUpdate(rule) {
        switch (rule) {
            case 0:
                if (this.dictRappkeyword.motCleLabelVrt == this.dictRuleCpam.motCleLabelVrt) {
                    this.modalRef.content.dictRappkeyword = this.dictRappkeyword;
                    this.modalRef.hide();
                } else {
                    this.oldRuleKeyLabel = this.dictRappkeyword.motCleLabelVrt?.valueOf() || '';
                }
                this.oldRuleKeyLabelVirement1 = '';
                break;
            case 1:
                if (this.dictRappkeyword.motCleLabelV1 == this.dictRuleCpam.motCleLabelV1 && this.dictRuleCpam.enabled) {
                    this.modalRef.content.dictRappkeyword = this.dictRappkeyword;
                    this.modalRef.hide();
                } else {
                    this.oldRuleKeyLabelVirement1 = this.dictRappkeyword.motCleLabelV1?.valueOf() || '';
                }
                this.oldRuleKeyLabel = '';
                break;
            case 2:
                if (this.dictRappkeyword.motCleLabelVrt == this.dictRuleCpam.motCleLabelVrt && this.dictRappkeyword.motCleLabelV1 !== this.dictRuleCpam.motCleLabelV1) {
                    this.oldRuleKeyLabelVirement1 = this.dictRappkeyword.motCleLabelV1?.valueOf() || '';
                    this.modalRef.content.dictRappkeyword = this.dictRappkeyword;
                    this.oldRuleKeyLabel = '';
                } else {
                    if (this.dictRappkeyword.motCleLabelV1 == this.dictRuleCpam.motCleLabelV1 && this.dictRappkeyword.motCleLabelVrt !== this.dictRuleCpam.motCleLabelVrt) {
                        this.oldRuleKeyLabel = this.dictRappkeyword.motCleLabelVrt?.valueOf() || '';
                        this.modalRef.content.dictRappkeyword = this.dictRappkeyword;
                        this.oldRuleKeyLabelVirement1 = '';
                    } else {
                        this.oldRuleKeyLabel = this.dictRappkeyword.motCleLabelVrt?.valueOf() || '';
                        this.oldRuleKeyLabelVirement1 = this.dictRappkeyword.motCleLabelV1?.valueOf() || '';
                    }
                }
                break;
            default:
                this.modalRef.content.dictRappkeyword = this.dictRappkeyword;
                this.oldRuleKeyLabel = '';
                this.oldRuleKeyLabelVirement1 = '';
                this.closeModal();

        }
    }

    get dictRuleFormFields() {
        return this.dictRuleCpamForm.controls;
    }

    resetOldRuleKeyLabel() {
        this.oldRuleKeyLabel = '';
        this.dictRappkeyword = {};
    }

    resetOldRuleKeyLabelVirement() {
        this.oldRuleKeyLabelVirement1 = '';
        this.dictRappkeyword = {};
    }

    clearMetadata() {
        this.dictRuleRapp.cleanMetadata(this.dictRuleCpam.idDictRapp).subscribe(data => {
            this.dictRuleCpam.occurrenceOrg = null;
            this.dictRuleCpam.occurrenceVrt = null;
            this.bankTransactions = [];
            this.showConfirmAlert = false;
            this.toastr.success(
                "Le nettoyage des métadonnées a été bien effectué pour l'organisme "+ this.dictRuleCpam.motCleLabelV1,
                'Nettoyage des métadonnées'
            );
        }, (error) => {
            this.showConfirmAlert = false
        });
    }
}
