<div class="full-careweb-container">
    <div id="filter" class="filter-container" carewebFilterMinHeight>
        <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
            <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
                (launchSearch)="showGridRappro ? launchSearch() : launchSearchCpam()"></careweb-accordion-header-buttons>
            <div class="filter-content">
                <mat-accordion displayMode="flat" class="accordion-filter">
                    <!-- Mot clé virement -->
                    <careweb-accordion-group id="motVirement" title="Mot clé virement" *ngIf="showGridRappro">
                        <input type="text" id="input_motVirement" [(ngModel)]="filters.keyLabel"
                            (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Organisme  -->
                    <careweb-accordion-group id="motOrganisme" title="Organisme" *ngIf="showGridRappro">
                        <input type="text" id="input_motOrganisme" [(ngModel)]="filters.organismeLabel"
                            (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Code règle -->
                    <careweb-accordion-group id="codeRegle" title="Code Règle" *ngIf="showGridRappro">
                        <ng-select name="coderegle" id="coderegle" placeholder="Code Règle" [searchable]="false"
                                 [(ngModel)]="filters.codeRegle" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
                            <ng-option [value]="''"> </ng-option>
                            <ng-option [value]="1">1</ng-option>
                            <ng-option [value]="2">2</ng-option>
                            <ng-option [value]="3">3</ng-option>
                            <ng-option [value]="4">4</ng-option>
                        </ng-select>
                    </careweb-accordion-group>
                    <careweb-accordion-group id="motCleLabelVrt" title="Mot clé virement"
                        *ngIf="showGridRapproTypeCpam">
                        <input type="text" id="input_motCleLabelVrt" [(ngModel)]="filtersCpam.motCleLabelVrt"
                            (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <careweb-accordion-group id="motCleLabelV1" title='Mot clé du "Libellé de VRT 1" des RSP'
                        *ngIf="showGridRapproTypeCpam">
                        <input type="text" id="input_motCleLabelV1" [(ngModel)]="filtersCpam.motCleLabelV1"
                            (keydown.enter)="onKeyDown($event)">
                    </careweb-accordion-group>
                </mat-accordion>
            </div>
        </div>

    </div>
    <div class="grid-container" id="grid">
        <div>
            <div class="row mt-3">
                <div class="col-lg-9">
                    <h2 class="title-border">Admin du dictionnaire de rappro. auto
                    </h2>
                </div>
            </div>
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" [destroyOnHide]="false" (navChange)="onTabChange($event)">
                <li [ngbNavItem]="1">
                    <button ngbNavLink (click)="initRulesGridData()">Rappro. sans clés</button>
                    <ng-template ngbNavContent>
                        <div id="ongletRappro" class="tab-pane fade show active  mt-4">
                            <div class="row grid-top-btn">
                                <div class="col-md-4 filter">
                                    <button type="button" name="btn_toggle_filter" id="filtre_rapp_Sans"
                                        class="btn btn-primary small left-open" [hidden]="showFilter"
                                        (click)="toggleFilterDisplay()">
                                        <i class="fa fa-sliders"></i>
                                        Filtrer
                                    </button>
                                    <careweb-pluralize *ngIf="showGridRappro" class="filter-count"
                                        [messageKey]="'Résultat(s) trouvé(s)'"
                                        [count]="rulesGridHelper.totalRowCount"></careweb-pluralize>
                                </div>
                                <div class="col-md-8 conf-col">
                                    <div class="careweb-toolbar">
                                        <button type="button" id="button_add" name="add-rule" class="btn btn-primary small"
                                            (click)="openDictRuleModal('Ajouter une nouvelle règle du dictionnaire')">
                                            Ajouter
                                        </button>
                                        <careweb-pagination-size id="v_rule_paging" [pageSizes]="[10, 20,30, 50, 100]"
                                            [(ngModel)]="gridDataRaw.size"
                                            (paginationSizeChange)="onPaginationSizeChange($event)">
                                        </careweb-pagination-size>
                                        <span class="btn btn-action small" id="reset_filters" tooltip="Réinitialiser l'affichage"
                                            placement="top" container="body" name="spn_reset_grid"
                                            (click)="resetGridState()">
                                            <i class="fa fa-refresh"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row grid-top-filter">
                                <div class="col-md-12">
                                    <careweb-filter-label *ngFor="let filter of filterList"
                                        (closeFilter)="removeFilter(filter.id)">
                                        <span id="filter.value" *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
                                    </careweb-filter-label>
                                </div>
                            </div>
                            <div class="row highlight-row">
                                <div class="col-md-12 relative">
                                    <ag-grid-angular style="width: 100%" class="ag-theme-balham"
                                        [gridOptions]="rulesGridHelper.gridOptions">
                                    </ag-grid-angular>
                                    <careweb-pagination [gridApi]="rulesGridHelper.gridApi"></careweb-pagination>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <button id="init_rules" ngbNavLink (click)="initRulesCpamGridData()"><span *ngIf="countDisabledCPAM" class="counter-badge">{{countDisabledCPAM}}</span> Rappro. avec clés de type CPAM</button>
                    <ng-template ngbNavContent>
                        <div id="ongletType" class="tab-pane fade show active  mt-4">
                            <div id="a" class="row grid-top-btn">
                                <div class="col-md-4 filter">
                                    <button type="button" id="id_Filter" name="btn_toggle_filter"
                                        class="btn btn-primary small left-open" [hidden]="showFilter"
                                        (click)="toggleFilterDisplay()">
                                        <i class="fa fa-sliders"></i>
                                        Filtrer
                                    </button>
                                    <careweb-pluralize id="id_results" *ngIf="showGridRapproTypeCpam" class="filter-count"
                                        [messageKey]="'Résultat(s) trouvé(s)'"
                                        [count]="rulesGridHelperCpam.totalRowCount"></careweb-pluralize>
                                </div>
                                <div class="col-md-8 conf-col">
                                    <div class="careweb-toolbar">
                                        <button type="button" name="add-rule" id="button_add" class="btn btn-primary small"
                                            (click)="openDictRuleCpamModal('Ajouter une nouvelle règle du dictionnaire')">
                                            Ajouter
                                        </button>
                                        <careweb-pagination-size id="dv_rule_paging" [pageSizes]="[10, 20,30, 50, 100]"
                                            [(ngModel)]="gridDataRaw2.size"
                                            (paginationSizeChange)="onPaginationSizeChangeTypeCpam($event)">
                                        </careweb-pagination-size>
                                        <span class="btn btn-action small" id="reset_filters" tooltip="Réinitialiser l'affichage"
                                            placement="top" container="body" name="spn_reset_grid"
                                            (click)="resetGridStateCpam()">
                                            <i class="fa fa-refresh"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="ab" class="row grid-top-filter">
                            <div class="col-md-12">
                                <careweb-filter-label *ngFor="let filter of filterCpamList"
                                    (closeFilter)="removeFilter(filter.id)">
                                    <span id="id_labelle" *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
                                </careweb-filter-label>
                            </div>
                        </div>
                        <div class="row highlight-row">
                            <div class="col-md-12 relative">

                                <div *ngIf="showAlert && countDisabledCPAM > 0" class="alert alert-info">
                                    De nouvelles suggestions de mots clés <b>CPAM</b> ont été détectées.
                                    Vous pouvez les intégrer dans votre dictionnaire de rapprochement automatique en cliquant sur l'icône « Ajouter ».
                                    <button (click)="showAlert = false" id="hide_alert" type="button" class="close position-absolute" style="top: 10px; right: 10px;" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <ag-grid-angular style="width: 100%" class="ag-theme-balham"
                                    [gridOptions]="rulesGridHelperCpam.gridOptions">
                                </ag-grid-angular>
                                <careweb-pagination [gridApi]="rulesGridHelperCpam.gridApi"></careweb-pagination>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
</div>