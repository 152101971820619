<div class="x-large-careweb-container">
  <section class="form-content">
    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
      <section class="password-section col-sm-6">
        <div class="col-form-label">Ancien mot de passe</div>
        <div class="form-group">
          <input type="password" class="form-control" id="actualPassword" name="actualPassword"
            formControlName="actualPassword" autocomplete="actualPassword" />
          <span toggle="#actualPassword" class="fa fa-eye field-icon" (mousedown)="showPassword('actualPassword')"
            (mouseup)="hidePassword('actualPassword')"></span>
        </div>
        <div class="col-form-label">Nouveau mot de passe</div>
        <div class="form-group">
          <input type="password" class="form-control" id="newPassword" name="newPassword" formControlName="newPassword"
            required minlength="5" maxlength="20" carewebPwdValid />
          <span toggle="#newPassword" class="fa fa-eye field-icon" (mousedown)="showPassword('newPassword')"
            (mouseup)="hidePassword('newPassword')"></span>
        </div>
        <div class="col-form-label">Confirmation mot de passe</div>
        <div class="form-group">
          <input type="password" class="form-control" id="confirmPassword" name="confirmPassword"
            formControlName="confirmPassword" required minlength="5" maxlength="20" carewebPwdValid />
          <span toggle="#confirmPassword" class="fa fa-eye field-icon" (mousedown)="showPassword('confirmPassword')"
            (mouseup)="hidePassword('confirmPassword')"></span>
        </div>
        <div class="button-bar control mb-3">
          <button type="button" class="btn btn-default me-3" (click)="cancel()">
            Annuler
          </button>
          <!-- Bouton -->
          <button type="submit" class="btn btn-primary">
            Valider
          </button>
        </div>
      </section>
      <section class="error-section col-sm-5"
        *ngIf="submitted && 
      ((formFields.newPassword.value !== formFields.confirmPassword.value) || incorrectActualPassword || passwordForm.invalid)">
        <div *ngIf="formFields.newPassword.value !== formFields.confirmPassword.value">
          <i class="fa fa-close iconClose"></i>Les deux mots de passe doivent être identiques
        </div>
        <div
          *ngIf="!formFields.actualPassword.errors?.required && !formFields.actualPassword.errors?.whitespaces && formFields.actualPassword.value === formFields.newPassword.value">
          <i class="fa fa-close iconClose"></i>Le nouveau mot de passe ne peut pas être le même que l'ancien
        </div>
        <div *ngIf="incorrectActualPassword">
          <i class="fa fa-close iconClose"></i>L'ancien mot de passe saisi n'est pas correct
        </div>
        <div *ngIf="formFields.actualPassword.errors?.required || formFields.actualPassword.errors?.whitespaces">
          <i class="fa fa-close iconClose"></i>L'ancien mot de passe est obligatoire
        </div>
        <div *ngIf="formFields.newPassword.errors?.required || formFields.newPassword.errors?.whitespaces">
          <i class="fa fa-close iconClose"></i>Le nouveau mot de passe est obligatoire
        </div>
        <div *ngIf="formFields.confirmPassword.errors?.required || formFields.confirmPassword.errors?.whitespaces">
          <i class="fa fa-close iconClose"></i>La confirmation du mot de passe est obligatoire
        </div>

        <div *ngIf="formFields.newPassword.errors?.hasLowercase==false">
          <i class="fa fa-close iconClose"></i>Le nouveau mot de passe doit comprendre au moins 1 lettre minuscule
        </div>

      </section>
    </form>
  </section>
</div>
