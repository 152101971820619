import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { isNullOrUndefined } from 'util';
import { OctService } from '../../core/services/oct.service';
import { PsFilterRaw } from '../../data/filters/psFilterRaw';
import { FseService } from '../flux/fse/fse.service';
import { LotsService } from '../flux/lots/lots.service';
import { OctPsService } from '../ps/service/octps.service';
import { LotsFilterRaw } from '../../data/filters/lots-filter';
import { formatDate } from '@angular/common';
import { FseFilterRaw } from '../../data/filters/fse-filter';
import { OctDataRaw } from '../../data/octDataRaw';
import { PsService } from './../ps/ps.service';
import { StorageService } from './../../shared/storage-service';
import { OctPsDataRaw } from './../../data/octpsDataRaw';
import { LotsDataRaw } from './../../data/lotsDataRaw';
import { FseDataRaw } from './../../data/fseDataRaw';
import { FseDataSetRaw } from './../../data/fseDataSetRaw';
import { ConvertDateFormat } from './../../core/utilities/convert-date-format.service';

@Component({
	selector: 'careweb-app-dashboard-admin',
	templateUrl: './dashboard-admin.component.html',
	styleUrls: [ './dashboard-admin.component.scss' ]
})
export class DashboardAdminComponent implements OnInit {
	chartLotsFseLine: any;
	nbInactifPs: number;
	nbDeLotsSansArl: number;
	chartLotFacture: any;
	fseImpayeVolumeRc: Number = 0;
	fseImpayeMontantRc = null;
	fseImpayeVolumeRo: Number = 0;
	fseImpayeMontantRo = null;
	dateFin: Date = new Date();
	dateDebut: Date = new Date();
	fseFilter = new FseFilterRaw();
	octDataRaw: OctDataRaw;
	hidechartLoader: boolean = false;
	hideFseRcLoader: boolean = false;
	hideFseRoLoader: boolean = false;
	hideLotsNoArlLoader: boolean = false;
	hideInactifPsLoader: boolean = false;

	showConfirmModal = false;
    modalMessage: string;
	clickedKpi: string;


	constructor(
		private octSvc: OctService,
		private lotsSvc: LotsService,
		private octPsSvc: OctPsService,
		private fseService: FseService,
		private psService: PsService,
		private storageService: StorageService,
		private convertFormatDate: ConvertDateFormat
	) {}

	ngOnInit() {
		this.octSvc.currentOctSubject.subscribe((oct) => {
			if (isNullOrUndefined(oct)) {
				oct = JSON.parse(localStorage.getItem('octCurrent'));
			}
			this.octDataRaw = oct;
		});
		this.dateFin = new Date();
		this.dateDebut.setDate(new Date().getDate() - 7);
		this.getStatistiques();
	}

	getStatistiques(): void {
		this.getLotsCountPerDay(this.octDataRaw);
		this.getInactifPs(this.octDataRaw);
		this.getLotsWithoutArl(this.octDataRaw);
		this.getFseImpayes(this.octDataRaw);
	}

	dateToString(dt) {
		const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(dt));
		const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(new Date(dt));
		const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(new Date(dt));
		return `${da}-${mo}-${ye}`;
	}

	getDaysArray(start, end) {
		for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
			arr.push(this.dateToString(dt));
		}
		return arr;
	}

	voirLotsWithoutArl(): void {
		/* const arlfilter = new ARLFilterRaw();
		arlfilter.securise = true;
		arlfilter.arp = false;
		let startDate = new Date();
		let endDate = new Date();
		startDate.setDate(startDate.getDate() - 2);
		endDate.setDate(endDate.getDate() - 30);
		arlfilter.dateReceptionPartitionTo = startDate;
		arlfilter.dateReceptionPartitionFrom = endDate;
		sessionStorage.setItem('arlFilter', JSON.stringify(arlfilter));
		this.router.navigate([ '/arl' ]); */
		const lotfilter: LotsFilterRaw = new LotsFilterRaw();
		lotfilter.securise = true;
		lotfilter.presenceArl = '3';
		lotfilter.dateEnvoieFrom = this.dateDebut;

		// pour arl sans lots tjr la date de fin est inférieur ou égale la date courante -2
		const today: Date = new Date();
		lotfilter.dateEnvoieTo = this.compareDateToXDaysAgo(this.dateFin, 2) ?
			this.dateFin :
			new Date(today.setDate(today.getDate() - 2));
		
		sessionStorage.setItem('lotsFilter', JSON.stringify(lotfilter));
		window.open('/careweb-client/lots', '_blank');
		this.showConfirmModal=false;
	}

	voirPsInactif(): void {
		let psFilter: PsFilterRaw = new PsFilterRaw();
		psFilter.isActif = false;
		psFilter.dateStartDerniereTransmission = this.dateDebut;
		psFilter.dateEndDerniereTransmission = this.dateFin;
		sessionStorage.setItem('psFilter', JSON.stringify(psFilter));
		window.open('/careweb-client/ps', '_blank');
		this.showConfirmModal=false;
	}

	getLotsCountPerDay(oct: OctDataRaw): void {
		this.hidechartLoader = false;
		const lotCriteria: LotsDataRaw = new LotsDataRaw();
		lotCriteria.idOct = oct.idOct;
		lotCriteria.dateReceptionPartitionFrom = this.convertFormatDate.getFormatDateFr(this.dateDebut);
		lotCriteria.dateReceptionPartitionTo =  this.convertFormatDate.getFormatDateFr(this.dateFin);
		
		this.lotsSvc.countLotsAndFactureByDay(lotCriteria).subscribe(
			(data) => {
				var dates = [];
				var lots = [];
				var factures = [];
				data.forEach((ele) => {
					dates.push(formatDate(ele[0], 'dd/MM/yy', 'fr-FR'));
					lots.push(ele[1]);
					factures.push(ele[2]);
				});

				this.configChartLotFactureByDate(dates, lots, factures);
			}
		);
	}

	getInactifPs(oct: OctDataRaw): void {
		this.hideInactifPsLoader = false;
		const octPs: OctPsDataRaw = new OctPsDataRaw();
		octPs.idOct = oct.idOct;
		octPs.actif = false;
		octPs.derniereTransmissionFrom = this.dateDebut;
		octPs.derniereTransmissionTo = this.dateFin;

		this.octPsSvc.getInactifPs(octPs).subscribe((data) => {
			this.nbInactifPs = data;
			this.hideInactifPsLoader = true;
		});
	}

	getLotsWithoutArl(oct: OctDataRaw): void {
		if (this.compareDateToXDaysAgo(this.dateDebut, 2)) {
			this.hideLotsNoArlLoader = false;
			const lotCriteria: LotsDataRaw = new LotsDataRaw();
			lotCriteria.idOct = oct.idOct;
			lotCriteria.dateEnvoiePartRoFrom = this.convertFormatDate.getFormatDateFr(this.dateDebut);
			lotCriteria.dateEnvoiePartRoTo = this.compareDateToXDaysAgo(this.dateFin, 2) ?
				this.convertFormatDate.getFormatDateFr(this.dateFin) :
				this.convertFormatDate.getFormatDateFr(new Date().setDate(new Date().getDate() - 2));

			this.lotsSvc.countLotsWithoutArls(lotCriteria).subscribe((data) => {
				this.nbDeLotsSansArl = data;
				this.hideLotsNoArlLoader = true;
			});
		} else {
			this.nbDeLotsSansArl = 0;
			this.hideLotsNoArlLoader = true;
		}
	}

	getFseImpayes(oct: OctDataRaw): void {
		const fseDataRaw: FseDataRaw = new FseDataRaw();
		fseDataRaw.idOct = oct.idOct;
		fseDataRaw.dateLotRecuFrom = this.convertFormatDate.getFormatDateFr(this.dateDebut);

		// Factures impayées AMC
		if (this.compareDateToXDaysAgo(this.dateDebut, 15)) {
			this.hideFseRcLoader = false;
			fseDataRaw.dateLotRecuTo = this.compareDateToXDaysAgo(this.dateFin, 15) ?
				this.convertFormatDate.getFormatDateFr(this.dateFin) :
				this.convertFormatDate.getFormatDateFr(new Date().setDate(new Date().getDate() - 15));
			
			this.fseService.getFseRcImpayeeAdmin(fseDataRaw).subscribe((data: FseDataSetRaw) => {
				this.fseImpayeVolumeRc = data.fseRcImpayeCount;
				this.fseImpayeMontantRc = data.fseRcImpayeSum !== null && data.fseRcImpayeSum !== undefined ?
					this.formatCurrency(data.fseRcImpayeSum) :
					'0';
				this.hideFseRcLoader = true;
			});
		} else {
			this.fseImpayeVolumeRc = 0;
			this.fseImpayeMontantRc = 0;
			this.hideFseRcLoader = true;
		}

		// Factures impayées AMO
		if (this.compareDateToXDaysAgo(this.dateDebut, 7)) {
			this.hideFseRoLoader = false;
			fseDataRaw.dateLotRecuTo = this.compareDateToXDaysAgo(this.dateFin, 7) ?
				this.convertFormatDate.getFormatDateFr(this.dateFin) :
				this.convertFormatDate.getFormatDateFr(new Date().setDate(new Date().getDate() - 7));
			
			this.fseService.getFseRoImpayeeAdmin(fseDataRaw).subscribe((data: FseDataSetRaw) => {
				this.fseImpayeVolumeRo = data.fseRoImpayeCount;
				this.fseImpayeMontantRo = data.fseRoImpayeSum !== null && data.fseRoImpayeSum != undefined ?
					this.formatCurrency(data.fseRoImpayeSum) :
					'0';
				this.hideFseRoLoader = true;
			});
		} else {
			this.fseImpayeVolumeRo = 0;
			this.fseImpayeMontantRo = 0;
			this.hideFseRoLoader = true;
		}
	}

	private configChartLotFactureByDate(dates: any[], lots: any[], factures: any[]) {
		const ctx = this;
		if (
			this.chartLotFacture !== null && this.chartLotFacture !== undefined &&
			this.chartLotFacture.chart !== null && this.chartLotFacture.chart !== undefined
		) {
            this.chartLotFacture.chart = null;
            this.chartLotFacture.data.datasets.length = 0;
		}
		
		this.chartLotFacture = new Chart('chartLotFacture', {
			type: 'line',
			data: {
				labels: dates,
				datasets: [
					{
						label: 'Lots',
						backgroundColor: '#21b8cf',
						borderColor: '#21b8cf',
						data: lots,
						fill: false,
						yAxisID: 'y-axis-1'
					},
					{
						label: 'Factures',
						backgroundColor: '#9e9e9e',
						borderColor: '#9e9e9e',
						data: factures,
						fill: false,
						yAxisID: 'y-axis-2'
					}
				]
			},
			options: {
				title: {
					display: false
				},
				legend: {
					position: 'bottom',
					labels: {
						fontSize: 17
					}
				},
				tooltips: {
					mode: 'index',
					intersect: false
				},
				responsive: true,
				scales: {
					yAxes: [
						{
							type: 'linear',
							display: true,
							position: 'left',
							id: 'y-axis-1'
						},
						{
							type: 'linear',
							display: true,
							position: 'right',
							id: 'y-axis-2'
						}
					]
				},
				animation: {
                    onComplete: function() {
						ctx.hidechartLoader = true;
                    }
                }
			}
		});
	}

	voirFactureImpayeRC() {
		this.fseFilter = new FseFilterRaw();
		this.fseFilter.dateLotRecuFrom = this.dateDebut;

		// pour FSE Impaye tjr la date de fin est inférieur ou égale la date courante -15
		const today: Date = new Date();
		this.fseFilter.dateLotRecuTo = this.compareDateToXDaysAgo(this.dateFin, 15) ?
			this.dateFin :
			new Date(today.setDate(today.getDate() - 15));

		this.fseFilter.presenceRspRc = false;
		this.fseFilter.partExtraite = ['RC'];
		sessionStorage['fseFilters'] = JSON.stringify(this.fseFilter);
		window.open('/careweb-client/fse', '_blank');
		this.showConfirmModal=false;
	}

	voirFactureImpayeRO() {
		this.fseFilter = new FseFilterRaw();
		this.fseFilter.dateLotRecuFrom = this.dateDebut;

		// pour FSE Impaye tjr la date de fin est inférieur ou égale la date courante -7
		const today: Date = new Date();
		this.fseFilter.dateLotRecuTo = this.compareDateToXDaysAgo(this.dateFin, 7) ?
			this.dateFin :
			new Date(today.setDate(today.getDate() - 7));

		this.fseFilter.presenceRspRo = false;
		this.fseFilter.partExtraite = ['RO'];
		sessionStorage['fseFilters'] = JSON.stringify(this.fseFilter);
		window.open('/careweb-client/fse', '_blank');
		this.showConfirmModal=false;
	}

	formatCurrency = (montant): string => {
		if (!isNullOrUndefined(montant)) {
			return montant / 100 + ' ';
		} else {
			return '0.00 ';
		}
	};


	openModalConfim(clickedKpi: string): void {
		this.clickedKpi = clickedKpi ;
		let numeroPs = localStorage.getItem('numeroPs');
		if (numeroPs) {
			this.showConfirmModal = true;
			this.modalMessage = `Le filtre Hotline est actuellement activé, en poursuivant cette action, ce filtre sera désactivé.
			Souhaitez-vous poursuivre ?`;
		} else {
			this.voirKpiDetail();		
		}		
	}


    showModal(showModal: boolean): void {
		this.showConfirmModal = showModal;
	}

    confirmModal(): void {
		this.storageService.remove('numeroPs', false);
		this.storageService.remove('idPs', false);
		this.storageService.remove('current_ps', false);
		this.psService.eventPsSelected.emit(this.storageService.get('numeroPs', false));
		this.voirKpiDetail();
	}

	voirKpiDetail(): void {
		switch (this.clickedKpi) {
			case 'lotsWithoutArl' : this.voirLotsWithoutArl();
			break;
			case 'factureImpayeRC' : this.voirFactureImpayeRC();
			break;
			case 'factureImpayeRO' : this.voirFactureImpayeRO();
			break;
			case 'psInactif' : this.voirPsInactif();
			break;
		}
	}

	compareDateToXDaysAgo(date: Date, days: number): boolean {
		return Math.abs((new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24)) >= days;
	}

	// Sélécteur Période
	onDateFilterChange($event) {
		if ($event) {
			this.dateDebut = $event.startDate;
			this.dateFin = $event.endDate;
			this.getStatistiques();
		}
	}
}
