<div class="modal modal-show fade show" id="modalPslogiciel" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalLabel">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header pb-1">
          <h3 class="modal-title">{{title}}</h3>
          <button class="close" name="btn_display_filter" id="close_support_modal" data-dismiss="modal" type="button" title="Fermer"
                  (click)="closeModal()"><span aria-hidden="true">×</span></button>
        </div>
        <div class="modal-body pb-0 mt-4" >
          <div class="row mb-2">
            <form [formGroup]="form" (ngSubmit)="onSubmit()" class="col-12">
              <div class="row">
                <div class="col-4 col-form-label">Adresse de contact  : </div>
                <div class="col-8 form-group">
                  <input type="email" class="form-control" id="email" formControlName="email"
                      maxlength="320"
                         onkeypress="if(this.value.length == 255) return false;"
                         [ngClass]="{ 'is-invalid': submitted && formFields.email.errors }" />
                  <div *ngIf="submitted && formFields.email?.errors" class="invalid-feedback">
                    <div *ngIf="formFields.email?.errors.required || formFields.email?.errors.whitespaces">
                      Ce champ est obligatoire
                    </div>
                    <div *ngIf="formFields.email?.errors?.pattern">
                      Format de l'email est incorrect
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" id="close_modal" (click)="closeModal()" class="btn btn-default">Annuler</button>
          <button type="button" id="save" (click)="onSubmit()" class="btn btn-primary" >Valider</button>
        </div>

      </div>
    </div>
  </div>

</div>
