<!-- les choix Exacte et Plage-->
<div class="accordion-content" *ngIf="isExactePlageChoice">
    <div style="display:inline-flex;margin-right:10px">
        <input type="radio" id="radioExacte_{{id}}" name="choix_{{id}}" class="me-1" value="EXACTE" [(ngModel)]="filterChoice" />
        <label for="radioExacte_{{id}}">Recherche exacte</label>
    </div>
    <div style="display:inline-flex">
        <input type="radio" id="radioPlage_{{id}}" name="choix_{{id}}" class="me-1" value="PLAGE" [(ngModel)]="filterChoice" />
        <label for="radioPlage_{{id}}">ou par plage :</label>
    </div>
</div>
<!-- Exacte-->
<div *ngIf="isExactePlageChoice && filterChoice=='EXACTE'">
    <span class="input-group" tooltip="Indiquer une date" triggers="" #popDebut="bs-tooltip"
                placement="top">
                <input type="text" class="form-control" id="dateExacte" name="dateExacte" #dpExacte="bsDatepicker" bsDatepicker
                    [(ngModel)]="modelExacte" size="25" placement="right" [maxDate]="maxDate"
                    (bsValueChange)="onModelExacteChange($event)" (change)="onChangeInputExacte($event)"
                    (input)="onModelExacteChange($event)" [bsConfig]="bsConfig"
                    placeholder="Choisir une date" autocomplete="off" (keydown.enter) = "onKeyEnter()" />
                <span class="input-group-btn" (click)="dpExacte.show()">
                    <label class="btn btn-secondary btn-sm" for="dateExacte" style="height: 38px; width: 55px">
                        <i class="fa fa-calendar"></i>
                    </label>
                </span>
            </span>
</div>
<!-- Par plage-->
<div *ngIf="!isExactePlageChoice || (isExactePlageChoice && filterChoice=='PLAGE')">
    <div class="filter-date-input form-group checkbox-filter">
        <label>Entre le</label>
        <p class="input-group no-margin">
            <span class="input-group" id="date_interieur" tooltip="Indiquer une date antérieure" triggers="" #popDebut="bs-tooltip"
                placement="top">
                <input type="text" class="form-control" id="{{idDate}}" name="dateMin" #dpMin="bsDatepicker" bsDatepicker
                    [(ngModel)]="modelFrom" [maxDate]="modelTo ? modelTo: maxDate" size="25" placement="right"
                    (bsValueChange)="onModelFromChange($event)" (change)="onChangeInputFrom($event)"
                    (input)="onModelFromChange($event)" [bsConfig]="bsConfig"
                    placeholder="Choisir une date" autocomplete="off"
                    [ngClass]="{'date-range-error': !isDateRangeValid}"
                    (mouseover)="!isDateRangeValid ? showTooltipDebut() : null"
                    (keydown.enter) = "onKeyEnter()" />
                <span class="input-group-btn" id="dpMin" (click)="dpMin.show()">
                    <label class="btn btn-secondary btn-sm" for="{{idDate}}" style="height: 38px; width: 55px">
                        <i class="fa fa-calendar"></i>
                    </label>
                </span>
            </span>
            <label>Et le</label>
            <span class="input-group" id="datePost" tooltip="Indiquer une date postérieure" triggers="" #popFin="bs-tooltip"
                placement="bottom">
                <input type="text" class="form-control" name="dateMax" #dpMax="bsDatepicker" bsDatepicker
                    [(ngModel)]="modelTo" [minDate]="modelFrom" id="DateSupp" [maxDate]="maxDate" size="25" placement="right"
                    (bsValueChange)="onModelToChange($event)" (change)="onChangeInputTo($event)"
                    (input)="onModelToChange($event)" [bsConfig]="bsConfig"
                    placeholder="Choisir une date" autocomplete="off"
                    [ngClass]="{'date-range-error': !isDateRangeValid}"
                    (mouseover)="!isDateRangeValid ? showTooltipFin() : null" 
                    (keydown.enter) = "onKeyEnter()"/>
                <span class="input-group-btn" id="dpMax" (click)="dpMax.show()">
                    <label class="btn btn-secondary btn-sm" for="dateMax"> <i class="fa fa-calendar"></i> </label>
                </span>
            </span>
        </p>
    </div>
</div>
