import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';
import { OrganismesService } from '../../organismes/organismes/organismes.service';

@Component({
  selector: 'app-link-cell-renderer',
  template: `<a [href]="link" id="linke_organisme" target="_blank">{{ params.value }}</a>`
})
export class LinkCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  link: string = '#';
  constructor(private router: Router, private organismesService: OrganismesService) { }

  agInit(params: any): void {
    this.params = params;
    this.generateLink(params);
  }

  refresh(params: any): boolean {
    this.params = params;
    this.generateLink(params);
    return true;
  }

  generateLink(params: any): void {
    const organismeId = params.data.idOrganisme;
    sessionStorage.previouslyClickedArlId = params.node.data.idArl;
    this.organismesService.getOrganismeTypeById(organismeId).subscribe((organismeType) => {
      const urlTree = this.router.createUrlTree(['/careweb-client/organisme-details', organismeId, organismeType]);
      this.link = this.router.serializeUrl(urlTree);
    });
  }
}
