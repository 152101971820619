import { StatusConnectionOxlin } from './../../data/statusConnectionOxlin';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { CONF } from '../../core/constants';
import { RapprochementDataRaw } from '../../data/rapprochementDataRaw';
import { RappComptePsDataRaw } from '../../data/rappComptePsDataRaw';
import { catchError, map } from 'rxjs/operators';
import { FilterService } from '../../core/services/filter-service';
import { CustomSortDataRow } from './../../data/customSortDatRaw';
import { OxlinCredentials } from '../../data/oxlinCredentials';

@Injectable({
    providedIn: 'root'
})
export class RapprochementComptePsService extends FilterService{
    private readonly baseUrl = CONF.envUrl + CONF.appContext + CONF.apiBaseUrl + '/v1/rapp';
    private _oxlinUserToken: any;
    private _oxlinUserRefreshToken: any;
    public _oxlinUserWidgetUrl: any;
    public _oxlinUserAccounts: any;
    public _oxlinUserConnections: any;
    rappComptePsDataRaw: RappComptePsDataRaw;

    constructor(private httpClient: HttpClient) {
        super();
    }

    validateAllRapprochements(idRappComptePs: string): Observable<number> {
        return this.httpClient.put(`${this.baseUrl}/rapprochements/validate/all`, null, { params: new HttpParams().set('idPs', idRappComptePs) })
            .pipe(map((data:number) => data));
    }

    validateRapprochements(rapprochements: any[]): Observable<any> {
        return new Observable((obs) => {
            this.httpClient
                .post<any>(this.baseUrl + '/rapprochements/validateRapprochements', rapprochements)
                .subscribe(
                    (data) => {

                        obs.next(data);
                        obs.complete();
                    },
                    (error) => {
                        obs.error();
                    }
                );
        });
    }

    createRapprochement(rapprochementDataRaw: RapprochementDataRaw) {
        return new Observable((obs) => {
            this.httpClient
                .post<any>(this.baseUrl + '/rapprochements/createRapprochement', rapprochementDataRaw)
                .subscribe(
                    (data) => {

                        obs.next(data);
                        obs.complete();
                    },
                    (error) => {
                        obs.error(error); 
                    }
                );
        });
    }

    deleteRapprochement(rapprochementDataRaw: RapprochementDataRaw) {
        return new Observable((obs) => {
            this.httpClient
                .post<any>(this.baseUrl + '/rapprochements/deleteRapprochement', rapprochementDataRaw)
                .subscribe(
                    (data) => {

                        obs.next(data);
                        obs.complete();
                    },
                    (error) => {
                        obs.error();
                    }
                );
        });
    }

    saveRappComptePs(rappComptePsDataRaw: RappComptePsDataRaw): Observable<any> {
        return new Observable(obs => {
            this.httpClient.post<any>(this.baseUrl + '/rappComptePs', rappComptePsDataRaw)
                .subscribe((data) => {
                    obs.next(data);
                    obs.complete();
                },
                    (error) => {
                        obs.error();
                    }
                );
        });
    }

    getRapprochementComptePsById(id: any): Observable<any> {
        return new Observable((obs) => {
            this.httpClient.get<any>(this.baseUrl + '/id/' + id).subscribe(
                (data) => {

                    obs.next(data);
                    obs.complete();
                },
                (error) => {
                    obs.error();
                }
            );
        });
    }

    getRapprochementsFilteredList(criteria: any, rapprochementDataRaw: RapprochementDataRaw): Observable<any> {
        const sorts: CustomSortDataRow[] = criteria.sorts;
        return this.httpClient.post<any>(
            this.baseUrl + '/rapprochements/searchFilter' + '?page=' + criteria.page + '&size=' + criteria.size,
            {
                filters: rapprochementDataRaw,
				sorts: sorts
            }
        );
    }

    exportRapprochementsValide(criteria: any, rapprochementDataRaw: RapprochementDataRaw, fields: string[]): Observable<any> {
        const sorts: CustomSortDataRow[] = criteria.sorts;
        return this.httpClient.post<HttpResponse<Blob>>(
            this.baseUrl + '/rapprochements/export',
            {
                filters: rapprochementDataRaw,
                fields: fields,
                sorts: sorts
            },
            {
                observe: 'response' as 'body',
                responseType: 'blob' as 'json'
            }
        ).pipe(
            map((response: HttpResponse<Blob>): any => {
                return this.getFileInfosFromResponse(response);
            })
        );
    }
    exportRapprochementByEmail(criteria: any, rapprochementDataRaw: RapprochementDataRaw, fields: string[]): Observable<any> {
        const sorts: CustomSortDataRow[] = criteria.sorts;
        return this.httpClient.post<HttpResponse<Blob>>(
            this.baseUrl + '/rapprochements/export-by-email',
            {
                filters: rapprochementDataRaw,
                fields: fields,
                sorts: sorts
            },
            {
                observe: 'response' as 'body',
                responseType: 'blob' as 'json'
            }
        );
    }
    getFileInfosFromResponse(response: HttpResponse<Blob>) {
        return {
            file: new Blob([ response.body ], { type: 'application/vnd.ms-excel' }),
            fileName: 'Rapprochements-validés.xlsx'
        };
    }

    getSortParameters(sorts) {

        if (sorts && sorts.length == 1) {
            var sort = sorts[0].property;
            return '&sort=' + sort + '&direction=' + sorts[0].direction;
        } else {
            // sort par défaut
            return '&sort=dateRapprochement&direction=DESC'
        }
    }

    //OXIN /////////////////////////////////////////////////////////////////////////

getUserOxlinScope(id: any): Observable<any> {
    return new Observable((observer) => {
        this.getRapprochementComptePsById(id).subscribe((respComptePs) => {
            if (respComptePs != null) {
                this.getOxlinUserToken(respComptePs.addressEmail)
                    .then((data) => {
                        if(data){
                            this.getOxlinUserWidget().subscribe((widget) => {
                                if (widget != null) {
                                    this._oxlinUserWidgetUrl = widget._links.add_connection;
                                    observer.next(['widget', widget._links.add_connection]);
                                    this.getOxlinUserConnections().subscribe((conn) => {
                                        this._oxlinUserConnections = conn;
                                        observer.next(['connections', conn]);
                                        if(respComptePs.connectionNumber!==conn.length || respComptePs.connectionNumber===null){
                                            respComptePs.connectionNumber=conn.length;
                                            this.updateConnectionNumber(respComptePs).subscribe(
                                                () => {},
                                                (error) => {
                                                    console.log('Error occurred when updating number of connection oxlin');
                                                }
                                            );
                                            
                                        }
                                        if (conn.length != 0 && conn != '') {
                                            const rappComptPS = new RappComptePsDataRaw();
                                            rappComptPS.idOxlinUser = respComptePs.idOxlinUser;
                                            let statut = StatusConnectionOxlin.SUCCESS;
                                            this._oxlinUserConnections.forEach((cnx) => {
                                                if (cnx.status !== StatusConnectionOxlin.SUCCESS) {
                                                    statut = cnx.status;
                                                }
                                            });
                                            rappComptPS.statusConnectionOxlin = statut;
    
                                            this.updateStatusConnectionOxlin(rappComptPS).subscribe(
                                                () => {},
                                                (error) => {
                                                    console.log('Error occurred when updating statut connection oxlin');
                                                }
                                            );
    
                                            this.getOxlinUserAccounts().subscribe(
                                                (accounts) => {
                                                    this._oxlinUserAccounts = accounts;
                                                    observer.next(['accounts', accounts]);
                                                },
                                                (error) => {
                                                    observer.error({
                                                        title: "Les comptes liés à votre connexion ne peuvent être" +
                                                            " lus pour l'instant",
                                                        content: "Les administrateurs de Careweb ont été prévenus " +
                                                            "de la situation et sont en train de remédier au problème." +
                                                            " Si vous souhaitez plus d'information, nous vous invitons à " +
                                                            "contacter le support en cliquant <a href=''>ici</a>.",
                                                    });
                                                }
                                            );
                                        }
                                        else {
                                            observer.next(['accounts', []]);
                                        }
                                    }, (error) => {
                                        observer.error(error);
                                    });
                                
                                }
                            }, (error) => {
                                observer.error(error);
                            });
                        }
                        else{
                            observer.error('Le compte OXLIN est introuvable');
                            console.error('Le compte OXLIN est introuvable');
                        }
                       
                    });
            }
        });
    });
}


    private getOxlinUserToken(addressEmail: string) {
        let oxlinCredentials: OxlinCredentials ={
            addressEmail : addressEmail
        };

        let promise = new Promise((resolve, reject) => {
            this.httpClient
                .post<any>(this.baseUrl + '/auth', oxlinCredentials)
                .toPromise()
                .then((res) => {
                    if(res!= null){
                        this._oxlinUserToken = res.access_token;
                        this._oxlinUserRefreshToken = res.refresh_token
                    }else{
                        this._oxlinUserToken = null;
                        this._oxlinUserRefreshToken = null;
                    }
                    localStorage.setItem('oxlin_token', this._oxlinUserToken);
                    localStorage.setItem('oxlin_refresh_token', this._oxlinUserRefreshToken);

                    resolve(res);
                },
                    (error) => {
                        localStorage.removeItem('oxlin_token');
                        localStorage.removeItem('oxlin_refresh_token');
                        reject(error);
                    });
        });

        return promise;
    }

    public createOxlinUser(token: string, numeroPs: string): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/oxlinUser?token=' + token + '&numeroPs=' + numeroPs, '');
    }

    public deleteOxlinUser(idUser: number): Observable<any> {
        return this.httpClient.delete<any>(this.baseUrl + '/oxlinUser/' + idUser)
    }

    public getOxlinRootUserToken(): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/token', '');
    }

    public deleteOxlinUserAccount(idAccount: string): Observable<any> {
        return this.httpClient.delete<any>(this.baseUrl + '/accounts/' + idAccount);
    }

    public getOxlinUserConnections(): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/connections', '');
    }

    public refreshConnections(): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/connections/synchronize', '');
    }

    public subscribeOxlinQueue(): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/subscribeOxlinQueue?queueParam=connections', '');
    }

    public restoreOxlinUserAccounts(idConnToRestore: string): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/connections/restore/' + idConnToRestore, '');
    }

    private getOxlinUserAccounts(): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/accounts', "");
    }

    private getOxlinUserWidget(): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/widget', '');
    }

    private updateStatusConnectionOxlin(rappComptPs: RappComptePsDataRaw)
    {
        return this.httpClient
			.put<any>(this.baseUrl + '/rappComptePs/', rappComptPs);
    }
    private updateConnectionNumber(rappComptPs: RappComptePsDataRaw)
    {
        return this.httpClient
			.put<any>(this.baseUrl + '/rappComptePsConnectionNumber/', rappComptPs);
    }
    

    public updateOxlinAccountConsent(idConnection: string, accounts: any): Observable<number> {
        return this.httpClient.put<any>(this.baseUrl + '/accountConsent/' + idConnection, accounts);
    }

    // Récupérer les infos de rapprochement selon les critéres de filtre
    getRappSuivieListByCriteriat(criteriaServer: any): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/suivi', criteriaServer);
	}

    // Récupérer les transactions a étudier pour un numeroAdeli (et selon les critéres de filtre idOct, période de recherche)
    getVrtEtudierListByCriteriat(criteriaServer: any): Observable<any> {
		return this.httpClient.post<any>(this.baseUrl + '/transactions/anomalies', criteriaServer);
	}

    //Récupérer la liste des Rapprochement Validés Pour l'exportation avec les  filtres & Tri
    exportRapprochementsListValide(criteria: any, rapprochementDataRaw: RapprochementDataRaw):Observable<any> {
        return this.httpClient.post<any>(
            this.baseUrl +
            '/rapprochements/export?'+
            this.getSortParameters(criteria.sorts).substring(1),
            rapprochementDataRaw
        );
    }

    replayProcRappAEtudier(replayStartDate: string, replayEndDate: string, idPs : number):Observable<any> {
        let url = this.baseUrl +'/rapprochements/replayProcRapproAEtudier?replayStartDate='+ replayStartDate +'&replayEndDate='+ replayEndDate;
        if(idPs) {
            url+='&idPs=' + idPs;
        }
        let octSelected = JSON.parse(localStorage.getItem('octCurrent'));
        if(octSelected) {
            url+='&idOct=' + octSelected.idOct;
        }
        return this.httpClient.post<any>(url,null);
    }

    getMissingTransaction(missingStartDate: string, missingEndDate: string, numeroAdeli : String ):Observable<any> {
        return this.httpClient.post<any>(
            this.baseUrl +
            '/missing/?missingStartDate='+ missingStartDate +'&missingEndDate='+ missingEndDate +'&numeroAdeli=' + numeroAdeli,null
            );
    }

    getRapprochementById(id: any): Observable<any> {
        return this.httpClient.get<any>(this.baseUrl + '/rapprochements/' + id);
    }

    getPerfDashboard(payload: any): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/rapprochements/dashboard/organism', payload);
    }

    getPerfDashboardByPs(organism:string, payload: any): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/rapprochements/dashboard/ps/' + organism, payload);
    }
    getPSList(): Observable<any> {
        return this.httpClient
            .get<any>(this.baseUrl+ '/rapprochements/ps/list')
            .pipe(
                map((data: any) => data),
                catchError(error => {
                    console.error('Erreur lors de la récupération de la liste des PS:', error);
                    return throwError(() => error);
                })
            );
    }

    generateOxlinTransactions(id: any): Observable<any> {
        return this.httpClient.get<any>(this.baseUrl + '/rapprochements/oxlin/' + id);
    }

}