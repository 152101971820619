<careweb-help></careweb-help>
<div class="full-careweb-container">
	<div id="filter" class="filter-container" carewebFilterMinHeight>
		<div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
			<careweb-accordion-header-buttons [disablesearch]="disableSearch()" (closeFilters)="toggleFilterDisplay()"
				(resetFilters)="resetAllFilter()" (launchSearch)="launchSearch()">
			</careweb-accordion-header-buttons>
			<div class="filter-content">
				<mat-accordion displayMode="flat" class="accordion-filter">
					<!-- Numéro de PS -->
					<careweb-accordion-group id="numero_ps" title="Numéro de PS" [hidden]="!existGroupNumeroPS()">
						<input type="text" id="input_num_ps" [(ngModel)]="filters.numeroPs"
							(keydown.enter)="onKeyDown($event)" onKeyPress="if(this.value.length==9) return false;"
							pattern="^[0-9]" min="0" step="1" max="999999999"
							oninput="this.value=this.value.replace(/[^\d]/,'');"
							onpaste="if(this.value.length==9) return false;" [hidden]="!isAuthorized()" placeholder="000000000" />

						<!--Afficher Numéro de PS avec ComboBox si l'utilisateur PS-->
						<ng-select name="numeroPScomboBox" id="numeroPScomboBox" placeholder="Numéro de PS"
							[searchable]="false" [(ngModel)]="filters.numeroPScomboBox" appendTo=".filter-container"
							[hidden]="isAuthorized()">
							<ng-option *ngFor="let numeroPScomboBox of psDelegueListFilter"
								[value]="numeroPScomboBox.numeroPsDelegue">
								{{numeroPScomboBox.numeroPsDelegue}}
							</ng-option>
						</ng-select>
					</careweb-accordion-group>
					<!-- Numéro du lot -->
					<careweb-accordion-group id="numero_lot" title="Numéro du lot">
						<input type="text" id="input_num_lot_from" class="form-control"
							onKeyPress="if(this.value.length==3) return false;" [(ngModel)]="filters.numLot" maxlength="3"
							onkeyup="this.value=this.value.replace(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,'')"
							onkeydown="this.value=this.value.replace(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,'')"
							(keydown.enter)="onKeyDown($event)"
							oninput="this.value=this.value.replace(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,'');"
							onpaste="if(this.value.length==3) return false;" />
					</careweb-accordion-group>
					<!-- Numéro FSE -->
					<careweb-accordion-group id="numero_fse" title="Numéro de FSE">
						<input type="text" id="input_numero_fse" [(ngModel)]="filters.numFacture"
							(keydown.enter)="onKeyDown($event)" onKeyPress="if(this.value.length==9) return false;"
							pattern="^[0-9]" min="0" step="1" max="999999999"
							oninput="this.value=this.value.replace(/[^\d]/,'');"
							onpaste="if(this.value.length==9) return false;" placeholder="000000000" />
					</careweb-accordion-group>
					<!-- Lots reçus -->
					<careweb-accordion-group id="lots_envoyes" title="Lots reçus" #dateLotAccordion>
						<careweb-date-from-to-filter labelFrom="entre le" [(modelFrom)]="filters.dateReceptionLotFrom"
							[(modelTo)]="filters.dateReceptionLotTo" [(modelExacte)]="filters.dateReceptionLotExacte" 
							[maxDate]="null" [isExactePlageChoice]="true" id="lots_envoyes"
							(filterChange)="this.onChoiceChange($event, 'dateReceptionLot')"
							(dateRangeValidityChange) = "checkDateRangeValidity($event, 'dateLot')" (onEnter) = "onKeyDown($event)" #dateLot>
						</careweb-date-from-to-filter>
					</careweb-accordion-group>
					<!-- Code organisme émetteur -->
					<careweb-accordion-group id="code_organisme_emetteur" title="Code organisme">
						<input type="text" id="input_organisme_emetteur" [(ngModel)]="filters.codeOrganisme"
							(keydown.enter)="onKeyDown($event)" onKeyPress="if(this.value.length==10) return false;"
							pattern="^[0-9]" min="0" step="1" max="9999999999"
							oninput="this.value=this.value.replace(/[^\d]/,'');"
							onpaste="if(this.value.length==9) return false;" />
					</careweb-accordion-group>
					<!-- Libellé organisme émetteur -->
					<careweb-accordion-group id="libelle_organisme_emetteur" title="Libellé organisme">
						<input type="text" id="input_libelle_organisme_emetteur" [(ngModel)]="filters.libelleOrganisme"
							onKeyPress="if(this.value.length==13) return false;" (keydown.enter)="onKeyDown($event)" />
					</careweb-accordion-group>
					<!-- Libellé de virement -->
					<careweb-accordion-group id="libelle_virement" title="Libellé de virement">
						<input type="text" id="input_libelle_virement" [(ngModel)]="filters.libelleVirement1"
							   (keydown.enter)="onKeyDown($event)" />
					</careweb-accordion-group>
					<!-- RSP reçus -->
					<careweb-accordion-group id="date_reception_rsp" title="RSP reçus" #dateRspAccordion>
					   	<careweb-date-from-to-filter labelFrom="entre le" [(modelFrom)]="filters.dateReceptionRspFrom"
							[(modelTo)]="filters.dateReceptionRspTo" [(modelExacte)]="filters.dateReceptionRspExacte" 
							[maxDate]="null" [isExactePlageChoice]="true" id="date_reception_rsp"
							(filterChange)="this.onChoiceChange($event, 'dateReceptionRsp')"
							(dateRangeValidityChange) = "checkDateRangeValidity($event, 'dateRsp')" (onEnter) = "onKeyDown($event)" #dateRsp>
						</careweb-date-from-to-filter>
					</careweb-accordion-group>
					<!-- Statut du RSP -->
					<careweb-accordion-group id="statut_rsp" title="Statut du RSP">
						<ng-select name="statut_rsp" id="statutRspSelector" placeholder="Statut du RSP"
							[searchable]="false" [(ngModel)]="filters.statutRsp" appendTo=".filter-container"
							(change)="onChange($event)" (keydown.enter)="onKeyDown($event)">
							<ng-option id="option_paiement" [value]="'0'">Paiement</ng-option>
							<ng-option id="option_rejet" [value]="'1'">Rejet</ng-option>
							<ng-option id="option_differe" [value]="'2'">Différé</ng-option>
							<ng-option id="option_indu" [value]="'3'">Indu</ng-option>
						</ng-select>
					</careweb-accordion-group>
					<!-- Libellé du rejet  -->
					<careweb-accordion-group id="lib_rejet" title="Libellé du rejet" [hidden]="!showLibelleRejet">
						<input type="text" id="input_lib_rejet" [(ngModel)]="filters.libelleRejet"
							(keydown.enter)="onKeyDown($event)" />
					</careweb-accordion-group>
					<!-- Rapprochement -->
					<careweb-accordion-group id="rapprochement" title="Rapprochement">
						<ng-select name="rapprochement" id="rapprochement" placeholder="Rapprochement"
							[searchable]="false" [(ngModel)]="filters.rapprochement" appendTo=".filter-container"
							(keydown.enter)="onKeyDown($event)">
							<ng-option id="option_"  [value]="''"> </ng-option>
							<ng-option id="option_oui" [value]="true">Oui</ng-option>
							<ng-option id="option_non"[value]="false">Non</ng-option>
						</ng-select>
					</careweb-accordion-group>
					<!-- Rapprochement Bancaire -->
					<careweb-accordion-group *ngIf="hasRapproBancaireAccess()" id="rapproBancaire" title="Rappro. bancaire">
						<ng-select name="rapproBancaire" id="rapproBancaire" placeholder="Rappro. bancaire"
							[searchable]="false" [(ngModel)]="filters.rapproBancaire" appendTo=".filter-container"
							(keydown.enter)="onKeyDown($event)">
							<ng-option id="option_"  [value]="''"> </ng-option>
							<ng-option id="option_oui" [value]="true">Oui</ng-option>
							<ng-option id="option_non" [value]="false">Non</ng-option>
						</ng-select>
					</careweb-accordion-group>
					<!-- Avec Virement -->
					<careweb-accordion-group id="avecVirement" title="Avec Virement">
						<ng-select name="avecVirement" id="avecVirement" placeholder="Avec Virement"
							[searchable]="false" [(ngModel)]="filters.avecVirement" appendTo=".filter-container"
							(keydown.enter)="onKeyDown($event)">
							<ng-option id="option_" [value]="''"> </ng-option>
							<ng-option id="option_oui" [value]="true">Oui</ng-option>
							<ng-option id="option_non"[value]="false">Non</ng-option>
						</ng-select>
					</careweb-accordion-group>
				</mat-accordion>
			</div>
		</div>
	</div>
	<div class="grid-container" id="grid">
		<h2 class="title-border mt-3">Liste des RSP
			<careweb-open-help code="help-003"></careweb-open-help>
		</h2>
		<div>
			<section>
				<div class="row grid-top-btn">
					<div class="col-md-4 filter">
						<button type="button" name="btn_toggle_filter" class="btn btn-primary small left-open"
							[hidden]="showFilter" id="filter_rsp" (click)="toggleFilterDisplay()">
							<i class="fa fa-sliders"></i>
							Filtrer
						</button>
						<careweb-pluralize *ngIf="totalResultsHidden" class="filter-count"
							[messageKey]="'Résultat(s) trouvé(s)'" id="resulta_rsp" [count]="gridHelper.totalRowCount">
						</careweb-pluralize>
					</div>
					<div class="col-md-8 conf-col">
						<!-- careweb-toolbar -->
						<div class="careweb-toolbar">
							<div [tooltip]="gridHelper.isAvailableData() ? 'Exporter les résultats (.xlsx)' : 'Aucune ligne à exporter, veuillez affiner votre recherche'"
								 placement="top" container="body">
								<span class="btn btn-action small" [class.disabled]="!gridHelper.isAvailableData()"
									  name="btn_export_csv" id="export_rsp" (click)="exportFileXlsxModal()">
								<i class="fa fa-file-excel-o"></i>
								</span>
							</div>
							<!-- Gérer les colonnes -->
							<careweb-column-selector (itemSelect)="selectColumnEvent($event)"
								(itemDeselect)="deselectColumnEvent($event)">
							</careweb-column-selector>
							<careweb-pagination-size id="dv_rsp_paging" [pageSizes]="[10, 20, 30, 50, 100]"
								[(ngModel)]="gridDataRaw.size"
								(paginationSizeChange)="onPaginationSizeChange($event)">
							</careweb-pagination-size>
							<span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top"
								container="body" name="spn_reset_grid" id="resetGridState" (click)="resetGridState()">
								<i class="fa fa-refresh"></i>
							</span>
						</div>
						<!-- fin careweb-toolbar -->
					</div>
				</div>
                <app-alert [export]="export" [exportState]="exportState" id="close_alert" (close)="closeAlert()"></app-alert>
				<div class="row grid-top-filter">
					<div class="col-md-12">
						<careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter.id)">
							<span *ngIf="filter" id="filter_label">{{ filter.label}}: {{filter.value }}</span>
						</careweb-filter-label>
					</div>
				</div>
				<div class="row highlight-row">
					<div class="col-md-12 relative">
						<ag-grid-angular style="width: 100%" class="ag-theme-balham"
							[gridOptions]="gridHelper.gridOptions">
						</ag-grid-angular>
						<careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
					</div>
				</div>
			</section>
		</div>
	</div>
</div>
