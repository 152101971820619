<div class="x-large-careweb-container">
    <section class="form-content">
        <!-- Titre H2 -->
        <div class="row form-title">
            <h2 class="col-md-6">Ajouter un nouvel éditeur</h2>
            <div class="col-md-6 text-title">
                <button class="btn btn-default btn-lg" type="button" (click)="retour()">Retour</button>
            </div>
        </div>

        <div class="form-block">
            <!-- Titre -->
            <div class="row">
                <div class="col">
                    <h3 class="title-border">Détails</h3>
                </div>
            </div>
            <form [formGroup]="editeurForm">
                <fieldset class="container-fluid">
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Raison sociale *</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="raisonSociale" formControlName="raisonSociale"
                                [ngClass]="{ 'is-invalid': editeurFormSubmitted && editeurFormFields.raisonSociale.errors }"
                                (change)="onChangeRaisonSoc()" onKeyPress="if(this.value.length==50) return false;"
                                onpaste="if(this.value.length==50) return false;" maxlength="50" required />
                            <div class="invalid-feedback">
                                <span *ngIf="editeurFormSubmitted && editeurFormFields.raisonSociale.errors?.required">
                                    Ce champ est obligatoire
                                </span>
                                <span
                                    *ngIf="editeurFormSubmitted && editeurFormFields.raisonSociale.errors?.raisonSocExist">
                                    Raison sociale existe déjà
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Adresse 1</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="adresse1" formControlName="adresse1"
                                onKeyPress="if(this.value.length==50) return false;"
                                onpaste="if(this.value.length==50) return false;" maxlength="50" /></div>
                        <div class="col-sm-2 col-form-label">Adresse 2</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="adresse2" formControlName="adresse2"
                                onKeyPress="if(this.value.length==50) return false;"
                                onpaste="if(this.value.length==50) return false;" maxlength="50" /></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Code postal</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="codePostal" formControlName="codePostal"
                                   cawCodePostal /></div>
                        <div class="col-sm-2 col-form-label">Ville</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="ville" formControlName="ville"
                                onKeyPress="if(this.value.length==50) return false;"
                                onpaste="if(this.value.length==50) return false;" maxlength="50" /></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Téléphone</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="telephone" formControlName="telephone"
                                   phoneNumberDirective />
                        </div>
                        <div class="col-sm-2 col-form-label">Fax</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="fax" formControlName="fax"
                                   phoneNumberDirective />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Email</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="email" formControlName="email"
                                (paste)="$event.preventDefault()" onKeyPress="if(this.value.length==100) return false;"
                                [ngClass]="{ 'is-invalid': editeurFormSubmitted && editeurFormFields.email.errors }"
                                onpaste="if(this.value.length==100) return false;" maxlength="100" carewebEmail />
                            <div *ngIf="editeurFormSubmitted && editeurFormFields.email.errors"
                                class="invalid-feedback">
                                Le format de l'adresse email n'est pas valide
                            </div>
                        </div>
                        <div class="col-sm-2 col-form-label">Site internet</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="url" formControlName="url"
                                onKeyPress="if(this.value.length==100) return false;"
                                onpaste="if(this.value.length==100) return false;" maxlength="100" /></div>
                    </div>
                </fieldset>
            </form>
            <!-- TOOLBAR -->
            <div class="row grid-top-btn" style="margin-top:0px">
                <div class="col-md-12 conf-col">
                    <div class="sy-toolbar">
                        <!-- TOOLBAR -->
                        <div>
                            <button type="button" class="btn btn-default me-3" (click)="cancel()">
                                Annuler
                            </button>
                            <!-- Bouton -->
                            <button type="button" class="btn btn-primary" (click)="enregistrer()">
                                Enregistrer
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</div>