import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
	selector: '[carewebPwdValid]',
	providers: [ { provide: NG_VALIDATORS, useExisting: forwardRef(() => PwdValidatorDirective), multi: true } ]
})
export class PwdValidatorDirective implements Validator {
	/**
     * Implémentation de la validation.
     */
	validate(control: AbstractControl): { [key: string]: boolean } {
		const pwd = control.value;


		if (!this.hasLowercase(pwd)) {
			return {
				hasLowercase: false
			};
		}


		return null;
	}



	private hasLowercase(pwd: string): boolean {
		return !pwd || /[a-z]/.test(pwd);
	}


}
