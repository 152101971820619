<div class="full-careweb-container">
  <div class="row" style="margin: 20px">
    <div class="col-lg-3 offset-lg-9">
      <careweb-date-selecteur-periode [startDate]="dateDebut" [endDate]="dateFin" [ranges]="[7, 15, 30, 60, 90]"
        (dateFilterChange)="onDateFilterChange($event)"></careweb-date-selecteur-periode>
    </div>
  </div>
  <div class="row" style="margin: 20px">
    <div class="col-3">
      <a class="link-box">
        <div class="box-content box-content-default">
          <div class="title">
            <span>PS</span>
            <div class="subtitle text-danger">Inactifs</div>
            <div class="number" [hidden]="!hideInactifPsLoader">{{nbInactifPs}}</div>
          </div>
          <div class="row align-right">
            <div class="col-md-12 mt-5">
              <a class="more" (click)="openModalConfim('psInactif')" [hidden]="!hideInactifPsLoader">Voir</a>
            </div>
          </div>
          <div class="loader" [hidden]="hideInactifPsLoader">
            <mat-spinner diameter="70"></mat-spinner>
          </div>
        </div>
      </a>
    </div>
    <div class="col-3">
      <a class="link-box">
        <div class="box-content box-content-default">
          <div class="title">
            <span>Factures</span>
            <div class="subtitle text-danger">Impayées AMO</div>
            <div class="row" style="margin-top:40px" [hidden]="!hideFseRoLoader">
              <div class="col-md-6" style="border-right:solid 2px">
                <div class="row">
                  <div class="col-md-12">Volume</div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="number" style="font-size:1.8vw">{{fseImpayeVolumeRo}}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">Montant</div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="number"  [style.fontSize]="fseImpayeMontantRo && fseImpayeMontantRo.length > 10 ? '1.5vw' : '1.8vw'">{{fseImpayeMontantRo | number:'':'fr-FR'}}€</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-right">
              <div class="col-md-12 mt-2">
                <a class="more" (click)="openModalConfim('factureImpayeRO')" [hidden]="!hideFseRoLoader || !this.compareDateToXDaysAgo(this.dateDebut, 7)">Voir</a>
              </div>
            </div>
  
            <div class="loader" [hidden]="hideFseRoLoader">
              <mat-spinner diameter="70"></mat-spinner>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="col-3">
      <a class="link-box">
        <div class="box-content box-content-default">
          <div class="title">
            <span>Factures</span>
            <div class="subtitle text-danger">Impayées AMC</div>
            <div class="row" style="margin-top:40px" [hidden]="!hideFseRcLoader">
              <div class="col-md-6" style="border-right:solid 2px">
                <div class="row">
                  <div class="col-md-12">Volume</div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="number" style="font-size:1.8vw">{{fseImpayeVolumeRc}}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">Montant</div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="number" [style.fontSize]="fseImpayeMontantRc && fseImpayeMontantRc.length > 10 ? '1.5vw' : '1.8vw'">{{fseImpayeMontantRc | number:'':'fr-FR'}}€</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-right">
            <div class="col-md-12 mt-2">        
              <a class="more" (click)="openModalConfim('factureImpayeRC')" [hidden]="!hideFseRcLoader || !this.compareDateToXDaysAgo(this.dateDebut, 15)">Voir</a>
            </div>
          </div>
          <div class="loader" [hidden]="hideFseRcLoader">
            <mat-spinner diameter="70"></mat-spinner>
          </div>
        </div>
      </a>
    </div>
    <div class="col-3">
      <a class="link-box">
        <div class="box-content box-content-default">
          <div class="title">
            <span>Lots</span>
            <div class="subtitle text-danger">Sans ARL</div>
            <div class="number" [hidden]="!hideLotsNoArlLoader">{{nbDeLotsSansArl}}</div>
          </div>
          <div class="row align-right">
            <div class="col-md-12 mt-5">        
              <a class="more" (click)="openModalConfim('lotsWithoutArl')" [hidden]="!hideLotsNoArlLoader || !this.compareDateToXDaysAgo(this.dateDebut, 2)">Voir</a>
            </div>
          </div>
          <div class="loader" [hidden]="hideLotsNoArlLoader">
            <mat-spinner diameter="70"></mat-spinner>
          </div>
        </div>
      </a>
    </div>
  </div>

  <div class="row" style="margin-left:20px; margin-right:20px">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <a class="link-box">
            <div class="box-content box-content-default">
              <div class="title">
                <span>Activité Lots/Factures par jour</span>
                <canvas id="chartLotFacture" style="margin-top:20px" [hidden]="!hidechartLoader">
                  {{chartLotFacture}}
                </canvas>
                <div class="loader" [hidden]="hidechartLoader">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- Modal confirmation -->
<careweb-confirm-modal  [title]= "'Cette action va désactiver le filtre Hotline'" (confirmModal)="confirmModal()" (showModal)="showModal($event)" [(visible)]="showConfirmModal"
    [text]="modalMessage">
</careweb-confirm-modal>