<careweb-help></careweb-help>
<div class="full-careweb-container">
    <div class="grid-container" id="grid">
        <h2 class="title-border mt-3">Administration des services de support</h2>
        <div>
            <section>
                <div class="row grid-top-btn"></div>
            </section>
        </div>
        <div class="row highlight-row">
            <div class="col-md-12 relative">
                <ag-grid-angular style="width: 100%" class="ag-theme-balham" [gridOptions]="gridHelper.gridOptions">
                </ag-grid-angular>
                <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
            </div>
        </div>
    </div>
</div>

