import { Component} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
  selector: 'delete-rule-column',
  template: `<i [ngClass]="'fa fa-trash'" container="body" id="delete_icone" [tooltip]="'Supprimer la règle'"
    containerClass="tooltip-grid"></i>`
})
export class DeleteRuleColumnComponent implements ICellRendererAngularComp {

  constructor() { }

  agInit(params: ICellRendererParams): void {
  }

  refresh(): boolean {
		return false;
	}

}
