import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { isNullOrUndefined } from 'util';

@Component({
	selector: 'careweb-date-from-to-filter',
	templateUrl: './date-from-to-filter.html',
	styleUrls: [ './date-from-to-filter.component.scss' ]
})
export class DateFromToFilterComponent implements OnInit {
	@Input() labelFrom: string;

	@Input() modelFrom: Date;
	@Output() modelFromChange = new EventEmitter<Date>();

	@Input() modelTo: Date;
	@Output() modelToChange = new EventEmitter<Date>();

	@Input() modelExacte: Date;
	@Output() modelExacteChange = new EventEmitter<Date>();

	@Input() maxDate?: Date | null = new Date();
	datePipe: DatePipe;

	isDateRangeValid = true;

	@Output() dateRangeValidityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('popDebut') popDebut: TooltipDirective;

	@ViewChild('popFin') popFin: TooltipDirective;
	/////////////////////
	@Input() isExactePlageChoice = false;
	@Input() id: string;
	@Output() filterChange = new EventEmitter<any>();
	@Output() onEnter = new EventEmitter<any>();
	@Input() fromRsp: boolean;
	@Input() idDate: string;
	public filterChoice = 'EXACTE';

	bsConfig
	@Input() fromPs: boolean;

	constructor(private localeService: BsLocaleService) {
		defineLocale('fr', frLocale);
		this.datePipe = new DatePipe('fr-FR');
	}

	ngOnInit() {
		this.setLocale();
		this.maxDate = !isNullOrUndefined(this.maxDate) ? this.maxDate : new Date();
		if (this.isExactePlageChoice) {
			if (this.modelFrom || this.modelTo) {
				this.filterChoice = 'PLAGE';
			} else if (this.modelExacte && !this.fromRsp) {
				this.filterChoice = 'EXACTE';
			}
		}
	}

	onChangeInputFrom($event) {
		if ($event.target.value !== 'Invalid date' && $event.target.value !== 'Date invalide' && $event.target.value !== '') {
			this.modelFrom = new Date(this.datePipe.transform(new Date($event.target.value).toISOString(), 'short'));

			if (this.modelTo) {
				this.checkDateRangeValidity();
				this.showTooltipDebut();
			}
		}
		else
			if($event.target.value === 'Invalid date'){
				$event.target.value = 'Date invalide'
			}
	}

	onChangeInputTo($event) {
		if ($event.target.value !== ''&& $event.target.value !== 'Date invalide' && $event.target.value !== 'Invalid date' ) {
			this.modelTo = new Date(this.datePipe.transform(new Date($event.target.value).toISOString(), 'short'));

			if (this.modelFrom) {
				this.checkDateRangeValidity();
				this.showTooltipFin();
			}
		}
		 else
		    if($event.target.value === 'Invalid date'){
			  $event.target.value = 'Date invalide'
		    }
	}

	onChangeInputExacte($event) {
		if ($event.target.value !== 'Invalid date' && $event.target.value !== 'Date invalide'  && $event.target.value !== '') {
			this.modelExacte = new Date(this.datePipe.transform(new Date($event.target.value).toISOString(), 'short'));
		}
		else
			if ($event.target.value === 'Invalid date'){
			$event.target.value = 'Date invalide ';
			}
	}

	onModelFromChange(date: Date | Event) {
		this.emitChange(date, this.modelFromChange);
		this.modelTo ? (this.isDateRangeValid = true) : null;
		this.dateRangeValidityChange.emit(this.isDateRangeValid);
		if (date) {
			this.onChoiceChange('PLAGE');
		}
	}

	onModelToChange(date: Date | Event) {
		this.emitChange(date, this.modelToChange);
		this.modelFrom ? (this.isDateRangeValid = true) : null;
		this.dateRangeValidityChange.emit(this.isDateRangeValid);
		if (date) {
			this.onChoiceChange('PLAGE');
		}
	}

	onModelExacteChange(date: Date | Event) {
		this.emitChange(date, this.modelExacteChange);
		if (date) {
			this.onChoiceChange('EXACTE');
		}
	}

	private setLocale() {
		this.localeService.use('fr');
		this.bsConfig=this.fromPs?{withTimepicker: true, keepDatepickerOpened: true, rangeInputFormat : 'MMMM Do YYYY, h:mm:ss a', dateInputFormat: 'MMMM Do YYYY, h:mm:ss a', containerClass: 'theme-kiss' , returnFocusToInput: true }: { containerClass: 'theme-kiss' , returnFocusToInput: true }
	}

	private emitChange(date: Date | Event, emitter: EventEmitter<Date>) {
		if (date) {
			let value = null;
			if (date instanceof Date) {
				value = date;
			}
			emitter.emit(value);
		}
	}

	checkDateRangeValidity() {
		if (this.modelFrom > this.modelTo) {
			this.isDateRangeValid = false;
			this.dateRangeValidityChange.emit(this.isDateRangeValid);
		} else {
			this.isDateRangeValid = true;
			this.dateRangeValidityChange.emit(this.isDateRangeValid);
		}
	}

	showTooltipFin() {
		if (this.popFin) {
			this.popFin.show();
		}
	}

	hideTooltlipFin() {
		if (this.popFin) {
		 this.popFin.hide();
		}
	}

	showTooltipDebut() {
		if (this.popDebut) {
			this.popDebut.show();
		}
	}

	hideTooltlipDebut() {
		if (this.popDebut) {
			this.popDebut.hide();
		}
	}

	onChoiceChange(filterChoice) {
		this.filterChange.emit({
			filterChoice: filterChoice,
			fromRsp: this.fromRsp
		});
	}

	onKeyEnter() {
		this.onEnter.emit();
	}

}
