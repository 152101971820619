import { map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { isNullOrUndefined } from 'util';

@Injectable({ providedIn: 'root' })
export class AuthentificationGuard implements CanActivate, CanActivateChild {
    // Authentification guard to check if user's token is expired or not
    lastRoute: any;
    constructor(private router: Router, private authService: AuthService) {
    }

    canActivate(): Observable<boolean>{
        const userConnected = JSON.parse(localStorage.getItem('careweb_user'));
        this.lastRoute = this.router.url;
        if (!this.authService.isTokenExpired() && !isNullOrUndefined(userConnected)) {
           return this.authService.getCurrentUserStatut().pipe(
                    map((statut)=>{
                            if(!statut) {
                                this.router.navigate([ '/login' ]);
                            }
                            return statut;
                    }));
        } else {
            this.checkLastRoute();
            this.router.navigate([ '/login' ]);
            return of(false);
        }
    }
    checkLastRoute(){
        if( this.lastRoute != "/"
            && this.lastRoute != "/login"
            && this.lastRoute != ""
            && this.lastRoute != null
            && this.lastRoute.url != '/404'
            && this.lastRoute.url != '/unknown-page'
            && this.lastRoute.url != '/hotline'
            && this.lastRoute.url != '/hotline-parameters'
            && this.lastRoute.url != '/hotline-dashboard'
            && this.lastRoute.url != '/sent-reinit-password'
            && this.lastRoute.url != '/update-password'
            && this.lastRoute.url != '/rapp-error-message'
            && this.lastRoute.url.indexOf("/hotline-edit") !== 0
            && this.lastRoute.url.indexOf("/forget-password") !== 0) {
            sessionStorage.setItem('lastRoute', this.lastRoute);
        }
    }
    canActivateChild(): Observable<boolean> {
        return this.canActivate();
    }
}
