<careweb-help></careweb-help>
<div class="full-careweb-container">
  <div id="filter" class="filter-container" carewebFilterMinHeight>
    <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
      <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" id="reset_filter" (resetFilters)="resetAllFilter()"
        (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
      <div class="filter-content">
        <mat-accordion displayMode="flat" class="accordion-filter">
          <!-- Type d'organisme -->
          <careweb-accordion-group id="type_organisme" title="Type d'organisme">
            <ng-select name="typeOrganisme" id="typeOrganismeSelector" placeholder="Type d'organisme"
              [clearable]="false" [(ngModel)]="filters.typeOrganisme" (change)="updateOrganismeView($event)"
              appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
              <ng-option [value]="'RO'">AMO</ng-option>
              <ng-option [value]="'RC'">AMC</ng-option>
            </ng-select>
          </careweb-accordion-group>
          <!-- Sigle -->
          <careweb-accordion-group id="sigle" title="Sigle">
            <input type="text" id="input_sigle" [(ngModel)]="filters.sigle" maxlength="13"
              (input)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Libellé -->
          <careweb-accordion-group id="libelle" title="Libellé">
            <input type="text" id="input_libelle" [(ngModel)]="filters.libelle" maxlength="150"
              (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Commentaire -->
          <careweb-accordion-group id="commentaire" title="Commentaire">
            <input type="text" id="input_commentaire" [(ngModel)]="filters.commentaire" maxlength="260"
              (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Etat des organismes -->
          <careweb-accordion-group id="etat_organismes" title="Etat des organismes">
            <ng-select name="actif" id="actif" placeholder="Etat des organismes" [searchable]="false"
              [(ngModel)]="filters.actif" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
              <ng-option [value]="''"> </ng-option>
              <ng-option [value]="true">Actif</ng-option>
              <ng-option [value]="false">Non actif</ng-option>
            </ng-select>
          </careweb-accordion-group>
          <!-- Code grand régime -->
          <careweb-accordion-group *ngIf="filters.typeOrganisme === 'RO'" id="code_grand_regime"
            title="Code grand régime">
            <input type="text" id="input_code_grand_regime" [(ngModel)]="filters.codeGrandRegime"
              onKeyPress="if(this.value.length==2) return false;" onpaste="if(this.value.length==2) return false;"
              oninput="this.value=this.value.replace(/[^\d]/,'');" pattern="^[0-9]"
              onkeyup="this.value=this.value.replace(/[^\d]/,'');"
              onkeydown="this.value=this.value.replace(/[^\d]/,'');" (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Code caisse C.A.S. -->
          <careweb-accordion-group *ngIf="filters.typeOrganisme === 'RO'" id="code_caisse_cas" title="Code caisse CAS">
            <input type="text" id="input_code_caisse_cas" [(ngModel)]="filters.codeCaisseCAS"
              onKeyPress="if(this.value.length==3) return false;" onpaste="if(this.value.length==3) return false;"
              oninput="this.value=this.value.replace(/[^\d]/,'');" pattern="^[0-9]"
              onkeyup="this.value=this.value.replace(/[^\d]/,'');"
              onkeydown="this.value=this.value.replace(/[^\d]/,'');" (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Code centre C.A.S. -->
          <careweb-accordion-group *ngIf="filters.typeOrganisme === 'RO'" id="code_centre_cas" title="Code centre CAS">
            <input type="text" id="input_code_centre_cas" [(ngModel)]="filters.codeCentreCAS"
              onKeyPress="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==4) return false;"
              onpaste="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==4) return false;"
              oninput="this.value=this.value.replace(/[^\d]/,'');" pattern="^[0-9]"
              onkeyup="this.value=this.value.replace(/[^\d]/,'');"
              onkeydown="this.value=this.value.replace(/[^\d]/,'');" (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Code officiel -->
          <careweb-accordion-group *ngIf="filters.typeOrganisme === 'RC'" id="code_officiel" title="Code officiel">
            <input type="text" id="input_code_officiel" [(ngModel)]="filters.codeOfficiel"
              onKeyPress="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==10) return false;"
              pattern="^[0-9]" oninput="this.value=this.value.replace(/[^\d]/,'');"
              onpaste="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==10) return false;"
              onkeyup="this.value=this.value.replace(/[^\d]/,'');"
              onkeydown="this.value=this.value.replace(/[^\d]/,'');" (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Code entrée -->
          <careweb-accordion-group *ngIf="filters.typeOrganisme === 'RC'" id="code_entree" title="Code entrée">
            <input type="text" id="input_code_entree" [(ngModel)]="filters.codeEntree"
              onKeyPress="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==10) return false;"
              oninput="this.value=this.value.replace(/[^\d]/,'');" pattern="^[0-9]"
              onpaste="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==10) return false;"
              onkeyup="this.value=this.value.replace(/[^\d]/,'');"
              onkeydown="this.value=this.value.replace(/[^\d]/,'');" (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <careweb-accordion-group *ngIf="filters.typeOrganisme === 'RC'" id="aide_perenne" title="Aide pérenne">
            <ng-select name="aidePerenne" id="aidePerenne" placeholder="Aide pérenne" [searchable]="false"
              [(ngModel)]="filters.aidePerenne" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
              <ng-option [value]="''"> </ng-option>
              <ng-option [value]="true">Oui</ng-option>
              <ng-option [value]="false">Non</ng-option>
            </ng-select>
          </careweb-accordion-group>

        </mat-accordion>
      </div>
    </div>
  </div>
  <div class="grid-container" id="grid">
    <h2 class="title-border mt-3">Liste des organismes
      <careweb-open-help code="help-007"></careweb-open-help>
    </h2>
    <div>
      <section>
        <div class="row grid-top-btn">
          <div class="col-md-4 filter">
            <button type="button" name="btn_toggle_filter" id="filter_organisme" class="btn btn-primary small left-open" [hidden]="showFilter"
              (click)="toggleFilterDisplay()">
              <i class="fa fa-sliders"></i>
              Filtrer
            </button>
            <careweb-pluralize class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
              [count]="gridHelper.totalRowCount"></careweb-pluralize>
          </div>
          <div class="col-md-8 conf-col">
            <div class="careweb-toolbar">
              <div [tooltip]="gridHelper.isAvailableData() ? 'Exporter les résultats (.xlsx)' : 'Aucune ligne à exporter, veuillez affiner votre recherche'"
                   placement="top" container="body">
                <span class="btn btn-action small" id="exportFileXls" [class.disabled]="!gridHelper.isAvailableData()"
                       name="btn_export_.xls"
                      (click)="exportFileXlsModal()">
                <i class="fa fa-file-excel-o"></i>
                </span>
              </div>
              <careweb-pagination-size id="dv_organismes_paging" [pageSizes]="[10, 20, 30, 50, 100]"
                [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
              </careweb-pagination-size>
              <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                name="spn_reset_grid" id="resetGrid" (click)="resetGridState()">
                <i class="fa fa-refresh"></i>
              </span>
            </div>
          </div>
        </div>
          <app-alert [export]="export" [exportState]="exportState" id="close_alert" (close)="closeAlert()"></app-alert>
        <div class="row grid-top-filter">
          <div class="col-md-12">
            <careweb-filter-label *ngFor="let filter of filterList" [filter]="filter.id" [isOrganisme]="true"
              (closeFilter)="removeFilter(filter.id)">
              <span *ngIf="filter" id="filterLabel">{{ filter.label}}: {{filter.value }}</span>
            </careweb-filter-label>
          </div>
        </div>
        <div class="row highlight-row">
          <div class="col-md-12 relative">
            <ag-grid-angular style="width: 100%" class="ag-theme-balham ag-theme-organisme"
              [gridOptions]="gridHelper.gridOptions">
            </ag-grid-angular>
            <div [hidden]="!isOrganismRc || !isAuthorized()" style="text-align: center;">
              *Les OCTs associés (ALL -> QTP) et transfert ne figurent pas dans la colonne OCT.
            </div>
            <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
