import { Component, OnInit } from '@angular/core';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';
import { GridDataRaw } from '../../../data/gridDataRaw';
import { ColDef, ColGroupDef, GetRowIdFunc, GridReadyEvent, SortChangedEvent } from 'ag-grid-community';
import { RappReconciliationRatecomponent } from '../rapp-reconciliation-rate.component';
import { RapprochementComptePsService } from '../../rapprochement/rapprochement.service';
import { PsService } from '../../ps/ps.service';
import { isEmpty } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OctDataRaw } from '../../../data/octDataRaw';
import { OctService } from '../../../core/services/oct.service';
import { RspRappSummaryFilterRaw } from '../../../data/filters/rspRappSummaryFilterRaw';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';

@Component({
	selector: 'careweb-rapp-dashboard-ps',
	templateUrl: './rapp-dashboard-ps.component.html',
	styleUrls: ['./rapp-dashboard-ps.component.scss']
})
export class RappDashboardPsComponent implements OnInit {

	gridOptions;
	gridApi;
	gridHelper: AgGridHelper;
	gridDataRaw: GridDataRaw;
	currentPage = 1;
	rowCount: number;
	totalElement = 0;
	groupDefaultExpanded = 0;
	public rowSelection: 'single' | 'multiple' = 'multiple';
	startDate: Date = new Date();
	endDate: Date = new Date();
	organism;

	matchingKeysRenderer = (params) => {
		if (params.data && params.value) {
			let matchingKey = '';
			for (let i = 0; params.value.length > i; i++) {
				if (params.value[i] !== 'ORGANISME')
					matchingKey += `<span class="badge badge-primary badge-spacer" style="margin-right: 2px;">${params.value[i]}</span>`;
			}
			return matchingKey;
		}
		return "";
	};

	matchingTypesRenderer = (params) => {
		if (params.data && params.value) {
			let matchingType = '';
			for (let i = 0; params.value.length > i; i++) {
				matchingType += `<span class="badge badge-warning badge-spacer" id="id_warning" style="margin-right: 2px;">${params.value[i]}</span>`;
			}
			return matchingType;
		}
		return "";
	};

	detailsRenderer = (params) => {
		if (!params.data.children) {
			return `<i class="fa fa-eye" container="body" id="id-eye" tooltip="Consulter détails du rapprochement" containerClass="tooltip-grid"></i>`;
		}
		return "";
	};

	public columnDefs: (ColDef | ColGroupDef)[] = [
		{
			onCellClicked: (params) => this.onShowDetails(params),
			headerName: 'Numéro de PS',
			field: 'numeroAdeli',
			flex: 19,
			sortable: true
		},
		{
			onCellClicked: (params) => this.onShowDetails(params),
			headerName: 'Règles de Clés',
			field: 'matchingKeys',
			cellRenderer: this.matchingKeysRenderer,
			cellClass: ['font-weight-bold'],
			flex: 19,
			sortable: false
		},
		{
			onCellClicked: (params) => this.onShowDetails(params),
			headerName: 'Règles de Dictionnaire',
			field: 'matchingTypes',
			cellRenderer: this.matchingTypesRenderer,
			cellClass: ['font-weight-bold'],
			flex: 19,
			sortable: false
		},
		{
			onCellClicked: (params) => this.onShowDetails(params),
			headerName: 'Non Rapprochés',
			field: 'totalRspNotRapp',
			cellClass: ['font-weight-bold'],
			flex: 19,
			sortable: true,
			sort: 'desc'
		},
		{
			onCellClicked: (params) => this.onShowDetails(params),
			headerName: '% Rapprochement',
			field: 'totalRspRapp',
			cellClass: ['font-weight-bold'],
			cellRenderer: RappReconciliationRatecomponent,
			flex: 19,
			sortable: true
		},
		{
			onCellClicked: (params) => this.onShowDetails(params),
			headerName: '',
			field: 'options',
			cellClass: ['font-weight-bold'],
			cellRenderer: this.detailsRenderer,
			flex: 5
		}
	];

	public defaultColDef: ColDef = {
		suppressMenu: true,
		resizable: true,
		filter: false,
	};

	organismName = (params): string => {
		if (params.data && params.data.organismName) return params.data.organismName + ' (' + params.data.children.length + ')';
	};

	public getRowId: GetRowIdFunc = (params) => {
		return params.data.uuid;
	};

	public rowData!: any[];

	constructor(private rapprochementService: RapprochementComptePsService, private modalRef: BsModalRef, private psService: PsService, private octSvc: OctService) {
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = 10;
		this.gridHelper = new AgGridHelper('rappManuRsp', this.gridDataRaw.size);
	}

	ngOnInit() {
		// Init grid options
		this.gridHelper.gridOptions = {
			columnDefs: this.columnDefs,
			defaultColDef: this.defaultColDef,
			animateRows: true,
			groupDefaultExpanded: this.groupDefaultExpanded,
			rowData: this.rowData,
			rowHeight: 50,
			headerHeight: 50,
			domLayout: 'autoHeight',
			colResizeDefault: 'shift',
			rowSelection: this.rowSelection,
			suppressRowClickSelection: true,
			rowModelType: 'serverSide',
			getRowId: this.getRowId,
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			paginationPageSize: this.gridDataRaw.size,
			infiniteInitialRowCount: 1,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			enableCellTextSelection: true,
			suppressHorizontalScroll: true,
			suppressDragLeaveHidesColumns: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params),
			onSortChanged: (event) => this.onSortChanged(event),
			onRowClicked: (params) => this.onRowClicked(params),
		};
	}

	onGridReady(params: GridReadyEvent) {
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.getPerfData();
	}

	onRowClicked(params) {
		if (!this.gridHelper.isCellTextSelected()) {
			params.node.setExpanded(!params.node.expanded);
		}
	}

	pageChange(currentPage: number) {
		this.currentPage = currentPage;
		this.getPerfData();
	}

	getPerfData() {
		const ctx = this;
		const dataSource = {
			getRows: (paramsRows) => {
				const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
				ctx.rapprochementService
					.getPerfDashboardByPs(this.organism, {
						filters: {
							startDate: this.startDate,
							endDate: this.endDate
						},
						page: ctx.currentPage,
						size: ctx.gridDataRaw.size,
						sorts: sorts
					})
					.subscribe((data) => {
						paramsRows.success({ "rowData": data.content });
						ctx.rowCount = Math.ceil(data.size / this.gridDataRaw.size);
						ctx.totalElement = data.size;
					})
			}
		};
		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
	}

	restoreGrid(gridHelper: AgGridHelper) {
		gridHelper.restoreGridStatePrefix();
	}

	onShowDetails(params) {
		this.psService.getPsByNumAdeli({ numAdeli: params.data.numeroAdeli }).subscribe(
			(ps: any[]) => {
				if (!isEmpty(ps) && ps.length > 0) {
					const psTrouve = ps[0];
					if (psTrouve.currentOct && psTrouve.currentOct.length > 0 && psTrouve.typeRappro) {
						localStorage.setItem("numeroPs", psTrouve.numeroAdeli)
						localStorage.setItem("idPs", psTrouve.idPs)
						localStorage.setItem('current_ps', JSON.stringify(psTrouve));
						this.psService.eventPsSelected.emit(psTrouve.numeroAdeli);
						const oct = psTrouve.currentOct[0];
						const octSelected = new OctDataRaw().withIdOct(oct[0]).withNom(oct[1]).withCode(oct[2]);
						this.octSvc.setOctValue(octSelected);
						localStorage['octCurrent'] = JSON.stringify(octSelected);
						const filtersRSP = new RspRappSummaryFilterRaw();
						filtersRSP.organismes = [this.organism];
						filtersRSP.dateComptableFrom = this.startDate;
						filtersRSP.dateComptableTo = this.endDate;
						filtersRSP.rspIndu = true;
						sessionStorage['rapproManRspFilter'] = JSON.stringify(filtersRSP);
						const url = '/careweb-client/rapp-manuel';
						window.open(url, '_blank');
					}
				}
			}
		)
	}

	closeModal() {
		this.modalRef.hide();
	}

	onPaginationSizeChange(paginationSize: number) {
		this.gridHelper.changePaginationSize(paginationSize);
		this.currentPage = 1;
		this.getPerfData();
		this.gridHelper.saveGridStatePrefix();
	}

	resetGridState() {
		this.gridHelper.resetGridStateRapp();
		this.gridDataRaw.size = 10;
	}

	onSortChanged(event: SortChangedEvent): void {
		this.currentPage = 1;
		this.getPerfData();
	}
}