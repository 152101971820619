import { ColumnTooltipComponent } from './../../../components/column-tooltip/column-tooltip-component';
import { ConvertDateFormat } from './../../../core/utilities/convert-date-format.service';
import { DateFromToFilterComponent } from './../../../components/date-from-to/date-from-to-filter.component';
import { isEmpty } from 'lodash-es';
//import { IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import {Component, OnInit, OnDestroy, QueryList, ViewChildren,EventEmitter} from '@angular/core';
import { AgGridHelper } from '../../../components/ag-grid/ag-grid-helper';

import { DatePipe } from '@angular/common';
import { TransactionDataRaw } from '../../../data/transactionDataRaw';
import { RspRappSummaryFilterRaw } from '../../../data/filters/rspRappSummaryFilterRaw';
import { TransactionFilterRaw } from '../../../data/filters/transactionFilterRaw';
import * as _ from 'lodash-es';

import { FilterItem } from '../../../data/filters/filter-item';
/* [CAW-1600-] Desactiver la checkAll & uncheckAll */
import { IServerSideDatasource, IServerSideGetRowsParams, RowNode } from 'ag-grid-enterprise';
import { gridConvertSort } from '../../../components/ag-grid/ag-grid.utils';
import { TransactionService } from '../transaction.service';
import { RspRappSummaryService } from '../rspRappSummary.service';
import { RappAutoPreviewcomponent } from '../rapp-auto-preview.component';
import { RapprochementDataRaw } from '../../../data/rapprochementDataRaw';
import { RapprochementComptePsService } from '../rapprochement.service';
import { ToastrService } from 'ngx-toastr';
import { RspRappSummaryDataRaw } from '../../../data/rspRappSummaryDataRaw';
import { uniqBy } from 'lodash-es';
import { findIndex } from 'lodash-es';
import { isNullOrUndefined } from 'util';
import { CustomSortDataRow } from '../../../data/customSortDatRaw';
import { GridDataRaw } from '../../../data/gridDataRaw';
import { StorageService } from '../../../shared/storage-service';
import { FormatThousandsPipe } from '../../../core/pipes/format-thousands.pipe';
import { ViewChild } from '@angular/core';
import { AccordionGroupComponent } from '../../../components/accordion/accordion-group.component';
import { RappDetailVrtCellRendererComponent } from '../rapp-detail-vrt-cell-enderer.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalConfirmRappAutoComponent } from '../../../components/modal-confirm-rapp-auto/modal-confirm-rapp-auto.component';
import { PointerSolderModalComponent } from '../pointer-solder-modal/pointer-solder-modal.component';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';
import { forkJoin } from 'rxjs';
import { FilterChoiceEnum } from '../../../data/filters/rspRappSummaryFilterRaw';
import { FilterChoiceExactePlageComponent } from '../../../components/filter-choice-exacte-plage/filter-choice-exacte-plage.component';
import {v4 as uuidv4} from 'uuid';
import { AgCheckAllService, AgCheckBoxState, AgHeaderCheckboxComponent, CheckAllState } from '../../../components/header-checkbox/header-checkbox-ag.component';
import {AgRspGridComponent} from "../ag-demo/ag-RspGrid.component";

@Component({
    selector: 'app-rapp-manuel',
    templateUrl: './rapp-manuel.component.html',
    styleUrls: ['./rapp-manuel.component.scss']
})
export class RappManuelComponent implements OnInit, OnDestroy {
    gridHelperRSP: AgGridHelper;
    gridHelperVRT: AgGridHelper;
    gridHelperRSPDetails: AgGridHelper;
    gridHelperVRTDetails: AgGridHelper;
    montantVrtMinError: boolean = false;
    montantVrtMaxError: boolean = false;

    montantRspMinError: boolean = false;
    montantRspMaxError: boolean = false;

    montantGroupeRspMinError: boolean = false;
    montantGroupeRspMaxError: boolean = false;
    datePipe: DatePipe;
    transactionDataRaw: TransactionDataRaw;
    rspRappSummaryDataRaw: RspRappSummaryDataRaw;
    showRspDetail: boolean;
    selectedRow: any;
    nbRowSelectedRSP: number;
    nbRowSelectedVRT: number;
    /* [CAW-1600-] Desactiver la checkAll & uncheckAll */
    montantTotalSelectedRSP: number;
    montantTotalSelectedVRT: number;
    initHidden = true;
    rspGridDataRaw: GridDataRaw;
    vrtGridDataRaw: GridDataRaw;

    totalRowCountRSP: number;
    totalRowCountVRT: number;

    totalDiffRSP: any;
    totalDiffVRT: any;

    showFilter = false;
    filtersRSP = new RspRappSummaryFilterRaw();
    filtersVRT = new TransactionFilterRaw();
    tempRsp = new RspRappSummaryFilterRaw();
    tempVrt = new TransactionFilterRaw();
    filterList: FilterItem[];


    isShowRspDetail: boolean = false;
    showConfirmModal: boolean = false;
    modalMessage: string;

    oxlinUserConnected: any;
    userConnected: any;

    labelValidButtonConfirmModal: string = 'Oui';
    labelCancelButtonConfirmModal: string = 'Non';

    isDateComptableRangeValid = true;

    isRspSoldable = false;
    isVrtPointable = false;
    /* [CAW-1600-] Desactiver la checkAll & uncheckAll */
    isCheckAll = false;
    /* [CAW-1866-]  checkAll & uncheckAll VRT */
    isVRTCheckAll = false;
    firstCallCheckAll = false;
    checkAllVrtmanuel = false;

    @ViewChild('dateComptable')
    dateComptable: DateFromToFilterComponent;

    @ViewChild('agDemoComponent')
    agDemoComponent: AgRspGridComponent;

    @ViewChild('dateComptableAccordion')
    dateComptableAccordion: AccordionGroupComponent;

    detailCellRendererParams: any;
    selectedRspRows: any[];
    selectedVrtRows: any[];
    /* [CAW-1600-] Desactiver la checkAll & uncheckAll */
    unSelectedRspRows: any[];
    unSelectedVrtRows: any[];
    openedModal: BsModalRef;
    pointerSolderModal: BsModalRef;
    nbRowSelectedRSPMAX: number = 0;
    childAlreadyRemoved: boolean = false;
    childAlreadyAdded: boolean = false;
    isResetGridRSP: boolean;
    isResetGridVRT: boolean;

    loadingRsp = false;

    resetRSPFilters = false;
    resetVRTFilters = false;
    searchRspFilter = false;

    modalEvent = new EventEmitter<{message,data}>();
    modalEventSubscription;
    vrtGridId = 'vrt-grid-id';
    rspGridId = 'rsp-grid-id';

    @ViewChildren(FilterChoiceExactePlageComponent) filterComponents: QueryList<FilterChoiceExactePlageComponent>;

    constructor(
        private transactionService: TransactionService,
        private rspRappSummaryService: RspRappSummaryService,
        private rapprochementComptePsService: RapprochementComptePsService,
        private toastr: ToastrService, //private lotsFilterSvc: LotsFilterSvc
        private formatThousands: FormatThousandsPipe,
        private storageService: StorageService,
        private convertFormatDate: ConvertDateFormat,
        private modalService: BsModalService,
        private agCheckAllService: AgCheckAllService
    ) {
        // Body styling
        const body = document.body;
        body.className = 'app';

        //Get RSP grid size from session if already stored
        /*const rspPageSize = this.storageService.get('rappManuRspPaginationPageSize', true);
        this.rspGridDataRaw = new GridDataRaw();
        this.rspGridDataRaw.size = !isNullOrUndefined(rspPageSize) ? rspPageSize : 10;*/

        //Get VRT grid size from session if already stored
        const vrtPageSize = this.storageService.get('rappManuVrtPaginationPageSize', true);
        this.vrtGridDataRaw = new GridDataRaw();
        this.vrtGridDataRaw.size = !isNullOrUndefined(vrtPageSize) ? vrtPageSize : 10;

        //Get RSP grid column state from session
        // this.rspGridDataRaw.columnState = this.storageService.get('rappManuRspGridColumnState', true);
        //Get VRT grid column state from session
        this.vrtGridDataRaw.columnState = this.storageService.get('rappManuVrtGridColumnState', true);

        //this.gridHelperRSP = new AgGridHelper('rappManuRsp', this.rspGridDataRaw.size);
        this.gridHelperVRT = new AgGridHelper('rappManuVrt', this.vrtGridDataRaw.size);
      //  this.gridHelperRSPDetails = new AgGridHelper();
        this.gridHelperVRTDetails = new AgGridHelper();

        this.selectedRow = null;
        this.transactionDataRaw = new TransactionDataRaw();
       // this.initSelectedRsp();
        this.initSelectedVrt();
        this.totalRowCountRSP = 0;

        frLocale.invalidDate = 'Date invalide';
        defineLocale('fr', frLocale);
    }

    resetGridStateVRT() {
        this.isResetGridVRT = true;
        this.gridHelperVRT.resetGridState();
        this.vrtGridDataRaw.size = this.gridHelperVRT.defaultPaginationPageSize;
        this.gridHelperVRT.gridApi.deselectAll();
        this.gridHelperVRT.gridApi.forEachNode(node => node.setExpanded(false) );
        this.gridHelperVRT.gridApi.getColumnDef('checkVrt').headerName = 'deselect';
        this.initSelectedVrt();
        this.gridHelperVRT.gridColumnApi.resetColumnState();
        this.gridHelperVRT.gridApi.sizeColumnsToFit();
    }

    resetGridStateRSP() {
        if(this.agDemoComponent){
            this.agDemoComponent.resetGridStateRSP();
        }
        /*this.isResetGridRSP = true;
        this.gridHelperRSP.resetGridState();
        var rappManuRspGridColumnState = this.storageService.get('rappManuRspGridColumnState',true);
        this.rspGridDataRaw.size = this.gridHelperRSP.defaultPaginationPageSize;
        this.gridHelperRSP.gridApi.paginationGoToPage(0);
        this.gridHelperRSP.gridApi.deselectAll();
        this.gridHelperRSP.gridApi.forEachNode(node => node.setExpanded(false) );
        if(!this.isCheckAll){
            this.gridHelperRSP.gridApi.getRenderedNodes().map((node: RowNode) => {
                node.setExpanded(false);
                const detailNode = node.detailNode?.detailGridInfo;
                detailNode?.api.deselectAll();
                this.initSelectedRsp();
                this.aggregateDiffSelected();
            });
        }
        this.isCheckAll = false;
        this.selectedRspRows = [];
        this.unSelectedRspRows = [];
        this.gridHelperRSP.gridApi.setColumnDefs(this.getColumnDefsRSP());
        this.gridHelperRSP.gridApi.getColumnDef('check').headerName = 'deselect';
        this.gridHelperRSP.gridApi.sizeColumnsToFit();
        this.storageService.set('rappManuRspGridColumnState', rappManuRspGridColumnState, true);
    */}

    
    initFilterChoices() {
        const filterComponentsArray = this.filterComponents.toArray();
        filterComponentsArray.forEach(component => {
          component.reserFilterChoice();
        });
    }

    resetAllFilter() {
        this.resetRSPFilters=this.hasRSPFilters()
        this.resetVRTFilters=this.hasVRTFilters()
        this.updateFilterList(this.filtersRSP, this.filtersVRT);
        this.filtersRSP = new RspRappSummaryFilterRaw();
        this.filtersRSP.montantRspFilter = FilterChoiceEnum.EXACTE;
        this.filtersRSP.montantGroupeRspFilter = FilterChoiceEnum.EXACTE;
        //this.filtersRSP.rsp30Jours = true;
        this.filtersVRT = new TransactionFilterRaw();
        this.filtersVRT.montantFilter = FilterChoiceEnum.EXACTE;
        this.dateComptable.isDateRangeValid = true;
        this.isDateComptableRangeValid = true;
        this.reloadData(this.resetRSPFilters, this.resetVRTFilters);
        this.aggregateDiffSelected();
        this.calculateVRT();
        this.initFilterChoices();
        this.montantRspMinError = false;
        this.montantRspMaxError = false;
        this.montantVrtMinError = false;
        this.montantVrtMaxError = false;
        this.montantGroupeRspMinError = false;
        this.montantGroupeRspMaxError = false;
        this.agDemoComponent.initSelectedRsp();
    }

   /* initSelectedRsp(): void {
        this.nbRowSelectedRSP = 0;
        /!* [CAW-1600-] Desactiver la checkAll & uncheckAll *!/
        this.montantTotalSelectedRSP = 0;
        this.totalDiffRSP = {
            montant: null,
            status: null
        };
        this.selectedRspRows = [];
        /!* [CAW-1600-] Desactiver la checkAll & uncheckAll *!/
        this.unSelectedRspRows = [];
        this.isCheckAll = false;
        this.gridHelperRSP.gridApi?.refreshHeader();
    }*/

    initSelectedVrt(): void {
        this.nbRowSelectedVRT = 0;
        /*  la checkAll & uncheckAll Vrt */
        this.montantTotalSelectedVRT =0;
        this.totalDiffVRT = {
            montant: null,
            status: null
        };
        this.selectedVrtRows = [];
        this.unSelectedVrtRows = [];
        this.isVRTCheckAll=false ;
        this.gridHelperVRT.gridApi?.refreshHeader();
        this.agCheckAllService.notifyCheckBox({ id: this.vrtGridId, state: CheckAllState.UNSELECT });
    }

    getRspId(row) {
        return uuidv4();
    }

    getTransactionId(row) {
        return row.data.idBankTransaction;
    }

    ngOnInit() {
        this.parseFilters();
        this.initMontantFilter();
        // Initiate date pipe format
        this.datePipe = new DatePipe('fr-FR');

        this.userConnected = JSON.parse(localStorage.getItem('careweb_user'));
        this.oxlinUserConnected = JSON.parse(localStorage.getItem('current_ps')).rappComptePs;
        // Init grid options FOR VRT
        this.gridHelperVRT.gridOptions = {
            columnDefs: this.getColumnDefsVRT(),
            defaultColDef: {
                suppressMenu: true,
                resizable: true
            },
            domLayout: 'autoHeight',
            rowHeight: 50,
            headerHeight: 50,
            rowModelType: 'serverSide',
            getRowId: this.getTransactionId,
            suppressServerSideInfiniteScroll: false,
            pagination: true,
            cacheBlockSize: 10,
            paginationPageSize: this.vrtGridDataRaw.size,
            infiniteInitialRowCount: 1,
           // paginateChildRows: true,
            suppressScrollOnNewData: true,
            suppressPaginationPanel: true,
            suppressContextMenu: true,
            enableCellTextSelection: true,
            suppressDragLeaveHidesColumns: true,
            localeText: this.gridHelperVRT.getLocaleText(),
            onGridReady: (params) => this.onGridReady(params, 'VRT'),
            //la checkAll & uncheckAll des VRT */
            onPaginationChanged: (params) => this.onVRTPaginationChanged(params),
            onGridSizeChanged: (params) => this.onGridSizeChanged(params, 'VRT'),
            onSelectionChanged: (params) => this.onSelectionChanged(params, 'VRT'),
            onSortChanged: (params) => this.onSortChanged(params, 'VRT'),
            onColumnMoved: (params) => this.onColumnMoved(params, 'VRT'),
            onRowGroupOpened: (params) => this.onRowGroupOpened(params),
            rowSelection: 'multiple',
            suppressRowClickSelection: true,
            masterDetail: true,
            alwaysShowVerticalScroll: false,
            alwaysShowHorizontalScroll: false,
            suppressHorizontalScroll: true,
            detailCellRenderer: 'myDetailCellRenderer',
            suppressCellSelection: true,
            components: {
                myDetailCellRenderer: RappDetailVrtCellRendererComponent,
                columnTooltipCellRenderer: ColumnTooltipComponent,
            },
            onCellClicked: (event) => {
                const isDetailCell = event.column.getColDef().cellRenderer === 'myDetailCellRenderer';
                if (isDetailCell) {
                    event.api.deselectAll();
                    event.event.preventDefault();
                }
            },
        };

        // Init grid options FOR RSP
        /*this.gridHelperRSP.gridOptions = {
            columnDefs: this.getColumnDefsRSP(),
            defaultColDef: {
                suppressMenu: true,
                resizable: true,
                flex: 1,
                minWidth: 100
            },
            colResizeDefault: 'shift',
            domLayout: 'autoHeight',
            rowHeight: 50,
            headerHeight: 50,
            rowModelType: 'serverSide',
            getRowId: this.getRspId,
            //suppressServerSideInfiniteScroll: false,
            pagination: true,
            cacheBlockSize: 10,
            //paginateChildRows: true,
            paginationPageSize: this.rspGridDataRaw.size,
            infiniteInitialRowCount: 1,
            suppressScrollOnNewData: true,
            suppressPaginationPanel: true,
            suppressContextMenu: true,
            enableCellTextSelection: true,
            alwaysShowVerticalScroll: false,
            alwaysShowHorizontalScroll: false,
            suppressHorizontalScroll: true,
            localeText: this.gridHelperVRT.getLocaleText(),
            onGridReady: (params) => this.onGridReady(params, 'RSP'),
            /!* [CAW-1600-] Desactiver la checkAll & uncheckAll *!/
            onGridSizeChanged: (params) => this.onGridSizeChanged(params, 'RSP'),
            onPaginationChanged: (params) => this.onPaginationChanged(params),
           // onRowSelected: (params) => this.onMasterRowSelected(params),
            onSortChanged: (params) => this.onSortChanged(params, 'RSP'),
            onColumnMoved: (params) => this.onColumnMoved(params, 'RSP'),
            onColumnResized: (params) => this.onColumnResized(params),
            onRowClicked: (params) => this.onRowClicked(params),
            onSelectionChanged: (params) => this.checkAllRspManagement(),
            rowSelection: 'multiple',
            suppressRowClickSelection: true,
            detailCellRendererParams: this.detailCellRendererParams,
            masterDetail: true,
            getRowHeight: function (params) {
                var isDetailRow = params.node.detail;
                if (isDetailRow) {
                    // Dynamically calculate detail rows height
                    return params.data.factureNumber * 50 + 1;
                } else {
                    return 50;
                }
            }
        }; */
        this.launchSearchRSP();
        this.agCheckAllService.checkAll$.subscribe((value: AgCheckBoxState) => {
            // on check all checkbox change
            if(this.vrtGridId == value.id) {
                this.onVRTCheckAll(value.state == CheckAllState.SELECT);
            }
            if(this.rspGridId == value.id) {
               // this.onCheckAll(value.state == CheckAllState.SELECT);
            }
        });

    }

    initMontantFilter() {
        if(!this.filtersRSP.montantRspFilter) {
            this.filtersRSP.montantRspFilter = FilterChoiceEnum.EXACTE
        }
        if(!this.filtersRSP.montantGroupeRspFilter) {
            this.filtersRSP.montantGroupeRspFilter = FilterChoiceEnum.EXACTE
        }
        if(!this.filtersVRT.montantFilter) {
            this.filtersVRT.montantFilter = FilterChoiceEnum.EXACTE
        }
    }

    parseFilters() {
        const parsedFilterRsp = JSON.parse(sessionStorage.getItem('rapproManRspFilter'));
      /*  if (isNullOrUndefined(this.filtersRSP.rsp30Jours) || isEmpty(this.filtersRSP.rsp30Jours)) {
                this.filtersRSP.rsp30Jours = true;
            }*/
        if (!isEmpty(parsedFilterRsp)) {
            this.filtersRSP = Object.assign({}, parsedFilterRsp);
            this.filtersRSP.dateComptableFrom = this.filtersRSP.dateComptableFrom
                ? new Date(parsedFilterRsp.dateComptableFrom)
                : null;
            this.filtersRSP.dateComptableTo = this.filtersRSP.dateComptableTo
                ? new Date(parsedFilterRsp.dateComptableTo)
                : null;
        }

        const parsedFilterVRT = JSON.parse(sessionStorage.getItem('rapproManVrtFilter'));
        if (!isEmpty(parsedFilterVRT)) {
            this.filtersVRT = Object.assign({}, parsedFilterVRT);
            this.filtersVRT.dateOperationFrom = this.filtersVRT.dateOperationFrom
                ? new Date(parsedFilterVRT.dateOperationFrom)
                : null;
            this.filtersVRT.dateOperationTo = this.filtersVRT.dateOperationTo
                ? new Date(parsedFilterVRT.dateOperationTo)
                : null;
        }
    }

  /*  private getColumnDefsRSP() {
        const columnDefs: any[] = [
            {
                headerName: 'Avis de réception comptable (RSP)',
                headerClass: 'ag-header-group-parent',
                children: [
                    {
                        headerName: 'Lot > FSE',
                        field: 'numLot',
                        cellRenderer: 'agGroupCellRenderer',
                        cellRendererParams: {
                            suppressDoubleClickExpand: true,
                        },
                        width: 140,
                        sortable: true,
                        sort: 'asc',
                        sortIndex: 2,
                        valueFormatter: this.formatNumLotCell
                    },
                    {
                        headerName: 'Organisme',
                        field: 'libelleOrganisme',
                        width: 100,
                        sortable: true,
                        sort: 'asc',
                        sortIndex: 1
                    },
                    {
                        headerName: 'Date comptable',
                        field: 'dateReception',
                        width: 100,
                        cellStyle: this.gridHelperRSP.centerContent(),
                        cellClass: (params) => this.cellWarningStyle(params),
                        valueFormatter: this.formatDate,
                        sortable: true,
                        sort: 'desc',
                        sortIndex: 0
                    },
                    {
                        headerName: 'Montant',
                        field: 'cumulMontant',
                        width: 90,
                        cellStyle: this.gridHelperRSP.endContent(),
                        valueFormatter: this.formatCurrencyRapp,
                        sortable: true,
                        sort: null,
                        cellClass: ['font-weight-bold']
                    },
                    {
                        headerName: '',
                        field: 'detailRSP',
                        width: 40,
                        minWidth: 40,
                        headerClass: 'ag-header-merge'
                    },
                ]
            },
            {
                headerName: '',
                field: 'check',
                width: 40,
                minWidth: 40,
                checkboxSelection: true,
                headerComponent: AgHeaderCheckboxComponent,
                headerComponentParams: {
                 isCheckAll: true,
                 id: this.rspGridId
               },
                cellStyle: this.gridHelperRSP.centerContent(),
                pinned: 'right'
            }
        ];
        this.detailCellRendererParams = {
            detailGridOptions: {
                columnDefs: [
                    {
                        colId: 'numLot',
                        field: 'numeroFacture',
                        minWidth: 100,
                        width: 140,
                        cellClass: 'ml-4',
                        valueFormatter: this.formatNumFacture
                    },
                    {
                        field: 'libelleOrganisme',
                        width: 100,
                        minWidth: 60
                    },
                    {
                        field: 'dateReception',
                        width: 100,
                        minWidth: 60,
                        cellStyle: this.gridHelperRSP.centerContent(),
                        valueFormatter: this.formatDate
                    },
                    {
                        colId: 'cumulMontant',
                        field: 'montantExacte',
                        width: 100,
                        minWidth: 60,
                        valueFormatter: this.formatCurrencyRapp,
                        cellStyle: this.gridHelperRSP.rightContent(),
                        cellClass: ['font-weight-bold']
                    },
                    this.getDetailsRSP(),
                    {
                        headerName: '',
                        field: 'check',
                        minWidth: 40,
                        width: 57,
                        checkboxSelection: true,
                        cellStyle: this.gridHelperRSP.centerContent(),
                        pinned: 'right'
                    },
                ],
                domLayout: 'autoHeight',
                rowHeight: 50,
                headerHeight: 0,
               // detailRowAutoHeight: true,
                //defaultColDef: { flex: 1, resizable: true },
                rowSelection: 'multiple',
                suppressContextMenu: true,
                enableCellTextSelection: true,
                suppressRowClickSelection: true,
                pagination:false,
                alwaysShowVerticalScroll: false,
                alwaysShowHorizontalScroll: false,
                suppressHorizontalScroll: true,
                onRowClicked: (params) => this.onRowClicked(params),
                onRowSelected: (params) => this.onSelectionChanged(params, 'RSP'),
                onGridReady: (params) => this.onGridReady(params, 'detailsRSP')
            },
            getDetailRowData: (params) => {
                params.successCallback(params.data.childs);
                this.isShowRspDetail = true;
            },
        };

        return columnDefs;
    }*/

    private getColumnDefsVRT() {
        const columnDefs: any[] = [
            {
                headerName: 'Virements banque (VRT)',
                headerClass: 'ag-header-group-parent',
                children: [
                    {
                        headerName: 'Libellé',
                        field: 'label',
                        cellRenderer: 'agGroupCellRenderer',
                        cellRendererParams: {
                            suppressDoubleClickExpand: true,
                            innerRenderer: 'columnTooltipCellRenderer'
                        },
                        valueGetter: (params) => params.data.notes ? `${params.data.label} : ${params.data.notes}` : params.data.label ,
                        minWidth: 100,
                        sortable: true,
                        onCellClicked: (params) => this.onRowClicked(params)
                    },
                    {
                        ColId:"montant",
                        headerName: 'Montant',
                        width: 70,
                        field: 'montant',
                        cellStyle: this.gridHelperVRT.endContent(),
                        valueFormatter: this.formatCurrencyVrtRapp,
                        sortable: true,
                        cellClass: ['font-weight-bold'],
                        onCellClicked: (params) => this.onRowClicked(params)
                    },
                    {
                        headerName: 'Date opération',
                        width: 100,
                        field: 'dateComptable',
                        cellStyle: this.gridHelperVRT.centerContent(),
                        valueFormatter: this.formatDate,
                        sortable: true,
                        sort: 'desc',
                        onCellClicked: (params) => this.onRowClicked(params)
                    }
                ]
            },
            {
                headerName: '',
                field: 'checkVrt',
                width: 40,
                minWidth: 40,
                checkboxSelection: true,
                headerComponent: AgHeaderCheckboxComponent,
                headerComponentParams: {
                  isCheckAll: true,
                  id: this.vrtGridId
                },
                cellStyle: this.gridHelperVRT.centerContent(),
                pinned: 'left'
            },
        ];

        return columnDefs;
    }


    getTotalelement(){
        this.rspRappSummaryService.getRspCountAndSumAmount(this.rspRappSummaryDataRaw).subscribe(data => {
            this.nbRowSelectedRSP = data.count;
            this.nbRowSelectedRSPMAX = data.count;
        });
    }
    /* [CAW-1600-] Desactiver la checkAll & uncheckAll */
   /* onCheckAll(checkAll: any): void {
        if (checkAll) {
            this.isCheckAll = true;
            this.firstCallCheckAll = true;
            this.loadingRsp = true;
            this.rspRappSummaryService.getRspCountAndSumAmount(this.rspRappSummaryDataRaw).subscribe(data => {
                this.nbRowSelectedRSP = data.count;
                this.nbRowSelectedRSPMAX = data.count;

                this.montantTotalSelectedRSP = data.sum / 100;
                this.firstCallCheckAll = false;
                this.aggregateDiffSelected();
                this.checkVrtPointability();
                this.checkRspSoldability();
                this.loadingRsp = false;
            }, (error)=> {
                this.loadingRsp = false;
            });
        } else {
            this.isCheckAll = false;
            this.selectedRspRows = [];
            this.unSelectedRspRows = [];
        }
    }*/

    onVRTCheckAll(checkAll: any): void {
        if (checkAll) {
            this.isVRTCheckAll = true;
            this.calculateVRT();
        }else {
            this.isVRTCheckAll = false;
        }
        this.selectedVrtRows = [];
        this.unSelectedVrtRows = [];
    }

    calculateVRT(){
        if(this.isVRTCheckAll )
        this.transactionService.getTransactionsList(this.transactionDataRaw).subscribe(data=>{
            this.nbRowSelectedVRT =data.nbrVrt;
            this.montantTotalSelectedVRT = data.montantVrt;
            this.aggregateDiffSelected();
        });
    }

  /*  onMasterRowSelected(params): void {
        /!* [CAW-1600-] Desactiver la checkAll & uncheckAll *!/

        if (params.node && params.node.detailNode) {
            // Select/deselect detail rows if master is already selected/deselected
            const detailNode = params.node.detailNode?.detailGridInfo;
            if (params.node.isSelected()) {
                detailNode?.api.selectAll();
            } else if (detailNode?.api.getSelectedNodes().length === detailNode?.api.getDisplayedRowCount()) {
                detailNode?.api.deselectAll();
            }
        }

        if (params.node && params.data) {
            // Add or delete master childs to/from selectedRspRows
            params.data.childs.forEach(child => {
            /!* [CAW-1600-] Desactiver la checkAll & uncheckAll *!/
            if (this.isCheckAll) {
                if (params.node.isSelected()) {
                        if(!this.childAlreadyAdded){
                        this.selectedRspRows.push(child);
                        let index= this.unSelectedRspRows.indexOf(child);
                            if (index!== -1) {
                                this.unSelectedRspRows.splice(this.unSelectedRspRows.indexOf(child), 1);
                            }
                        if(index!== -1 && !this.firstCallCheckAll && this.nbRowSelectedRSP < this.nbRowSelectedRSPMAX){
                            if(child.signe == 0) {
                                this.montantTotalSelectedRSP -= child.montantExacte / 100;
                            }else {
                                this.montantTotalSelectedRSP += child.montantExacte / 100;
                            }
                            this.nbRowSelectedRSP = ++this.nbRowSelectedRSP;
                        }
                    }

                    } else if (!params.node.isSelected()) {
                        if(this.selectedRspRows.some(rsp => rsp.idRspRappSummary === child.idRspRappSummary)){
                            if(!this.childAlreadyRemoved){
                                let index= this.selectedRspRows.indexOf(child);
                                if (index!== -1) {
                                    this.selectedRspRows.splice(index, 1);
                                }
                                if(child.signe ==0) {
                                    this.montantTotalSelectedRSP += child.montantExacte / 100;
                                }else {
                                    this.montantTotalSelectedRSP -= child.montantExacte / 100;
                                }
                            this.nbRowSelectedRSP = --this.nbRowSelectedRSP;
                            this.unSelectedRspRows.push(child);
                            }
                        }
                    }
                } else {                   
                    if (params.node.isSelected() && !this.selectedRspRows.some(rsp => rsp.idRspRappSummary === child.idRspRappSummary)) {
                        this.selectedRspRows.push(child);
                    } else if (!params.node.isSelected()) {
                        if(!this.childAlreadyRemoved){
                            if(this.selectedRspRows.includes(child)){
                                this.selectedRspRows.splice(this.selectedRspRows.indexOf(child), 1);
                            }
                        }
                    }

                    
                    // checkALL or UncheckALL RSP
                    let selectedRspRowsGroup = this.groupBy(this.selectedRspRows, "numLot", 'libelleOrganisme','dateReception');
                    if( selectedRspRowsGroup.size == this.gridHelperRSP.totalRowCount )
                    {
                        this.gridHelperRSP.gridApi.getColumnDef('check').headerName = 'select';
                    }else
                    {
                        this.gridHelperRSP.gridApi.getColumnDef('check').headerName = 'deselect';
                        this.isCheckAll = false;
                    }

                }
            });
            this.childAlreadyRemoved = false;
            this.childAlreadyAdded = false;
        }

        /!* [CAW-1600-] Desactiver la checkAll & uncheckAll *!/
            //this.calculateSelectedRsp(params);
            this.checkVrtPointability();
            this.checkRspSoldability();
    }
*/
    groupBy(collection, property1, property2, property3){
        const getKey = (item) => `${item[property1]}-${item[property2]}-${item[property3]}`;
        const groups = new Map();

        for (const item of collection) {
            const key = getKey(item);
            if (groups.has(key)) {
                groups.get(key)?.push(item);
            } else {
                groups.set(key, [item]);
            }
        }
        return groups;
    }

    onSelectionChanged(params, grid: String) {
        this.isResetGridRSP = false;
        /* [CAW-1600-] Desactiver la checkAll & uncheckAll */
        if (grid == 'RSP') {
           /* if (this.isCheckAll) {
                if (params.node.isSelected() && this.nbRowSelectedRSP < this.nbRowSelectedRSPMAX) {
                    if(!this.selectedRspRows.some(rsp => rsp.idRspRappSummary === params.node.data.idRspRappSummary)){
                        this.childAlreadyAdded = true;
                        this.selectedRspRows.push(params.node.data);
                        let index= this.unSelectedRspRows.indexOf(params.node.data);
                        if (index !== -1) {
                           this.unSelectedRspRows.splice(index, 1);
                        }
                        if(index !== -1 && !this.firstCallCheckAll){
                            if(params.node.data.signe == 0){
                                this.montantTotalSelectedRSP -= params.node.data.montantExacte / 100;
                            }else{
                                this.montantTotalSelectedRSP += params.node.data.montantExacte / 100;
                            }
                            this.nbRowSelectedRSP = ++this.nbRowSelectedRSP;
                        }
                    }

                } else if (!params.node.isSelected()) {
                    if(this.selectedRspRows.some(rsp => rsp.idRspRappSummary === params.node.data.idRspRappSummary)){
                        this.childAlreadyRemoved = true;
                        this.selectedRspRows.splice(this.selectedRspRows.indexOf(params.node.data), 1);
                        if(params.node.data.signe == 0){
                            this.montantTotalSelectedRSP += params.node.data.montantExacte / 100;
                        }else{
                            this.montantTotalSelectedRSP -= params.node.data.montantExacte / 100;
                        }
                        this.nbRowSelectedRSP = --this.nbRowSelectedRSP;
                        this.unSelectedRspRows.push(params.node.data);
                    }
                }
            } else {
                if (params.node.isSelected()) {
                    if(!this.selectedRspRows.some(rsp => rsp.idRspRappSummary === params.node.data.idRspRappSummary)){
                        this.selectedRspRows.push(params.node.data);
                    }
                } else if (!params.node.isSelected()) {
                    if(this.selectedRspRows.some(rsp => rsp.idRspRappSummary === params.node.data.idRspRappSummary)){
                        this.childAlreadyRemoved = true;
                        this.selectedRspRows.splice(this.selectedRspRows.indexOf(params.node.data), 1);
                    }
                    params.node.parent.setSelected(false);
                }
            }

            // Select or deselect master node if details node selection is changed
            this.gridHelperRSP.gridOptions.api.forEachNode(masterRowNode => {
                const detailRowNode = masterRowNode.detailNode?.detailGridInfo;

                if (detailRowNode) {
                    if (detailRowNode.api.getSelectedNodes().length != 0 && detailRowNode.api.getSelectedNodes().length == detailRowNode.api.getDisplayedRowCount()) {
                        masterRowNode.setSelected(true);
                    } else if (detailRowNode.api.getSelectedNodes().length < detailRowNode.api.getDisplayedRowCount()) {
                        masterRowNode.setSelected(false);
                    }
                }
            });*/
            /* [CAW-1600-] Desactiver la checkAll & uncheckAll */
            //this.calculateSelectedRsp(params);

           // this.checkAllRspManagement();

        }
        else {
            if (this.isVRTCheckAll) {
                if(params.api !=null){
                    params.api.getRenderedNodes().map((node: RowNode) => {
                        if (node.isSelected()){
                            if (this.unSelectedVrtRows.some(vrt => vrt.idBankTransaction === node.data.idBankTransaction)){
                                this.selectedVrtRows.push(node.data);
                                this.montantTotalSelectedVRT += (node.data ? node.data.montant : 0);
                                this.nbRowSelectedVRT = ++this.nbRowSelectedVRT;
                                this.unSelectedVrtRows = this.unSelectedVrtRows.filter(vrt => vrt.idBankTransaction !== node.data.idBankTransaction);
                            }
                        }else{
                                if (!this.unSelectedVrtRows.some(vrt => vrt && node.data && vrt.idBankTransaction === node.data.idBankTransaction) && node.master ){
                                    this.unSelectedVrtRows.push(node.data);
                                    this.montantTotalSelectedVRT -= (node.data ? node.data.montant : 0);
                                    this.nbRowSelectedVRT = --this.nbRowSelectedVRT;
                                    this.selectedVrtRows.splice(this.selectedVrtRows.indexOf(node.data), 1);
                            }
                        }
                    });
                    this.aggregateDiffSelected();
                }
            } else {
                if(params.api !=null){
                    params.api.getRenderedNodes().map((node: RowNode) => {
                        if (node.isSelected() && !this.selectedVrtRows.some(vrt => vrt && node.data && vrt.idBankTransaction === node.data.idBankTransaction)) {
                            this.selectedVrtRows.push(node.data);
                        }
                        if(!node.isSelected() && this.selectedVrtRows.some(vrt => vrt && node.data && vrt.idBankTransaction === node.data.idBankTransaction)) {
                            this.selectedVrtRows.splice(this.selectedVrtRows.indexOf(node.data), 1);
                        }
                    })
                }
            }

            this.calculateSelectedVrt();
            this.checkAllVrtManagement();
        }
        /* [CAW-1600-] Desactiver la checkAll & uncheckAll */
        this.checkVrtPointability();
        this.checkRspSoldability();
    }


    checkAllRspManagement() {
        if(this.isCheckAll) {
            if(this.unSelectedRspRows.length == 0) {
                this.agCheckAllService.notifyCheckBox({ id: this.rspGridId, state: CheckAllState.SELECT });
                this.isCheckAll = true;
                this.unSelectedRspRows=[];
            } else if (this.unSelectedRspRows.length == this.nbRowSelectedRSPMAX){
                this.agCheckAllService.notifyCheckBox({ id: this.rspGridId, state: CheckAllState.UNSELECT });
                this.isCheckAll = false;
                //this.initSelectedRsp();
            }
        } else {
            var selectedRows = this.gridHelperRSP.gridApi.getSelectedRows();
            if(selectedRows.length  == 0) {
                this.agCheckAllService.notifyCheckBox({ id: this.rspGridId, state: CheckAllState.UNSELECT });
            } else if(this.selectedRspRows.length  == this.nbRowSelectedRSPMAX) {
                this.agCheckAllService.notifyCheckBox({ id: this.rspGridId, state: CheckAllState.SELECT });
                this.isCheckAll = true;
            }
        }
    }

    checkAllVrtManagement() {
        if(this.isVRTCheckAll) {
            if(this.unSelectedVrtRows.length == 0) {
                this.agCheckAllService.notifyCheckBox({ id: this.vrtGridId, state: CheckAllState.SELECT });
            } else if (this.unSelectedVrtRows.length == this.gridHelperVRT.totalRowCount){
                this.agCheckAllService.notifyCheckBox({ id: this.vrtGridId, state: CheckAllState.UNSELECT });
                this.isVRTCheckAll = false;
            } else {
                this.agCheckAllService.notifyCheckBox({ id: this.vrtGridId, state: CheckAllState.INDETERMINATE });
            }
        } else {
            var selectedRows = this.gridHelperVRT.gridApi.getSelectedRows();
            if(selectedRows.length  == 0) {
                this.agCheckAllService.notifyCheckBox({ id: this.vrtGridId, state: CheckAllState.UNSELECT });
            } else if(selectedRows.length  != this.gridHelperVRT.totalRowCount) {
                this.agCheckAllService.notifyCheckBox({ id: this.vrtGridId, state: CheckAllState.INDETERMINATE });
            } else if(selectedRows.length  == this.gridHelperVRT.totalRowCount) {
                this.agCheckAllService.notifyCheckBox({ id: this.vrtGridId, state: CheckAllState.SELECT });
            }
        }
    }

    /* [CAW-1600-] Desactiver la checkAll & uncheckAll */
     aggregateDiffSelected() {
         /* if(this.gridHelperRSP.gridApi?.getSelectedRows()?.length == 0 && this.isResetGridRSP){
            this.montantTotalSelectedRSP = 0;
            this.nbRowSelectedRSP = 0;
        }*/
        if(this.gridHelperVRT.gridApi?.getSelectedRows()?.length == 0 && this.isResetGridVRT){
            this.montantTotalSelectedVRT = 0;
            this.nbRowSelectedVRT = 0;
        }
        //let diffRSP = (this.montantTotalSelectedRSP ? parseFloat(this.montantTotalSelectedRSP.toFixed(2)) : 0) - (this.montantTotalSelectedVRT ? parseFloat(this.montantTotalSelectedVRT.toFixed(2)) : 0);
         let diffRSP = (this.agDemoComponent.montantTotalSelectedRSP ? parseFloat(this.agDemoComponent.montantTotalSelectedRSP.toFixed(2)) : 0) - (this.montantTotalSelectedVRT ? parseFloat(this.montantTotalSelectedVRT.toFixed(2)) : 0);
         this.totalDiffRSP = {
            montant: this.formatThousands.transform(diffRSP),
            status: diffRSP != 0 ? 'text-danger' : 'text-success'
        };
        this.agDemoComponent.totalDiffRSP = this.totalDiffRSP;

        this.totalDiffVRT = {
            montant: this.formatThousands.transform(-diffRSP),
            status: diffRSP != 0 ? 'text-danger' : 'text-success'
        };

    }
    gettotalDiffVRT(data: string) {
        this.totalDiffVRT=data;
    }
    calculateSelectedVrt():void{
        var selectedRows = this.gridHelperVRT.gridApi.getSelectedRows();
         if(this.isVRTCheckAll){
             this.aggregateDiffSelected();
         }else {
             this.nbRowSelectedVRT = selectedRows.length;
             var aggMontant = 0;

             selectedRows.forEach((row) => {
                 aggMontant += row.montant;
             });
             this.montantTotalSelectedVRT = aggMontant;
             this.aggregateDiffSelected();
         }
    }

  /*  calculateSelectedRsp(params: any): void {
        if (this.isCheckAll) {
            this.aggregateDiffSelected();
        } else {
            this.nbRowSelectedRSP = this.selectedRspRows.length;
            var aggMontant = 0;

            this.selectedRspRows.forEach((row) => {
                if(row.signe == 0){
                    aggMontant -= row.montantExacte / 100;
                }else
                aggMontant += row.montantExacte / 100;
            });
            this.montantTotalSelectedRSP = aggMontant;

            this.aggregateDiffSelected();
        }
    }*/

    resetMontantsNbrSelected(){
        this.montantTotalSelectedVRT = this.gridHelperVRT.gridApi.getSelectedRows().length != 0 
            ? this.montantTotalSelectedVRT
            : 0;

        this.montantTotalSelectedRSP = this.gridHelperRSP.gridApi.getSelectedRows().length != 0 
            ? this.montantTotalSelectedRSP
            : 0;

        this.nbRowSelectedVRT = this.gridHelperVRT.gridApi.getSelectedRows().length != 0 
            ? this.nbRowSelectedVRT
            : 0;

        this.nbRowSelectedRSP = this.gridHelperRSP.gridApi.getSelectedRows().length != 0 
            ? this.nbRowSelectedRSP
            : 0;
    }

    onGridReady(params, grid: String) {
        if (grid == 'RSP') {
            this.gridHelperRSP.gridApi = params.api;
            this.gridHelperRSP.gridColumnApi = params.columnApi;
            this.restoreGrid(this.gridHelperRSP);
            this.gridHelperRSP.gridApi.sizeColumnsToFit();
            if (this.rspGridDataRaw.columnState) {
                this.gridHelperRSP.gridColumnApi.applyColumnState({state: this.rspGridDataRaw.columnState});
            }
            this.launchSearchRSP();
        } else if (grid == 'VRT') {
            this.gridHelperVRT.gridApi = params.api;
            this.gridHelperVRT.gridColumnApi = params.columnApi;
            this.restoreGrid(this.gridHelperVRT);
            this.gridHelperVRT.gridApi.sizeColumnsToFit();
            if (this.vrtGridDataRaw.columnState) {
                this.gridHelperVRT.gridColumnApi.applyColumnState({state: this.vrtGridDataRaw.columnState});
            }
            this.launchSearchVRT();
        } /*else if (grid === 'detailsRSP') {
            params.api.sizeColumnsToFit();
            if (this.rspGridDataRaw.columnState) {
                params.columnApi.applyColumnState({state: this.rspGridDataRaw.columnState, applyOrder: true});
            }

            // Select details nodes if the master is selected
            this.gridHelperRSP.gridApi.getSelectedNodes().forEach(node => {
                //node.detailNode?.detailGridInfo?.api.selectAll();
            });

            // Select details nodes if they are already selected
            this.gridHelperRSP.gridApi.forEachDetailGridInfo(detail => {
                detail?.api.getRenderedNodes().filter(node => this.selectedRspRows
                    .map(rsp => rsp.idRspRappSummary)
                    .includes(node.data.idRspRappSummary))
                    .map(node => {
                        node.setSelected(true);
                    });
            });
        }*/
    }

    restoreGrid(gridHelper: AgGridHelper) {
        gridHelper.restoreGridStatePrefix();
    }

    onGridSizeChanged(params, grid: String) {
        if (grid == 'RSP') {
            this.gridHelperRSP.gridApi = params.api;
            this.gridHelperRSP.gridColumnApi = params.columnApi;
            this.rspGridDataRaw.columnState = this.storageService.get('rappManuRspGridColumnState', true);
            this.gridHelperRSP.gridColumnApi.applyColumnState({state:this.rspGridDataRaw.columnState, applyOrder: true});
            this.gridHelperRSP.gridApi.sizeColumnsToFit();
        } else {
            this.gridHelperVRT.gridApi.sizeColumnsToFit();
        }
    }

    reloadData(resetRSP,resetVRT) {
        if (this.isDateComptableRangeValid) {
            this.dateComptable.hideTooltlipDebut();
            this.dateComptable.hideTooltlipFin();
            if (resetVRT) { this.launchSearchVRT(); }
            if (resetRSP) { this.launchSearchRSP(); }
        } else {
            this.dateComptableAccordion.expansionPannel.open();
            this.dateComptable.showTooltipDebut();
            this.dateComptable.showTooltipFin();
        }
        this.assingRspList();
        this.assignVrtList();
    }

    launchSearchFilter() {
        this.montantVrtMinError = false;
        this.montantVrtMaxError = false;

        this.montantRspMinError = false;
        this.montantRspMaxError = false;

        this.montantGroupeRspMinError = false;
        this.montantGroupeRspMaxError = false;

        // Vérifier les montants et afficher les messages d'erreur si nécessaire
        let montantVrtFrom = parseFloat(String(this.filtersVRT.montantFrom));
        let montantVrtTo = parseFloat(String(this.filtersVRT.montantTo));

        if (!isNaN(montantVrtFrom) && !isNaN(montantVrtTo) && montantVrtFrom > montantVrtTo) {
            this.montantVrtMinError = true;
            this.montantVrtMaxError = true;
        } else if ((isNaN(montantVrtFrom) && !isNaN(montantVrtTo)) || (!isNaN(montantVrtFrom) && isNaN(montantVrtTo))) {
            this.montantVrtMinError = false;
            this.montantVrtMaxError = false;
        }

        let montantRspFrom = parseFloat(String(this.filtersRSP.montantRspFrom));
        let montantRspTo = parseFloat(String(this.filtersRSP.montantRspTo));

        if (!isNaN(montantRspFrom) && !isNaN(montantRspTo) && montantRspFrom > montantRspTo) {
            this.montantRspMinError = true;
            this.montantRspMaxError = true;
        } else if ((isNaN(montantRspFrom) && !isNaN(montantRspTo)) || (!isNaN(montantRspFrom) && isNaN(montantRspTo))) {
            this.montantRspMinError = false;
            this.montantRspMaxError = false;
        }

        const montantGroupeRspFrom = parseFloat(String(this.filtersRSP.montantGroupeRspFrom));
        const montantGroupeRspTo = parseFloat(String(this.filtersRSP.montantGroupeRspTo));

        if (!isNaN(montantGroupeRspFrom) && !isNaN(montantGroupeRspTo) && montantGroupeRspFrom > montantGroupeRspTo) {
            this.montantGroupeRspMinError = true;
            this.montantGroupeRspMaxError = true;
        } else if ((isNaN(montantGroupeRspFrom) && !isNaN(montantGroupeRspTo))
            || (!isNaN(montantGroupeRspFrom) && isNaN(montantGroupeRspTo))) {
            this.montantGroupeRspMinError = false;
            this.montantGroupeRspMaxError = false;
        }
        if (!this.montantGroupeRspMinError && !this.montantGroupeRspMaxError && !this.montantRspMinError &&
            !this.montantRspMaxError && !this.montantVrtMinError && !this.montantVrtMaxError) {
            let isVrtFiltersChanged = false;
            let isRspFiltersChanged = false;
            if (Object.keys(this.tempVrt).length) {
                isVrtFiltersChanged = Object.keys(this.filtersVRT).some(key => this.filtersVRT[key] !== this.tempVrt[key]);
            }
            else {
                isVrtFiltersChanged = this.hasVRTFilters();
            }
            if (Object.keys(this.tempRsp).length) {
                isRspFiltersChanged = Object.keys(this.filtersRSP).some(key =>
                    key!=='organismes'? this.filtersRSP[key] !== this.tempRsp[key]:
                    JSON.stringify(this.filtersRSP[key]) !== JSON.stringify(this.tempRsp[key])
                    );
            }
            else {
                isRspFiltersChanged = this.hasRSPFilters();
            }
            if (this.isDateComptableRangeValid) {
                this.dateComptable.hideTooltlipDebut();
                this.dateComptable.hideTooltlipFin();
                if (isVrtFiltersChanged) {
                    this.launchSearchVRT();
                }
                if (isRspFiltersChanged) {
                    this.agDemoComponent.initSelectedRsp();
                    this.launchSearchRSP();
                }
            } else {
                this.dateComptableAccordion.expansionPannel.open();
                this.dateComptable.showTooltipDebut();
                this.dateComptable.showTooltipFin();
            }
            this.unSelectedRspRows =[];
            this.assingRspList();
            this.assignVrtList();
        }
    }

    assingRspList() {
        this.tempRsp.montantRspFrom = this.filtersRSP.montantRspFrom;
        this.tempRsp.montantRspTo = this.filtersRSP.montantRspTo;
        this.tempRsp.montantRspExacte = this.filtersRSP.montantRspExacte;
        this.tempRsp.montantRspFilter = this.filtersRSP.montantRspFilter;
        this.tempRsp.numLot = this.filtersRSP.numLot;
        this.tempRsp.montantGroupeRspFrom = this.filtersRSP.montantGroupeRspFrom;
        this.tempRsp.montantGroupeRspTo = this.filtersRSP.montantGroupeRspTo;
        this.tempRsp.montantGroupeRspExacte = this.filtersRSP.montantGroupeRspExacte;
        this.tempRsp.montantGroupeRspFilter = this.filtersRSP.montantGroupeRspFilter;
        //this.tempRsp.rsp30Jours = this.filtersRSP.rsp30Jours;
        this.tempRsp.rspIndu = this.filtersRSP.rspIndu;
        this.tempRsp.libelleOrganisme = this.filtersRSP.libelleOrganisme;
        this.tempRsp.idOrganisme = this.filtersRSP.idOrganisme;
        this.tempRsp.organismes=this.filtersRSP.organismes?JSON.parse(JSON.stringify(this.filtersRSP.organismes)):this.filtersRSP.organismes
        this.tempRsp.dateComptableTo = this.filtersRSP.dateComptableTo;
        this.tempRsp.dateComptableFrom = this.filtersRSP.dateComptableFrom;
        this.tempRsp.dateReception = this.filtersRSP.dateReception;
        this.tempRsp.numFacture = this.filtersRSP.numFacture;
        this.tempRsp.idPs = this.filtersRSP.idPs;

        this.tempRsp.dateReceptionFacture = this.filtersRSP.dateReceptionFacture;
        this.tempRsp.libelleVirement = this.filtersRSP.libelleVirement;
        this.tempRsp.periode = this.filtersRSP.periode;
        this.tempRsp.numLotFrom = this.filtersRSP.numLotFrom;
        this.tempRsp.numLotTo = this.filtersRSP.numLotTo;
    }

    assignVrtList() {
        this.tempVrt.montant = this.filtersVRT.montant;
        this.tempVrt.dateOperationTo = this.filtersVRT.dateOperationTo;
        this.tempVrt.dateOperationFrom = this.filtersVRT.dateOperationFrom;
        this.tempVrt.label = this.filtersVRT.label;
        this.tempVrt.notes = this.filtersVRT.notes;
        this.tempVrt.dateComptable = this.filtersVRT.dateComptable;
        this.tempVrt.dateImport = this.filtersVRT.dateImport;
        this.tempVrt.emetteur = this.filtersVRT.emetteur;
        this.tempVrt.montantFrom = this.filtersVRT.montantFrom;
        this.tempVrt.montantTo = this.filtersVRT.montantTo;
        this.tempVrt.montantFilter = this.filtersVRT.montantFilter;
    }

    launchSearchRSP(initFilter = true): void {
        this.rspRappSummaryDataRaw = new RspRappSummaryDataRaw();

        this.rspRappSummaryDataRaw.idRapprochement = -1;
        this.rspRappSummaryDataRaw.statutPaiement = 0;
        this.rspRappSummaryDataRaw.signe = 1;
        this.rspRappSummaryDataRaw.idPs = this.getPsId();// this.userConnected.idPs;

        if(initFilter) {
            this.updateFilterList(this.filtersRSP, this.filtersVRT);
        }

        this.rspRappSummaryDataRaw.dateComptableFrom = this.convertFormatDate.getFormatDateFr(this.filtersRSP.dateComptableFrom);
        this.rspRappSummaryDataRaw.dateComptableTo = this.convertFormatDate.getFormatDateFr(this.filtersRSP.dateComptableTo);
        this.rspRappSummaryDataRaw.libelleOrganisme = this.filtersRSP.libelleOrganisme;
        this.rspRappSummaryDataRaw.organismes = this.filtersRSP.organismes?JSON.parse(JSON.stringify(this.filtersRSP.organismes)):this.filtersRSP.organismes;
        this.rspRappSummaryDataRaw.montantFrom = this.filtersRSP.montantRspFrom;
        this.rspRappSummaryDataRaw.montantTo = this.filtersRSP.montantRspTo;
        this.rspRappSummaryDataRaw.montantExacte = this.filtersRSP.montantRspExacte;

        this.rspRappSummaryDataRaw.montantGroupeFrom = this.filtersRSP.montantGroupeRspFrom;
        this.rspRappSummaryDataRaw.montantGroupeTo = this.filtersRSP.montantGroupeRspTo;
        this.rspRappSummaryDataRaw.montantGroupeExacte = this.filtersRSP.montantGroupeRspExacte;
        this.rspRappSummaryDataRaw.libelleVirement = this.filtersRSP.libelleVirement;
        this.rspRappSummaryDataRaw.idOrganisme = this.filtersRSP.idOrganisme;

        this.filtersRSP.numLot = this.formatNumLot(this.filtersRSP.numLot);
        //this.tempRsp.numLot = this.formatNumLot(this.filtersRSP.numLot);
        this.rspRappSummaryDataRaw.numLot = this.filtersRSP.numLot || null;
        //this.rspRappSummaryDataRaw.rsp30Jours = this.filtersRSP.rsp30Jours;
        this.rspRappSummaryDataRaw.rspIndu = this.filtersRSP.rspIndu;

        Object.keys(this.rspRappSummaryDataRaw).forEach((key) => {
            if (isNullOrUndefined(this.rspRappSummaryDataRaw[key]) || this.rspRappSummaryDataRaw[key] === '') {
                delete this.rspRappSummaryDataRaw[key];
            }
        });
        if(!isNullOrUndefined(this.agDemoComponent)){
            this.agDemoComponent.loadData(this.rspRappSummaryDataRaw);
            if(!isNullOrUndefined(this.rspRappSummaryDataRaw.organismes)){
                this.searchRspFilter=true;
            }
        }
        //this.initSelectedRsp();
      /*  const dataSource: IServerSideDatasource = {
            getRows: function (paramsRows: IServerSideGetRowsParams) {
                const sorts: CustomSortDataRow[] = gridConvertSort(ctx.gridHelperRSP.gridSortModel(), []);
                ctx.rspRappSummaryService
                    .getRappSummarysFilteredList(
                        {
                            page: ctx.gridHelperRSP.gridApi.paginationGetCurrentPage(),
                            size: ctx.gridHelperRSP.gridApi.paginationGetPageSize(),
                            sorts
                        },
                        ctx.rspRappSummaryDataRaw
                    )
                    .subscribe(
                        (data) => {
                            const rowCount = data.totalElements;
                            ctx.gridHelperRSP.manageNoRowsOverlay(rowCount);
                            paramsRows.success({"rowData": data.content, "rowCount": rowCount});
                        },
                        () => paramsRows.fail()
                    );
            }
        };*/

     /*   if(!this.isCheckAll) {
            this.gridHelperRSP.gridApi.deselectAll();
            this.gridHelperRSP.gridApi.getColumnDef('check').headerName = 'deselect';
            this.initSelectedRsp();
            this.aggregateDiffSelected();
        }else {
            this.onCheckAll(this.isCheckAll);
        }
      //  this.gridHelperRSP.gridApi.setServerSideDatasource(dataSource);
        this.gridHelperRSP.gridApi.sizeColumnsToFit();
        //this.gridHelperRSP.gridApi.deselectAll();
        this.isShowRspDetail = false;
       // this.gridHelperRSP.gridApi?.refreshHeader();
        this.getTotalelement();
       // this.gridHelperRSP.gridApi?.refreshHeader();*/
        sessionStorage['rapproManRspFilter'] = JSON.stringify(this.filtersRSP);
    }

    launchSearchVRT(initFilter = true, initSelectionFilter = true): void {
        const ctx = this;
        this.transactionDataRaw = new TransactionDataRaw();

        this.transactionDataRaw.idRapprochement = -1;

        this.transactionDataRaw.idPs = this.getPsId();

        if(initFilter) {
            this.updateFilterList(this.filtersRSP, this.filtersVRT);
        }

        this.transactionDataRaw.label = this.filtersVRT.label;
        this.transactionDataRaw.notes = this.filtersVRT.label;
        this.transactionDataRaw.emetteur = this.filtersVRT.emetteur;
        this.transactionDataRaw.dateOperationFrom = this.convertFormatDate.getFormatDateFr(this.filtersVRT.dateOperationFrom);
        this.transactionDataRaw.dateOperationTo = this.convertFormatDate.getFormatDateFr(this.filtersVRT.dateOperationTo);
        this.transactionDataRaw.montantFrom = this.filtersVRT.montantFrom;
        this.transactionDataRaw.montantTo = this.filtersVRT.montantTo;
        this.transactionDataRaw.montant = this.filtersVRT.montant;

        Object.keys(this.transactionDataRaw).forEach((key) => {
            if (isNullOrUndefined(this.transactionDataRaw[key]) || this.transactionDataRaw[key] === '') {
                delete this.transactionDataRaw[key];
            }
        });

        const dataSource: IServerSideDatasource = {
            getRows: function (paramsRows: IServerSideGetRowsParams) {
                const sorts: CustomSortDataRow[] = gridConvertSort(ctx.gridHelperVRT.gridSortModel(), []);
                ctx.transactionService
                    .getTransactionsFilteredList(
                        {
                            page: ctx.gridHelperVRT.gridApi.paginationGetCurrentPage(),
                            size: ctx.gridHelperVRT.gridApi.paginationGetPageSize(),
                            sorts
                        },
                        ctx.transactionDataRaw
                    )
                    .subscribe(
                        (data) => {
                            const rowCount = data.totalElements;
                            ctx.gridHelperVRT.manageNoRowsOverlay(rowCount);
                            paramsRows.success({"rowData": data.content, "rowCount": rowCount});
                        },
                        () => paramsRows.fail()
                    );
            }
        };
        this.gridHelperVRT.gridApi.setServerSideDatasource(dataSource);
        this.gridHelperVRT.gridApi.sizeColumnsToFit();
        sessionStorage['rapproManVrtFilter'] = JSON.stringify(this.filtersVRT);
        if(initSelectionFilter) {
            this.initSelectedVrt();
            this.gridHelperVRT.gridApi.deselectAll();
            this.calculateVRT();
        }
    }

    onDetailClicked(params) {
        this.selectedRow = params.data;

        this.selectedRow.montantTotalDemande = parseFloat(this.selectedRow.montantTotalDemande);
        this.showRspDetail = true;
    }
    /* [CAW-1600-] Desactiver la checkAll & uncheckAll */
    validateRapprochements(commentaire?: string, rapprochementType: number = 0, pointerKeyword?: string) {
        var rapp = new RapprochementDataRaw();
        rapp.dateRapprochement = new Date();
        rapp.dateValide = new Date();
        rapp.rapprochementAuto = rapprochementType;
        rapp.valide = 1;
        rapp.idRappComptePs = this.getIdRappComptePs();
        rapp.idPs = this.getPsId();
        rapp.label = rapprochementType === 3 ? 'Pointage' : rapprochementType === 2 ? 'Solde' : 'RAPPROCHEMENT MANUEL';
        rapp.montantTotalRsp = 0;
        rapp.nbRsp = 0;
        rapp.montantTotalTr = 0;
        rapp.nbTransactions = 0;
        rapp.commentaire = commentaire;
        rapp.pointerKeyword = pointerKeyword;
        // user check all rsp and vrt
        if (this.agDemoComponent.checkAll && this.isVRTCheckAll) {
            this.rspRappSummaryDataRaw.idRspRappSummaries = this.unSelectedRspRows.map(rsp => rsp.idRspRappSummary);
            this.transactionDataRaw.idBankTransactions = this.unSelectedVrtRows.map(vrt => vrt.idBankTransaction);
            forkJoin([
                    this.rspRappSummaryService.getAllCheckedRsp(this.rspRappSummaryDataRaw),
                    this.transactionService.getAllCheckedVRT(this.transactionDataRaw)
                ]).subscribe((data: any) => {
                    rapp.rsps = data[0];
                    rapp.bankTransactions = data[1];
                    this.createRapprochement(rapp);
                });
        } 
        // user check all rsp
        else if(this.agDemoComponent.checkAll && !this.isVRTCheckAll) {
            this.rspRappSummaryDataRaw.idRspRappSummaries = this.unSelectedRspRows.map(rsp => rsp.idRspRappSummary);
            this.rspRappSummaryService.getAllCheckedRsp(this.rspRappSummaryDataRaw).subscribe((data: any) =>{
                rapp.rsps = data;
                rapp.bankTransactions = this.gridHelperVRT.gridApi.getSelectedRows();
                this.createRapprochement(rapp);
                this.agDemoComponent.checkAll= false;
            });
        } 
        // user check all vrt
        else if(!this.isCheckAll && this.isVRTCheckAll) {
            this.transactionDataRaw.idBankTransactions = this.unSelectedVrtRows.map(vrt => vrt && vrt.idBankTransaction);
            this.transactionService.getAllCheckedVRT(this.transactionDataRaw).subscribe((data: any) =>{
                rapp.bankTransactions = data;
                rapp.rsps = this.selectedRspRows;
                this.createRapprochement(rapp);
                this.isVRTCheckAll = false;
            });
        }else{
            rapp.rsps = this.selectedRspRows;
            rapp.bankTransactions = this.gridHelperVRT.gridApi.getSelectedRows();
            this.createRapprochement(rapp);
        }
    }

    createRapprochement(rapp: RapprochementDataRaw): void {
        rapp.organismeLibelle = rapp.rsps
            ?.map((elem) => elem.libelleOrganisme)
            ?.filter((value, index, self) => self.indexOf(value) === index)
            ?.join(', ');
        rapp.montantTotalRsp = this.agDemoComponent.montantTotalSelectedRSP;
        rapp.nbRsp = rapp.rsps?.length;

        rapp.rsps?.map((rsp) => {
            if (rapp.dateReceptionRsp == null || rapp.dateReceptionRsp < rsp.dateReception)
                rapp.dateReceptionRsp = rsp.dateReception;
        });

        rapp.montantTotalTr = this.montantTotalSelectedVRT;
        rapp.nbTransactions = rapp.bankTransactions?.length;

        rapp.bankTransactions?.map((bt) => {
            if (rapp.dateComptableTr == null || rapp.dateComptableTr < bt.dateComptable)
            rapp.dateComptableTr = bt.dateComptable;
        });

        // rapp.bankTransactions = this.gridHelperVRT.gridApi.getSelectedRows();
        // rapp.bankTransactions.map((bt) => {
        //     rapp.montantTotalTr += bt.montant;
        //     rapp.nbTransactions += 1;

        //     if (rapp.dateComptableTr == null || rapp.dateComptableTr < bt.dateComptable)
        //         rapp.dateComptableTr = bt.dateComptable;
        // });

        this.rapprochementComptePsService.createRapprochement(rapp).subscribe((data : RapprochementDataRaw) => {
            this.modalEvent?.emit({message:'hide',data:null})
            this.modalEventSubscription?.unsubscribe()
            this.modalEvent=null
         //   this.gridHelperRSP.gridApi.deselectAll();
            this.gridHelperVRT.gridApi.deselectAll();
            if(data.idRapprochement !== 0 ) {
             this.toastr.success('Rapprochement effectué avec succès', 'Rapprochement manuel');
            }
            else{
             this.toastr.success('Votre pointage automatique est bien enregistré.', 'Rapprochement manuel');
            }

            if(this.isCheckAll) {
                this.isCheckAll = false;
                this.agCheckAllService.notifyCheckBox({ id: this.rspGridId, state: CheckAllState.UNSELECT });
            }
            this.agDemoComponent.initSelectedRsp();
            this.launchSearchRSP();
            this.launchSearchVRT();

        },
        err=>{
            if(err.message.includes('règle Toujours pointer dupliquée')){
                this.modalEvent.emit({message:'erreur',data:rapp.pointerKeyword})
            }
        });
    }

    formatDate = (params): string => {
        if (params.value != 'MULTI') return this.datePipe.transform(params.value, 'shortDate');
        else return params.value;
    };

    formatCurrencyVrtRapp = (params): string => {
        if (params.data) {
            return this.formatThousands.transform(params.value);
        }
    };

    formatCurrencyRapp = (params): string => {
        var montant =this.formatThousands.transform(parseFloat(params.value) / 100);
        if (params.data) {
            if(params.node.data && params.value!= 0 && params.node.data.signe==0 ){
                montant = '-'+ montant;
            }
            return montant;
        }}

    formatNumLotCell = (params): string => {
        if (params.data) {
            let numLot: string;
            if (params.value.trim() == '') {
                numLot = 'Aucun lot';
            } else {
                numLot = params.value;
            }
            return numLot + ' (' + params.data.factureNumber + ')';
        }
    };

    formatNumFacture = (params): string => {
        if (params.data && params.value.trim() != '') {
            return 'FSE n° ' + params.value;
        }
    }

    getDetailsRSP() {
        return {
            headerName: '',
            field: 'detailRSP',
            width: 60,
            minWidth: 40,
            headerClass: 'ag-header-merge',
            cellRenderer: RappAutoPreviewcomponent,
            onCellClicked: (params) => this.onDetailClicked(params),
            cellStyle: this.gridHelperRSP.centerContent()
        };
    }

    toggleFilterDisplay() {
        if (this.initHidden) {
            this.initHidden = false;
            setTimeout(() => {
                this.showFilter = !this.showFilter;
            }, 10);
        } else {
            this.showFilter = !this.showFilter;
        }
    }

    public closeModalRspDetail() {
       // this.selectedRow = null;
        //this.showRspDetail = false;
        this.agDemoComponent.closeModalRspDetail();
    }

    initRspMontant() {
		if(this.filtersRSP.montantRspFilter == FilterChoiceEnum.EXACTE) {
			this.filtersRSP.montantRspFrom = null;
			this.filtersRSP.montantRspTo = null;
		} else if (this.filtersRSP.montantRspFilter == FilterChoiceEnum.PLAGE) {
			this.filtersRSP.montantRspExacte = null;
		}

        if(this.filtersRSP.montantGroupeRspFilter == FilterChoiceEnum.EXACTE) {
			this.filtersRSP.montantGroupeRspFrom = null;
			this.filtersRSP.montantGroupeRspTo = null;
		} else if (this.filtersRSP.montantGroupeRspFilter == FilterChoiceEnum.PLAGE) {
			this.filtersRSP.montantGroupeRspExacte = null;
		}
    }

    initVrtMontant() {
		if(this.filtersVRT.montantFilter == FilterChoiceEnum.EXACTE) {
			this.filtersVRT.montantFrom = null;
			this.filtersVRT.montantTo = null;
		} else if (this.filtersVRT.montantFilter == FilterChoiceEnum.PLAGE) {
			this.filtersVRT.montant = null;
		}
    }

    updateFilterList(filtersRSP: RspRappSummaryFilterRaw, filtersVRT: TransactionFilterRaw) {
        const ctx = this;
        ctx.filterList = [];
        let montantRspKeys = ['montantRspFrom', 'montantRspTo', 'montantRspExacte', 'montantRspFilter'
                ,'montantGroupeRspFrom', 'montantGroupeRspTo','montantGroupeRspExacte','montantGroupeRspFilter'];
        let montantVrtKeys = ['montant', 'montantFrom', 'montantTo', 'montantFilter'];
        this.initRspMontant();
        this.initVrtMontant();
        Object.keys(filtersRSP)
        .filter((key) => !montantRspKeys.includes(key))
        .forEach((key) => {
            if (filtersRSP[key] !== null && filtersRSP[key] !== '') {
                if (key === 'organismes') {
                    filtersRSP[key].forEach((organisme) => {
                        ctx.filterList.push(ctx.translateFilterLabelValue(key, organisme));
                    });
                } else {
                    ctx.filterList.push(ctx.translateFilterLabelValue(key, filtersRSP[key]));
                }
            }
        });
        if(filtersRSP.montantRspFilter) {
            if(filtersRSP.montantRspFilter == FilterChoiceEnum.EXACTE) {
                if(filtersRSP.montantRspExacte)
                ctx.filterList.push(ctx.translateFilterLabelValue('montantRspExacte', filtersRSP.montantRspExacte));
            } else if(filtersRSP.montantRspFilter == FilterChoiceEnum.PLAGE) {
                if(filtersRSP.montantRspFrom) {
                    ctx.filterList.push(ctx.translateFilterLabelValue('montantRspFrom', filtersRSP.montantRspFrom));
                }
                if(filtersRSP.montantRspTo) {
                    ctx.filterList.push(ctx.translateFilterLabelValue('montantRspTo', filtersRSP.montantRspTo));
                }
            }
        }
        if(filtersRSP.montantGroupeRspFilter) {
            if(filtersRSP.montantGroupeRspFilter == FilterChoiceEnum.EXACTE) {
                if(filtersRSP.montantGroupeRspExacte)
                ctx.filterList.push(ctx.translateFilterLabelValue('montantGroupeRspExacte', filtersRSP.montantGroupeRspExacte));
            } else if(filtersRSP.montantGroupeRspFilter == FilterChoiceEnum.PLAGE) {
                if(filtersRSP.montantGroupeRspFrom) {
                    ctx.filterList.push(ctx.translateFilterLabelValue('montantGroupeRspFrom', filtersRSP.montantGroupeRspFrom));
                }
                if(filtersRSP.montantGroupeRspTo) {
                    ctx.filterList.push(ctx.translateFilterLabelValue('montantGroupeRspTo', filtersRSP.montantGroupeRspTo));
                }
            }
        }
        Object.keys(filtersVRT)
        .filter((key) => !montantVrtKeys.includes(key))
        .forEach((key) => {
            if (filtersVRT[key] != null && filtersVRT[key] != '') {
                ctx.filterList.push(ctx.translateFilterLabelValue(key, filtersVRT[key]));
            }
        });
        if(filtersVRT.montantFilter) {
            if(filtersVRT.montantFilter == FilterChoiceEnum.EXACTE) {
                if(filtersVRT.montant)
                ctx.filterList.push(ctx.translateFilterLabelValue('montant', filtersVRT.montant));
            } else if(filtersVRT.montantFilter == FilterChoiceEnum.PLAGE) {
                if(filtersVRT.montantFrom) {
                    ctx.filterList.push(ctx.translateFilterLabelValue('montantFrom', filtersVRT.montantFrom));
                }
                if(filtersVRT.montantTo) {
                    ctx.filterList.push(ctx.translateFilterLabelValue('montantTo', filtersVRT.montantTo));
                }
            }
        }
        // Remove duplicated ids
        ctx.filterList = uniqBy(ctx.filterList, 'id');
        // filter null
        ctx.filterList = ctx.filterList.filter((item) => {
            return item.id != null;
        });
    }

    formatDateForTags = (params): string => {
        if (params.value !== null && params.value != 'Invalid Date') {
            return this.datePipe.transform(params.value, 'dd/MM/yyyy');
        }
    };

    formatNumLot(numLot) {
        if (!isNullOrUndefined(numLot)) {
            numLot = numLot.trim();
            if (numLot.length == 3) {
                return numLot;
            } else if (numLot.length == 2) {
                return '0' + numLot;
            } else if (numLot.length == 1) {
                return '00' + numLot;
            }
        } else {
            return null;
        }
    }

    translateFilterLabelValue(key, value) {
        let translatedKey = key;
        let formattedValue = value;
        let formattedKey = key;
        const ctx = this;
        switch (key) {
            case 'label':
                translatedKey = 'Libellé / Notes virement contient';
                break;
            case 'libelleOrganisme':
                translatedKey = 'Libellé organisme contient';
                break;
            case 'emetteur':
                translatedKey = 'Emetteur virement contient';
                break;
            case 'dateComptableFrom':
                translatedKey = 'Date comptable supérieure au';
                formattedValue = ctx.formatDateForTags({ value: value });
                break;
            case 'dateComptableTo':
                translatedKey = 'Date comptable inférieure au';
                formattedValue = ctx.formatDateForTags({ value: value });
                break;
            case 'montantFrom':
                translatedKey = 'Montant VRT supérieur ou égal à';
                formattedValue = value + ' €';
                break;
            case 'montantTo':
                translatedKey = 'Montant VRT inférieur ou égal à';
                formattedValue = value + ' €';
                break;
            case 'montant':
                translatedKey = 'Montant VRT';
                formattedValue = value + ' €';
                break;
            case 'dateOperationFrom':
                translatedKey = 'Date opération supérieure au';
                formattedValue = ctx.formatDateForTags({ value: value });
                break;
            case 'dateOperationTo':
                translatedKey = 'Date opération inférieure au';
                formattedValue = ctx.formatDateForTags({ value: value });
                break;
            case 'numLot':
                translatedKey = 'N° du lot';
                break;
            case 'montantRspExacte':
                translatedKey = 'Montant RSP';
                formattedValue = value + ' €';
                break;
            case 'montantRspFrom':
                translatedKey = 'Montant RSP supérieur ou égal à';
                formattedValue = value + ' €';
                break;
            case 'montantRspTo':
                translatedKey = 'Montant RSP inférieur ou égal à';
                formattedValue = value + ' €';
                break;
            case 'montantGroupeRspExacte':
                translatedKey = "Montant lot de RSP";
                formattedValue = value + ' €';
                break;
            case 'montantGroupeRspFrom':
                translatedKey = "Montant minimal d'un lot de RSP";
                formattedValue = value + ' €';
                break;
            case 'montantGroupeRspTo':
                translatedKey = "Montant maximal d'un lot de RSP";
                formattedValue = value + ' €';
                break;
            case 'organismes':
                translatedKey = "Organisme";
                formattedKey = "organisme-" + value;
                break;
            case 'libelleVirement':
                translatedKey = 'Libellé de virt. du RSP contient';
                break;
            case 'idOrganisme':
                translatedKey = 'Code organisme émetteur';
                break;
            case 'rsp30Jours':
                translatedKey = 'Mettre en avant les RSP de + 30j';
                formattedValue = value ? 'Oui' : 'Non';
                break;
            case 'rspIndu':
                translatedKey = 'RSP INDU';
                formattedValue = value ? 'Oui' : 'Non';
                break;
            default:
                break;
        }
        return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
    }

    removeFilter(id, value?) {
        const ctx = this;
        if (ctx.filtersRSP[id] != null || id.startsWith("organisme") /*|| ctx.filtersRSP['organismes'] != null*/) {
            if (ctx.filtersRSP['organismes'] != null && value != null && id === 'organisme-' + value) {
                for (let index = 0; index < ctx.filtersRSP['organismes'].length; index++) {
                    const organisme = ctx.filtersRSP['organismes'][index];
                    if (value === organisme) {
                        delete ctx.filtersRSP['organismes'][index];
                    }
                }
            } else if (ctx.filtersRSP[id] != null && id !== 'rsp30Jours') {
                delete ctx.filtersRSP[id];
            }

            const currentFilterList = ctx.filterList;
            const index = findIndex(currentFilterList, (filt) => filt.id === id);
            currentFilterList.splice(index, 1);
            ctx.filterList = currentFilterList;
            this.fillFilterObjectFromTagsOnly('filtersRSP');
            this.agDemoComponent.initSelectedRsp();
            ctx.launchSearchRSP(false);
        }

        if (ctx.filtersVRT[id] != null) {
            ctx.filtersVRT[id]=null;
            let currentFilterList = ctx.filterList;
            const index = findIndex(currentFilterList, (filt) => filt.id === id);
            currentFilterList.splice(index, 1);
            ctx.filterList = currentFilterList;
            this.fillFilterObjectFromTagsOnly('filtersVRT');            
            ctx.launchSearchVRT(false);
            this.calculateVRT();
        }
    }

    fillFilterObjectFromTagsOnly(filters) {
        const vrtFilterList = ['notes', 'label', 'montantFrom', 'montantTo', 'montant', 'dateOperationFrom', 'dateOperationTo'];
        if (filters === 'filtersRSP') {
            let montantRspFilter = this.filtersRSP.montantRspFilter;
            let montantGroupeRspFilter = this.filtersRSP.montantGroupeRspFilter;

            this.filtersRSP = new RspRappSummaryFilterRaw();
            this.tempRsp = new RspRappSummaryFilterRaw();
            this.filterList.forEach(filter => {
                if (filter.label === 'Organisme') {
                    if (this.filtersRSP['organismes'] == null) {
                        this.filtersRSP['organismes'] = new Array<string>();
                        this.tempRsp['organismes'] = new Array<string>();
                    }
                    this.filtersRSP['organismes'].push(filter.value2);
                    this.tempRsp['organismes'].push(filter.value2);
                } else if (!vrtFilterList.includes(filter.id)) {
                    this.filtersRSP[filter.id] = filter.value2;
                    this.tempRsp[filter.id] = filter.value2;
                }
            });
            this.filtersRSP.montantRspFilter = montantRspFilter;
            this.filtersRSP.montantGroupeRspFilter = montantGroupeRspFilter;
            this.tempRsp.montantRspFilter = montantRspFilter;
            this.tempRsp.montantGroupeRspFilter = montantGroupeRspFilter;
        } else {
            let montantFilter = this.filtersVRT.montantFilter;
            this.filtersVRT = new TransactionFilterRaw();
            this.tempVrt = new TransactionFilterRaw();
            this.filterList.forEach(filter => {
                if (vrtFilterList.includes(filter.id)) {
                this.filtersVRT[filter.id] = filter.value2;
                this.tempVrt[filter.id] = filter.value2;
                }
            });
            this.filtersVRT.montantFilter = montantFilter;
            this.tempVrt.montantFilter = montantFilter;
        }
    }

    /* ----------------  CONFIRM MODAL -------------- */

    public openModalConfim() {
        let totalRSP = this.agDemoComponent.montantTotalSelectedRSP ? parseFloat(this.agDemoComponent.montantTotalSelectedRSP .toFixed(2)) : 0;
        let totalVRT = this.montantTotalSelectedVRT ? parseFloat(this.montantTotalSelectedVRT.toFixed(2)) : 0;
        const transformedSelectedRspRows = this.agDemoComponent.selectedRspRows.map(item => item.rspRappSummaryDto);
        this.selectedRspRows=transformedSelectedRspRows;
        const transformedUnSelectedRspRows = this.agDemoComponent.unSelectedRspRows.map(item => item.rspRappSummaryDto);
        this.unSelectedRspRows=transformedUnSelectedRspRows;
        if (totalRSP != totalVRT) {
            this.openedModal = this.modalService.show(ModalConfirmRappAutoComponent, { initialState: { hasCommentaire: true } });
            const sub = this.modalService.onHide.subscribe(_ => {
                if (this.openedModal.content.isConfirmed) {
                    this.validateRapprochements(this.openedModal.content.commentaire);
                }
                sub.unsubscribe();
            });
        } else {
            this.validateRapprochements();
        }
    }

     /* ----------------  POINTER-SOLDER MODAL -------------- */
    openPointerSolderModal(type: string): void {
        this.modalEvent = new EventEmitter<{message,data}>();
        this.pointerSolderModal = this.modalService.show(PointerSolderModalComponent, {
            initialState: {
                modalEvent:this.modalEvent,
                title: type === 'solder' ? 'Voulez vous solder ce RSP ?' : 'Voulez-vous pointer ce VRT ?',
                isSolderForm: type === 'solder' ? true : false,
                motifs: type === 'solder' ? ['Autre banque ou autre compte', 'RSP en doublon',
                    'Toujours solder', 'Autre motif'] : ['Hors Assurance maladie',
                    'Aucun RSP correspondant', ...this.gridHelperVRT.gridApi?.getSelectedRows().length === 1 ? ['Toujours pointer'] : [], 'Autre motif'],
            }
        });
        const transformedSelectedRspRows = this.agDemoComponent.selectedRspRows.map(item => item.rspRappSummaryDto);
        this.selectedRspRows=transformedSelectedRspRows;
        const transformedUnSelectedRspRows = this.agDemoComponent.unSelectedRspRows.map(item => item.rspRappSummaryDto);
        this.unSelectedRspRows=transformedUnSelectedRspRows;
       this.modalEventSubscription= this.modalEvent.subscribe(
            data=>{
                if(data && data.message==="valider" && this.pointerSolderModal.content.motif){
                    this.validateRapprochements(
                        this.pointerSolderModal.content.motif,
                        type === 'solder' ? 2 : 3,
                        this.pointerSolderModal.content.pointerKeyword
                    );
                }
            }
        )
    }

    enabledToValidate() {
        if(!isNullOrUndefined(this.agDemoComponent))
        return this.agDemoComponent.totalRspSelected > 0 || this.nbRowSelectedVRT > 0;
    }

    ngOnDestroy() {
        if (!this.isDateComptableRangeValid) {

            if (JSON.parse(sessionStorage.getItem('rapproManRspFilter')).dateComptableFrom) {
                this.filtersRSP.dateComptableFrom = new Date(JSON.parse(sessionStorage.getItem('rapproManRspFilter')).dateComptableFrom);

            } else {
                this.filtersRSP.dateComptableFrom = null;
            }

            if (JSON.parse(sessionStorage.getItem('rapproManRspFilter')).dateComptableTo) {
                this.filtersRSP.dateComptableTo = new Date(JSON.parse(sessionStorage.getItem('rapproManRspFilter')).dateComptableTo);

            } else {
                this.filtersRSP.dateComptableTo = null;
            }

        }
        // this.filtersRSP.initMontantFilter();
        // this.filtersVRT.initMontantFilter();
        sessionStorage['rapproManVrtFilter'] = JSON.stringify(this.filtersVRT);
        sessionStorage['rapproManRspFilter'] = JSON.stringify(this.filtersRSP);
       // this.gridHelperRSP.saveGridStatePrefix();
        this.gridHelperVRT.saveGridStatePrefix();
    }

    onRspPaginationSizeChange(paginationSize: number): void {
     /*   this.gridHelperRSP.changePaginationSize(paginationSize);
        this.launchSearchRSP();
        this.gridHelperRSP.saveGridStatePrefix();*/
    }

    onVrtPaginationSizeChange(paginationSize: number): void {
        this.gridHelperVRT.changePaginationSize(paginationSize);
        this.launchSearchVRT(true, false);
        this.gridHelperVRT.saveGridStatePrefix();
    }

    /* [CAW-16f00-] Desactiver la checkAll & uncheckAll */
   onPaginationChanged(params: any): void {
        if (this.isCheckAll && (params.newPage || !this.isShowRspDetail)) {
            this.isResetGridRSP = false;
            params.api.getRenderedNodes().map((node: RowNode)  => {
                if (node.data && node.data.childs != null) {
                    let exitLoop = false;
                    for (const child of node.data.childs) {
                        for (const rsp of this.unSelectedRspRows) {
                            if (rsp.idRspRappSummary == child.idRspRappSummary) {
                                node.setSelected(false);
                                exitLoop = true;
                                break;
                            } else {
                                node.setSelected(true);
                            }
                        }
                        if (exitLoop) break;
                    }
                }
                if(this.unSelectedRspRows.length==0){
                    node.setSelected(true);
                }

            });
            this.isShowRspDetail = false;
                this.aggregateDiffSelected();
        }
    }

    /* [CAW-1600-] Desactiver la checkAll & uncheckAll VRT */
   onVRTPaginationChanged(params: any): void {
        if (this.isVRTCheckAll) {
            this.isResetGridVRT = false;
            params.api.getRenderedNodes().map((node: RowNode) => {
                if(this.isVrtUnselected(node.data)){
                    node.setSelected(false);
                }else
                node.setSelected(true);
            });
        }
    }

    isVrtUnselected(data) {
        return this.unSelectedVrtRows.find((obj)=> obj && obj.idBankTransaction == data.idBankTransaction) !== undefined;
    }

    onColumnMoved(params: any, grid: string): void {
        if (grid == 'RSP') {
            this.gridHelperRSP.saveGridColumnState();
            this.gridHelperRSP.setDetailGridsColumnState();
        } else {
            this.gridHelperVRT.saveGridColumnState();
        }
    }

    onColumnResized(params: any): void {
        this.gridHelperRSP.setDetailGridsColumnState();
    }

    onSortChanged(params, grid: string): void {
        this.isShowRspDetail = false;
        /*if (grid === 'RSP' ) {
            if(!this.isCheckAll){
                this.gridHelperRSP.gridApi.deselectAll();
                if(this.gridHelperVRT.gridApi.getColumnDef('check')){
                    this.gridHelperVRT.gridApi.getColumnDef('check').headerName = 'deselect';
                }
                this.initSelectedRsp();
                this.aggregateDiffSelected();
                // this.calculateVRT();
            }else {
                if(this.unSelectedRspRows.length!=0){
                    this.unSelectedRspRows=[];
                    this.onCheckAll(this.isCheckAll);
                }
            }
        }*/
    }

    onRowClicked(params: any): void {
        if (!this.gridHelperVRT.isCellTextSelected()) {
            params.node.setExpanded(!params.node.expanded);
        }
    }

    onRowGroupOpened(params: any): void {
       if(!isNullOrUndefined(params.node.isExpanded)){
           params.node.setExpanded(params.node.isExpanded);
       }
    }

    checkDateComptaleRangeValidity(event) {
        this.isDateComptableRangeValid = event;
        if (this.dateComptable) {
            this.dateComptable.hideTooltlipDebut();
            this.dateComptable.hideTooltlipFin();
        }
    }

    onChoiceChange($event: any, type: string): void {
        if (type === 'montant') {
            this.filtersVRT.montantFilter = $event.filterChoice === '' ? null : $event.filterChoice;
        }
        if (type === 'montantRsp') {
            this.filtersRSP.montantRspFilter = $event.filterChoice === '' ? null : $event.filterChoice;
        }
        if (type === 'montantGroupeRsp') {
            this.filtersRSP.montantGroupeRspFilter = $event.filterChoice === '' ? null : $event.filterChoice;
        }
    }

    getOrganismeList(event) {
        this.filtersRSP.organismes = event;
    }

    checkRspSoldability() {
        /* [CAW-1600-] Desactiver la checkAll & uncheckAll */
       /* if (this.isCheckAll) {
            const selctedItem=this.gridHelperRSP.gridApi?.getSelectedRows();
            const rspOrganisms = selctedItem.map(selectedRsp => selectedRsp.libelleOrganisme);
            this.isRspSoldable = (selctedItem.length > 0 && rspOrganisms.every(el => el === rspOrganisms[0])) && this.gridHelperVRT.gridApi?.getSelectedRows().length === 0;
        } else {
            const rspOrganisms = this.selectedRspRows.map(selectedRsp => selectedRsp.libelleOrganisme);
            this.isRspSoldable = (this.selectedRspRows.length > 0 && rspOrganisms.every(el => el === rspOrganisms[0])) &&
            this.gridHelperVRT.gridApi?.getSelectedRows().length === 0;
        }*/
        this.agDemoComponent.checkRspSoldability();
    }

    checkVrtPointability() {
        //this.isVrtPointable = this.gridHelperVRT.gridApi?.getSelectedRows()?.length > 0 && this.selectedRspRows.length === 0;
        this.agDemoComponent.isVrtPointable = this.gridHelperVRT.gridApi?.getSelectedRows().length > 0 && this.agDemoComponent.selectedRspRows.length === 0 && !this.agDemoComponent.checkAll;
    }

    cellWarningStyle(params: any): string[] {
        if (params && params.data) {
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            let dateReception = new Date(params.data.dateReception);
            dateReception.setHours(0, 0, 0, 0);
            if (Math.abs(dateReception.getTime() - today.getTime()) / (1000 * 60 * 60 * 24) > 30) {
                return ['table-warning', 'text-dark'];
            }
        }
    }

    onKeyDown(event) {
        this.launchSearchFilter();
    }

    formatRoRc(selectedRow, key) {
        let total = selectedRow.signe === 0 ? selectedRow.montantTotalPaye * -1 : selectedRow.montantTotalPaye
        let ro = selectedRow.montantRo ? parseFloat(selectedRow.montantRo) / 100 : 0
        let rc = selectedRow.montantRc ? parseFloat(selectedRow.montantRc) / 100 : 0
        switch (key) {
            case 'montantRc': { return ro + rc * -1 === total || ro * -1 + rc * -1 === total ? rc * -1 : rc; break; }
            case 'montantRo': { return rc + ro * -1 === total || ro * -1 + rc * -1 === total ? ro * -1 : ro; break; }
            case 'montantTotalPaye': { return total; break; }
        }
    }

    getPsId() {
        return (localStorage.getItem('supervised_ps') != null && localStorage.getItem('supervised_ps') !='-1') ? localStorage.getItem('supervised_ps').split(';')[1]:(localStorage.getItem('idPs') != null) ? localStorage.getItem('idPs') : this.userConnected.idPs;
        // return (localStorage.getItem('idPs') != null) ? localStorage.getItem('idPs') : this.userConnected.idPs
    }

    getIdRappComptePs() {
        let currentPsStorage = localStorage.getItem('current_ps');
        if(currentPsStorage) {
            const currentPs = JSON.parse(currentPsStorage);
			if(currentPs.rappComptePs &&  currentPs.rappComptePs.idRappComptePs) {
				return currentPs.rappComptePs.idRappComptePs;
			}
        }
        return this.oxlinUserConnected?.idRappComptePs;
    }

    getIdPsEbics() {
        const currentPs = JSON.parse(localStorage.getItem('current_ps'));
        var ebicsUser = JSON.parse(localStorage.getItem('careweb_ebics_user'));
        if(currentPs && currentPs.psEbics) {
			return currentPs.psEbics[0].id;
        }else if(ebicsUser){
            return ebicsUser.id;
        }
        return null;
    }

    hasRSPFilters() {
        if (this.filtersRSP?.organismes
            || this.filtersRSP?.numLot
            || this.filtersRSP?.dateComptableFrom
            || this.filtersRSP?.dateComptableTo
            || this.filtersRSP?.idOrganisme
            || this.filtersRSP?.montantRspExacte
            || this.filtersRSP?.montantRspFrom
            || this.filtersRSP?.montantRspTo
            || this.filtersRSP?.montantGroupeRspExacte
            || this.filtersRSP?.montantGroupeRspFrom
            || this.filtersRSP?.montantGroupeRspTo
            || this.filtersRSP?.libelleVirement
            || this.filtersRSP?.rsp30Jours === false || (this.filtersRSP?.rsp30Jours === true && this.tempRsp?.rsp30Jours !== undefined && this.filtersRSP?.rsp30Jours !== this.tempRsp?.rsp30Jours) || this.filtersRSP?.rsp30Jours === null
            || this.filtersRSP?.rspIndu === false || this.filtersRSP?.rspIndu === true || this.filtersRSP?.rspIndu === null
        ) {
            return true
        }
        return false;
    }

    hasVRTFilters() {
        if (this.filtersVRT?.label
            || this.filtersVRT?.montant
            || this.filtersVRT?.montantFrom
            || this.filtersVRT?.montantTo
            || this.filtersVRT?.dateOperationFrom
            || this.filtersVRT?.dateOperationTo) {
            return true
        }
        return false
    }

    formatWithSigne(montant, signe) {
		if (montant) {
			if (signe === 0) {
				if(parseFloat(montant) / 100!==0){
					return (parseFloat(montant) / 100 * -1).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ') + ' €';
				}
				return '0.00 €';
			}
			else {
				return (parseFloat(montant) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ') + ' €';
			}
		}
		else {
			return '0.00 €';
		}
	}

    formatTotalWithSigne(montant, signe) {
        if (montant) {
            if (signe === 0) {
                if (parseFloat(montant) !== 0) {
                    return (parseFloat(montant) * -1).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ') + ' €';
                }
                return '0.00 €';
            }
            else {
                return parseFloat(montant).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ') + ' €';
            }
        }
        else {
            return '0.00 €';
        }
    }

}
