<div class="modal modal-show fade show" id="modalEditBAL" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel">
    <div class="modal-dialog modal-xxl" role="document">
        <div class="modal-content">
            <div class="inmodal">

                <div class="modal-header pb-1">
                    <h3 class="modal-title">Rattacher un utilisateur</h3>
                    <button class="close" name="btn_display_filter" data-dismiss="modal" id="close_modal" type="button" title="Fermer"
                        (click)="closeModal()"><span id="x" aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body pb-0">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active"  [destroyOnHide]="false" class="nav-tabs">
                        <li [ngbNavItem]="1"title="Utilisateur existant">
                            		<button id="utilisateur_existant" ngbNavLink>Utilisateur existant</button>
		                                <ng-template ngbNavContent>
                                <careweb-app-ps-user-existant [idPs]="idPs"></careweb-app-ps-user-existant>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2"title="Nouvel utilisateur">
                            		<button id="newUser" ngbNavLink>Nouvel utilisateur</button>
		                            <ng-template ngbNavContent>
                                <careweb-app-ps-user-nouveau [idPs]="idPs"></careweb-app-ps-user-nouveau>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div> 
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>

</div>