import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../core/services/auth.service";
import {ToastrService} from "ngx-toastr";
import {AdminService} from "./admin-content.service";

@Component({
    selector: 'admin-content-management',
    templateUrl: './admin-content-management.html',
    styleUrls: ['./admin-content-management.css']
})
export class AdminContentManagement  implements  OnInit  {
    videoUrl: string = '';
    videoVisibility = true;
    hasChanges: boolean = false;
    isUrlValid: boolean | null = null;

    constructor(private adminService: AdminService, private authSvc: AuthService,
                private toastr: ToastrService) { }

    ngOnInit(): void {
        this.adminService.getVideoSettings().subscribe((data)=>{
            this.videoVisibility= data.visibility;
            this.videoUrl=data.url;
        })
    }
    onSubmit() {
        if (this.isUrlValid === false) {
            this.toastr.error("L'URL n'est pas valide. Veuillez corriger l'erreur.");
            return;
        }
        if(this.isUrlValid || this.hasChanges  ){
            this.adminService.updateVideoSettings(this.videoUrl, this.videoVisibility).subscribe((data) => {
                    this.toastr.success(' Informations mises à jour avec succès.');
                    this.hasChanges = false;

                }, (error) => {
                    this.toastr.error('Erreur lors de la mise à jour des informations');
                }
            );
        }
    }
    isAuthorized() {
        return  this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
    }

    changeInputs($event) {
        this.videoVisibility = ($event.target.value === 'true');
        this.hasChanges = true;
    }
    onUrlChange() {
        this.checkValidateVideo();
    }

    checkValidateVideo(){
        if (this.videoUrl.trim()) {
            this.isUrlValid = null;

            this.adminService.validateVideoUrl(this.videoUrl).subscribe(
                (data) => {
                    this.isUrlValid = data;
                },
                (error) => {
                    this.toastr.error('Erreur lors de la validation de l’URL.');
                    this.isUrlValid = false;
                }
            );
        } else {
            this.isUrlValid = false;
        }
    }
}
