import { Component, Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
    selector: 'modal-confirm-rapp-auto',
    template: `
    <div class="modal modal-show fade show"  id="confirmationModal" tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                    <button type="button" id="close_modal" (click)="modalRef?.hide()" class="close" aria-label="Close"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body">
                    <p>Les montants sélectionnés sont différents. Voulez-vous les rapprocher ?</p>
                    <textarea [(ngModel)]="commentaire" *ngIf="hasCommentaire" id="commentaire" class="form-control-input" placeholder="Indiquer le motif :"></textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" id="button_non" (click)="modalRef?.hide()" class="btn btn-default" >Non</button>
                    <button type="button" id="button_oui" (click)="confirm()" class="btn btn-primary">Oui</button>
                </div>
            </div>
        </div>
    </div>
    `
})

export class ModalConfirmRappAutoComponent {
    @Input() public hasCommentaire: boolean;
    public commentaire: string;

    constructor(public modalRef: BsModalRef) {}

    confirm() {         
        this.modalRef.content.isConfirmed = true;
        this.modalRef.content.commentaire = this.commentaire;
        this.modalRef.hide(); 
    }
}