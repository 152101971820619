import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {OctService} from "../../../core/services/oct.service";
import {ToastrService} from "ngx-toastr";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {InputValidators} from "../../../components/validators/InputValidators";

@Component({
    selector: 'support-service-modal',
    templateUrl:'./support-service-modal.component.html'
})

export class SupportServiceModalComponent implements OnInit {
    @Input() title: string;
    @Input() adressContact:string;
    form: UntypedFormGroup;
    idOct : Number;
    submitted = false;
    constructor(private modalRef: BsModalRef , private octService : OctService,
                private toastr: ToastrService,private inputValidators: InputValidators,private fb: UntypedFormBuilder) {}

    ngOnInit() {
        this.validForm();
        this.setInitialValue();
    }
    validForm() {
        this.form = this.fb.group({
            email: [
                '', [Validators.required, this.inputValidators.inputWithSpaces,
                    Validators.pattern(this.inputValidators.emailPattern)]
            ]
        })

    }
    get formFields() {
        return this.form.controls;
    }
    closeModal() {
        this.modalRef.hide();
    }
    setInitialValue() {
        if (this.adressContact) {
            this.form.patchValue({
                email: this.adressContact
            });
        }
    }

    onSubmit() {
        const ctx = this;
        ctx.submitted=true;
        if (this.form.invalid) {
            return;
        }
        this.octService.updateOct(this.form.value.email,this.idOct).subscribe((data)=>{
            this.toastr.success('L\'adresse de contact est mise à jour avec succès.');
            this.closeModal();
            window.location.reload();
        }, (error) => {
            this.toastr.error('Erreur lors de la mise à jour des informations');
        });
    }


}
