<div class="full-careweb-container">
  <div id="filter" class="filter-container" carewebFilterMinHeight>
    <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
      <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
        (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
      <div class="filter-content">
        <mat-accordion displayMode="flat" class="accordion-filter">
          <!-- ADELI - RPPS-->
          <careweb-accordion-group id="adeli" title="Numéro de PS">
            <input type="text" id="input_adeli" [(ngModel)]="filters.numeroAdeli"
             (keydown.enter)="onKeyDown($event)" onlyNum="9"   placeholder="000000000"/>
          </careweb-accordion-group>
          <careweb-accordion-group [hidden]="!isAuthorized()" id="derniere_transmission" title="Dernière télétransmission" >
            <careweb-date-time-filter labelFrom=" entre le " id="date" [(modelStart)]="filters.dateStartDerniereTransmission"
                                  [(modelEnd)]="filters.dateEndDerniereTransmission" [maxDate]="null" (keydown.enter)="onKeyDown($event)">
            </careweb-date-time-filter>
        </careweb-accordion-group>
          <careweb-accordion-group id="input_rpps" title="Numéro RPPS">
            <input type="text" id="input_rpps" [(ngModel)]="filters.rpps"
              oninput="this.value=this.value.replace(/[^\d]/,'');" onpaste="if(this.value.length==11) return false;"
              onKeyPress="if(this.value.length==11) return false;" (keydown.enter)="onKeyDown($event)" placeholder="00000000000" />
          </careweb-accordion-group>
          <!-- Etablissement -->
          <careweb-accordion-group id="etablissement" title="Etablissement">
            <input type="text" id="input_etablissement" [(ngModel)]="filters.raisonSocialeEtablissement"
              onpaste="if(this.value.length==50) return false;" onKeyPress="if(this.value.length==50) return false;"
              (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Nom -->
          <careweb-accordion-group id="nom" title="Nom">
            <input type="text" id="input_nom" [(ngModel)]="filters.nom"
              onpaste="if(this.value.length==50) return false;" onKeyPress="if(this.value.length==50) return false;"
              (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Téléphone -->
          <careweb-accordion-group id="telephone" title="Téléphone">
            <!-- <label for="input_rpps">Téléphone</label> -->
            <input type="text" id="input_telephone" [(ngModel)]="filters.telephoneEtablissment"
              oninput="this.value=this.value.replace(/[^\d\s.]/g, '');" onpaste="if(this.value.length==16) return false;"
              onKeyPress="if(this.value.length==16) return false;" (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Code Postal -->
          <careweb-accordion-group id="code_postal" title="Code Postal">
            <input type="text" id="input_code_postal" [(ngModel)]="filters.codepostalEtablissment"
              oninput="this.value=this.value.replace(/[^\d]/,'');" onpaste="if(this.value.length==5) return false;"
              onKeyPress="if(this.value.length==5) return false;" (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Ville -->
          <careweb-accordion-group id="ville" title="Ville">
            <input type="text" id="input_ville" [(ngModel)]="filters.villeEtablissment"
              onpaste="if(this.value.length==50) return false;" onKeyPress="if(this.value.length==50) return false;"
              (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Utilisateur -->
          <careweb-accordion-group id="fax" title="Fax">
            <input type="text" id="input_fax" [(ngModel)]="filters.faxEtablissment"
              oninput="this.value=this.value.replace(/[^\d\s]/g,'');" onpaste="if(this.value.length==16) return false;"
              onKeyPress="if(this.value.length==16) return false;" (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Email -->
          <careweb-accordion-group id="email" title="Email utilisateur">
            <input type="text" id="input_email" [(ngModel)]="filters.email" (keydown.enter)="onKeyDown($event)"/>
          </careweb-accordion-group>
          <!-- BAL -->
          <careweb-accordion-group id="bal" title="BAL émettrice">
            <input type="text" id="input_bal" [(ngModel)]="filters.balEmettriceSiteEmission"
              onpaste="if(this.value.length==255) return false;" onKeyPress="if(this.value.length==255) return false;"
              (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <careweb-accordion-group id="site" title="Site d’émission du PS" [hidden]="!isThalesOct()">
            <label class="input_filter">Numéro GEN</label>
            <input type="text" id="input_gen" [(ngModel)]="filters.genSiteEmission"
              onpaste="if(this.value.length==5) return false;" onKeyPress="if(this.value.length==5) return false;"
              (keydown.enter)="onKeyDown($event)" />

            <label class="input_filter">Numéro Maître</label>
            <input type="text" id="input_maitre" [(ngModel)]="filters.numeroMaitreSiteEmission"
              onpaste="if(this.value.length==5) return false;" onKeyPress="if(this.value.length==5) return false;"
              (keydown.enter)="onKeyDown($event)" />

            <label class="input_filter">Numéro esclave</label>
            <input type="text" id="input_esclave" [(ngModel)]="filters.numeroEsclaveSiteEmission"
              onpaste="if(this.value.length==5) return false;" onKeyPress="if(this.value.length==5) return false;"
              (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- actif -->
          <careweb-accordion-group id="id-statut" title="Actif">
            <ng-select name="isActif" id="isActif" placeholder="Actif" [searchable]="false"
              [(ngModel)]="filters.isActif" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
              <ng-option id="option_" [value]="''"> </ng-option>
              <ng-option id="option_oui" [value]="true">Oui</ng-option>
              <ng-option id="option_non" [value]="false">Non</ng-option>
            </ng-select>
          </careweb-accordion-group>        
          <!-- SEL -->
          <careweb-accordion-group id="id-sel" title="SEL">
            <ng-select name="sel" id="sel" placeholder="SEL" [searchable]="false" [(ngModel)]="filters.sel"
            appendTo=".filter-container" (keydown.enter)="onKeyDown($event)" [readonly]="isAdminSel()">
              <ng-option id="option_" [value]="''"> </ng-option>
              <ng-option id="option_2" [value]="2">Indéfini</ng-option>
              <ng-option id="option_oui"[value]="1">Oui</ng-option>
              <ng-option id="option_non" [value]="0">Non</ng-option>
            </ng-select>
          </careweb-accordion-group>
          <!-- Rapprochement bancaire -->
          <careweb-accordion-group id="id-sel" title="Rapprochement bancaire">
            <ng-select name="rappBancaire" id="rappBancaire" placeholder="Rapprochement bancaire" [searchable]="false" [(ngModel)]="filters.rappBancaire"
              appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
              <ng-option id="option_" [value]="''"> </ng-option>
              <ng-option id="option_actif" [value]="true">Activé</ng-option>
              <ng-option id="option_desactif" [value]="false">Désactivé</ng-option>
            </ng-select>
          </careweb-accordion-group>
        </mat-accordion>
      </div>
    </div>
  </div>
  <div class="grid-container" id="grid">
    <h2 class="title-border mt-3">Liste des professionnels de santé</h2>
    <div>
      <section>
        <div class="row grid-top-btn">
          <div class="col-md-4 filter">
            <button type="button" id="Filtre_ps" name="btn_toggle_filter" class="btn btn-primary small left-open" [hidden]="showFilter"
              (click)="toggleFilterDisplay()">
              <i class="fa fa-sliders"></i>
              Filtrer
            </button>
            <careweb-pluralize class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
              [count]="gridHelper.totalRowCount"></careweb-pluralize>
          </div>
          <div class="col-md-8 conf-col">
            <div class="careweb-toolbar">
              <div [tooltip]="gridHelper.isAvailableData() ? 'Exporter les résultats (.xlsx)' : 'Aucune ligne à exporter, veuillez affiner votre recherche'"
                   placement="top" container="body">
                <span class="btn btn-action small" [class.disabled]="!gridHelper.isAvailableData()"
                       name="btn_export_csv" id="exportExcel" (click)="exportExcel()">
                <i class="fa fa-file-excel-o"></i>
                </span>
              </div>
              <careweb-column-selector (itemSelect)="selectColumnEvent($event)"
                (itemDeselect)="deselectColumnEvent($event)"></careweb-column-selector>
              <careweb-pagination-size id="dv_ps_paging" [pageSizes]="[10, 20, 30, 50, 100]"
                [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
              </careweb-pagination-size>
              <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                name="spn_reset_grid" (click)="resetGridState()" id="reset_grid">
                <i class="fa fa-refresh"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="row grid-top-filter m-0" *ngIf="psOnOct && !hideAlert" >
          <div class="alert alert-info">
            <div>
              <i class="fa fa-info-circle"></i> Le PS <span class="font-weight-bold">{{ numeroAdeli }}</span> est associé à la liste des OCT suivante:
              <span *ngFor="let oct of octsNotInUserList; let i = index " let  last =last>
                <span id="{{ oct[1] }}" class="font-weight-bold">
                  {{ oct[1] }}<span *ngIf="octsInUserList.length > 0 || i < octsNotInUserList.length - 1">, </span>
                </span>
              </span>

              <span *ngFor="let oct of octsInUserList.slice(0); let i = index">
                <a id=" {{ oct[1] }}" [routerLink]="['.']" (click)="onOctChange(oct)" id="octChange">
                  {{ oct[1] }}<span *ngIf="i < octsInUserList.length - 1">, </span>
                </a>
              </span>
              <span *ngIf="octsNotInUserList.length > 0 || octsInUserList.length > 0">.</span>
              <span itemid="dismissAlert" (click)="dismissAlert()" id="dismissAlert" style="cursor: pointer; float: right;"><i class="fa fa-times"></i></span>
            </div>
          </div>
        </div>
          <app-alert [export]="export" [exportState]="exportState" id="close_alert" (close)="closeAlert()"></app-alert>
        <!-- show chips -->
        <div class="row grid-top-filter">
          <div class="col-md-12">
            <careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter.id)" id="remove_filter" [isCloseToDisable] = "filter.id === 'sel' && isAdminSel()">
              <span *ngIf="filter" id="filter_label">{{ filter.label}}: {{filter.value }}</span>
            </careweb-filter-label>
          </div>
        </div>
        <div class="row highlight-row">
          <div class="col-md-12 relative">
            <ag-grid-angular style="width: 100%" class="ag-theme-balham" [gridOptions]="gridHelper.gridOptions">
            </ag-grid-angular>
            <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
