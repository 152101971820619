import { GridOptions } from 'ag-grid-enterprise';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { FilterItem } from './../../data/filters/filter-item';
import { RappSuiviFilterRaw } from './../../data/filters/rapp-suivi-filter';
import { GridDataRaw } from './../../data/gridDataRaw';
import { StatusConnectionOxlin } from './../../data/statusConnectionOxlin';
import { StorageService } from './../../shared/storage-service';
import { PsService } from './../ps/ps.service';
import { RapprochementComptePsService } from './../rapprochement/rapprochement.service';
import {Component, OnInit, OnDestroy, HostListener} from '@angular/core';
import * as _ from 'lodash-es';
import { AgGridHelper } from '../../components/ag-grid/ag-grid-helper';
import { BsModalService } from 'ngx-bootstrap/modal';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';
import { VirementEtudierModalComponent } from './virement-annomalie-modal/virement-etudier-modal.component';
import { SuiviRappProcReplayComponent } from "./suivi-rapp-procedureReplay";
import * as moment from 'moment';
import { gridConvertSort } from '../../components/ag-grid/ag-grid.utils';
import { SuiviRappMissingTransactionsComponent } from './missing-transactions/suivi-rapp-missing-transactions';
import { ToastrService } from 'ngx-toastr';
import {EbicsService} from "../ps/service/ebics.service";
import {TypeConnexionRapprochement} from "../../data/TypeConnexionRapprochement";
import {isNullOrUndefined} from "util";
@Component({
	selector: 'careweb-suivi-rapp',
	templateUrl: './suivi-rapp.component.html',
	styleUrls: ['./suivi-rapp.component.scss']
})

export class SuiviRappComponent implements OnInit, OnDestroy {
	gridHelper: AgGridHelper;
	initHidden = true;
	showFilter = false;
	filters = new RappSuiviFilterRaw();
	filterCriteria = new RappSuiviFilterRaw();
	filterList: FilterItem[];
	gridDataRaw: GridDataRaw;
	isValidDate = true;
	startDate: Date = new Date();
	endDate: Date = new Date();
	datePipe: DatePipe;
	showReplayProcDetail: boolean = false;
	showMissingTransactionDetail: boolean = false;
	showReplayProcConfirm: boolean = false;
	replayStartDate: Date = new Date();
	replayEndDate: Date = new Date();
	missingStartDate: Date = new Date();
	missingEndDate: Date = new Date();
	returnMsg: string = '';
	connectionNumber: Number;
	numeroAdeli: String = null;
	idPs: number = null;
	isBtnExecuterDisabled: boolean = false;
	isBtnMissingDisabled: boolean = false;
	typeConnexion;
	withoutDate: boolean = false;
	showModalLog: boolean = false;

	constructor(
		private rappComptePsService: RapprochementComptePsService,
		private psService: PsService,
		private router: Router,
		private storageService: StorageService,
		private modalService: BsModalService,
		private toastr: ToastrService,
		private ebicsService: EbicsService
	) {
		// Define local date fr
		defineLocale('fr', frLocale);
		// Get grid size from session if already stored
		const sessionPageSize = this.storageService.get('rappSuiviPaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = sessionPageSize ? sessionPageSize : 10;
		this.gridHelper = new AgGridHelper('rappSuivi', 10);
	}
	getPsId(row) {
        return row.data.psId;
    }
	ngOnInit() {
		this.datePipe = new DatePipe('fr-FR');
		// Init filters
		this.parseFilter();
		// Init sélécteur de période
		this.initSelecteurPeriode();
		// Init grid options
		this.initGridOptions();
	}

	parseFilter() {
		const parsedFilter = JSON.parse(sessionStorage.getItem('rappSuiviFilter'));
		if (parsedFilter) {
			this.filters = Object.assign({}, parsedFilter);
		} else {
			this.filterList = [];
		}
		this.filters.numeroAdeli = !isNullOrUndefined(localStorage.getItem("numeroPs")) ? localStorage.getItem("numeroPs") : null;
		this.updateFilterList(this.filters);
	}

	initSelecteurPeriode() {
		if (sessionStorage.getItem('dateFin')) {
			this.endDate = new Date(sessionStorage.getItem('dateFin'));
		} else {
			this.endDate = new Date();
		}
		if (sessionStorage.getItem('dateDebut')) {
			this.startDate = new Date(sessionStorage.getItem('dateDebut'));
		} else {
			this.startDate.setDate(new Date().getDate() - 7);
		}
	}
	
	initGridOptions() {
		this.gridHelper.gridOptions = <GridOptions>(<unknown>{
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				suppressMenu: true,
				resizable: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			getRowId: this.getPsId,
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			paginationPageSize: this.gridDataRaw.size,
			infiniteInitialRowCount: 1,
			maxBlocksInCache: 0,
			paginateChildRows: true,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelper.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params),
			getRowClass: (params) => this.stylePreviouslyClickedRow(params),
			//onGridSizeChanged: (params) => this.onGridSizeChanged(params),
			onColumnMoved: (params) => this.onColumnChanged(params),
			rowSelection: 'multiple',
			suppressRowClickSelection: true
		});
	}

	private getColumnDefs(): any[] {
		return [
			{
				headerName: 'N° de PS',
				field: 'numeroAdeli',
				cellClass: (params) => this.styleLink(params),
				minWidth: 100,
				maxWidth: 150,
				onCellClicked: (params) => this.onPsCellClicked(params),
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'Connexion',
				field: 'typeRappro',
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: (params) => this.formatTypeConnexion(params),
				minWidth: 160,
				maxWidth: 200,
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'Nombre de connexions',
				field: 'connectionNumber',
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: (params) => this.setConnectionNumber(params),
				minWidth: 150,
				maxWidth: 250,
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'Comptes désynchro.',
				field: 'statutCompte',
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: (params) => this.formatStatutCompte(params),
				minWidth: 150,
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'Dernière synchro',
				field: 'dateAppelSych',
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: (params) => this.formatDateDernierTransaction(params),
				minWidth: 150,
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'Synchro reussie',
				field: 'statutSync',
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: (params) => this.formatSynchoReussie(params),
				minWidth: 100,
				maxWidth: 150,
				lockVisible: true,
				sortable: true,
			},
			{
				headerName: 'VRT',
				field: 'virementsCount',
				cellStyle: this.gridHelper.centerContent(),
				minWidth: 100,
				maxWidth: 150,
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'Rapprochés',
				headerClass: 'ag-header-group-parent',
				marryChildren: true,
				children: [
					{
						headerName: 'total',
						field: 'rapproTotal',
						cellStyle: this.gridHelper.centerContent(),
						minWidth: 100,
						maxWidth: 150,
						lockVisible: true,
						sortable: true
					},
					{
						headerName: 'auto',
						field: 'rapproAuto',
						cellStyle: this.gridHelper.centerContent(),
						minWidth: 100,
						maxWidth: 150,
						lockVisible: true,
						sortable: true
					},
					{
						headerName: 'manuel',
						field: 'rapproManuel',
						cellStyle: this.gridHelper.centerContent(),
						minWidth: 100,
						maxWidth: 150,
						lockVisible: true,
						sortable: true
					}
				]
			},
			{
				headerName: 'Rapprochés (%)',
				headerClass: 'ag-header-group-parent',
				marryChildren: true,
				children: [
					{
						headerName: 'total',
						field: 'txVirements',
						valueFormatter: this.calculeTxVrtRappro,
						cellStyle: this.gridHelper.centerContent(),
						minWidth: 100,
						maxWidth: 150,
						lockVisible: true,
						sortable: true
					},
					{
						headerName: 'auto',
						field: 'txRappAuto',
						valueFormatter: this.calculeTxRapproAuto,
						cellStyle: this.gridHelper.centerContent(),
						minWidth: 100,
						maxWidth: 150,
						lockVisible: true,
						sortable: true
					},
					{
						headerName: 'manuel',
						field: 'txRappManu',
						valueFormatter: this.calculeTxRapproManu,
						cellStyle: this.gridHelper.centerContent(),
						minWidth: 100,
						maxWidth: 150,
						lockVisible: true,
						sortable: true
					}
				]
			},
			{
				headerName: 'Rappro. fantômes',
				field: 'nbrRapproFantomes',
				cellStyle: this.gridHelper.centerContent(),
				minWidth: 120,
				maxWidth: 180,
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'Rappro auto déséquilibrés',
				field: 'rapproAnomalies',
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: (params) => this.formatRapproAutoDes(params),
				minWidth: 160,
				maxWidth: 200,
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'VRT à étudier',
				field: 'virementsAetudier',
				cellRenderer: (params) => this.formatVrtAEtudier(params),
				onCellClicked: (params) => this.onVrtAetudierCellClicked(params),
				minWidth: 100,
				maxWidth: 150,
				lockVisible: true,
				sortable: true
			},
			{
				id:'relancerProcedure',
				headerName: ' ',
				//headerComponent: SuiviRappProcReplayComponent,
				field: 'replayProc',
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: SuiviRappProcReplayComponent,
				onCellClicked: (params) => this.openModalReplayProcDetail(params),
				minWidth: 50,
				maxWidth: 50,
				lockVisible: true,
			},
			{
				id:'forcerProcedure',
				headerName: ' ',
				//headerComponent: SuiviRappProcReplayComponent,
				field: 'missingTransactions',
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: SuiviRappMissingTransactionsComponent,
				onCellClicked: (params) => this.openModalMissingTransactionsDetail(params),
				minWidth: 80,
				maxWidth: 100,
				lockVisible: true,
			},
		];
	}

	onGridReady(params) {
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGridState();
		this.setColumnOrder(window.innerWidth);
		this.launchSearch();
	}

	onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		this.launchSearch();
		this.gridHelper.saveGridStatePrefix();
	}

	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	restoreGridState(): void {
		this.gridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.gridHelper.paginationPageSize;
	}

	resetGridState() {
		this.gridHelper.resetGridState();
		this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
	}

	initFilter() {
		this.rappComptePsService.reset();
		this.updateFilterList(this.filters);
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		let width = event.target.innerWidth;
		this.setColumnOrder(width);
	}
	setColumnOrder(width:any) {
		const columnDefs = this.getColumnDefs();
		if(width<=2560){
			const columnApi = this.gridHelper.gridColumnApi;
			const replayProcColumnIndex = columnDefs.findIndex(column => column.field === 'replayProc');
			const missingTransactions = columnDefs.findIndex(column => column.field === 'missingTransactions');
			if (replayProcColumnIndex !== -1 || missingTransactions  !== -1) {
				columnApi.moveColumn('replayProc', 0);
				columnApi.moveColumn('missingTransactions', 1);
			}
		}else {
			this.gridHelper.gridApi.sizeColumnsToFit();
			this.gridHelper.gridApi.setColumnDefs(columnDefs);
		}
	}
	resetAllFilter(): void {
		this.initFilter();
		this.filters = new RappSuiviFilterRaw();
		this.launchSearch();
	}

	updateFilterList(filters) {
		this.filterList = [];
		Object.keys(filters).forEach((key) => {
			if (
				filters[key] !== null &&
				filters[key] !== '' &&
				key !== 'idOct' &&
				key !== 'startDate' &&
				key !== 'endDate'
			) {
				this.filterList.push(this.translateFilterLabelValue(key, filters[key]));
			}
		});
		// Remove duplicated ids
		this.filterList = _.uniqBy(this.filterList, 'id');
		// filter null
		this.filterList = this.filterList.filter((item) => {
			return item.id !== null;
		});
	}

	translateFilterLabelValue(key, value) {
		let translatedKey = key;
		let formattedValue = value;
		switch (key) {
			case 'numeroAdeli':
				translatedKey = 'N° de PS';
				break;
			case 'typeRappro':
				translatedKey = 'Connexion';
				break;
			case 'email':
				translatedKey = 'Email';
				break;
			case 'compteDesyncho':
				translatedKey = 'Comptes désynchronisés';
				formattedValue = value ? 'Oui' : 'Non';
				break;
			case 'vrt':
				translatedKey = 'Virement importés';
				formattedValue = value ? 'Oui' : 'Non';
				break;
			case 'rapproAnomalies':
				translatedKey = 'Rappro. auto déséquilibrés';
				formattedValue = value ? 'Oui' : 'Non';
				break;
			default:
				break;
		}
		return new FilterItem().withId(key).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
	}

	onKeyDown(event) {
		this.launchSearch();
	}

	toggleFilterDisplay() {
		let width =window.innerWidth;
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
			if(!this.showFilter && width>3000){
				setTimeout(() => {
					this.gridHelper.gridApi.sizeColumnsToFit();
				}, 200);
			}
		}
	}

	removeFilter(id) {
		delete this.filters[id];
		const currentFilterList = this.filterList;
		const index = _.findIndex(currentFilterList, (filt) => filt.id === id);
		currentFilterList.splice(index, 1);
		this.filterList = currentFilterList;
		this.fillFilterObjectFromTagsOnly();
		this.launchSearch();
	}

	fillFilterObjectFromTagsOnly() {
		this.filters = new RappSuiviFilterRaw();
		this.filterList.forEach((filter) => {
			this.filters[filter.id] = filter.value2;
		});
	}

	launchSearch(): void {
		this.isValidDate = false;
		if (this.startDate && this.endDate) {
			if (this.startDate.getTime() <= this.endDate.getTime()) {
				this.isValidDate = true;
				sessionStorage['dateDebut'] = this.startDate;
				sessionStorage['dateFin'] = this.endDate;
				this.updateFilterList(this.filters);
				this.getRappSuivieListByCriteriat();
				sessionStorage['rappSuiviFilter'] = JSON.stringify(this.filters);
			}
		}
	}

	getRappSuivieListByCriteriat() {
		const currentOct = JSON.parse(localStorage.getItem('octCurrent'));
		this.filterCriteria.idOct = currentOct ? currentOct.idOct : 1;
		this.filterCriteria.startDate = this.startDate;
		this.filterCriteria.endDate = this.endDate;
		this.filterCriteria.numeroAdeli = this.filters.numeroAdeli ? this.filters.numeroAdeli.replace(/\s/g, '').replace(/\./gi, '') : null;
		this.filterCriteria.typeRappro = this.filters.typeRappro==''? null : this.filters.typeRappro;
		this.filterCriteria.email = this.filters.email ? this.filters.email.replace(/\s/g, '') : null;
		this.filterCriteria.compteDesyncho = this.filters.compteDesyncho;
		this.filterCriteria.vrt = this.filters.vrt;
		this.filterCriteria.rapproAnomalies = this.filters.rapproAnomalies;
		this.filterCriteria.connectionNumber = this.filters.connectionNumber;

		const dataSource = {
			getRows: (paramsRows) => {
				const sorts = gridConvertSort(this.gridHelper.gridSortModel(), []);
				this.rappComptePsService
					.getRappSuivieListByCriteriat({
						filters: this.filterCriteria,
						sorts: sorts, page: this.gridHelper.gridApi.paginationGetCurrentPage(),
						size: this.gridHelper.gridApi.paginationGetPageSize()
					})
					.toPromise()
					.then(
						(data) => {
							const rowCount = data.totalElements;
							this.gridHelper.manageNoRowsOverlay(rowCount);
							paramsRows.success({ "rowData": data.content, "rowCount": rowCount });
							const pageN = Number.parseInt(sessionStorage.getItem('rappSuiviCurrentPage'));
							if (pageN && pageN !== this.gridHelper.gridApi.paginationGetCurrentPage() && pageN > 0) {
								this.gridHelper.gridApi.paginationGoToPage(pageN - 1);
							}
							sessionStorage['rappSuiviCurrentPage'] = null;
						},
						() => paramsRows.fail()
					);
			}
		};
		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
	}

	// Sélécteur Période
	onDateFilterChange($event) {
		if ($event) {
			this.startDate = $event.startDate;
			this.endDate = $event.endDate;
			this.launchSearch();
		}
	}
	/// Grid Style, Format columns and caclulated values
	stylePreviouslyClickedRow(params: any) {
		const rowNode = params.node;
		if (rowNode.data) {
			const previouslyClickedRow = sessionStorage.getItem('previouslyClickedNumAdeliSuivi');
			if (rowNode.data.numeroAdeli === previouslyClickedRow) {
				return 'ag-row-selected';
			}
		}
	}

	onPsCellClicked(params) {
		sessionStorage.previouslyClickedNumAdeliSuivi = params.node.data.numeroAdeli;
		this.psService.getPsByNumAdeli({ numAdeli: params.value }).toPromise().then((data) => {
			if (data && data[0]) {
				this.router.navigate(['/ps-details', data[0].idPs]);
			}
		});
	}

	onVrtAetudierCellClicked(params) {
		if (params.value && params.value > 0) {
			const initialState = {
				numeroAdeli: params.data.numeroAdeli,
				idOct: this.filterCriteria.idOct,
				startDate: this.filterCriteria.startDate,
				endDate: this.filterCriteria.endDate
			};
			this.modalService.show(VirementEtudierModalComponent, { initialState, class: 'modal-xl' });
		}
	}

	styleLink(params) {
		const previouslyClickedRow = sessionStorage.getItem('previouslyClickedNumAdeliSuivi');
		if (params.data && params.data.numeroAdeli === previouslyClickedRow) {
			return 'link-in-selected-row';
		} else {
			return ['link-in-not-selected-rows', 'underline'];
		}
	}

	formatStatutCompte(params) {
		if (this.connectionNumber == 0) { return '-' };
		if (params.value) {
			if (params.value === StatusConnectionOxlin.SUCCESS) {
				return "<i class='fa fa-check green' container='body'></i>";
			} else {
				return "<i class='fa fa-close red' container='body'></i>";
			}
		} else {
			return '-';
		}
	}

	setConnectionNumber(params) {
		this.connectionNumber = params.value;
		return params.value;
	}

	formatSynchoReussie(params) {
		if (params.value) {
			if (params.value === 'NOK' || params.value === 'KO') {
				return "<span class='ui-grid-cell-contents red cell-bold' >KO </span>";
			}
			 else {
				if (params.value === 'OK') {
					return "<span class='ui-grid-cell-contents' container='body'>OK </span>";
				}
				else {
					return '-';
				}
			}
		}
		else{
			return '-';
		}
	}
	formatSynchoReussieEBICS(params) {
		let isOk = params.value;
		if (isOk) {
			if (isOk === 'ERROR' && params.data.nbreCompteEbics != 0) {
				return "<span class='ui-grid-cell-contents red cell-bold' >KO </span>";
			} else {
				if (isOk === 'SUCCESS' && params.data.nbreCompteEbics != 0) {
					return "<span class='ui-grid-cell-contents' container='body'>OK </span>";
				}
				else {
					return '-';
				}
			}
		}
	}

	formatRapproAutoDes(params) {
		if (params.value && params.value > 0) {
			return "<span class='ui-grid-cell-contents red cell-bold' container='body'>" + params.value + '</span>';
		} else {
			return "<span class='ui-grid-cell-contents' container='body'>" + params.value + '</span>';
		}
	}

	formatVrtAEtudier(params) {
		if (params.value && params.value > 0) {
			return (
				"<span class='ui-grid-cell-contents red cell-bold' container='body'><u>" + params.value + '</u></span>'
			);
		} else {
			return params.value;
		}
	}

	formatDateDernierTransaction(params) {
		if (params.value !== null && params.value !== 'Invalid Date') {
			const date = new Date(params.value);
			const dateMoins48H: Date = new Date();
			dateMoins48H.setDate(dateMoins48H.getDate() - 2);
			//if (this.connectionNumber == 0) { return '-'; }

			if (date <= dateMoins48H) {
				return `<span class='ui-grid-cell-contents red cell-bold' container='body'>${this.datePipe.transform(
					date,
					'dd/MM/yyyy HH:mm:ss'
				)}</span>`;
			} else {
				return `<span class='ui-grid-cell-contents' container='body'>${this.datePipe.transform(
					date,
					'dd/MM/yyyy HH:mm:ss'
				)}</span>`;
			}
		} else {
			return '-';
		}
	}
	 formatTypeConnexion(params) {
	 	return params.value!==TypeConnexionRapprochement.UNKNOWN?params.value:'-';
	 }
	formatDateDernierTransactionEBICS(params) {
		if (params.value !== null && params.value !== 'Invalid Date') {
			const date = new Date(params.value);
			const dateMoins48H: Date = new Date();
			dateMoins48H.setDate(dateMoins48H.getDate() - 2);
			if (params.data.nbreCompteEbics == 0) { return '-'; }

			if (date <= dateMoins48H) {
				return `<span class='ui-grid-cell-contents red cell-bold' container='body'>${this.datePipe.transform(
					date,
					'dd/MM/yyyy HH:mm:ss'
				)}</span>`;
			} else {
				return `<span class='ui-grid-cell-contents' container='body'>${this.datePipe.transform(
					date,
					'dd/MM/yyyy HH:mm:ss'
				)}</span>`;
			}
		} else {
			return '-';
		}
	}
	calculeTxVrtRappro = (params): string => {
		if (params.value) {
			return params.value.toFixed(2) + ' %';
		} else {
			return '0.00 %';
		}
	};

	calculeTxRapproManu = (params): string => {
		return params.value ? params.value.toFixed(2) + ' %' : '0.00 %';
	};

	calculeTxRapproAuto = (params): string => {
		return params.value ? params.value.toFixed(2) + ' %' : '0.00 %';
	};

	ngOnDestroy(): void {
		sessionStorage['rappSuiviFilter'] = JSON.stringify(this.filters);
		sessionStorage['rappSuiviCurrentPage'] = this.gridHelper.gridApi.paginationGetCurrentPage();
		this.gridHelper.saveGridStatePrefix();
	}

	onColumnChanged(params: any): void {
		this.gridHelper.saveGridColumnState();
	}

	openModalReplayProcDetail(params: any) {
		this.isBtnExecuterDisabled = false;
		if (params.data != null) {
			this.numeroAdeli = params.data.numeroAdeli;
			this.idPs = params.data.psId;
		}
		this.showReplayProcDetail = true;
	}

	openModalMissingTransactionsDetail(params: any) {
		this.isBtnMissingDisabled = false;
		if (params.data != null) {
			this.numeroAdeli = params.data.numeroAdeli;
			this.idPs = params.data.psId;
		}
		this.showMissingTransactionDetail = true;
		this.typeConnexion = params.data.typeRappro;
	}

	closeModalReplayProcDetail() {
		this.showReplayProcDetail = false;
		this.returnMsg = '';
	}

	closeModalMissingTransactionDetail() {
		this.showMissingTransactionDetail = false;
	}

	closeModalLog() {
		this.showModalLog = false;
	}

	redirectToLogRapp() {
		let url = "/careweb-client/logs-rapprochement"
		window.open(url,'_blank');
	}

	closeModalReplayProcConfirm() {
		this.showReplayProcConfirm = false;
		this.showReplayProcDetail = true;
	}

	openModalReplayProcConfirm() {
		this.showReplayProcDetail = false;
		this.showReplayProcConfirm = true;
	}

	onDateSelectReplay($event) {
		if ($event) {
			this.replayStartDate = $event.startDate;
			this.replayEndDate = $event.endDate;
		}
	}

	onDateSelectMissing($event) {
		if ($event) {
			this.missingStartDate = $event.startDate;
			this.missingEndDate = $event.endDate;
		}
	}

	onRelpayProc() {
		const replayStartDate = (moment(this.replayStartDate)).format('DD-MM-YYYY');
		const replayEndDate = (moment(this.replayEndDate)).format('DD-MM-YYYY');
		this.rappComptePsService.replayProcRappAEtudier(replayStartDate, replayEndDate, this.idPs).subscribe((res) => {
			this.showReplayProcConfirm = false;
			this.showReplayProcDetail = false;
			this.showModalLog = true;
			this.idPs = null;
			//this.numeroAdeli =null;
			this.isBtnExecuterDisabled = true;
		}, (err) => {
			console.log('Erreur onRelpayProc' + err);
			this.returnMsg = 'Erreur lors de la Replay Proc';
			this.showReplayProcConfirm = false;
			this.showReplayProcDetail = true;
			//this.numeroAdeli =null;
			this.idPs = null;
			this.isBtnExecuterDisabled = true;
		});
	}

	onExecuteMissingTransactions() {
		const missingStartDate = (moment(this.missingStartDate)).format('DD-MM-YYYY');
		const missingEndDate = (moment(this.missingEndDate)).format('DD-MM-YYYY');
		if (this.typeConnexion === TypeConnexionRapprochement.OXLIN){
			this.rappComptePsService.getMissingTransaction(missingStartDate, missingEndDate, this.numeroAdeli).subscribe((res) => {
				this.showMissingTransactionDetail = false;
				this.numeroAdeli = null;
				this.isBtnMissingDisabled = true;
				this.toastr.success("Le processus est en cours d'exécution, et peut durer plusieurs minutes");
				this.launchSearch();
			}, (err) => {
				this.showMissingTransactionDetail = false;
				this.numeroAdeli = null;
				this.isBtnMissingDisabled = true;
				this.toastr.error("Une erreur est survenue lors du lancement de la récuperation de transactions");
			});
		}
		if (this.typeConnexion === TypeConnexionRapprochement.EBICS){
			this.ebicsService.getMissingTransactionEbics(missingStartDate, missingEndDate, this.idPs,this.withoutDate).subscribe((res) => {
				this.showMissingTransactionDetail = false;
				this.idPs = null;
				this.isBtnMissingDisabled = true;
				this.toastr.success("Le processus est en cours d'exécution, et peut durer plusieurs minutes");
				this.withoutDate = false;
			}, (err) => {
				this.showMissingTransactionDetail = false;
				this.idPs = null;
				this.isBtnMissingDisabled = true;
				this.toastr.error("Une erreur est survenue lors du lancement de la récuperation de transactions");
			});
		}
	}

	protected readonly TypeConnexionRapprochement = TypeConnexionRapprochement;
}
