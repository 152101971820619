<careweb-help></careweb-help>
<div class="full-careweb-container">
    <div class="grid-container" id="grid">
    <h1 class="mt-3">Récapitulatif annuel <careweb-open-help
            [code]="currentUser.etablissementRaisonSocial == 'Ps' ? (isSupervisor? 'help-019':'help-016' ): 'help-017'"></careweb-open-help>
    </h1>
    <app-alert [export]="export" [exportState]="exportState" id="close_alert" (close)="closeAlert()"></app-alert>
    <section class="form-content">
        <div class="row">
            <div class="col">
                <h3 class="title-border">Téléchargement du récapitulatif annuel</h3>
            </div>
        </div>
        <div class="list-group mt-4 ">
            <form [formGroup]="formcontrol">
                <!--------------- ADMIN SECTION ------------->
                <div class="row m-0 mt-4" *ngIf="currentUser.etablissementRaisonSocial !== 'Ps'">
                    <div class="col-sm-2">Eléments du récapitulatif
                    </div>
                    <div class="col-sm-10">
                        <textarea name="text" id="myTextArea" cols="160" rows="10"
                            formControlName="textarea"></textarea>
                        <p class="font-italic text-danger">N°PS#Année1/Année2...</p>
                    </div>
                </div>
                <!--------------- PS SECTION ------------->
                <div class="row m-0 mt-4" *ngIf="isSupervisor">
                    <div class="col-sm-2">PS supervisés
                    </div>
                    <div class="col-sm-4">
                        <ng-select name="supervisedPsList" id="supervisedPsList" formControlName="supervisor"
                                   [searchable]="false"
                                   [clearable]="false"
                                   (change)="onSupervisedPsSelected()"
                                   *ngIf = "isSupervisor">
                            <ng-option [value]="-1">Tous les Ps</ng-option>
                            <ng-option  *ngFor="let supervisedPs of supervisedPsList" [value]="supervisedPs.numeroPsDelegue">{{ supervisedPs.numeroPsDelegue }}</ng-option>
                        </ng-select>
                    </div>
                </div>

                <div class="row m-0 mt-4" *ngIf="currentUser.etablissementRaisonSocial == 'Ps'">
                    <div class="col-sm-2">Période du récapitulatif
                    </div>
                    <div class="col-sm-4">
                        <ng-select formControlName="years"
                            placeholder="Veuillez sélectionner une ou plusiseurs année(s)" name="year" id="year"
                            [searchable]="false" [clearable]="true" [multiple]="true" class="custom-ng-select">
                            <ng-option *ngFor="let year of years" [value]="year">{{ year }}</ng-option>
                        </ng-select>
                    </div>
                </div>

                <div class="row m-0 mt-4" *ngIf="currentUser.etablissementRaisonSocial == 'Ps'">
                    <h6 class="reporting-help-msg"><i class="fa fa-info"></i> La période du récapitulatif ne peut pas
                        dépasser les trois dernières années indiquées.</h6>
                </div>

                <div class="row m-0 mt-4">
                    <div class="col-sm-2">Modèles du récapitulatif</div>
                    <div class="col-sm-10 d-flex align-items-center">
                        <button (click)="reportMode = true" id="model_recap" class="btn btn-action btn-margin mt-3 mb-5 report-type-btn">
                            <i class="fa report-icon"
                                [ngClass]="{ 'fa-check-circle': reportMode, 'fa-circle-o': !reportMode }"></i>
                            Simplifié
                        </button>
                        <button (click)="reportMode = false" id="detaille" class="btn btn-action mt-3 mb-5 report-type-btn">
                            <i class="fa report-icon"
                                [ngClass]="{ 'fa-check-circle': !reportMode, 'fa-circle-o': reportMode }"></i>
                            Détaillé
                        </button>
                    </div>
                </div>

                <div class="row align-right">
                    <div class="col-md-12 ">
                        <button (click)="submit()" id="generer_recap" class="btn btn-primary mt-3 mb-5 float-right" type="submit"
                            [disabled]="!formcontrol.valid || isDisabled"><i class="fa fa-file-pdf-o"></i> Générer le
                            récapitulatif</button>
                    </div>
                </div>
            </form>
        </div>
    </section>
</div>
</div>
