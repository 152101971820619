<careweb-help></careweb-help>
<div class="full-careweb-container">
    <div id="filter" class="filter-container" carewebFilterMinHeight>
      <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
        <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
          (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
        <div class="filter-content">
          <h3 style="color:white; margin-left:10px">Rapprochements</h3>
          <mat-accordion displayMode="flat" class="accordion-filter">
            <careweb-accordion-group id="periode" title="Période de rapp.">
              <ng-select name="periode" id="periode" [searchable]="false" appendTo=".filter-container" [clearable]="false"
                [(ngModel)]="filters.periode" style="width:100%; height:calc(1.5em + .75rem + 2px)" (keydown.enter)="onKeyDown($event)">
                <ng-option id="option_7" [value]="7">7 derniers jours</ng-option>
                <ng-option id="option_15" [value]="15">15 derniers jours</ng-option>
                <ng-option id="option_30"  [value]="30">30 derniers jours</ng-option>
                <ng-option id="option_60" [value]="60">60 derniers jours</ng-option>
                <ng-option id="option_90" [value]="90">90 derniers jours</ng-option>
              </ng-select>
            </careweb-accordion-group>

            <careweb-accordion-group id="id-montant-diff" title="Montant diff. RSP/VRT">
              <ng-select name="montantDiff" id="filter-montantDiff" placeholder="Montant diff. RSP/VRT"
                         [searchable]="false" [(ngModel)]="filters.montantDiff" appendTo=".filter-container"
                         (keydown.enter)="onKeyDown($event)">
                <ng-option id="option_" [value]="''"> </ng-option>
                <ng-option id="option_oui" [value]="true">Oui</ng-option>
                <ng-option id="option_non" [value]="false">Non</ng-option>
              </ng-select>
            </careweb-accordion-group>
            <!-- Commentaire   -->
            <careweb-accordion-group id="commentaire" title="Commentaire">
              <input type="text" id="commentaire" class="form-control" [(ngModel)]="filters.commentaire"
                     (keydown.enter)="onKeyDown($event)" />
            </careweb-accordion-group>

            <h3 style="color:white; margin-top:15px; margin-left:10px">Avis de réception (RSP)</h3>
            <!--  ORGANISME -->
            <careweb-accordion-group id="organisme" title="Organisme">
              <input type="text" id="organisme" class="form-control" [(ngModel)]="filters.organismeLibelle"
                (keydown.enter)="onKeyDown($event)" />
            </careweb-accordion-group>
            <!-- Code organisme émetteur -->
            <careweb-accordion-group id="code_organisme_emetteur" title="Code organisme">
              <input type="text" id="input_organisme_emetteur" class="form-control" [(ngModel)]="filters.idOrganisme"
                name="codeOrganisme" onlyNum="10" (keydown.enter)="onKeyDown($event)"/>
            </careweb-accordion-group>
            <!-- Montant RSP-->
            <careweb-accordion-group id="montantRSP" title="Montant" >
              <careweb-filter-choice-exacte-plage [id]="'montantTotalRsp'"
                                                  [(filterChoiceFrom)]="this.filters.montantTotalRspFrom"
                                                  [(filterChoiceTo)]="this.filters.montantTotalRspTo"
                                                  (filterChange)="this.onChoiceChange($event, 'montantTotalRsp')">
                <input EXACTE type="text" id="input_montant_total_rsp_Exacte" class="form-control" placeholder="0.00" [(ngModel)]="this.filters.montantTotalRspExacte" (keydown.enter)="onKeyDown($event)" name="montantRspExacte" carewebOnlyDouble/>
                <input FROM type="text" id="input_montant_total_rsp_from" class="form-control" placeholder="0.00" [(ngModel)]="this.filters.montantTotalRspFrom" (keydown.enter)="onKeyDown($event)" name="montantRspFrom" carewebOnlyDouble/>
                <div FROM *ngIf="montantRspMinError" class="text-danger">Indiquer un montant minimum</div>
                <input TO type="text" id="input_montant_total_rsp_to" class="form-control" placeholder="0.00" [(ngModel)]="this.filters.montantTotalRspTo" (keydown.enter)="onKeyDown($event)" name="montantRspTo" carewebOnlyDouble/>
                <div TO  *ngIf="montantRspMaxError" class="text-danger">Indiquer un montant maximum</div>
              </careweb-filter-choice-exacte-plage>
            </careweb-accordion-group>

            <!-- Libellé de virement -->
            <careweb-accordion-group id="libelle_virement" title="Libellé de virement">
              <input type="text" id="input_libelle_virement" [(ngModel)]="filters.libelleVirement1"
                     (keydown.enter)="onKeyDown($event)" />
            </careweb-accordion-group>

            <h3 style="color:white; margin-top:15px; margin-left:10px">Virement banque (VRT)</h3>
            <careweb-accordion-group id="label" title="Libellé">
              <input type="text" id="label" class="form-control" [(ngModel)]="filters.label"
                (keydown.enter)="onKeyDown($event)" />
            </careweb-accordion-group>
            <!-- Montant VRT-->
            <careweb-accordion-group id="montantVRT" title="Montant" >
              <careweb-filter-choice-exacte-plage [id]="'montantTotalTr'"
                                                  [(filterChoiceFrom)]="this.filters.montantTotalTrFrom"
                                                  [(filterChoiceTo)]="this.filters.montantTotalTrTo"
                                                  (filterChange)="this.onChoiceChange($event, 'montantTotalTr')">
                <input EXACTE type="text" id="input_montant_total_vrt_Exacte" class="form-control" placeholder="0.00" [(ngModel)]="this.filters.montantTotalTrExacte" (keydown.enter)="onKeyDown($event)" name="montantVrtExacte" carewebOnlyPositiveDouble/>
                <input FROM type="text" id="input_montant_total_vrt_from" class="form-control" placeholder="0.00" [(ngModel)]="this.filters.montantTotalTrFrom" (keydown.enter)="onKeyDown($event)" name="montantVrtFrom" carewebOnlyPositiveDouble/>
                <div FROM *ngIf="montantVrtMinError" class="text-danger">Indiquer un montant minimum</div>
                <input TO type="text" id="input_montant_total_vrt_to" class="form-control" placeholder="0.00" [(ngModel)]="this.filters.montantTotalTrTo" (keydown.enter)="onKeyDown($event)" name="montantVrtTo" carewebOnlyPositiveDouble/>
                <div TO  *ngIf="montantVrtMaxError" class="text-danger">Indiquer un montant maximum</div>
              </careweb-filter-choice-exacte-plage>
            </careweb-accordion-group>

          </mat-accordion>
        </div>
      </div>
    </div>
    <div class="grid-container" id="grid">
      <h2 class="title-border mt-3">Rapprochements validés
        <careweb-open-help code="help-011"></careweb-open-help>
      </h2>
      <div>
        <section>
          <div class="row grid-top-btn">
            <div class="col-md-4 filter">
              <button type="button" name="btn_toggle_filter" id="filters" class="btn btn-primary small left-open" [hidden]="showFilter"
                (click)="toggleFilterDisplay()">
                <i class="fa fa-sliders"></i>
                Filtrer
              </button>
              <careweb-pluralize class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
                [count]="gridHelper.totalRowCount">
              </careweb-pluralize>
            </div>
            <div class="col-md-8 conf-col">
              <div class="careweb-toolbar">
                <div [tooltip]="rowCount > 0 ? 'Exporter les résultats (.xlsx)' : 'Aucune ligne à exporter, veuillez affiner votre recherche'"
                     placement="top" container="body">
                  <span class="btn btn-action small" [class.disabled]="!gridHelper.isAvailableData()"
                          name="btn_export_csv"
                       id="export_files"   (click)="exportFileCsv()">
                    <i class="fa fa-file-excel-o"></i>
                  </span>
                </div>

                <!--<careweb-column-selector (itemSelect)="selectColumnEvent($event)"
                  (itemDeselect)="deselectColumnEvent($event)"></careweb-column-selector>-->
                  <careweb-pagination-size id="dv_transactions_paging" [pageSizes]="[10, 20, 30, 50, 100]"
                  [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
                </careweb-pagination-size>
                <span class="btn btn-action small" id="reset_grid" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                  name="spn_reset_grid" (click)="resetGridState()">
                  <i class="fa fa-refresh"></i>
                </span>
              </div>
            </div>
          </div>
            <app-alert [export]="export" [exportState]="exportState" (close)="closeAlert()"></app-alert>
        <div class="row grid-top-filter">
            <div class="col-md-12">
                <careweb-filter-label *ngFor="let filter of filterList" id="closeFilter" (closeFilter)="removeFilter(filter.id)">
                    <span *ngIf="filter" id="{{filter.value}}">{{ filter.label}}: {{filter.value }}</span>
                </careweb-filter-label>
            </div>
        </div>
          <div class="row highlight-row">
            <div class="col-md-12 relative">
              <ag-grid-angular style="width: 100%;" class="ag-theme-balham ag-theme-no-rows" [gridOptions]="gridHelper.gridOptions">
              </ag-grid-angular>
              <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

  <div *ngIf="showRappAutoDetail">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalRappValide" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xxl" role="document">
            <div class="modal-content" style="padding-bottom:10px">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Détails du rapprochement</h3>
                        <button class="close" name="btn_display_filter" data-dismiss="modal" type="button"
                            title="Fermer" id="close_modal" (click)="closeModalRappAutoDetail()"><span id="x" aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body pb-0">
                        <div class="form-block">
                            <div class="row">
                                <div class="col">
                                  <ul ngbNav #nav="ngbNav" [(activeId)]="active"  [destroyOnHide]="false" class="nav-tabs">
                                      <li [ngbNavItem]="1"  *ngIf="!allRspPurger && loadData">
                                      		<button ngbNavLink>Avis de réception comptable ({{this.selectedRow.nbRsp}})</button>
		                                        <ng-template ngbNavContent>
                                        <div class="tab-pane fade show active mt-4">
                                          <div *ngIf="rspPurger && loadData" class="alert alert-info mt-4">
                                              <div>
                                                  <i class="fa fa-info-circle"></i> <span class="font-weight-bold"> Purge de données </span>
                                              </div>
                                              <div>
                                                  Les RSP liées à ce rapprochement peuvent ne pas être disponibles. Pour rappel, les informations de télétransmission sont conservées pendant une période de 3 mois plus le mois en cours.
                                              </div>
                                          </div>
                                          <div class="row grid-top-btn">
                                            <div class="col-6" style="text-align: left; display: flex; align-items: center;">
                                              Montant total RSP : {{this.selectedRow.montantTotalRsp | formatThousands}}
                                            </div>
                                            <div class="col-6 careweb-toolbar">
                                              <careweb-pagination-size id="dv_rule_paging" [pageSizes]="[10, 20,30, 50, 100]"
                                              [(ngModel)]="gridDataRawRsp.size" (paginationSizeChange)="onRspPaginationSizeChange($event)">
                                              </careweb-pagination-size>
                                              <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                                              name="spn_reset_grid" id="reset_grid" (click)="resetRspGridState()">
                                               <i class="fa fa-refresh"></i>
                                              </span>
                                            </div>
                                          </div>
                                          <div *ngIf="selectedRow.nbRsp == 0 ||  !isNullOrUndefined(selectedRow.rsps)" class="row modal-scroll">
                                            <div class="col-md-12 text-title noPadding ">
                                              <ag-grid-angular #gridRsp style="width: 100%;" class="ag-theme-balham"
                                                [gridOptions]="gridHelperRSP.gridOptions">
                                              </ag-grid-angular>
                                              <careweb-pagination [gridApi]="gridHelperRSP.gridApi"></careweb-pagination>
                                            </div>
                                          </div>
                                        </div>
                                      </ng-template>
                                    </li>
                                    <li [ngbNavItem]="1" *ngIf="allRspPurger && loadData">
                                      <button ngbNavLink>Avis de réception comptable ({{this.selectedRow.nbRsp}})</button>
                                      <ng-template ngbNavContent>
                                        <div class=" modal-scroll">
                                          <div class="tab-pane fade show mt-4 " >
                                            <div class="alert alert-info mt-4">
                                              <div>
                                                <i class="fa fa-info-circle"></i> <span class="font-weight-bold"> Purge de données </span>
                                              </div>
                                              <div>
                                                  Les RSP liées à ce rapprochement peuvent ne pas être disponibles. Pour rappel, les informations de télétransmission sont conservées pendant une période de 3 mois plus le mois en cours.
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </ng-template>
                                    </li>
                                    <li [ngbNavItem]="1" *ngIf="!loadData">
                                      <button id="reception_compt" ngbNavLink>Avis de réception comptable ({{this.selectedRow.nbRsp}})</button>
                                      <ng-template ngbNavContent>
                                      </ng-template>
                                    </li>
                                    <li [ngbNavItem]="2">
                                      		<button id="virement_banque" ngbNavLink>Virements banque ({{this.selectedRow.nbTransactions}})</button>
		                                    <ng-template ngbNavContent>
                                        <div class="tab-pane fade show mt-4">
                                          <div class="row grid-top-btn">
                                            <div class="col-6" style="text-align: left; display: flex; align-items: center;">
                                              Montant total VRT : {{this.selectedRow.montantTotalTr | formatThousands}}
                                            </div>
                                            <div class="col-6 careweb-toolbar">
                                              <careweb-pagination-size id="dv_rule_paging" [pageSizes]="[10, 20,30, 50, 100]"
                                              [(ngModel)]="gridDataRawVrt.size" (paginationSizeChange)="onVrtPaginationSizeChange($event)">
                                              </careweb-pagination-size>
                                              <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                                              name="spn_reset_grid" (click)="resetVrtGridState()">
                                               <i class="fa fa-refresh"></i>
                                              </span>
                                            </div>
                                          </div>
                                          <div class="row modal-scroll">
                                            <div class="col-md-12 text-title noPadding ">
                                              <ag-grid-angular gridVrt style="width: 100%;" class="ag-theme-balham"
                                                [gridOptions]="gridHelperVRT.gridOptions">
                                              </ag-grid-angular>
                                              <careweb-pagination [gridApi]="gridHelperVRT.gridApi"></careweb-pagination>
                                            </div>
                                          </div>
                                        </div>
                                      </ng-template>
                                    </li>
                                  </ul>
                                  <div [ngbNavOutlet]="nav" class="mt-2"></div> 
                                </div>
                            </div>
                            <div class="row"><div class="col">
                              <div class="button-bar control">
                                <button type="button" class="btn btn-primary" style="margin-right:15px;margin-bottom:5px;" id="close_modalRapp" (click)="closeModalRappAutoDetail()">
                                    <span>Fermer</span>
                                </button>
                          </div>
                        </div></div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
        </div>
    </div>
</div>

<!-- Modal confirmation -->
<careweb-confirm-modal (confirmModal)="confirmModal()" (showModal)="showModal($event)" [(visible)]="showConfirmModal"
  [title]="confirmModalTitle" labelValidButton="Valider" labelCancelButton="Annuler" [text]="confirmModalContent">
</careweb-confirm-modal>
