import { Component, AfterViewInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'rapp-auto-preview',
	template: `
        <i
			[ngClass]="iconClass"
            container="body"
			id="{{params.colDef.field}}"
			[tooltip]="tooltip"
            containerClass="tooltip-grid"
		></i>
    `
})
export class RappAutoPreviewcomponent implements ICellRendererAngularComp, AfterViewInit {
	iconClass: string;
	tooltip: string;
	params: any;

	constructor() {}

	refresh(params: any): boolean {
		return  false;
	}

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit() {
		const cellParams = this.params;
		if (cellParams.data) {
			// Colonne options pour afficher le détail
			if (cellParams.colDef.field && cellParams.colDef.field === 'options') {
				this.iconClass = 'fa fa-eye';
				this.tooltip = 'Consulter détails du rapprochement';
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'unlink') {
				this.iconClass = 'fa fa-unlink';
				this.tooltip = 'Dissocier le rapprochement auto';
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'detailRSP') {
				if(cellParams.data.node){
					this.iconClass = 'fa fa-eye';
					this.tooltip = 'Consulter détails du RSP';
				}
			} else if (cellParams.colDef.field && cellParams.colDef.field === 'unvalidate') {
				this.iconClass = 'fa fa-unlink';
				this.tooltip = 'Dissocier le rapprochement';
			}
			// Append cursor pointer
			this.iconClass = this.iconClass + ' cursor-pointer';
		}
	}
}
