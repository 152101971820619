import { Component, OnInit } from '@angular/core';
import { EditeurService } from '../services/editeurs.service';
import { EditeurDataRaw } from '../../../data/editeurDataRaw';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';
import { InputValidators } from '../../../components/validators/InputValidators';
import { ToastrService } from 'ngx-toastr';
import {UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators} from '@angular/forms';

@Component({
	selector: 'careweb-app-ajout-editeur',
	templateUrl: './ajout-editeur.component.html',
	styleUrls: [ './ajout-editeur.component.scss' ]
})
export class AjoutEditeurComponent implements OnInit {
	selectedEditeur: EditeurDataRaw;
	editeurFormSubmitted = false;
	editeurForm: UntypedFormGroup;

	constructor(
		private editeurService: EditeurService,
		private router: Router,
		private inputValidators: InputValidators,
		private toastr: ToastrService,
		private fb: UntypedFormBuilder
	) {
		this.selectedEditeur = new EditeurDataRaw();
	}

	ngOnInit() {
		this.initEditeurForm();
	}

	initEditeurForm() {
		this.editeurForm = this.fb.group({
			raisonSociale: new UntypedFormControl('', [Validators.required, this.inputValidators.inputWithSpaces]),
			adresse1: new UntypedFormControl(''),
			adresse2: new UntypedFormControl(''),
			ville: new UntypedFormControl(''),
			codePostal: new UntypedFormControl(''),
			telephone: new UntypedFormControl(''),
			fax: new UntypedFormControl(''),
			email: new UntypedFormControl(''),
			url: new UntypedFormControl(''),
			idEditeur: ''
		});
	}

	public enregistrer() {
		this.editeurFormSubmitted = true;
		this.fromEditeurFormToModel();
		// verify if form valid if
		if (this.editeurForm.valid && !isNullOrUndefined(this.selectedEditeur)) {
			this.selectedEditeur.raisonSociale = this.selectedEditeur.raisonSociale
				? this.selectedEditeur.raisonSociale.trim()
				: '';
			this.selectedEditeur.adresse1 = this.selectedEditeur.adresse1 ? this.selectedEditeur.adresse1.trim() : '';
			this.selectedEditeur.adresse2 = this.selectedEditeur.adresse2 ? this.selectedEditeur.adresse2.trim() : '';
			this.selectedEditeur.ville = this.selectedEditeur.ville ? this.selectedEditeur.ville.trim() : '';
			this.selectedEditeur.email = this.selectedEditeur.email ? this.selectedEditeur.email.trim() : '';
			this.selectedEditeur.url = this.selectedEditeur.url ? this.selectedEditeur.url.trim() : '';
			// verifier si la raison social existe déjà
			this.editeurService.getEditeurByRaisonSocial(this.selectedEditeur.raisonSociale).subscribe((editeur) => {
				if (!isNullOrUndefined(editeur)) {
					this.editeurForm.controls.raisonSociale.setErrors({ raisonSocExist: true });
				} else {
					// si RS n existe pas ajouter le nouveau editeur
					this.editeurService.saveEditeur(this.selectedEditeur).subscribe(
						(addedEditeur) => {
							if (!isNullOrUndefined(addedEditeur)) {
								this.toastr.success("L'éditeur a été ajouté avec succès");
								this.router.navigate([ '/editeur-details', addedEditeur.idEditeur ]);
							} else {
								this.toastr.error(
									"Une erreur est survenue lors de l'ajout d'éditeur.",
									"Ajout d'un éditeur"
								);
							}
						},
						(err) => {
							this.toastr.error(
								"Une erreur est survenue lors de l'ajout d'éditeur.",
								"Ajout d'un éditeur"
							);
						}
					);
				}
			});
		}
	}

	onChangeRaisonSoc() {
		if (this.editeurForm.valid) {
			this.editeurFormSubmitted = false;
		}
	}

	cancel() {
		this.editeurFormSubmitted = false;
		this.selectedEditeur = new EditeurDataRaw();
		this.fromModelToEditeurForm();
	}

	fromEditeurFormToModel() {
		this.selectedEditeur.raisonSociale = this.editeurForm.controls.raisonSociale.value;
		this.selectedEditeur.adresse1 = this.editeurForm.controls.adresse1.value;
		this.selectedEditeur.adresse2 = this.editeurForm.controls.adresse2.value;
		this.selectedEditeur.ville = this.editeurForm.controls.ville.value;
		this.selectedEditeur.codePostal = this.editeurForm.controls.codePostal.value;
		this.selectedEditeur.email = this.editeurForm.controls.email.value;
		this.selectedEditeur.url = this.editeurForm.controls.url.value;
		this.selectedEditeur.telephone = this.editeurForm.controls.telephone.value;
		this.selectedEditeur.fax = this.editeurForm.controls.fax.value;
	}

	fromModelToEditeurForm() {
		this.editeurForm.controls.idEditeur.setValue(this.selectedEditeur.idEditeur);
		this.editeurForm.controls.raisonSociale.setValue(this.selectedEditeur.raisonSociale);
		this.editeurForm.controls.adresse1.setValue(this.selectedEditeur.adresse1);
		this.editeurForm.controls.adresse2.setValue(this.selectedEditeur.adresse2);
		this.editeurForm.controls.ville.setValue(this.selectedEditeur.ville);
		this.editeurForm.controls.codePostal.setValue(this.selectedEditeur.codePostal);
		this.editeurForm.controls.email.setValue(this.selectedEditeur.email);
		this.editeurForm.controls.url.setValue(this.selectedEditeur.url);
		this.editeurForm.controls.telephone.setValue(this.selectedEditeur.telephone);
		this.editeurForm.controls.fax.setValue(this.selectedEditeur.fax);
	}

	get editeurFormFields() {
		return this.editeurForm.controls;
	}

	retour() {
		this.router.navigate([ '/editeurs' ]);
	}
}
