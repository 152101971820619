<careweb-help></careweb-help>
<div class="full-careweb-container">
  <div id="filter" class="filter-container" carewebFilterMinHeight>
    <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
      <careweb-accordion-header-buttons [disablesearch]="disableSearch()" (closeFilters)="toggleFilterDisplay()"
        (resetFilters)="resetAllFilter()" (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
      <div class="filter-content">
        <mat-accordion displayMode="flat" class="accordion-filter" *ngIf="filters">
          <!-- Numéro de PS -->
          <careweb-accordion-group id="num_ps" title="Numéro de PS" [hidden]="!existGroupNumeroPS()">
            <input type="text" id="input_num_ps" [(ngModel)]="filters.numeroPs" [hidden]="!isAuthorized()"
              (keydown.enter)="onKeyDown($event)" onlyNum="9" placeholder="000000000"/>

            <!--Afficher Numéro de PS avec ComboBox si l'utilisateur PS-->
            <ng-select name="numeroPScomboBox" id="numeroPScomboBox" placeholder="Numéro de PS" [searchable]="false"
              [(ngModel)]="filters.numeroPScomboBox" appendTo=".filter-container" [hidden]="isAuthorized()">
              <ng-option *ngFor="let numeroPScomboBox of psDelegueListFilter"
                [value]="numeroPScomboBox.numeroPsDelegue">
                {{numeroPScomboBox.numeroPsDelegue}}
              </ng-option>
            </ng-select>
          </careweb-accordion-group>
          <!-- "Numéro du lot -->
          <careweb-accordion-group id="num_lot" title="Numéro du lot">
            <input type="text" id="input_num_lot_from" class="form-control"
              onKeyPress="if(this.value.length==3) return false;" [(ngModel)]="filters.numLot" maxlength="3"
              onkeyup="this.value=this.value.replace(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,'')"
              onkeydown="this.value=this.value.replace(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,'')"
              (keydown.enter)="onKeyDown($event)"
              oninput="this.value=this.value.replace(/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/,'');"
              onpaste="if(this.value.length==3) return false;" />
          </careweb-accordion-group>
          <!-- Tiers-payant -->
          <careweb-accordion-group id="tiers_payant" title="Tiers-payant">
            <ng-select name="tiersPayant" id="tiersPayant" placeholder="Tiers-Payant" [searchable]="false"
              [(ngModel)]="filters.tiersPayant" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
              <ng-option id="option_" [value]="''"> </ng-option>
              <ng-option id="option_oui" [value]="true">Oui</ng-option>
              <ng-option id="option_non" [value]="false">Non</ng-option>
            </ng-select>
          </careweb-accordion-group>
          <!-- N° de FSE -->
          <careweb-accordion-group id="num_fse" title="Numéro de FSE">
            <careweb-filter-choice-exacte-plage [id]="'numFse'" 
                [(filterChoiceFrom)]="this.filters.numeroFseFrom" 
                [(filterChoiceTo)]="this.filters.numeroFseTo">
                <input EXACTE type="text" id="input_numeroFse_exacte" class="form-control"
                 [(ngModel)]="this.filters.numFacture" onlyNum="9"  [ngClass]="{'date-range-error': numeroFseExacteError}" 
                 tooltip="Veuillez saisir au minimum 3 chiffres" triggers="" placement="top" #tooltipExacte="bs-tooltip" 
                 (mouseover) = "numeroFseExacteError ? showErrorTooltip('Exacte') : null" (keypress)="onNumeroFseFilterChange('Exacte')" 
                 (input)="onNumeroFseFilterChange('Exacte')" (keydown.enter)="onKeyDown($event)" placeholder="000000000"/>
                <input FROM type="text" id="input_numeroFse_from" class="form-control" 
                 [(ngModel)]="this.filters.numeroFseFrom" onlyNum="9" [ngClass]="{'date-range-error': numeroFseError}"
                 tooltip="Veuillez saisir un numéro antérieure" triggers="" placement="top" #tooltipFrom="bs-tooltip"
                 (mouseover) = "numeroFseError ? showErrorTooltip('From') : null" (keypress)="onNumeroFseFilterChange('From')"
                 (input)="onNumeroFseFilterChange('From')" (keydown.enter)="onKeyDown($event)" placeholder="000000000"/>
                <input TO type="text" id="input_to_numeroFse_To" class="form-control"
                 [(ngModel)]="this.filters.numeroFseTo" onlyNum="9" [ngClass]="{'date-range-error': numeroFseError}"
                 tooltip="Veuillez saisir un numéro postérieure" triggers="" placement="bottom" #tooltipTo="bs-tooltip"
                 (mouseover) = "numeroFseError ? showErrorTooltip('To') : null" (keypress)="onNumeroFseFilterChange('To')"
                 (input)="onNumeroFseFilterChange('To')" (keydown.enter)="onKeyDown($event)" placeholder="000000000"/>
            </careweb-filter-choice-exacte-plage><br>
          </careweb-accordion-group>
          <!-- Lot reçus-->
          <careweb-accordion-group id="lots_ recus" title="Lots Reçus" #dateAccordion>
            <careweb-date-from-to-filter labelFrom="entre le" [(modelFrom)]="filters.dateLotRecuFrom"
              [(modelTo)]="filters.dateLotRecuTo" [(modelExacte)]="filters.dateLotRecuExacte"
							[maxDate]="null" [isExactePlageChoice]="true" id="lots_ recus"
							(filterChange)="this.onChoiceChange($event)" [fromRsp]="false"
              (dateRangeValidityChange) = "checkDateRangeValidity($event)" (onEnter) = "onKeyDown($event)" #dateEnvoie></careweb-date-from-to-filter>
          </careweb-accordion-group>
          <careweb-accordion-group id="rsp_ recus" title="RSP Reçus" #dateAccordion>
            <careweb-date-from-to-filter labelFrom="entre le" [(modelFrom)]="filters.dateRspRecuFrom"
                                         [(modelTo)]="filters.dateRspRecuTo" [(modelExacte)]="filters.dateRspRecuExacte"
                                         [maxDate]="null" [isExactePlageChoice]="true" id="rsp_ recus"
                                         (filterChange)="this.onChoiceChange($event)" [fromRsp]="true"
                                         (dateRangeValidityChange) = "checkDateRangeValidity($event)" (onEnter) = "onKeyDown($event)" #dateEnvoie></careweb-date-from-to-filter>
          </careweb-accordion-group>
          <!-- Présence d'ARL SCOR -->
         <!-- <careweb-accordion-group id="presence_arl_scor" title="Présence d'un ARL SCOR">
            <ng-select name="presenceArlScor" id="presenceArlScor" placeholder="Présence d'un ARL SCOR"
              [searchable]="false" [(ngModel)]="filters.presenceArlScor" appendTo=".filter-container"
              (keydown.enter)="onKeyDown($event)">
              <ng-option [value]="''"> </ng-option>
              <ng-option [value]="true">Oui</ng-option>
              <ng-option [value]="false">Non</ng-option>
            </ng-select>
          </careweb-accordion-group>-->
          <!-- Présence de RSP RO -->
          <careweb-accordion-group id="presence_rsp_ro" title="Présence d'un RSP AMO">
            <ng-select name="presenceRspRo" id="presenceRspRo" placeholder="Présence d'un RSP AMO" [searchable]="false"
              [(ngModel)]="filters.presenceRspRo" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
              <ng-option id="option_"  [value]="''"> </ng-option>
              <ng-option id="option_oui" [value]="true">Oui</ng-option>
              <ng-option id="option_non" [value]="false">Non</ng-option>
            </ng-select>
          </careweb-accordion-group>

          <!-- Présence de RSP RC -->
          <careweb-accordion-group id="presence_rsp_rc" title="Présence d'un RSP AMC">
            <ng-select name="presenceRspRc" id="presenceRspRc" placeholder="Présence d'un RSP AMC" [searchable]="false"
              [(ngModel)]="filters.presenceRspRc" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
              <ng-option id="option_"  [value]="''"> </ng-option>
              <ng-option id="option_oui"  [value]="true">Oui</ng-option>
              <ng-option id="option_non" [value]="false">Non</ng-option>
            </ng-select>
          </careweb-accordion-group>
          <!-- Code destinataire RO -->
          <careweb-accordion-group id="code_destinataire_ro" title="Code destinataire AMO">
            <label for="orderRef">Régime</label>
            <input type="text" id="input_code_destinataire_ro_regime" class="form-control" min="0"
              [(ngModel)]="filters.codeDestinataireRoRegime" onpaste="if(this.value.length==2) return false;"
              onKeyPress="if(this.value.length==2) return false;" oninput="this.value=this.value.replace(/[^\d]/,'');"
              pattern="^[0-9]" onkeyup="this.value=this.value.replace(/[^\d]/,'');"
              onkeydown="this.value=this.value.replace(/[^\d]/,'');" (keydown.enter)="onKeyDown($event)"  placeholder="00"/>
            <label for="orderRef" class="mt-2">Caisse</label>
            <input type="text" id="input_code_destinataire_ro_caisse" class="form-control" min="0"
              [(ngModel)]="filters.codeDestinataireRoCaisse" onpaste="if(this.value.length==3) return false;"
              onKeyPress="if(this.value.length==3) return false;" oninput="this.value=this.value.replace(/[^\d]/,'');"
              pattern="^[0-9]" onkeyup="this.value=this.value.replace(/[^\d]/,'');"
              onkeydown="this.value=this.value.replace(/[^\d]/,'');" (keydown.enter)="onKeyDown($event)" placeholder="000"/>
            <label for="orderRef" class="mt-2">Centre</label>
            <input type="text" id="input_code_destinataire_ro_center" class="form-control"
              [(ngModel)]="filters.codeDestinataireRoCenter" onpaste="if(this.value.length==4) return false;"
              onKeyPress="if(this.value.length==4) return false;" oninput="this.value=this.value.replace(/[^\d]/,'');"
              pattern="^[0-9]" onkeyup="this.value=this.value.replace(/[^\d]/,'');"
              onkeydown="this.value=this.value.replace(/[^\d]/,'');" (keydown.enter)="onKeyDown($event)" placeholder="0000" />
          </careweb-accordion-group>
          <!-- Code destinataire RC-->
          <careweb-accordion-group id="code_destinataire_rc" title="Code destinataire AMC">
            <input type="text" min="0" max="9999999999" id="input_code_destinataire_rc" pattern="^[0-9]"
              [(ngModel)]="filters.codeDestinataireRC" onkeyup="this.value=this.value.replace(/[^\d]/,'')"
              onKeyPress="if(this.value.length==10) return false;" (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Type d'organisme -->
          <careweb-accordion-group id="type_organisme" title="Type d'organisme">
            <ng-select name="typeOrganisme" id="typeOrganismeSelector" placeholder="Type d'organisme"
              [(ngModel)]="filters.typeOrganisme" appendTo=".filter-container"
              (keydown.enter)="onKeyDown($event)">
              <ng-option id="option_RO" [value]="'RO'">AMO</ng-option>
              <ng-option id="option_RC"[value]="'RC'">AMC</ng-option>
            </ng-select>
          </careweb-accordion-group>
          <!-- FSE extraite -->
          <careweb-accordion-group id="part_extraite" title="Parts prises en compte">
              <div class="form-check" *ngFor="let item of partExtraiteFilter" name="partExtraite" id="partExtraite" placeholder="Parts prises en compte" >
                <input class="form-check-input custom-checkbox" type="checkbox" id="check_{{item.id}}" [(ngModel)]="item.checked" (change)="onCheckBoxChange($event, item)"
                       (keydown.enter)="onKeyDown($event)"/>
                <label class="form-check-label" for="check_{{item.id}}">{{item.value}}</label>
              </div>
          </careweb-accordion-group>
          <!-- Libellé organisme RO -->
          <careweb-accordion-group id="libelle_ro" title="Libellé organisme AMO">
            <input type="text" id="input_libelle_ro" [(ngModel)]="filters.libelleRo"
              onKeyPress="if(this.value.length==150) return false;" onpaste="if(this.value.length==150) return false;"
              (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Libellé organisme RC -->
          <careweb-accordion-group id="libelle_rc" title="Libellé organisme AMC" >
            <input type="text" id="input_libelle_rc" [(ngModel)]="filters.libelleRc"
              onKeyPress="if(this.value.length==150) return false;" onpaste="if(this.value.length==150) return false;"
              (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Centre Info. du type 0 -->
          <careweb-accordion-group id="centre_info_type0" title="Centre Info. du type 0">
            <input type="text" id="input_centre_info_type0" [(ngModel)]="filters.centreInfoTypeZero"
              onKeyPress="if(this.value.length==3) return false;" onpaste="if(this.value.length==3) return false;"
              (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Rapprochement Bancaire -->
          <careweb-accordion-group *ngIf="hasRapproBancaireAccess()" id="rapproBancaire" title="Rappro. bancaire">
					  <ng-select name="rapproBancaire" id="rapproBancaire" placeholder="Rappro. bancaire"
							[searchable]="false" [(ngModel)]="filters.rapproBancaire" appendTo=".filter-container"
							(keydown.enter)="onKeyDown($event)">
							<ng-option id="option_" [value]="''"> </ng-option>
							<ng-option id="option_oui" [value]="1">Oui</ng-option>
                            <ng-option id="option_partiel" [value]="2"> Partiel</ng-option>
							<ng-option id="option_non"[value]="0">Non</ng-option>
						</ng-select>
					</careweb-accordion-group>
        </mat-accordion>
      </div>
    </div>
  </div>
  <div class="grid-container" id="grid">
    <h2 class="title-border mt-3">Liste des FSE
      <careweb-open-help code="help-002"></careweb-open-help>
    </h2>
    <div>
      <section>
        <div class="row grid-top-btn">
          <div class="col-md-4 filter">
            <button type="button" name="btn_toggle_filter" id="Filtre_fse" class="btn btn-primary small left-open" [hidden]="showFilter"
              (click)="toggleFilterDisplay()">
              <i class="fa fa-sliders"></i>
              Filtrer
            </button>
            <careweb-pluralize *ngIf="totalResultsHidden" class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
              [count]="gridHelper.totalRowCount"></careweb-pluralize>
          </div>
          <div class="col-md-8 conf-col">
            <div class="careweb-toolbar">
              <div [tooltip]="gridHelper.isAvailableData() ? 'Exporter les résultats (.xlsx)' : 'Aucune ligne à exporter, veuillez affiner votre recherche'"
                   placement="top" container="body">
                <span class="btn btn-action small" [class.disabled]="!gridHelper.isAvailableData()"
                     id="export_files"  name="btn_export_.xls" (click)="exportFileXlsxModal()">
                <i class="fa fa-file-excel-o"></i>
                </span>
              </div>

              <careweb-column-selector (itemSelect)="selectColumnEvent($event)"
                (itemDeselect)="deselectColumnEvent($event)"></careweb-column-selector>
              <careweb-pagination-size id="dv_fse_paging" [pageSizes]="[10, 20,30, 50, 100]"
                [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
              </careweb-pagination-size>
              <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" id="reset_filters" container="body"
                name="spn_reset_grid" (click)="resetGridState()">
                <i class="fa fa-refresh"></i>
              </span>
            </div>
          </div>
        </div>
          <app-alert [export]="export" [exportState]="exportState" id="closeAlert" (close)="closeAlert()"></app-alert>
        <div class="row grid-top-filter">
          <div class="col-md-12">
            <careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter)">
              <span  id="filter.value" *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
            </careweb-filter-label>
          </div>
        </div>
        <div class="row highlight-row">
          <div class="col-md-12 relative">
            <ag-grid-angular style="width: 100%" class="ag-theme-balham" [gridOptions]="gridHelper.gridOptions">
            </ag-grid-angular>
            <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
