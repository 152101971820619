import { Directive, forwardRef, HostListener } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
    selector: '[phoneNumberDirective]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => PhoneNumberDirective), multi: true }]
})
export class PhoneNumberDirective implements Validator {
    private readonly maxLength: number = 16;

    validate(control: AbstractControl): ValidationErrors | null {
        const value = control.value as string;
        if (value && (value.length > this.maxLength || !/^\d*$/.test(value))) {
            return { invalidPhoneNumber: true };
        }
        return null;
    }

    // Limite la longueur lors de la saisie
    @HostListener('keypress', ['$event'])
    onKeyPress(event: KeyboardEvent): void {
        const input = (event.target as HTMLInputElement).value;
        if (input.length >= this.maxLength && !this.isControlKey(event)) {
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent): void {
        event.preventDefault();
        const clipboardData = event.clipboardData?.getData('text') ?? '';
        const sanitized = this.sanitizeInput(clipboardData);
        this.setInputValue(event.target as HTMLInputElement, sanitized);
    }

    // Filtre les caractères non numériques lors de la saisie
    @HostListener('input', ['$event'])
    onInput(event: Event): void {
        const input = (event.target as HTMLInputElement).value;
        const sanitized = this.sanitizeInput(input);
        this.setInputValue(event.target as HTMLInputElement, sanitized);
    }

    // Nettoie et limite l'entrée à des chiffres uniquement
    private sanitizeInput(value: string): string {
        return value.replace(/[^\d]/g, '').substring(0, this.maxLength);
    }

    private setInputValue(inputElement: HTMLInputElement, value: string): void {
        inputElement.value = value;
    }

    // Vérifie si une touche est une touche de contrôle (backspace, flèches)
    private isControlKey(event: KeyboardEvent): boolean {
        const controlKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];
        return controlKeys.includes(event.key);
    }
}
