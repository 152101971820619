<div class="full-careweb-container">
  <div id="filter" class="filter-container" carewebFilterMinHeight>
    <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
      <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" id="reset_filter" (resetFilters)="resetAllFilter()"
        (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
      <div class="filter-content">
        <mat-accordion displayMode="flat" class="accordion-filter">
          <!-- Type d'émetteur -->
          <careweb-accordion-group id="type_emetteur" title="Type d'émetteur">
            <input type="text" id="input_type_emetteur" [(ngModel)]="filters.typeEmetteur"
              onKeyPress="if(this.value.length==2) return false;"
              onpaste="if(this.value.length==2) return false;"
              (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Emetteur -->
          <careweb-accordion-group id="emetteur" title="Émetteur">
            <input type="text" id="input_emetteur" [(ngModel)]="filters.emetteur" 
            onKeyPress="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==14) return false;"
            onpaste="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==14) return false;"
            oninput="this.value=this.value.replace(/[^\d]/,'');" pattern="^[0-9]"
            onkeyup="this.value=this.value.replace(/[^\d]/,'');"
            onkeydown="this.value=this.value.replace(/[^\d]/,'');" (keydown.enter)="onKeyDown($event)"/>
          </careweb-accordion-group>
          <!-- Type de destinataire -->
          <careweb-accordion-group id="type_destinataire" title="Type de destinataire">
            <input type="text" id="input_type_destinataire" [(ngModel)]="filters.typeDestinataire"
            onKeyPress="if(this.value.length==2) return false;"
              onpaste="if(this.value.length==2) return false;"
              (keydown.enter)="onKeyDown($event)" />
          </careweb-accordion-group>
          <!-- Destinataire -->
          <careweb-accordion-group id="Destinataire" title="N° Destinataire">
            <input type="text" id="input_destinataire" [(ngModel)]="filters.numDestinataire"
            onKeyPress="if(this.value.length==14) return false;" maxlength="14"
            onpaste="if(this.value.length==14) return false;" (keydown.enter)="onKeyDown($event)"/>
          </careweb-accordion-group>
          <!-- Compactage -->
          <careweb-accordion-group id="id-compactage" title="Compactage">
            <ng-select name="compactage" id="compactage" placeholder="Compactage" [searchable]="false"
            [(ngModel)]="filters.compactage" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
            <ng-option [value]="''"> </ng-option>
            <ng-option [value]="true">Oui</ng-option>
            <ng-option [value]="false">Non</ng-option>
          </ng-select>
          </careweb-accordion-group>
          <!-- Cryptage -->
          <careweb-accordion-group id="id-cryptage" title="Cryptage">
            <ng-select name="cryptage" id="cryptage" placeholder="Cryptage" [searchable]="false"
            [(ngModel)]="filters.cryptage" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
            <ng-option [value]="''"> </ng-option>
            <ng-option [value]="true">Oui</ng-option>
            <ng-option [value]="false">Non</ng-option>
          </ng-select>
          </careweb-accordion-group>
          <!-- Regroupement -->
          <careweb-accordion-group id="regroupement" title="Regroupement">
            <ng-select name="regroupement" id="regroupement" [searchable]="false"
              [(ngModel)]="filters.regroupement" appendTo=".filter-container" [clearable]="false" (keydown.enter)="onKeyDown($event)">
              <ng-option [value]="'Tous'">Tous</ng-option>
              <ng-option *ngFor="let regroupement of regroupementList" [value]="regroupement.libelle">
                {{regroupement.libelle}}</ng-option>
            </ng-select>
          </careweb-accordion-group>
        </mat-accordion>
      </div>
    </div>
  </div>
  <div class="grid-container" id="grid">
    <h2 class="title-border mt-3">Liste des types 0</h2>
    <div>
      <section>
        <div class="row grid-top-btn">
          <div class="col-md-4 filter">
            <button type="button" name="btn_toggle_filter" id="filter_organisme0" class="btn btn-primary small left-open" [hidden]="showFilter"
              (click)="toggleFilterDisplay()">
              <i class="fa fa-sliders"></i>
              Filtrer
            </button>
            <careweb-pluralize class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
              [count]="gridHelper.totalRowCount">
            </careweb-pluralize>
          </div>
          <div class="col-md-8 conf-col">
            <div class="careweb-toolbar">
              <div [tooltip]="gridHelper.isAvailableData() ? 'Exporter les résultats (.xlsx)' : 'Aucune ligne à exporter, veuillez affiner votre recherche'"
                   placement="top" container="body">
                <span class="btn btn-action small" [class.disabled]="!gridHelper.isAvailableData()"
                      name="btn_export"  id="Export_data" (click)="ExportDataOfTypeZero()">
                <i class="fa fa-file-excel-o"></i>
                </span>
              </div>
              <careweb-pagination-size id="dv_type0_paging" [pageSizes]="[10, 20, 30, 50, 100]"
                [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
              </careweb-pagination-size>
              <span class="btn btn-action small" id="reset_grid" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                name="spn_reset_grid" (click)="resetGridState()">
                <i class="fa fa-refresh"></i>
              </span>
            </div>
          </div>
        </div>
          <app-alert [export]="exportType0" [exportState]="exportState" id="close_alert" (close)="closeAlert()"></app-alert>
        <div class="row grid-top-filter">
          <div class="col-md-12">
            <careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter.id)">
              <span *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
            </careweb-filter-label>
          </div>
        </div>
        <div class="row highlight-row">
          <div class="col-md-12 relative">
            <ag-grid-angular style="width: 100%" class="ag-theme-balham ag-theme-type0"
              [gridOptions]="gridHelper.gridOptions">
            </ag-grid-angular>
            <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
