<div class="full-careweb-container">
    <div id="filter" class="filter-container" carewebFilterMinHeight>
        <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
            <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
                                              (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
            <div class="filter-content">
                <mat-accordion displayMode="flat" class="accordion-filter">

                     <!-- Date demarrage -->
                    <careweb-accordion-group id="dateDemarrage" title="Date démarrage" #dateAccordion>
                        <careweb-date-from-to-filter labelFrom="entre le" [(modelFrom)]="filters.dateDemarrageFrom"
                            [(modelTo)]="filters.dateDemarrageTo" [(modelExacte)]="filters.dateDemarrageExacte" 
                                        [maxDate]="maxDate" [isExactePlageChoice]="true" id="dateDemarrage"
                                        (filterChange)="this.onChoiceChange($event, 'dateDemarrage')"
                            (dateRangeValidityChange) = "checkDateRangeValidity($event)"  #dateDemarrage (onEnter) = "onKeyDown($event)">
                        </careweb-date-from-to-filter>
                    </careweb-accordion-group>
             
                     <!-- N° ps -->
                    <careweb-accordion-group id="ps" title="N° de PS">
                        <input type="text" id="input_ps" [(ngModel)]="filters.idPs"
                               (keydown.enter)="onKeyDown($event)"/>
                    </careweb-accordion-group>

                    <!-- Status -->
                    <careweb-accordion-group id="status" title="Statut">
                        <ng-select name="status" id="status" placeholder="Status"
                                [(ngModel)]="filters.status" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
                            <ng-option id="option_vide" [value]="''"> </ng-option>
                            <ng-option id="option_succes" [value]="'SUCCESS'">SUCCESS</ng-option>
                            <ng-option id="option_error" [value]="'ERRORS'">ERRORS</ng-option>
                        </ng-select>
                    </careweb-accordion-group>
            
                </mat-accordion> 
            </div>
        </div>

    </div>
    <div class="grid-container" id="grid">
        <div>
            <div class="row mt-3">
                <div class="col-lg-9">
                    <h2 class="title-border">Journal des exécutions de la procédure de rapprochement
                    </h2>
                </div>
            </div>
            <section>  
                <div class="row grid-top-btn">
                    <div class="col-md-4 filter">
                        <button type="button" id="filtre_log" name="btn_toggle_filter" class="btn btn-primary small left-open"
                                [hidden]="showFilter" (click)="toggleFilterDisplay()">
                            <i class="fa fa-sliders"></i>
                            Filtrer
                        </button>
                      <careweb-pluralize  class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
                      [count]="rowCount"></careweb-pluralize>
                    </div>
                    <div class="col-md-8 conf-col">
                        <div class="careweb-toolbar">
                            <div [tooltip]="logsRapproGridHelper.isAvailableData() ? 'Exporter les résultats (.xlsx)' : 'Aucune ligne à exporter, veuillez affiner votre recherche'"
                                 placement="top" container="body">
                                <span [hidden]="true">{{logsRapproGridHelper.isAvailableData()}}</span>
                                <span  class="btn btn-action small" [class.disabled]="this.rowCount == -1"
                                        name="btn_export_xlsx" id="exportFiles" (click)="exportFileXlsx()">
                            <i class="fa fa-file-excel-o"></i>
                          </span>
                            </div>
                         
                          <careweb-pagination-size id="dv_rule_paging" [pageSizes]="[10, 20,30, 50, 100]"
                          [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
                          </careweb-pagination-size>
                          <span class="btn btn-action small" id="rsetGrid" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                          name="spn_reset_grid" (click)="resetGridState()">
                           <i class="fa fa-refresh"></i>
                          </span>
                        </div>
                    </div>
                </div>
                <app-alert [export]="export" [exportState]="exportState" id="closeAlert" (close)="closeAlert()"></app-alert>
                <div class="row grid-top-filter">
                    <div class="col-md-12">
                        <careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter.id)">
                            <span *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
                        </careweb-filter-label>
                    </div>
                </div>
                <div class="row highlight-row">
                  <div class="col-md-12 relative">
                    <ag-grid-angular style="width: 100%" class="ag-theme-balham" [gridOptions]="logsRapproGridHelper.gridOptions">
                    </ag-grid-angular>
                    <careweb-pagination [gridApi]="logsRapproGridHelper.gridApi"></careweb-pagination>
                  </div>
                </div>
            </section>
        </div>
    </div>
  </div>


  <div *ngIf="showRappAutoDetail">
    <div class="modal modal-show show" id="modalRestoreBankAccount" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" style="padding-bottom:10px">
          <div class="inmodal">
            <div class="modal-header pb-1">
              <h3 class="modal-title">Virements bancaires rapprochés</h3>
              <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" title="Fermer"
                (click)="closeModalRappAutoDetail()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body pb-0">
              <div class="form-block">
                <div class="row">
                  <div class="col">
                    <div class="row modal-scroll">
                        <div class="col-md-12 text-title noPadding ">
                          <ag-grid-angular style="width: 100%;" class="ag-theme-balham"
                            [gridOptions]="gridHelper.gridOptions">
                          </ag-grid-angular>
                          <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="button-bar control">
                      <button type="button" class="btn btn-primary" style="margin-right:15px;margin-bottom:5px;"
                        (click)="closeModalRappAutoDetail()">
                        <span>Fermer</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
