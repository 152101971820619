<div class="full-careweb-container">
    <div id="filter" class="filter-container" carewebFilterMinHeight>
        <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
            <careweb-accordion-header-buttons (closeFilters)="toggleFilterDisplay()" (resetFilters)="resetAllFilter()"
                (launchSearch)="launchSearch()"></careweb-accordion-header-buttons>
            <div class="filter-content">
                <mat-accordion displayMode="flat" class="accordion-filter">
                    <!-- N° de PS -->
                    <careweb-accordion-group id="numeroAdeli" title="N° de PS">
                        <input type="text" id="input_numeroAdeli" [(ngModel)]="filters.numeroAdeli" onlyNum="9"
                            (keydown.enter)="onKeyDown($event)" placeholder="000000000"  />
                    </careweb-accordion-group>
                    <!-- Type Rapprochement -->
                    <careweb-accordion-group id="typeRappro" title="Type de connexion">
                        <ng-select name="input_typeRappro" id="input_typeRappro"
                            placeholder="Type de connexion" [searchable]="false"
                            [(ngModel)]="filters.typeRappro" appendTo=".filter-container"
                            (keydown.enter)="onKeyDown($event)">
                            <ng-option [value]="''"> </ng-option>
                            <ng-option [value]="'OXLIN'">OXLIN</ng-option>
                            <ng-option [value]="'EBICS'">EBICS</ng-option>
                        </ng-select>
                    </careweb-accordion-group>
                    <!-- Email utilisateur -->
                    <careweb-accordion-group id="email" title="Email OXLIN">
                        <input type="text" id="input_email" [(ngModel)]="filters.email" maxlength="100" carewebEmail
                            (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Comptes désynchronisés -->
                    <careweb-accordion-group id="compteDesyncho" title="Comptes désynchronisés">
                        <ng-select name="input_compteDesyncho" id="input_compteDesyncho"
                            placeholder="Comptes désynchronisés" [searchable]="false"
                            [(ngModel)]="filters.compteDesyncho" appendTo=".filter-container"
                            (keydown.enter)="onKeyDown($event)">
                            <ng-option id="option_" [value]="''"> </ng-option>
                            <ng-option id="option_oui" [value]="true">Oui</ng-option>
                            <ng-option id="option_non" [value]="false">Non</ng-option>
                        </ng-select>
                    </careweb-accordion-group>
                    <!-- Virement importés -->
                    <careweb-accordion-group id="vrt" title="Virement importés">
                        <ng-select name="input_vrt" id="input_vrt" placeholder="Virement importés" [searchable]="false"
                            [(ngModel)]="filters.vrt" appendTo=".filter-container" (keydown.enter)="onKeyDown($event)">
                            <ng-option id="option_" [value]="''"> </ng-option>
                            <ng-option id="option_1" [value]="true">Oui</ng-option>
                            <ng-option id="option_2" [value]="false">Non</ng-option>
                        </ng-select>
                    </careweb-accordion-group>
                    <!-- Rappro. auto déséquilibrés -->
                    <careweb-accordion-group id="rapproAnomalies" title="Rappro. auto déséquilibrés">
                        <ng-select name="input_rapproAnomalies" id="input_rapproAnomalies"
                            placeholder="Rappro. auto déséquilibrés" [searchable]="false"
                            [(ngModel)]="filters.rapproAnomalies" appendTo=".filter-container"
                            (keydown.enter)="onKeyDown($event)">
                            <ng-option id="option_" [value]="''"> </ng-option>
                            <ng-option id="option_1" [value]="true">Oui</ng-option>
                            <ng-option id="option_2" [value]="false">Non</ng-option>
                        </ng-select>
                    </careweb-accordion-group>
                </mat-accordion>
            </div>
        </div>
    </div>
    <div class="grid-container" id="grid">
        <div>
            <div class="row mt-3">
                <div class="col-lg-9">
                    <h2 class="title-border">Suivi du rapprochement
                    </h2>
                </div>
                <div class="col-lg-3">
                    <careweb-date-selecteur-periode [startDate]="startDate" [applyMindate]="false" [endDate]="endDate"
                        [ranges]="[7, 30, 60]" (dateFilterChange)="onDateFilterChange($event)">
                    </careweb-date-selecteur-periode>
                </div>
            </div>
            <section>
                <div class="row grid-top-btn">
                    <div class="col-md-4 filter">
                        <button type="button" name="btn_toggle_filter" id="filter" class="btn btn-primary small left-open"
                            [hidden]="showFilter" (click)="toggleFilterDisplay()">
                            <i class="fa fa-sliders"></i>
                            Filtrer
                        </button>
                        <careweb-pluralize class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'"
                            [count]="gridHelper.totalRowCount"></careweb-pluralize>
                    </div>
                    <div class="col-md-8 conf-col">
                        <div class="careweb-toolbar">
                            <button type="button" name="btn_toggle_filter" class="btn btn-primary small"
                                (click)="openModalReplayProcDetail('null')" id="replay_procedure">
                                Replay de la procédure
                            </button>
                            <careweb-pagination-size id="dv_editeurs_paging" [pageSizes]="[10, 20, 30, 50, 100]"
                                [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
                            </careweb-pagination-size>
                            <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top"
                                container="body" name="spn_reset_grid" (click)="resetGridState()">
                                <i class="fa fa-refresh"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row grid-top-filter">
                    <div class="col-md-12">
                        <careweb-filter-label *ngFor="let filter of filterList" id="close_filter" (closeFilter)="removeFilter(filter.id)">
                            <span *ngIf="filter">{{ filter.label}}: {{filter.value }}</span>
                        </careweb-filter-label>
                    </div>
                </div>
                <div class="row highlight-row">
                    <div class="col-md-12 relative">
                        <ag-grid-angular style="width: 100%;" class="ag-theme-balham"
                            [gridOptions]="gridHelper.gridOptions">
                        </ag-grid-angular>
                        <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

<div *ngIf="showReplayProcDetail">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalReplayProc" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content" style="padding-bottom:10px;margin-top: 200px;">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Lancer la Replay de la procédure</h3>
                        <button class="close" name="btn_display_filter" id="close_modal" data-dismiss="modal" type="button"
                            title="Fermer" (click)="closeModalReplayProcDetail()"><span
                                aria-hidden="true">×</span></button>
                    </div>
                    <div class="row mb-2 mt-4 w-100 pl-4">
                        <div class="col-12 px-5">
                            <careweb-date-selecteur-periode [startDate]="replayStartDate" id="date_range" [applyMindate]="true"
                                [endDate]="replayEndDate" [ranges]="[7, 30, 60]"
                                (dateFilterChange)="onDateSelectReplay($event)"></careweb-date-selecteur-periode>
                        </div>
                    </div>
                    <h3 class="w-75 m-auto mb-4"> {{returnMsg}} </h3>
                    <div class="modal-footer">
                        <button type="button" (click)="closeModalReplayProcDetail()" id="cancel"
                            class="btn btn-default">Annuler</button>
                        <button type="button" [disabled]="isBtnExecuterDisabled" id="modal_confirm" (click)="openModalReplayProcConfirm()"
                            class="btn btn-primary">Exécuter</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showModalLog">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalLog" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content" style="padding-bottom:10px;margin-top: 200px;">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Lancer la Replay de la procédure</h3>
                        <button class="close" name="btn_display_filter" data-dismiss="modal" type="button"
                                title="Fermer" id="close_modal" (click)="closeModalLog()"><span
                                aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body pb-0">
                        <div class="col-sm-12 col-form-label alert alert-info">
                            <div>
                                <i class="fa fa-info-circle"></i>
                                La procédure est en cours d'exécution et peut durer quelques minutes.
                                Pour consulter le journal des exécutions du rapprochement automatique, cliquez sur le bouton ci-dessous.
                            </div>
                            <div>
                                <button id="redirection-rapp" (click)="redirectToLogRapp()"
                                         class="btn btn-primary mt-4">Vérifier le journal des éxécutions</button>
                            </div>
                        </div>
                        <div class="button-bar control">
                            <button type="button" (click)="closeModalLog()"
                                 id="close_modal"   class="btn btn-default">Annuler</button>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showReplayProcConfirm">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalConfirmExecutionReplayProcConfirm" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content" style="padding-bottom:10px;margin-top: 200px;">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Etes-vous sûr de vouloir lancer l'exécution de la Replay de
                            la procédure ?</h3>
                        <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" id="close_modal"
                            title="Fermer" (click)="closeModalReplayProcConfirm()"><span
                                aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-footer">
                        <button type="button" (click)="closeModalReplayProcConfirm()" id="cancel"
                            class="btn btn-default">Annuler</button>
                        <button type="button" [disabled]="isBtnExecuterDisabled" (click)="onRelpayProc()"
                          id="replay_porc"  class="btn btn-primary">Exécuter</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showMissingTransactionDetail">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalMissingTransactions" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content" style="padding-bottom:10px;margin-top: 200px;">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Forcer la récupération  de transactions manquantes</h3>
                        <button class="close" name="btn_display_filter" data-dismiss="modal" type="button" id="close_modal"
                            title="Fermer" (click)="closeModalMissingTransactionDetail()"><span
                                aria-hidden="true">×</span></button>
                    </div>
                    <div class="row mb-2 mt-4 w-100 pl-4">
                        <div class="col-12 px-5" [ngClass]="{'disabled-section': withoutDate} ">
                            <careweb-date-selecteur-periode [startDate]="missingStartDate" id="date_range" [applyMindate]="true"
                                [endDate]="missingEndDate" [ranges]="[7, 30, 60]"
                                (dateFilterChange)="onDateSelectMissing($event)"></careweb-date-selecteur-periode>
                        </div>
                    </div>

                    <div class="row w-100 pl-4 mt-2" *ngIf="this.typeConnexion === TypeConnexionRapprochement.EBICS">
                        <div class="col-12 px-5">
                            <input type="checkbox" id="sans_date" [(ngModel)]="withoutDate"> Sans plage de date
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" (click)="closeModalMissingTransactionDetail()" id="cancel"
                            class="btn btn-default">Annuler</button>
                        <button type="button" [disabled]="isBtnExecuterDisabled" id="executTrans" (click)="onExecuteMissingTransactions()"
                            class="btn btn-primary">Exécuter</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
