import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { validateText } from "./validateText.directive";
import { User } from "../../data/userDataRaw";
import { AuthService } from "../../core/services/auth.service";
import { UsersService } from "../users/users.service";
import * as jwt_decode from 'jwt-decode';
import {BehaviorSubject, Subscription} from "rxjs";
import * as JSZip from 'jszip';
import { ExcelService } from "./excel.service";
import { ZipService } from "./zip.service";
import { PsService } from "../ps/ps.service";
import { ReportService } from "./report.service";
import { StorageService } from '../../shared/storage-service';
import {ExportState, Reporting, State} from '../../shared/model/storage.model';
import { Workbook } from 'exceljs';
import {PsDelegueDataRaw} from "../../data/PsDelegueDataRaw";
import {isNullOrUndefined} from "util";
import {PsDelegueService} from "../ps/service/ps-delegue.service";
import {ElementRecap} from "./ElementRecap";
import { saveAs } from 'file-saver';
import {OctService} from "../../core/services/oct.service";

const REPORT_SIMPLE_MODE = 'simp';
const REPORT_DETAILS_MODE = 'details';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit, OnDestroy {
  formcontrol: UntypedFormGroup;
  currentUser: User;
  subscription: Subscription;
  numPs: any;
  isDisabled: boolean;
  footerData: any[][] = [];
  columnsSimp: any[] = ['Numéro PS', 'Code organisme', 'Organisme', 'Nombre de facture', 'Année'];
  columnsDet: any[] = ['Numéro PS', 'Code organisme', 'Organisme', 'Nombre de facture', 'mois'];
  years: number[] = [];
  reportMode = true;
  reportingStorage:Reporting;
  isSupervisor: boolean = false;
  public supervisedPsList: PsDelegueDataRaw[];
  public selectedSupervisedPs: any = -1; // to Select All PS
  userConnected;
  export: boolean = false;
  exportState: ExportState;
  elementRecap: ElementRecap[];
  localStorageOctCurrented;
  psOctsCods;
  listOctBynumAdeli:[];
  constructor(private authSvc: AuthService,

              private psDelegueSVC: PsDelegueService,
    public userSvc: UsersService,
    private excelService: ExcelService,
    private zip: ZipService,
    private psService: PsService,
    private report: ReportService,
    private storageService: StorageService,private octSvc: OctService) {
  }

  ngOnInit(): void {
    this.initLast3Years();
    this.currentUser = this.authSvc.currentUserValue;
    this.userConnected = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('careweb_user')))
    this.initPsData();
    const decodedToken = jwt_decode(localStorage.getItem('careweb_token'));
    this.reportingStorage = this.storageService.getReportingStorage();
    if(this.reportingStorage) {
      this.reportMode = this.reportingStorage.mode;
    }
    this.formcontrol = new UntypedFormGroup({
      "textarea": new UntypedFormControl(this.reportingStorage ? this.reportingStorage.text : null),
      "years": new UntypedFormControl(this.reportingStorage ? this.reportingStorage.years : null),
      "supervisor": new UntypedFormControl(this.selectedSupervisedPs)
    });
    if (this.currentUser) {
      this.subscription = this.userSvc.getUserById(this.currentUser.idUser).subscribe((res) => {
        this.currentUser.username = res.username;
        if (decodedToken.userType == 2) {
          this.currentUser.etablissementRaisonSocial = 'Ps';
          if (!this.isSupervisor){
            this.numPs = this.psService.getPsById(this.currentUser.idPs).then((data) => this.numPs = data.numeroAdeli);
          }
        } else if (decodedToken.userType === 1) {
          this.currentUser.etablissementRaisonSocial = 'Admin';
        } else if (decodedToken.userType === 3) {
          this.currentUser.etablissementRaisonSocial = 'Hotline';
        } else if (decodedToken.userType === 4) {
          this.currentUser.etablissementRaisonSocial = 'Admin SEL';
        }
        if(this.currentUser.etablissementRaisonSocial == 'Ps') {
          this.formcontrol.get('years').setValidators([Validators.required]);
          this.formcontrol.get('years').updateValueAndValidity();
        } else {
          this.formcontrol.get('textarea').setValidators([Validators.required, validateText(decodedToken.userType)]);
          this.formcontrol.get('textarea').updateValueAndValidity();
        }
        if (this.isSupervisor) {
          this.formcontrol.get('supervisor').setValidators([Validators.required]);
          this.formcontrol.get('supervisor').updateValueAndValidity();
        }
      });
    }
  }

  initPsData(){
    if (!isNullOrUndefined(this.userConnected.value) && this.userConnected.value.idTypeUser == 2) {
      const idPs = this.userConnected.value.idPs;

      // Verify if connected user is a supervisor ps

      this.psDelegueSVC.getPsDelegueByIdPs(idPs).subscribe((data: Array<PsDelegueDataRaw>) => {
        if (data.length > 0 ) {
          this.isSupervisor = true;
          this.initListDelegues(data);
          this.numPs = this.supervisedPsList.map((ps: PsDelegueDataRaw) => ps.numeroPsDelegue);
        }
      });


    }
  }

  private initListDelegues(psDelegues: PsDelegueDataRaw[]): void {
    const psSuperviseur = new PsDelegueDataRaw();

    // Ajouter Ps de l'utilisateur
    psSuperviseur.idPsDelegue = psDelegues[0].idPsAssocie;
    psSuperviseur.numeroPsDelegue = psDelegues[0].numeroPsAssocie;
    psSuperviseur.nomPsDelegue = psDelegues[0].nomPsAssocie;

    this.supervisedPsList = psDelegues;
    this.supervisedPsList = [...psDelegues, psSuperviseur];
  }

  initLast3Years() {
    let currentYear: number = new Date().getFullYear();
    for (let i = 0; i < 3; i++) {
      this.years.push(currentYear - i);
    }
  }

  getPreviousYears(currentYear: number, count: number): number[] {
    const previousYears: number[] = [];
    for (let i = 1; i <= count; i++) {
      previousYears.push(currentYear - i);
    }
    return previousYears;
  }

  getNameFile() : any{
    let nameFile;
    if (this.isSupervisor){
      nameFile = this.selectedSupervisedPs === -1 ?
          "TousLesPs" :
          this.selectedSupervisedPs;
    } else {
      nameFile = this.numPs;
    }
    return nameFile;
  }
  getOctName(numAdeli: string): Promise<string | null> {

    return new Promise((resolve, reject) => {
      this.octSvc.getAllOctByNumAdeli(numAdeli, '').subscribe(data => {
        this.listOctBynumAdeli = data;
        let result: string | null = null;

        for (let index = 1; index < data.length; index++) {
          const value = data[index]?.[2];
          if (value?.includes("QTP")) {
            result = "QTP";
            break;
          } else if (value?.includes("CDS")) {
            result = "CDS";
            break;
          }
        }
        resolve(result);

      }, error => {
        reject(error);
      });
    });
  }

  closeAlert() {
    this.export = false;
  }

  onsubmit(): void {
    if (this.formcontrol.valid) {
      this.saveReportingStorage();
      this.isDisabled = false;
      this.export = true;
      let tab;// = this.formcontrol.get("textarea").value.split("\n");
      const mode = this.reportMode ? REPORT_SIMPLE_MODE : REPORT_DETAILS_MODE;// this.formcontrol.get("model").value;
      let dates = [];
      let num;
      let nameFile;
      if (this.currentUser.etablissementRaisonSocial === 'Ps') {
        nameFile = this.getNameFile();
        num = this.numPs;
        dates = this.formcontrol.get("years").value;//tab[0].split("/");
        tab = [dates.map(String).join('/')];
      } else {
        tab = this.formcontrol?.get("textarea").value?.split("\n");
        num = tab[0].split("#")[0];
        dates = tab[0].split("#")[1].split("/");
      }
      if (this.isSupervisor){
        num = this.numPs;
      }
      // Generation d'un fichier excel
      if ((dates.length == 1 && tab.length == 1) || (this.same_value(dates) && tab.length == 1) || (this.same_value(tab) && dates.length == 1)) {
        if (mode === REPORT_SIMPLE_MODE) {
          this.exportState = {
            message : "Votre récapitulatif annuel est en cours de téléchargement.",
            state: State.INPROGRESS
          }
          this.report.getRecapSimp(num, dates[0]).subscribe(data => {
            this.isDisabled = true;
                this.exportState = {
                  message : "Votre récapitulatif annuel a été téléchargé avec succès.",
                  state: State.SUCCESS
                }
            this.excelService.exportAsExcelFile('', this.getcolumnsSimp(), this.convertArrayToJson(data), this.footerData, 'Récap_' + nameFile + '_' + dates[0], 'Récap_' + nameFile + '_' + dates[0]);
            this.isDisabled = false;
          },
            error => {
              this.exportState = {
                message : "Le téléchargement de votre récapitulatif annuel a rencontré un problème.",
                state: State.FAILED
              }
              return;
            });
        } else {
          this.exportState = {
            message : "Votre récapitulatif annuel est en cours de téléchargement.",
            state: State.INPROGRESS
          }
          this.report.getRecapDetailled(num, dates[0]).subscribe(data => {
            this.isDisabled = true;
                this.exportState = {
                  message : "Votre récapitulatif annuel a été téléchargé avec succès.",
                  state: State.SUCCESS
                }
            this.excelService.exportAsExcelFile('', this.getColumnsDet(), this.convertArrayToJson(data), this.footerData, 'Récap_' + nameFile + '_' + dates[0], 'Récap_' + nameFile + '_' + dates[0]);
            this.isDisabled = false;
          },
            error => {
              this.exportState = {
                message : "Le téléchargement de votre récapitulatif annuel a rencontré un problème.",
                state: State.FAILED
              }
              return;
            });
        }
      }
      // generation du dossier compressé (ensemble de rapports)
      else {
        this.isDisabled = true;
        const promises = [];
        this.exportState = {
          message : "Votre récapitulatif annuel est en cours de téléchargement.",
          state: State.INPROGRESS
        }
        const workbook = new Workbook();
        for (let j = 0; j < tab.length; j++) {
          if (this.currentUser.etablissementRaisonSocial === 'Ps') {
            tab[j].split("/").forEach(date => {
              if (mode === REPORT_SIMPLE_MODE) {

                promises.push(new Promise((resolve) => {
                  this.report.getRecapSimp(this.numPs, date).subscribe(data => {
                    const worksheet = workbook.addWorksheet('Récap_' + this.numPs + '_' + date);
                    resolve(this.zip.generateExcel(worksheet, this.convertArrayToJson(data), this.getcolumnsSimp()));
                  },
                    error => {
                      this.exportState = {
                        message : "Le téléchargement de votre récapitulatif annuel a rencontré un problème.",
                        state: State.FAILED
                      }
                      return;
                    });
                }));
              } else {
                promises.push(new Promise((resolve) => {
                  this.report.getRecapDetailled(this.numPs, date).subscribe(data => {
                    const worksheet = workbook.addWorksheet('Récap_' + this.numPs + '_' + date);
                    resolve(this.zip.generateExcel(worksheet, this.convertArrayToJson(data), this.getColumnsDet()));
                  },
                    error => {
                      this.exportState = {
                        message : "Le téléchargement de votre récapitulatif annuel a rencontré un problème.",
                        state: State.FAILED
                      }
                      return;
                    })
                }));
              }
            });
          } else {
            tab[j].split("#")[1].split("/").forEach(date => {
              if (mode === REPORT_SIMPLE_MODE) {
                promises.push(new Promise((resolve) => {
                  this.report.getRecapSimp(tab[j].split("#")[0], date).subscribe(data => {
                    resolve(this.zip.generateExcelFile(this.convertArrayToJson(data), 'Récap_' + tab[j].split("#")[0] + '_' + date, this.getcolumnsSimp()));
                  },
                    error => {
                      this.exportState = {
                        message : "Le téléchargement de votre récapitulatif annuel a rencontré un problème.",
                        state: State.FAILED
                      }
                      return;
                    });
                }));


              } else {
                promises.push(new Promise((resolve) => {
                  this.report.getRecapDetailled(tab[j].split("#")[0], date).subscribe(data => {
                    resolve(this.zip.generateExcelFile(this.convertArrayToJson(data), 'Récap_' + tab[j].split("#")[0] + '_' + date, this.getColumnsDet()));
                  },
                    error => {
                      this.exportState = {
                        message : "Le téléchargement de votre récapitulatif annuel a rencontré un problème.",
                        state: State.FAILED
                      }
                      return;
                    });
                }));
              }
            });
          }
        }
        if (this.currentUser.etablissementRaisonSocial === 'Ps') {
          Promise.all(promises).then(() => {
            const bufferPromise = workbook.xlsx.writeBuffer();
            bufferPromise.then(buffer => {
              const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = 'Récap_' + nameFile + '_' + dates.join('_');
              link.click();
              this.exportState = {
                message : "Votre récapitulatif annuel a été téléchargé avec succès.",
                state: State.SUCCESS
              }
            });
          });
        } else {
          Promise.all(promises).then(files => {
            this.downloadZipFile(files);
            this.exportState = {
              message : "Votre récapitulatif annuel a été téléchargé avec succès.",
              state: State.SUCCESS
            }
          });
        }
        this.isDisabled = false;
      }
    }
  }
  submit(){
    if (this.formcontrol.valid){
      let criteria = new Map();
      this.elementRecap = [];
      const mode = this.reportMode ? REPORT_SIMPLE_MODE : REPORT_DETAILS_MODE;
      this.exportState = {
        message : "Votre récapitulatif annuel est en cours de téléchargement.",
        state: State.INPROGRESS
      }
      this.export = true;
      let octName: string | null = null;
      if (this.currentUser.etablissementRaisonSocial === 'Ps') {
        let dates = this.formcontrol.get("years").value;//tab[0].split("/");
        let tab = dates.map(String);
        if (Array.isArray(this.numPs)){
          this.numPs.forEach(num => criteria.set(num, tab));
        } else {
          criteria.set(this.numPs, tab);
        }
      } else {
        const lines = this.formcontrol?.get("textarea").value?.trim().split("\n");
        lines.forEach(line => {
          const parts = line.split("#");
          const key = parts[0];
          const values = parts[1].split('/');
          if (criteria.has(key)){
            const existingValues = criteria.get(key) || new Set();
            criteria.set(key, [...existingValues]);
          }else {
            const newValuesSet = new Set<string>(values);
            criteria.set(key, [...newValuesSet]);
          }
        });
      }
      criteria.forEach((value, key) => {
        this.elementRecap.push(new ElementRecap(key, value));
      });
      var element = this.elementRecap[0];
      var numAdeli = element.numeroAdeli;
      var date = element.dates;
      this.getOctName(numAdeli).then(nameOct => {
        octName = nameOct;
        if (this.elementRecap.length == 1 && date.length == 1){
          if (mode === REPORT_SIMPLE_MODE) {
            this.report.getRecapSimpReport(numAdeli, date[0], octName).subscribe((response) => {
              this.exportState = {
                message: "Votre récapitulatif annuel a été téléchargé avec succès.",
                state: State.SUCCESS
              }
              saveAs(response.file, response.fileName);
            }, () => {
              this.exportState = {
                message: "Le téléchargement de votre récapitulatif annuel a rencontré un problème.",
                state: State.FAILED
              }
            });
          } else {
            this.report.getRecapDetReport(numAdeli, date[0], octName).subscribe((response) => {
              this.exportState = {
                message: "Votre récapitulatif annuel a été téléchargé avec succès.",
                state: State.SUCCESS
              }
              saveAs(response.file, response.fileName);
            }, () => {
              this.exportState = {
                message: "Le téléchargement de votre récapitulatif annuel a rencontré un problème.",
                state: State.FAILED
              }
            });
          }
        } else {
          if (mode === REPORT_SIMPLE_MODE) {
            this.report.getRecapSimpAllReport(this.elementRecap, octName).subscribe((response) => {
              this.exportState = {
                message : "Votre récapitulatif annuel a été téléchargé avec succès.",
                state: State.SUCCESS
              }
              saveAs(response.file, response.fileName);
            },()=> {
              this.exportState = {
                message : "Le téléchargement de votre récapitulatif annuel a rencontré un problème.",
                state: State.FAILED
              }
            });
          } else {
            this.report.getRecapDetAllReport(this.elementRecap, octName).subscribe((response) => {
              this.exportState = {
                message : "Votre récapitulatif annuel a été téléchargé avec succès.",
                state: State.SUCCESS
              }
              saveAs(response.file, response.fileName);
            },()=> {
              this.exportState = {
                message : "Le téléchargement de votre récapitulatif annuel a rencontré un problème.",
                state: State.FAILED
              }
            });
          }
        }
      })
    }
  }
  getCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear(); toString();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    const currentDate = year + month + day;
    return currentDate;
  }

  onSupervisedPsSelected(): void {
    this.selectedSupervisedPs = this.formcontrol.get("supervisor").value;
    this.numPs = this.selectedSupervisedPs === -1 ?
        this.supervisedPsList.map((ps: PsDelegueDataRaw) => ps.numeroPsDelegue) :
        [this.selectedSupervisedPs];
  }
  downloadZipFile(files: { file: Blob, fileName: string }[]) {
    const zip = new JSZip();
    files.forEach(file => {
      zip.file(file.fileName, file.file);
    });
    zip.generateAsync({ type: 'blob' }).then((content) => {
      const element = document.createElement('a');
      element.href = URL.createObjectURL(content);
      element.download = 'Récap_Annuel_RC_' + this.getCurrentDate();
      element.click();
    });
  }

  convertArrayToJson(Arr: any[]): any {
    let Json = [];
    if (Arr !== undefined) {
      let len = Arr.length;
      let i;
      for (i = 0; i < len; i++) {
        let obj = {};
        if(this.currentUser.etablissementRaisonSocial !== 'Ps' || this.isSupervisor) {
          obj[`NumPs`] = Arr[i][0];

        }
        // obj[`IdOrganismeRc`] = Arr[i][1];
        obj[`CodeRcOfficiel`] = Arr[i][2];
        obj[`LibelleLong`] = Arr[i][3];
        obj[`CountFacture`] = Arr[i][4];
        obj[`Annee`] = Arr[i][5];
        Json.push(obj);
      }
    }
    return Json;
  }

  same_value(Arr: any[]) {
    return Arr.every((elt) => elt === Arr[0]);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  saveReportingStorage() {
    if(this.reportingStorage) {
      this.reportingStorage.text = this.formcontrol.get("textarea").value;
      this.reportingStorage.years = this.formcontrol.get("years").value;
      this.reportingStorage.mode = this.reportMode;
    } else {
      this.reportingStorage = {
        text: this.formcontrol.get("textarea").value,
        years: this.formcontrol.get("years").value,
        mode: this.reportMode
      }
    }
    this.storageService.saveReportingStorage(this.reportingStorage);
  }

  getcolumnsSimp() {
    if(this.currentUser.etablissementRaisonSocial == 'Ps' && !this.isSupervisor) {
      return this.columnsSimp.filter(c => c != 'Numéro PS' /*&& c!= 'Code organisme'*/);
    }
    return this.columnsSimp;
  }
  getColumnsDet() {
    if(this.currentUser.etablissementRaisonSocial == 'Ps' && !this.isSupervisor) {
      return this.columnsDet.filter(c => c != 'Numéro PS' /*&& c!= 'Code organisme'*/);
    }
    return this.columnsDet;
  }
}
