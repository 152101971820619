<div class="modal modal-show fade show" id="modalPslogiciel" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="inmodal">
                <div class="modal-header pb-1">
                    <h3 class="modal-title">{{title}}</h3>
                    <button class="close" name="btn_display_filter" data-dismiss="modal" id="close_modal" type="button" title="Fermer"
                        (click)="closeModal()"><span id="x" aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body pb-0">
                  <div class="alert alert-info mb-4">
                    <p><strong>Notes:</strong></p> 
                    <div *ngIf="isSolderForm">
                      <p>Les RSP à solder sont les RSP qui ne peuvent pas être rapprochés automatiquement pour plusieurs raisons tels que:</p>
                      <li>Paiement effectué par chèque ou sur un autre compte bancaire non référencé.</li>
                      <li>Retard du retour de l'organisme dont le virement a été effectué il y a plus de 90 jours donc plus visible dans la liste des VRT.</li>
                      <li>RSP en doublon.</li>
                      <li>Existence d'un relevé papier ce qui explique l'absence du virement correspondant au RSP dans la liste.</li>
                    </div>
                    <div *ngIf="!isSolderForm">
                      <p>Les VRT à pointer sont les VRT qui ne peuvent pas être rapprochés automatiquement pour plusieurs raisons tels que:</p>
                      <li>L’organisme ne fait pas ou ne peut pas faire des retours RSP</li>
                      <li>Le virement ne concerne pas la télétransmission</li>
                      <li>Le virement a été effectué après la purge de la liste des RSP (après 90 jours)</li>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <form [formGroup]="pointerSolderForm" class="col-12">
                      <div class="row">
                        <div class="col-4 col-form-label">Veuillez sélectionner un motif: </div>
                        <div class="col-8 form-group">
                            <ng-select formControlName="motif" id="motif" bindLabel="name" [clearable]="false" [searchable]="false" (change) = onMotifChange($event)>
                                <ng-option *ngFor = "let motif of motifs" [value]="motif">
                                  <span id="{{motif}}"> {{motif}}</span>
                                </ng-option>
                            </ng-select>
                        </div>
                      </div >

                      <div class="row" *ngIf="isOtherMotifSeleted">
                        <div class="col-4 col-form-label other-motif-label">Libellé: </div>
                        <div class="col-8 form-group">
                          <input type="text" class="form-control" id="autreMotif" formControlName="otherMotif" [required]="isOtherMotifSeleted"
                          [ngClass]="{ 'is-invalid': isPointerSolderFormSubmitted && pointerSolderFormFields.otherMotif.errors }"/>
                          <div *ngIf="isPointerSolderFormSubmitted && pointerSolderFormFields.otherMotif.errors" class="invalid-feedback">
                            <div *ngIf="pointerSolderFormFields.otherMotif.errors?.required">
                              Ce champ est obligatoire
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row" *ngIf="pointerSolderFormFields.motif.value == 'Toujours pointer'">
                        <div class="col-4 col-form-label other-motif-label">Mot clé: </div>
                        <div class="col-8 form-group">
                          <input type="text" class="form-control" id="motCle" (input)="setPointerKeyword($event)" formControlName="pointerKeyword" [required]="pointerSolderFormFields.motif.value == 'Toujours pointer'"
                          [ngClass]="{ 'is-invalid': isPointerSolderFormSubmitted && pointerSolderFormFields.pointerKeyword.errors }"/>
                          <div *ngIf="isPointerSolderFormSubmitted && pointerSolderFormFields.pointerKeyword.errors" class="invalid-feedback">
                            <div *ngIf="pointerSolderFormFields.pointerKeyword.errors?.required">
                              Ce champ est obligatoire
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row text-danger" *ngIf="erreur">
                        <div class="col-11">
                          {{erreur}}
                        </div>
                      </div>
                      <div class = "row">
                        <div class="col-11 col-form-label">
                          <span *ngIf="isSolderForm"> <i class=" fa fa-star" aria-hidden="true"></i> Le motif ‘Toujours solder’ permet de solder automatiquement tous les RSP provenant d’un même organisme.</span>
                          <span *ngIf="!isSolderForm"> <i class=" fa fa-star" aria-hidden="true"></i> Le motif ‘Toujours pointer’ permet de pointer automatiquement tous les VRT contenant le mot clé (chaine de caractères) que vous aurez indiqué.</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div class="modal-footer">
                  <button id="cancel" type="button" (click)="closeModal()" class="btn btn-default">Annuler</button>
                  <button id="valider" type="button" (click)="onSubmit()" class="btn btn-primary" >Valider</button>
                </div>

            </div>
        </div>
    </div>

</div>