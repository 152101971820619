<careweb-help></careweb-help>
<div class="full-careweb-container" *ngIf="isPageLoaded">
    <div class="row grid-container">
        <!-- COL KPI --->
        <div class="col-lg-12">
            <!--  FILTRES  -->
            <div *ngIf="valideConnections().length > 0  " class="row">
                <h2 class="col-md-4">
                    Liste des comptes bancaires synchronisés
                    <careweb-open-help code="help-012"></careweb-open-help>
                </h2>

                <div class="col-md-8" style="text-align:right">
                    <span class="btn btn-action small" tooltip="Rafraichir les comptes" placement="top" container="body" name="spn_reset_grid"
                    (click)="refreshAccounts()">
                    <i class="fa fa-refresh"></i>
                  </span>&nbsp;&nbsp;&nbsp;
                    <button class="btn btn-default btn-lg" [disabled]="!isPageLoaded || oxlinUserConnections.length === 0 "
                            (click)='openModalRestoreAccounts()' id="restaurés_comptes" type="button">Restaurer les
                        comptes supprimés</button>&nbsp;&nbsp;&nbsp;
                    <button class="btn btn-primary btn-lg" [disabled]="!isPageLoaded || oxlinUserConnections.length === 0 "
                            (click)='addNewAccount()' id="add_new_bank" type="button">Ajouter une nouvelle banque
                    </button>&nbsp;&nbsp;&nbsp;
                    <button *ngIf="showOxlinLinkTest" class="btn btn-primary copy-link-btn btn-lg" [disabled]="!isPageLoaded || oxlinUserConnections.length === 0 "
                        (click)='copyOxlinLink()' type="button" tooltip="Copier l'URL pour importer les transactions de la banque de test Linxo." 
                        placement="bottom">Copier l'URL des transactions de test <i class="fa fa-clone"></i>
                    </button>
                </div>
            </div>

            <div class="alert alert-info" *ngIf="refreshDone">
                <i (click)="refreshDone = false" class="fa fa-close iconClose"></i>
                <h3>Votre demande de mise à jour a bien été prise en compte</h3>
                <p>Le résultat sera disponible d'ici quelques instants : rafraichissez votre page pour retrouver vos comptes ci-dessous.
                    <br>Si le problème persiste, contactez le support via le bouton "Contacter le support".</p>
            </div>

            <app-rapp-error-message *ngFor="let conn of oxlinUserConnections" [connection]="conn" 
            (contactEvent)="contactSupport()" 
            (authEvent)="renewAuthOxlinUserAccount(conn)"
            (possibleMigrationEvent) = "migrateToRedirectChannel($event)"
            (refreshEvent)="refreshEvent()"
            ></app-rapp-error-message>

            
            <!--- INDICATEURS HAUT --->
            <div class="row" style="margin-top:10px">
                    <div class="col" *ngIf="valideConnections().length === 0">
                        <div class="box-bank">
                            <div class="box-bank-content">
                                <div class="row" style="margin-top:10px">
                                    <div class="col">
                                        <h3 class="text-primary">Bienvenue sur votre espace de rapprochement bancaire</h3>
                                    </div>
                                </div>
                                <div class="row" style="margin-top:20px">
                                    <div class="col">Pour lier un compte bancaire à votre espace, munissez-vous de votre
                                        identifiant bancaire et votre mot de passe.</div>
                                </div>
                                <div class="row">
                                    <div class="col">Cliquez ensuite sur le bouton ci-dessous pour ajouter votre compte
                                        bancaire.</div>
                                </div>
                                <div class="row" style="margin-top:30px">
                                    <div class="col">
                                        <button class="btn btn-primary btn-lg" id="ajouterPremierCb"  (click)='addNewAccount()'
                                                             [disabled]="!isPageLoaded"
                                                             type="button">Ajouter mon premier compte bancaire</button>
                                                             &nbsp;&nbsp;&nbsp;
                                        <button *ngIf="showOxlinLinkTest" class="btn btn-primary copy-link-btn btn-lg" [disabled]="!isPageLoaded"
                                                           id="copy_url_traansaction2"  (click)='copyOxlinLink()' type="button" tooltip="Copier l'URL pour importer les transactions de la banque de test Linxo." 
                                                           placement="bottom">Copier l'URL des transactions de test <i class="fa fa-clone"></i>
                                        </button>
                                    </div>



                                </div>
                            </div>
                        </div>

                    </div>

                <div *ngFor="let accordedConnection of oxlinUserConnAcc" class="col-md-6">
                    <div class="box-bank">
                        <div class="box-bank-content">
                            <div class="row title">
                                <div class="col-md-1">
                                    <img src="{{accordedConnection[1].logo_url}}" id="image" class="imgbank" />
                                </div>
                                <div class="col">
                                    <div>
                                        <div class="title text-primary">{{accordedConnection[1].name}}</div>
                                        <div class="subtitle">{{accordedConnection[0].name}}</div>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div class="btnSynchro">
                                        <button class="btn btn-primary" id="buttonDesynchro" (click)='openConfirmationModal(accordedConnection[0])'
                                            data-toggle="tooltip" title="Désynchroniser"><i
                                                class="fa fa-chain-broken"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="row infos">
                                <div class="col">
                                    <div class="row">
                                        <div class="col-md-3">Référence</div>
                                        <div class="col-md-9">{{accordedConnection[0].account_number}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">IBAN</div>
                                        <div class="col-md-9">{{accordedConnection[0].iban}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">Ajouté le</div>
                                        <div class="col-md-9">
                                            {{accordedConnection[0].creation_date * 1000 | date: 'dd-MM-yyyy HH:mm:ss'}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row infos" style="margin-top:15px !important">
                                <div class="col"
                                    style="padding-top:5px; margin-right:10px; border-top:solid 1px #e9e9e9;">
                                    <div class="row">
                                        <div class="col-md-6">Dernière synchro effectuée</div>
                                        <div class="col-md-6">
                                            {{accordedConnection[1].channels[0].last_end_date * 1000 | date: 'dd-MM-yyyy HH:mm:ss'}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">Dernière synchro avec succès</div>
                                        <div class="col-md-6">
                                            {{accordedConnection[1].channels[0].last_success_date * 1000 | date: 'dd-MM-yyyy HH:mm:ss'}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">Connexion accordée jusqu'au</div>
                                        <div class="col-md-6">
                                            {{accordedConnection[1].channels[0].expires ? (accordedConnection[1].channels[0].expires * 1000 | date: 'dd-MM-yyyy HH:mm:ss') : 'Pas de fin'}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">Statut</div>
                                        <div class="col-md-6" [ngClass]="{
                                                'text-success': accordedConnection[1].status === 'SUCCESS',
                                                'text-danger': accordedConnection[1].status !== 'SUCCESS'}">{{accordedConnection[1].status}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">Message</div>
                                        <div class="col-md-6" [ngClass]="{
                                            'text-success': accordedConnection[1].channels[0].status === 'SUCCESS',
                                            'text-danger': accordedConnection[1].channels[0].status !== 'SUCCESS'}">{{accordedConnection[1].channels[0].status}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="full-careweb-container" *ngIf="showSupportButton">
    <div class="row grid-container">
        <div class="row" style="margin-top:20px">
            <div class="col-md-12">
                <div class="box-bank">
                    <div class="box-bank-error-content">
                        <div class="row">
                            <div class="col-md-12 box-bank-error-title">
                                <div>Les comptes liés à votre connexion ne peuvent être lus pour l'instant</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div [innerHTML]="'Nous ne parvenons pas à afficher vos comptes bancaires pour le moment. Cela pourrait être lié à une
                                désactivation de vos comptes par la banque. Si vous avez besoin d\'assistance, n\'hésitez pas à
                                contacter notre support'"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 box-bank-error-footer">
                                <div>
                                    <button class="btn btn-warning" id="contact_support" (click)="contactSupport()">
                                        <i class="fa fa-exclamation-triangle"></i> Contacter le support
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Account add -->
<div *ngIf="showAddBankAccountModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalAddBankAccount" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content" style="padding-bottom:10px">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <button class="close" id="close_modal" (click)="closeModal()" name="btn_display_filter" type="button"
                            title="Fermer"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body pb-0">
                        <iframe *ngIf="oxlinUserWidgetSafeUrl" height="700px" width="100%" frameborder="0" [src]="oxlinUserWidgetSafeUrl" sandbox="allow-scripts allow-same-origin allow-forms allow-top-navigation allow-popups"></iframe>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>
</div>

<div *ngIf="showRestoreBankAccountModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalRestoreBankAccount" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content" style="padding-bottom:10px">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Restaurer les comptes supprimés</h3>
                        <button class="close" name="btn_display_filter" data-dismiss="modal" type="button"
                            title="Fermer" id="close_modalRestaure" (click)="closeModalRestoreAccounts()"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body pb-0">
                        <div class="form-block">
                            <div class="row">
                                <div class="col">
                                    <h3 class="title-border">
                                        <div class="row">
                                            <div class="col-auto me-auto">Selectionnez une banque</div>
                                        </div>
                                    </h3>
                                </div>
                            </div>
                            <fieldset class="container-fluid">
                                <div *ngIf="displayRestoreAccountSuccess" class="col text-success">
                                    <p>Votre demande est en cours de traitement.<br>Les comptes restaurés seront disponibles rapidement.</p>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <select class="form-control form-control form-control-input" placeholder="Selectionnez une banque" [(ngModel)]="idConnToRestore">
                                            <option *ngFor="let conn of oxlinUserConnections" [ngValue]="conn.id">
                                                {{conn.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row" style="margin-top:20px;margin-bottom:10px">
                                    <div class="col" style="text-align:end">
                                        <button *ngIf="displayRestoreAccountSuccess" id="close_modalRappRes" class="btn btn-primary btn-lg" (click)='closeModalRestoreAccounts()' type="button">Fermer</button>
                                        <button *ngIf="!displayRestoreAccountSuccess" type="button" id="close_modalRappRes" (click)="closeModalRestoreAccounts()" class="btn btn-default ">Annuler</button>&nbsp;&nbsp;&nbsp;
                                        <button *ngIf="!displayRestoreAccountSuccess" class="btn btn-primary btn-lg" id="close_modalRappRes" (click)='restoreOxlinUserAccount(idConnToRestore)' type="button">Restaurer</button>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
        </div>
    </div>
</div>


<!-- Modal confirmation de suppression du compte -->
<careweb-confirm-modal (confirmModal)="onConfirmModal()" (showModal)="showModal($event)" [(visible)]="showConfirmModal"
    [text]="modalMessage" [title] = "modalTitle" >
</careweb-confirm-modal>