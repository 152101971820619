import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { InputValidators } from '../../components/validators/InputValidators';
import { AuthService } from '../../core/services/auth.service';
import { User } from '../../data/userDataRaw';
import { UsersService } from '../users/users.service';
import { PsService } from '../ps/ps.service';
import { OctService } from '../../core/services/oct.service';

@Component({
	selector: 'careweb-app-contact-support',
	templateUrl: './contact-support.component.html',
	styleUrls: [ './contact-support.component.scss' ]
})
export class ContactSupportComponent implements OnInit {
	contactForm: UntypedFormGroup;
	currentUser: User;
	submitted = false;
	emailSent = false;
	octCurrent;
	numeroAdeli;
	octName: string;

	constructor(
		private authSvc: AuthService,
		private userService: UsersService,
		private fb: UntypedFormBuilder,
		private inputValidators: InputValidators,
		private toastr: ToastrService,
		private psSvc: PsService,
		private octSvc: OctService
	) {
		this.currentUser = new User();
	}

	ngOnInit() {
		this.initForm();
		// get currentUser connected
		this.currentUser = this.authSvc.currentUserValue;
		if (!isNullOrUndefined(this.currentUser)) {
			// if connected user is PS
			this.getUserById(this.currentUser.idUser);
			if (!isNullOrUndefined(this.currentUser.idPs)) {
				this.psSvc.getPsById(this.currentUser.idPs).then((res) => {
					this.numeroAdeli = res.numeroAdeli
				})
			}
		}
		this.octCurrent = localStorage.getItem('octCurrent');
		// get selected OCT
		if (isNullOrUndefined(this.octCurrent)) {
			this.octSvc.currentOctSubject.subscribe((oct) => {
				if (!isNullOrUndefined(oct)) {
					this.octName = oct.nom;
				} else {
					let attachedOct = JSON.parse(localStorage.getItem('psOctsCods'));
					this.octName = attachedOct[0];
				}
			});
		} else {
			let obj = JSON.parse(this.octCurrent);
			this.octName = obj.nom
		}
	}

	initForm() {
		this.contactForm = this.fb.group({
			motif: new UntypedFormControl('', [Validators.required, this.inputValidators.inputWithSpaces]),
			description: new UntypedFormControl('', [Validators.required, this.inputValidators.inputWithSpaces])
		});
	}

	getUserById(idUser) {
		return this.userService.getUserById(idUser).subscribe((res) => {
			this.currentUser = res;
		});
	}

	onSubmit() {
		if(this.currentUser.idTypeUser == 1 || this.currentUser.idTypeUser == 3){
			this.numeroAdeli = localStorage.getItem('numeroPs');
		}
		this.submitted = true;
		if (this.contactForm.invalid) {
			return;
		}
		// send email
		this.userService
			.contactSupport(
				this.currentUser.email,
				this.contactForm.controls.motif.value,
				this.contactForm.controls.description.value,
				this.numeroAdeli,
				this.octName
			)
			.subscribe(
				(res) => {
					this.emailSent = true;
					
				},
				(err) => {
					this.emailSent = false;
					this.toastr.error(err);
					
				}
			);
	}

	cancel() {
		this.submitted = false;
		this.contactForm.controls.motif.setValue(null);
		this.contactForm.controls.description.setValue(null);
	}

	get formFields() {
		return this.contactForm.controls;
	}
}
