import { Component, HostListener, Renderer2 } from '@angular/core';
import { Router } from "@angular/router";
import {VisibilityService} from "./pages/ps/service/VisibilityService";
@Component({
	selector: 'careweb-app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})

export class AppComponent {
	title = 'client';
	readonly XHR_WITHOUT_SPINNER = [
		'/v1/flux/fse/fseRcImpayeeAdmin',
		'/v1/flux/fse/fseRoImpayeeAdmin',
		'/v1/flux/lots/count/statByDay',
		'/v1/ps/oct/count/actifStatus',
		'/v1/flux/lots/count/withoutarl',
		'/v1/flux/fse/getFseGraphDataSet'
	];

	constructor(private renderer: Renderer2, private router: Router, private visibilityService: VisibilityService) {
	}

	ngOnInit() {
		const host = window.location.host;
		const headNode = document.querySelector('head');
		const script = this.renderer.createElement('script');
		script.src = `https://tarteaucitron.io/load.js?domain=${host}&uuid=efb74bb5341e1ddb85e7bb7979c9ca9ff895cb01`;
		script.type = 'text/javascript';
		this.renderer.appendChild(headNode, script);
		sessionStorage['nbr'] = 0;

		this.visibilityService.visibilityChange$.subscribe(isVisible => {
			if (isVisible) {
				this.visibilityService.sendTabCheck();
				let refrech =localStorage.getItem('shouldRefresh');
				if(refrech==='true'){
					if(!this.router.url.includes('/hotline')){
						this.router.navigate(['/ps']);
					}
					localStorage.setItem('shouldRefresh',false.toString());
				}
			}
		});
	}

	@HostListener('window:beforeunload')
	onSaveRoute() {
		const lastRoute = this.router.url;
		if (lastRoute != "/login"
			&& lastRoute != ""
			&& lastRoute != "/"
			&& lastRoute != "/404"
			&& lastRoute != null
			&& lastRoute != '/unknown-page'
			&& lastRoute != '/hotline'
			&& lastRoute != '/hotline-parameters'
			&& lastRoute != '/hotline-dashboard'
			&& lastRoute != '/sent-reinit-password'
			&& lastRoute != '/update-password'
			&& lastRoute != '/rapp-error-message'
			&& lastRoute.indexOf("/hotline-edit") !== 0
			&& lastRoute.indexOf("/forget-password") !== 0){
			sessionStorage.setItem('lastRoute', lastRoute);
		}
	}
}
