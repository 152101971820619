import { Directive, HostListener, forwardRef, NgZone } from '@angular/core';
import {
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validator,
    AbstractControl,
    ValidationErrors,
    ControlValueAccessor,
} from '@angular/forms';

@Directive({
    selector: '[numeroAdeliDirective]',
    providers: [{provide: NG_VALIDATORS, useExisting: forwardRef(() => NumeroAdeliDirective), multi: true,},
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NumeroAdeliDirective), multi: true,},],
})
export class NumeroAdeliDirective implements Validator, ControlValueAccessor {
    private readonly maxLength: number = 9;

    private onChange: (value: string) => void = () => {};
    private onTouched: () => void = () => {};

    constructor(private zone: NgZone) {}

    // Validator interface
    validate(control: AbstractControl): ValidationErrors | null {
        const value = control.value as string;
        if (value && (value.length > this.maxLength || !/^\d*$/.test(value))) {
            return { invalidNumeroAdeli: true };
        }
        return null;
    }

    // ControlValueAccessor interface
    writeValue(value: string): void {
        const inputElement = this.getInputElement();
        if (inputElement && inputElement.value !== value) {
            inputElement.value = value;
        }
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    @HostListener('input', ['$event'])
    onInput(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        const sanitized = this.sanitizeInput(inputElement.value);

        this.updateInputValue(inputElement, sanitized);

        // Notifier Angular
        this.onChange(sanitized);
        this.onTouched();
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent): void {
        event.preventDefault();

        const clipboardData = event.clipboardData?.getData('text') ?? '';
        const sanitized = this.sanitizeInput(clipboardData);

        const inputElement = event.target as HTMLInputElement;
        // Met à jour l'élément HTML
        this.updateInputValue(inputElement, sanitized);

        this.zone.run(() => {
            this.onChange(sanitized);
            this.onTouched();
        });
    }

    private sanitizeInput(value: string): string {
        return value.replace(/[^\d]/g, '').substring(0, this.maxLength);
    }

    private updateInputValue(inputElement: HTMLInputElement, value: string): void {
        if (inputElement.value !== value) {
            inputElement.value = value;
        }
    }

    private getInputElement(): HTMLInputElement | null {
         return document.querySelector('[numeroAdeliDirective]') as HTMLInputElement | null;
    }
}
