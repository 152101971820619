import { SelAccessGuard } from './core/guards/sel-access.guard';
import { RappAccessGuard } from './pages/rapprochement/rapp-access.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CommonModule, DatePipe } from '@angular/common';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { AuthService } from './core/services/auth.service';
import { UserService } from './core/services/user.service';
import { LoginService } from './pages/login/login.service';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { ServiceUnavailableInterceptor } from './core/interceptors/service-unavailable.interceptor';
import { ScrollTopService } from './core/utilities/scroll-top.service';
import { MinHeightDirective } from './directives/min-height.directive';

import { ToastrModule } from 'ngx-toastr';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { AuthentificationGuard } from './core/guards/auth.guard';
import { EditeursAccessGuard } from './pages/editeurs/editeurs-access.guard';
import { InputValidators } from './components/validators/InputValidators';
import { OrganismeIsAuthAccessGuard } from './pages/organismes/organisme-is-auth-access.guard';
import { PsIsAuthAccessGuard } from './pages/ps/ps-is-auth-access.guard';
import { IsOctAllowedAccessGuard } from './pages/organismes/is-oct-allowed-access.guards';
import { UsersService } from './pages/users/users.service';
import { RouteHistoryService } from './shared/route-history.service';
import { ConvertDateFormat } from './core/utilities/convert-date-format.service';
import { TutorialService } from './core/services/tutorial.service';
import { StorageService } from './shared/storage-service';
import { IsNotAdminSelAccessGuard } from './core/guards/is_not_admin_sel-access.guard';
import { IsNotPsGuard } from './core/guards/is_not_ps.guard';
import { ExportModalComponent } from './pages/export-modal/export-modal.component';
import { ModalreportingComponent } from './pages/reporting/modalreporting/modalreporting.component';
import { NgSelectModule } from '@ng-select/ng-select';
import {AdminLayoutModule} from "./layouts/admin-layout/admin-layout.module";
import { CustomRouteHistoryService } from './shared/custom-route-history.service';
import { CAWDateService } from './shared/utils/date-utils.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PsModalComponent } from './pages/ps-modal/ps-modal.component';
import {IsNotAdminHotlineGuard} from "./core/guards/is_not_admin_hotline.guard";
import { MigrationOctGuard } from './core/guards/migration_oct.guard';
import { RappAccessEbicsGuard } from './pages/rapprochement/rapp-access-ebics.guard';
import {AdminService} from "./pages/ contenus-plateforme/admin-content.service";
import {VisibilityService} from "./pages/ps/service/VisibilityService";

export const httpInterceptorProviders = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: ServiceUnavailableInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: ErrorInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: JwtInterceptor,
		multi: true
	}
];

@NgModule({
    imports: [
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
		ToastrModule.forRoot({ timeOut: 15000, closeButton: true, tapToDismiss: false }),
        FormsModule,
        HttpClientModule,
        ComponentsModule,
        NgbModule,
        RouterModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        NgHttpLoaderModule.forRoot(),
        MatTableModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        NgSelectModule,
        AdminLayoutModule
    ],
	declarations: [ AppComponent, AdminLayoutComponent, AuthLayoutComponent, MinHeightDirective, ExportModalComponent, ModalreportingComponent, NotFoundComponent, PsModalComponent ],
	providers: [
		AuthService,
		UserService,
		LoginService,
		httpInterceptorProviders,
		ScrollTopService,
		ConvertDateFormat,
		DatePipe,
		AdminService ,
		{ provide: LOCALE_ID, useValue: 'fr-FR' },
		AuthentificationGuard,
		EditeursAccessGuard,
		OrganismeIsAuthAccessGuard,
		IsOctAllowedAccessGuard,
		PsIsAuthAccessGuard,
		IsNotPsGuard,
		IsNotAdminHotlineGuard,
		RappAccessGuard,
		RappAccessEbicsGuard,
		SelAccessGuard,
		IsNotAdminSelAccessGuard,
		MigrationOctGuard,
		InputValidators,
		UsersService,
		RouteHistoryService,
		CustomRouteHistoryService,
		CAWDateService,
		BsModalRef,
		TutorialService,
		StorageService,
		VisibilityService,
		PsModalComponent
	],
	bootstrap: [ AppComponent ],
	exports: [ MatTableModule, MatButtonModule, MatIconModule, MatMenuModule ]
})
export class AppModule {}
