import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { isEmpty } from 'lodash-es';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { InputValidators } from 'src/main/webapp/src/app/components/validators/InputValidators';
import { User } from 'src/main/webapp/src/app/data/userDataRaw';
import { UserPreferencesDataRaw } from '../../../../../data/UserPreferencesDataRaw';
import { UsersService } from '../../../../users/users.service';
import { PsService } from '../../../ps.service';

@Component({
	selector: 'careweb-app-ps-user-nouveau',
	templateUrl: './ps-user-nouveau.component.html',
	styleUrls: ['./ps-user-nouveau.component.scss']
})
export class PsUserNouveauComponent implements OnInit {
	@Input() idPs: number;
	@Input() idEtablissement: number;
	userForm: UntypedFormGroup;
	typeUserArray = [
		{ value: 1, viewValue: 'Administrateur' },
		{ value: 3, viewValue: 'Hotline' },
		{ value: 2, viewValue: 'Utilisateur PS' }
	];
	civiliteArray: any = [{ value: 1, viewValue: 'M' }, { value: 3, viewValue: 'Mme' }];
	actifArray: any = [{ value: true, viewValue: 'Oui' }, { value: false, viewValue: 'Non' }];
	submitted: Boolean = false;
	idTypeUserPs = 2;
	alreadyExists = false;

	constructor(
		private modalRef: BsModalRef,
		private toastr: ToastrService,
		private fb: UntypedFormBuilder,
		private userService: UsersService,
		private psSVC: PsService,
		private inputValidators: InputValidators
	) { }

	ngOnInit() {
		this.initialiseCurrentPage();
	}

	initialiseCurrentPage() {
		this.submitted = false;
		this.validForm();
	}

	validForm() {
		this.userForm = this.fb.group({
			typeUser: [this.typeUserArray.find((type) => type.value === this.idTypeUserPs).viewValue],
			groupUser: ['PS'],
			typeCivilite: [this.civiliteArray.find((civilite) => civilite.value === 1)],
			fonction: [''],
			nom: [''],
			prenom: [''],
			telephone: [''],
			mobile: [''],
			fax: [''],
			email: [
				'',
				[
					Validators.required, this.inputValidators.inputWithSpaces,
					Validators.pattern(this.inputValidators.emailPattern)
				]
			],
			actif: [this.actifArray.find((actif) => actif.value === true)],
			userSupprimmer: [''],
			modifMdp: [''],
			modifAdministration: [''],
			commentaire: [''],
			rapproActif: [this.actifArray.find((rapproActif) => rapproActif.value === true)]
		});
	}


	get formFields() {
		return this.userForm.controls;
	}

	closeModal() {
		this.modalRef.hide();
	}

	formatActif(value) {
		return value === true ? 'ACTIF' : 'INACTIF';
	}

	getTypeUser(value) {
		return this.typeUserArray.find((type) => type.value === value);
	}

	onSubmit() {
		this.submitted = true;
		if (this.userForm.invalid) {
			return;
		}
		const controlEmail = this.userForm.controls['email'];
		// verif exist email in database
		if (!isEmpty(this.userForm.value.email)) {
			this.userService.getUsersByEmail(this.userForm.value.email).subscribe(
				(data) => {
					if (data != null) {
						controlEmail.setErrors({ validmail: true });
						this.alreadyExists = true
					} else {
						controlEmail.setErrors(null);
						this.saveUser();
					}
				},
				(err) => { }
			);
		} else {
			this.saveUser();
		}
	}

	saveUser() {
		let currentUser: User = new User();
		let data = this.userForm.value;
		currentUser.idTypeUser = this.typeUserArray.find((type) => type.value === this.idTypeUserPs).value;
		currentUser.idCivilite = data.typeCivilite.value;
		currentUser.fonction = data.fonction ? (data.fonction as String).trim() : data.fonction;
		currentUser.nom = data.nom ? (data.nom as String).trim() : data.nom;
		currentUser.prenom = data.prenom ? (data.prenom as String).trim() : data.prenom;
		currentUser.telephone = data.telephone;
		currentUser.mobile = data.mobile;
		currentUser.fax = data.fax;
		currentUser.email = data.email ? (data.email as String).trim() : data.email;
		currentUser.actif = data.actif.value;
		currentUser.commentaire = data.commentaire ? (data.commentaire as String).trim() : data.commentaire;
		currentUser.idEtablissement = this.idEtablissement;
		currentUser.userPreferences = new UserPreferencesDataRaw();
		currentUser.userPreferences.rapproActif = data.rapproActif.value;
		this.psSVC.saveUserWithIdPs(this.idPs, currentUser).subscribe((data) => {
			if (data) {
				this.toastr.success("L'utilisateur a été ajouté avec succès");
				this.toastr.success("Un email de création de mot de passe a été envoyé");
				this.closeModal();
			}
			this.submitted = false;
		});
	}

	reset() {
		this.initialiseCurrentPage();
		this.modalRef.hide();
	}
}
