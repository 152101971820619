<div class="x-large-careweb-container">
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <section class="form-content">
            <!-- Titre H2 -->
            <div class="row form-title">
                <h2 class="col-md-6">
                    {{getTitle()}}
                </h2>

                <div class="col-md-6 text-title">
                    <button type="button" id="reinit_password" class="btn btn-primary" (click)="onReinitializePassword()"
                        [hidden]="this.createUserComponent">
                        Réinitialiser le mot de passe
                    </button>
                    <ng-container *ngIf="currentUser.blocked">
                        &nbsp;&nbsp;
                        <button type="button" id="id_debloquer" class="btn btn-primary" (click)="unblockAccount()">
                            Débloquer
                        </button>
                    </ng-container>
                    &nbsp;&nbsp;<button class="btn btn-default btn-lg" type="button"
                       id="retour" (click)="goToListUsers()">Retour</button>
                </div>
            </div>
            <div class="form-block">
                <!-- Titre -->
                <div class="row">
                    <div class="col">
                        <h3 class="title-border">
                            Informations générales
                        </h3>
                    </div>
                </div>
                <fieldset class="container-fluid">
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Civilité *</div>
                        <div class="col-sm-4 form-group">
                            <select formControlName="typeCivility" id="typeCibility" style="width:100%; height:calc(1.5em + .75rem + 2px)"
                                class="form-control form-control-input"
                                [ngClass]="{ 'is-invalid': submitted && formFields.typeCivility.errors }">
                                <option *ngFor="let civility of civiliteArray" [ngValue]="civility">
                                    <span id="{{civility.viewValue}}"> {{civility.viewValue}}</span>
                                </option>
                            </select>
                            <div *ngIf="submitted && formFields.typeCivility.errors" class="invalid-feedback">
                                <div *ngIf="formFields.typeCivility.errors.required">Ce champ est obligatoire</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Nom *</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" id="nom" name="nom" formControlName="nom"
                                onkeypress="if(this.value.length == 50) return false;"
                                [ngClass]="{ 'is-invalid': submitted && formFields.nom.errors }" />
                            <div *ngIf="submitted && formFields.nom.errors" class="invalid-feedback">
                                <div *ngIf="formFields.nom.errors.required || formFields.nom.errors.whitespaces">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2 col-form-label">Prénom</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" id="prenom" formControlName="prenom"
                                onkeypress="if(this.value.length == 50) return false;" />
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Email *</div>
                        <div class="col-sm-4 form-group">
                            <input type="email" class="form-control" id="email" formControlName="email"
                                onkeypress="if(this.value.length == 255) return false;"
                                [ngClass]="{ 'is-invalid': submitted && formFields.email.errors }" />

                            <div *ngIf="submitted && formFields.email.errors" class="invalid-feedback">
                                <div *ngIf="formFields.email.errors.required || formFields.email.errors.whitespaces">
                                    Ce champ est obligatoire
                                </div>
                                <div *ngIf="formFields.email.errors.pattern">
                                    Le format du champ E-mail est invalide
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2 col-form-label">Fonction</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" id="fonction" formControlName="fonction"
                                onkeypress="if(this.value.length == 50) return false;" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Téléphone</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" id="telephone" formControlName="telephone"
                                   phoneNumberDirective/>
                        </div>
                        <div class="col-sm-2 col-form-label">Fax</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" id="faxe" class="form-control" formControlName="fax"
                                   phoneNumberDirective/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Mobile</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" id="mobile" class="form-control" formControlName="mobile" min="0"
                                max="99999999999999999" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13 || this.value.length >= 16)? 
                                                        false :
                                                        event.charCode >= 48 && event.charCode <= 57" />
                        </div>
                    </div>

                </fieldset>
            </div>
            <div class="form-block">
                <!-- Titre -->
                <div class="row">
                    <div class="col">
                        <h3 class="title-border">
                            <div class="row">
                                <div class="col-auto me-auto">Droits</div>
                            </div>
                        </h3>
                    </div>
                </div>
                <fieldset class="container-fluid">
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Actif</div>
                        <div class="col-sm-4 form-group">
                            <select formControlName="selectActif" id="selectActif" style="width:100%; height:calc(1.5em + .75rem + 2px)"
                                class="form-control form-control-input">
                                <option *ngFor="let actif of actifArray" [ngValue]="actif">
                                    <span id="{{actif.viewValue}}"> {{actif.viewValue}}</span>
                                    </option>
                            </select>
                        </div>
                        <div class="col-sm-2 col-form-label">Type utilisateur</div>
                        <div class="col-sm-4 form-group">
                            <select formControlName="selectTypeUser" id="typeUser" (click)="octListAccordingUserType($event)"
                                style="width:100%; height:calc(1.5em + .75rem + 2px)"
                                class="form-control form-control-input">
                                <option *ngFor="let type of typeUserArray" [ngValue]="type">
                                    <span id="{{type.viewValue}}"> {{type.viewValue}}</span>
                                    </option>
                            </select>
                        </div>

                    </div>
                    <div class="row" style="display: none;">
                        <div class="col-sm-2 col-form-label">Superviseur</div>
                        <div class="col-sm-4 form-group">
                            <select formControlName="selectSuperviseur" id="superviseur"
                                style="width:100%; height:calc(1.5em + .75rem + 2px)"
                                class="form-control form-control-input">
                                <option *ngFor="let superviseur of SuperviseurArray" [ngValue]="superviseur">
                                    <span id="{{superviseur.viewValue}}"> {{superviseur.viewValue}}</span>
                                    {{superviseur.viewValue}}</option>
                            </select>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="form-block">
                <!-- Titre -->
                <div class="row">
                    <div class="col">
                        <h3 class="title-border">
                            <div class="row">
                                <div class="col-auto me-auto">OCT</div>
                                <div class="col-auto">{{nbOctActif}} OCT actifs</div>
                            </div>
                        </h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col-md-3" *ngFor="let oct of listOCT">
                                <button type="button" id="editOctListe" [ngClass]="getColorButton(oct.value)" class="btn btn-primary"
                                    [disabled]="isAdmiSEL" style="width:100%;height:50px;"
                                    (click)="editOctList(oct.value)" name="oct.value">
                                    <span id="{{oct.viewValue}}">{{oct.viewValue}}</span>
                                    
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="form-content">
            <div class="button-bar control">
                <button type="button" id="cancel" class="btn btn-default" (click)="onReset()"> Annuler </button>
                <button type="submit" id="save" class="btn btn-primary" [disabled]="isUpdateDisabled">
                    Enregistrer
                </button>
            </div>
        </section>
    </form>
</div>
