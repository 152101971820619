import {Component, HostListener, OnInit} from '@angular/core';
import { ColDef } from 'ag-grid-community';
import {AgGridHelper} from "../../components/ag-grid/ag-grid-helper";
import {GridDataRaw} from "../../data/gridDataRaw";
import {isNullOrUndefined} from "util";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {StorageService} from "../../shared/storage-service";
import {SupportServiceModalComponent} from "../dict-rule-rapp/support-service-modal/support-service-modal.component";
import {OctService} from "../../core/services/oct.service";
import {IServerSideDatasource, IServerSideGetRowsParams} from "ag-grid-enterprise";
import {AuthService} from "../../core/services/auth.service";
import {OctDataRaw} from "../../data/octDataRaw";

@Component({
    selector: 'app-support-service',
    templateUrl: './support-service.html',
    styleUrls: ['./support-service.css']
})
export class SupportServiceComponent implements OnInit {
    gridHelper: AgGridHelper;
    gridDataRaw: GridDataRaw;
    octDataRow : OctDataRaw;
    updatModal: BsModalRef;
    rowData: any[];
    adressContact:'';
    public rowCount: number = 0;
    octOrder: string[] = [
        'Qualitrans Telepharma',
        'Concentrateur du Soleil',
        'Alliadis',
        'Santesurf',
        'Isante',
        'Thales',
        'Cetip AM',
        'Cetip BIO',
        'Cetip CS',
        'Cetip PH'
    ];

    constructor(private storageService: StorageService ,private modalService: BsModalService,
             private octService: OctService ,private authSvc: AuthService
    ) {
        const sessionPageSize = this.storageService.get('SupportServPageSize', true);

        this.gridDataRaw = new GridDataRaw();
        this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;
        this.gridHelper = new AgGridHelper('supportService', this.gridDataRaw.size, 10);
        this.octDataRow = new OctDataRaw();
    }

    ngOnInit(): void {
        this.initGrid();
    }
    showModal(titre: string, data:any) {
        this.adressContact = data.url || '';
        this.updatModal = this.modalService.show(SupportServiceModalComponent, {
            initialState: {
                title: titre,
                adressContact : data.url,
                idOct : data.idOct
            }
        });

    }
    initGrid() {
        this.gridHelper.gridOptions = {
            columnDefs: this.getColumnDefs(),
            defaultColDef: {
                suppressMenu: true,
                resizable: true
            },
            domLayout: 'autoHeight',
            rowHeight: 50,
            headerHeight: 50,
            rowModelType: 'serverSide',
            pagination: true,
            cacheBlockSize: 10,
            paginationPageSize: this.gridDataRaw.size,
            suppressScrollOnNewData: true,
            suppressPaginationPanel: true,
            suppressContextMenu: true,
            localeText: this.gridHelper.getLocaleText(),
            enableCellTextSelection: true,
            rowData: this.rowData,
            onGridReady: (params) => this.onGridReady(params)
        };
    }

    private getColumnDefs(): ColDef[] {
        return [
            {
                headerName: 'OCT',
                field: 'nom',
                colId: 'nom',
                minWidth: 150,
                lockVisible: true,
                cellStyle: this.gridHelper.centerContent(),
            },
            {
                headerName: 'Adresse de contact',
                field: 'url',
                colId: 'url',
                minWidth: 200,
                lockVisible: true,
                cellStyle: this.gridHelper.centerContent(),
            },
            {
                headerName: '',
                colId: 'option', // Pas de "field"
                minWidth: 60,
                maxWidth: 60,
                cellStyle: this.gridHelper.centerContent(),
                cellRenderer: (params) => this.renderEditIcon(params),
                onCellClicked: (params) => this.showModal('Modifier l\'adresse de contact de l\'OCT ',params.data)

            }
        ];
    }F
    private renderEditIcon(params: any): HTMLElement {
        const iconElement = document.createElement('i');
        iconElement.className = 'fa fa-edit';
        iconElement.style.cursor = 'pointer';
        iconElement.title = 'Edit';

        return iconElement;
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.gridHelper.gridApi.sizeColumnsToFit();
    }

    onGridReady(params) {
        const ctx = this;
        this.gridHelper.gridApi = params.api;
        this.gridHelper.gridColumnApi = params.columnApi;
        this.gridHelper.gridApi.sizeColumnsToFit();

        // Créer la source de données côté serveur
        const dataSource: IServerSideDatasource = {
            getRows: function (paramsRows: IServerSideGetRowsParams) {
                const currentPage = paramsRows.request.startRow / ctx.gridDataRaw.size;
                const pageSize = ctx.gridDataRaw.size;

                ctx.octService.getListOct(currentPage, pageSize).subscribe(
                    (data) => {
                        const rowCount = data.totalElements;

                        // Appliquer le tri personnalisé basé sur l'ordre défini dans `octOrder`
                        const sortedData = data.content.sort((a, b) => {
                            const indexA = ctx.octOrder.indexOf(a.nom);
                            const indexB = ctx.octOrder.indexOf(b.nom);

                            // Les éléments non trouvés dans l'ordre seront placés en dernier
                            return (
                                (indexA !== -1 ? indexA : Number.MAX_SAFE_INTEGER) -
                                (indexB !== -1 ? indexB : Number.MAX_SAFE_INTEGER)
                            );
                        });

                        ctx.gridHelper.manageNoRowsOverlay(rowCount);

                        paramsRows.successCallback(sortedData, rowCount);
                    },
                    () => paramsRows.failCallback()
                );
            }
        };

        this.gridHelper.gridApi.setServerSideDatasource(dataSource);
    }

    isAuthorized() {
        return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
    }

}
