import {RappDetailVrtCellRendererComponent} from './../rapp-detail-vrt-cell-enderer.component';
import {IServerSideDatasource, IServerSideGetRowsParams} from 'ag-grid-enterprise';
import {DatePipe} from '@angular/common';
import {Component, HostListener, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {findIndex, isEmpty, uniqBy} from 'lodash-es';
import {ToastrService} from 'ngx-toastr';
import {isNullOrUndefined} from 'util';
import {AgGridHelper} from '../../../components/ag-grid/ag-grid-helper';
import {gridConvertSort} from '../../../components/ag-grid/ag-grid.utils';
import {FilterItem} from '../../../data/filters/filter-item';
import {RappTransactionFilterRaw} from '../../../data/filters/rappTransactionFilterRaw';
import {RappComptePsDataRaw} from '../../../data/rappComptePsDataRaw';
import {RapprochementDataRaw} from '../../../data/rapprochementDataRaw';
import {LotsFilterSvc} from '../../flux/lots/lots-filter.service';
import {RappAutoExternalPreviewComponent} from '../rapp-auto-external-preview.component';
import {TransactionService} from '../transaction.service';
import {RapprochementComptePsService} from '../rapprochement.service';
import {RappAutoPreviewcomponent} from '../rapp-auto-preview.component';
import {RappAutoCountComponent} from '../rapp-auto-count.component';
import {Router} from '@angular/router';
import {GridDataRaw} from '../../../data/gridDataRaw';
import {StorageService} from '../../../shared/storage-service';
import {FormatThousandsPipe} from '../../../core/pipes/format-thousands.pipe';
import {ColumnTooltipComponent} from '../../../components/column-tooltip/column-tooltip-component';
import {EbicsService} from '../../ps/service/ebics.service';
import {FilterChoiceEnum} from "../../../data/filters/rspRappSummaryFilterRaw";
import {FilterChoiceExactePlageComponent} from "../../../components/filter-choice-exacte-plage/filter-choice-exacte-plage.component";

@Component({
    selector: 'careweb-app-rapp-auto',
    templateUrl: './rapp-auto.component.html',
    styleUrls: ['./rapp-auto.component.scss']
})
export class RappAutoComponent implements OnInit, OnDestroy {
    gridHelper: AgGridHelper;
    gridHelperRSP: AgGridHelper;
    gridHelperVRT: AgGridHelper;
    datePipe: DatePipe;
    rapprochementDataRaw: RapprochementDataRaw;
    rappComptePsDataRaw: RappComptePsDataRaw;
    showRappAutoDetail: boolean;
    selectedRow: any;
    nbRowSelected: number;
    montantTotalSelected: string;
    initHidden = true;
    lastSyncDateEbics: Date=null;
    syncBannerVisible: boolean;
    connError: boolean = false;
    showFilter = false;
    filters = new RappTransactionFilterRaw();
    filterList: FilterItem[];
    connections: any[];
    conenctionEbics: any;
    showConfirmModal: boolean = false;
    modalMessage: string;
    oxlinUserConnected: any;
    timeLeft:number = 0;
    interval: any;
    userConnected: any;
    labelValidButtonConfirmModal: string = 'Oui';
    labelCancelButtonConfirmModal: string = 'Non';
    gridDataRaw: GridDataRaw;
    gridDataRawRsp: GridDataRaw;
    gridDataRawVrt: GridDataRaw;
    montantVrtMinError: boolean = false;
    montantVrtMaxError: boolean = false;
    montantRspMinError: boolean = false;
    montantRspMaxError: boolean = false;
    active = 1;
    static MIN_WIDTH = 1173;
    totalImportedVrtAlert = null;
    rspPurger: boolean = false;
    loadData: boolean = false;
    allRspPurger: boolean = false;
    @ViewChildren(FilterChoiceExactePlageComponent) filterComponents: QueryList<FilterChoiceExactePlageComponent>;

    constructor(
        private rapprochementComptePsService: RapprochementComptePsService,
        private transactionService: TransactionService,
        private lotsFilterSvc: LotsFilterSvc,
        private toastr: ToastrService,
        private storageService: StorageService,
        private router: Router,
        private formatThousands: FormatThousandsPipe,
        private ebicsService: EbicsService,
    ) {
        // Body styling
        const body = document.body;
        body.className = 'app';

        //Get grid size from session if already stored
        const sessionPageSize = this.storageService.get('rappAutoPaginationPageSize', true);
        this.gridDataRaw = new GridDataRaw();
        this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;
        this.gridHelper = new AgGridHelper('rappAuto', this.gridDataRaw.size, 10);

        const sessionPageSizeRsp = this.storageService.get('rappAutoRspPaginationPageSize', true);
        this.gridDataRawRsp = new GridDataRaw();
        this.gridDataRawRsp.size = !isNullOrUndefined(sessionPageSizeRsp) ? sessionPageSizeRsp : 10;
        this.gridHelperRSP = new AgGridHelper('rappAutoRsp', this.gridDataRawRsp.size, 10);

        const sessionPageSizeVrt = this.storageService.get('rappAutoVrtPaginationPageSize', true);
        this.gridDataRawVrt = new GridDataRaw();
        this.gridDataRawVrt.size = !isNullOrUndefined(sessionPageSizeVrt) ? sessionPageSizeVrt : 10;
        this.gridHelperVRT = new AgGridHelper('rappAutoVrt', this.gridDataRawVrt.size, 10);

        this.selectedRow = null;
        this.rapprochementDataRaw = new RapprochementDataRaw();
        this.nbRowSelected = 0;
        this.montantTotalSelected = null;
        this.syncBannerVisible = false;
        this.connections = [];

        // Initiate date pipe format
        this.datePipe = new DatePipe('fr-FR');
    }

    getPsId() {
        return (localStorage.getItem('supervised_ps') != null && localStorage.getItem('supervised_ps') !='-1') ? localStorage.getItem('supervised_ps').split(';')[1]:(localStorage.getItem('idPs') != null) ? localStorage.getItem('idPs') : this.userConnected.idPs;
        //return (localStorage.getItem('idPs') != null) ? localStorage.getItem('idPs') : this.userConnected.idPs
    }

    getIdRappComptePs() {
        let currentPsStorage = localStorage.getItem('current_ps');
        if (currentPsStorage) {
            const currentPs = JSON.parse(currentPsStorage);
            if (currentPs.rappComptePs && currentPs.rappComptePs.actif && currentPs.rappComptePs.idRappComptePs) {
                return currentPs.rappComptePs.idRappComptePs;
            }
        }else if(this.oxlinUserConnected && this.oxlinUserConnected.actif){
            return this.oxlinUserConnected?.idRappComptePs;
        }else {
            return 0;
        }

    }

    isEbicsActif(ebicsPs) {
        return ebicsPs.filter((e) => e.actif)?.length > 0;
    }
    isEbicsUser() {
        let currentPsStorage = localStorage.getItem('current_ps');
        let careweb_user = localStorage.getItem('careweb_user');
        if (currentPsStorage) {
            const currentPs = JSON.parse(currentPsStorage);
            return currentPs && ( (currentPs.typeRappro == 'EBICS' && currentPs.psEbics && this.isEbicsActif(currentPs.psEbics)))
        }
        else if(careweb_user){
            const currentUser = JSON.parse(careweb_user);
            return currentUser && currentUser.typeRappro=='EBICS';
        }
        return false;
    }
    ngOnInit() {
        this.parseFilters();
        this.initMontantFilter();

        this.userConnected = JSON.parse(localStorage.getItem('careweb_user'));
        var ebicsUser = JSON.parse(localStorage.getItem('careweb_ebics_user'));
        const currentPs = JSON.parse(localStorage.getItem('current_ps'));
        if(currentPs){
            this.oxlinUserConnected=currentPs.rappComptePs;
        }
        if(!ebicsUser && currentPs && currentPs.psEbics){
            ebicsUser = currentPs.psEbics;
        }

        if (this.isEbicsUser()) {
            this.syncBannerVisible = true;
            this.getDateDerniereSynchro();
            this.conenctionEbics = ebicsUser;
        } else { //Oxlin
            this.updateRappComptePs();
            this.rapprochementComptePsService.getUserOxlinScope(this.getPsId()).subscribe(
                (data) => {
                    // Get current user rapp infos
                    if (data[0] === 'connections' && data[1] !== '' && data[1].length > 0) {
                        data[1].map((conn) => {
                            if (conn.status !== 'SUCCESS') this.connError = true;
                        });
                        this.connections = data[1];
                        //console.log(data[1]);
                    }

                    this.syncBannerVisible = true;
                },
                (error) => {
                    this.toastr.error("Une erreur s'est produite à la connexion avec Oxlin.", 'Connexion Oxlin');
                }
            );
        }


        // Init grid options
        this.gridHelper.gridOptions = {
            columnDefs: this.getColumnDefs(),
            defaultColDef: {
                suppressMenu: true,
                resizable: true
            },
            domLayout: 'autoHeight',
            rowHeight: 50,
            headerHeight: 50,
            rowModelType: 'serverSide',
            suppressServerSideInfiniteScroll: false,
            pagination: true,
            cacheBlockSize: 10,
            paginationPageSize: this.gridDataRaw.size,
            infiniteInitialRowCount: 1,
            suppressScrollOnNewData: true,
            suppressPaginationPanel: true,
            suppressContextMenu: true,
            suppressPropertyNamesCheck: true,
            angularCompileHeaders: true,
            enableCellTextSelection: true,
            suppressDragLeaveHidesColumns: true,
            localeText: this.gridHelper.getLocaleText(),
            onGridReady: (params) => this.onGridReady(params),
            onSelectionChanged: (params) => this.onSelectionChanged(params),
            // onGridSizeChanged: (params) => this.onGridSizeChanged(params),
            onSortChanged: (params) => this.onSortChanged(params),
            rowSelection: 'multiple',
            suppressRowClickSelection: true,
            getRowClass: (params) => this.rowStyleMontantDiff(params),
            onColumnMoved: (params) => this.onColumnChanged(params),
        };
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        let width = event.target.innerWidth;
        this.setColumnOrder(width);
    }
    setColumnOrder(width:any) {
        const columnDefs = this.getColumnDefs();
        if(width <= 1110){
            const columnApi = this.gridHelper.gridColumnApi;
            const optionsColumnIndex = columnDefs.findIndex(column => column.field === 'options');
            if (optionsColumnIndex !== -1) {
                columnApi.moveColumn('options', 1);
                columnApi.moveColumn('unlink', 2);
            }
        }else {
            this.gridHelper.gridApi.sizeColumnsToFit();
            this.gridHelper.gridApi.setColumnDefs(columnDefs);
        }
    }

    parseFilters() {
        const parsedFilter = JSON.parse(sessionStorage.getItem('rapproAutoFilter'));
        if (!isEmpty(parsedFilter)) {
            this.filters = Object.assign({}, parsedFilter);
        } else {
            this.filterList = [new FilterItem()];
        }
    }

    goToRappBankAccount() {
        this.router.navigate(['/rapp-bank-account']);
    }

    rowStyleMontantDiff(params) {
        if (params.data != null && params.data.montantTotalRsp != null && params.data.montantTotalTr != null) {
            if (params.data.montantTotalRsp.toFixed(2) != params.data.montantTotalTr.toFixed(2)) {
                return 'row-orange';
            }
        }
    }

    onGridSizeChanged(params) {
        this.gridHelper.gridApi.sizeColumnsToFit();
    }

    onRspGridSizeChanged(params) {
        this.gridHelperRSP.gridApi.sizeColumnsToFit();
    }

    onVrtGridSizeChanged(params) {
        this.gridHelperVRT.gridApi.sizeColumnsToFit();
    }

    onSortChanged(params) {
        this.gridHelper.gridApi.deselectAll();
    }

    onGridReadyRSP(params) {
        if (this.selectedRow != null) {
            this.gridHelperRSP.gridApi = params.api;
            this.gridHelperRSP.gridColumnApi = params.columnApi;
            this.restoreRspGrid();
            this.gridHelperRSP.gridApi.setRowData(this.selectedRow.rsps);
            this.gridHelperRSP.gridApi.sizeColumnsToFit();
        }
    }

    onGridReadyVRT(params) {
        if (this.selectedRow != null) {
            this.gridHelperVRT.gridApi = params.api;
            this.gridHelperVRT.gridColumnApi = params.columnApi;
            this.gridHelperVRT.gridApi.sizeColumnsToFit();
            this.restoreVrtGrid();
            this.gridHelperVRT.gridApi.setRowData(this.selectedRow.bankTransactions);
        }
    }

    onVrtSortChanged(params) {
        this.gridHelperVRT.saveGridSort();
    }

    onRspSortChanged(params) {
        this.gridHelperRSP.saveGridSort();
    }

    onGridReady(params) {
        var width = window.innerWidth;
        this.gridHelper.gridApi = params.api;
        this.gridHelper.gridColumnApi = params.columnApi;
        this.restoreGrid();
        this.setColumnOrder(width);
        let parsedFilter = JSON.parse(sessionStorage.getItem('lotsFilter'));
        if (parsedFilter) {
            Object.keys(parsedFilter).forEach(
                (key) => (parsedFilter[key] === undefined || parsedFilter[key] === null ? delete parsedFilter[key] : {})
            );
        }
        this.launchSearch();
    }

    resetFilter() {
        this.lotsFilterSvc.reset();
    }

    initFilter() {
        // Liste des filtres
        this.resetFilter();
        this.updateFilterList(this.filters);
    }
    initFilterChoices() {
        const filterComponentsArray = this.filterComponents.toArray();
        filterComponentsArray.forEach(component => {
            component.reserFilterChoice();
        });
    }
    initMontantFilter() {
        if(!this.filters.montantRspFilter) {
            this.filters.montantRspFilter = FilterChoiceEnum.EXACTE
         }
        if(!this.filters.montantVrtFilter) {
            this.filters.montantVrtFilter = FilterChoiceEnum.EXACTE
        }
    }
    initVrtMontant() {
        if(this.filters.montantVrtFilter == FilterChoiceEnum.EXACTE) {
            this.filters.montantTotalTrFrom = null;
            this.filters.montantTotalTrTo = null;
        } else if (this.filters.montantVrtFilter == FilterChoiceEnum.PLAGE) {
            this.filters.montantTotalTrExacte = null;
        }
    }
    initRspMontant() {
        if(this.filters.montantRspFilter == FilterChoiceEnum.EXACTE) {
            this.filters.montantTotalRspFrom = null;
            this.filters.montantTotalRspTo = null;
        } else if (this.filters.montantRspFilter == FilterChoiceEnum.PLAGE) {
            this.filters.montantTotalRspExacte = null;
        }
    }
    resetAllFilter(): void {
        this.initFilter();
        this.filters = new RappTransactionFilterRaw();
        this.filters.montantRspFilter = FilterChoiceEnum.EXACTE;
        this.filters.montantVrtFilter = FilterChoiceEnum.EXACTE;
        this.launchSearch();
        this.lotsFilterSvc.reset();
        this.montantRspMinError = false;
        this.montantRspMaxError = false;
        this.montantVrtMinError = false;
        this.montantVrtMaxError = false;
        this.initFilterChoices();
    }

    launchSearch(): void {

        this.montantVrtMinError = false;
        this.montantVrtMaxError = false;

        this.montantRspMinError = false;
        this.montantRspMaxError = false;

        // Vérifier les montants et afficher les messages d'erreur si nécessaire
        let montantVrtFrom = parseFloat(String(this.filters.montantTotalTrFrom));
        let montantVrtTo = parseFloat(String(this.filters.montantTotalTrTo));

        if (!isNaN(montantVrtFrom) && !isNaN(montantVrtTo) && montantVrtFrom > montantVrtTo) {
            this.montantVrtMinError = true;
            this.montantVrtMaxError = true;
        } else if ((isNaN(montantVrtFrom) && !isNaN(montantVrtTo)) || (!isNaN(montantVrtFrom) && isNaN(montantVrtTo))) {
            this.montantVrtMinError = false;
            this.montantVrtMaxError = false;
        }

        let montantRspFrom = parseFloat(String(this.filters.montantTotalRspFrom));
        let montantRspTo = parseFloat(String(this.filters.montantTotalRspTo));

        if (!isNaN(montantRspFrom) && !isNaN(montantRspTo) && montantRspFrom > montantRspTo) {
            this.montantRspMinError = true;
            this.montantRspMaxError = true;
        } else if ((isNaN(montantRspFrom) && !isNaN(montantRspTo)) || (!isNaN(montantRspFrom) && isNaN(montantRspTo))) {
            this.montantRspMinError = false;
            this.montantRspMaxError = false;
        }

        if (!this.montantRspMinError && !this.montantRspMaxError && !this.montantVrtMinError && !this.montantVrtMaxError) {
            this.rapprochementDataRaw = new RapprochementDataRaw();

            this.rapprochementDataRaw.valide = 0;

            this.rapprochementDataRaw.idPs = this.getPsId();

            this.updateFilterList(this.filters);

            this.rapprochementDataRaw.periode = this.filters.periode;
            this.rapprochementDataRaw.label = this.filters.label ? this.filters.label.trim() : null;
            this.rapprochementDataRaw.organismeLibelle = this.filters.organismeLibelle
                ? this.filters.organismeLibelle
                : null;
            this.rapprochementDataRaw.montantTotalRspExacte = this.filters.montantTotalRspExacte;
            this.rapprochementDataRaw.montantTotalRspFrom = this.filters.montantTotalRspFrom;
            this.rapprochementDataRaw.montantTotalRspTo = this.filters.montantTotalRspTo;
            this.rapprochementDataRaw.montantTotalTrExacte = this.filters.montantTotalTrExacte;
            this.rapprochementDataRaw.montantTotalTrFrom = this.filters.montantTotalTrFrom;
            this.rapprochementDataRaw.montantTotalTrTo = this.filters.montantTotalTrTo;
            this.rapprochementDataRaw.montantDiff = isNullOrUndefined(this.filters.montantDiff)
                ? null
                : this.filters.montantDiff;
            this.rapprochementDataRaw.idOrganisme = this.filters.idOrganisme;
            this.rapprochementDataRaw.libelleVirement1 = this.filters.libelleVirement1 || null;
            this.rapprochementDataRaw.libelleVirement2 = this.filters.libelleVirement1 || null;
            Object.keys(this.rapprochementDataRaw).forEach((key) => {
                if (isNullOrUndefined(this.rapprochementDataRaw[key]) || this.rapprochementDataRaw[key] === '') {
                    delete this.rapprochementDataRaw[key];
                }
            });

            this.gridHelper.gridApi.setServerSideDatasource(this.getServerDataSource());
            this.gridHelper.gridApi.deselectAll();
            sessionStorage['rapproAutoFilter'] = JSON.stringify(this.filters);
        }
    }

    toggleFilterDisplay() {
        let width =window.innerWidth;
        if (this.initHidden) {
            this.initHidden = false;
            setTimeout(() => {
                this.showFilter = !this.showFilter;
            }, 10);
        } else {
            this.showFilter = !this.showFilter;
            if(!this.showFilter && width>1800){
                setTimeout(() => {
                    this.gridHelper.gridApi.sizeColumnsToFit();
                }, 200);
            }
        }
    }

    restoreGrid() {
        this.gridHelper.restoreGridStatePrefix();
        this.gridDataRaw.size = this.gridHelper.paginationPageSize;
    }

    restoreRspGrid() {
        this.gridHelperRSP.restoreGridStatePrefix();
        this.gridDataRawRsp.size = this.gridHelperRSP.paginationPageSize;
    }

    restoreVrtGrid() {
        this.gridHelperVRT.restoreGridStatePrefix();
        this.gridDataRawVrt.size = this.gridHelperVRT.paginationPageSize;
    }

    onSelectionChanged(param) {
        var selectedRows = this.gridHelper.gridApi.getSelectedRows();

        this.nbRowSelected = 0;
        this.montantTotalSelected = null;

        this.nbRowSelected = selectedRows.length;
        var aggMontant = 0;

        selectedRows.forEach((row) => {
            aggMontant += row.montantTotalTr;
        });

        this.montantTotalSelected = this.formatThousands.transform(aggMontant);
    }

    enabledToValidate() {
        return this.nbRowSelected > 0;
    }

    refresh() {
        this.gridHelper.gridApi.deselectAll();
    }

    validateSelectedTransactions() {
        var rapprochements = [];
        rapprochements = this.gridHelper.gridApi.getSelectedRows();

        rapprochements.map((t) => {
            t.dateValide = new Date();
            t.valide = 1;
        });

        this.rapprochementComptePsService.validateRapprochements(rapprochements).subscribe((data) => {
            this.gridHelper.gridApi.deselectAll();
            this.toastr.success('Validation effectuée avec succès', 'Rapprochement automatique');

            this.launchSearch();
        });
    }

    validateAllTransactions(): void {
        this.rapprochementComptePsService
            .validateAllRapprochements(this.getPsId())
            .subscribe(_ => {
                this.gridHelper.gridApi.setServerSideDatasource(this.getServerDataSource());
            });
    }

    onDetailClicked(params) {
        this.loadData=false;
        if (!this.gridHelper.isCellTextSelected()) {
            this.selectedRow = params.data;
            this.showRappAutoDetail = true;

            // Init grid options
            this.gridHelperRSP.gridOptions = {
                columnDefs: this.getColumnRSPDefs(),
                defaultColDef: {
                    resizable: true,
                    suppressMenu: true
                },
                domLayout: 'autoHeight',
                rowHeight: 50,
                headerHeight: 50,
                pagination: true,
                cacheBlockSize: 5,
                paginationPageSize: this.gridDataRawRsp.size,
                infiniteInitialRowCount: 1,
                suppressScrollOnNewData: true,
                suppressPaginationPanel: true,
                suppressContextMenu: true,
                suppressPropertyNamesCheck: true,
                angularCompileHeaders: true,
                localeText: this.gridHelper.getLocaleText(),
                onGridReady: (params) => this.onGridReadyRSP(params),
                // onGridSizeChanged: (params) => this.onRspGridSizeChanged(params),
                onSortChanged: (params) => this.onRspSortChanged(params),
            };

            // Init grid options
            this.gridHelperVRT.gridOptions = {
                columnDefs: this.getColumnVRTDefs(),
                defaultColDef: {
                    resizable: true,
                    suppressMenu: true
                },
                domLayout: 'autoHeight',
                rowHeight: 50,
                headerHeight: 50,
                pagination: true,
                cacheBlockSize: 5,
                paginationPageSize: this.gridDataRawVrt.size,
                infiniteInitialRowCount: 1,
                suppressScrollOnNewData: true,
                suppressPaginationPanel: true,
                suppressContextMenu: true,
                suppressPropertyNamesCheck: true,
                masterDetail: true,
                detailCellRenderer: 'myDetailCellRenderer',
                components: {
                    columnTooltipCellRenderer: ColumnTooltipComponent,
                    myDetailCellRenderer: RappDetailVrtCellRendererComponent,
                },
                localeText: this.gridHelper.getLocaleText(),
                onGridReady: (params) => this.onGridReadyVRT(params),
                // onGridSizeChanged: (params) => this.onVrtGridSizeChanged(params),
                onSortChanged: (params) => this.onVrtSortChanged(params),
            };

            // load rapp details
            this.rapprochementComptePsService.getRapprochementById(this.selectedRow.idRapprochement).subscribe((res) => {
                this.selectedRow.rsps = res.rsps;
                this.selectedRow.bankTransactions = res.bankTransactions;
                this.restoreRspGrid();
                this.rspPurger = this.selectedRow.nbRsp > 0 && !isNullOrUndefined(this.selectedRow.rsps) && this.selectedRow.rsps.length < this.selectedRow.nbRsp;
                this.allRspPurger = this.selectedRow.nbRsp > 0 && isNullOrUndefined(this.selectedRow.rsps);
                this.loadData=true;
                this.gridHelperRSP.gridApi?.setRowData(this.selectedRow.rsps);
                this.gridHelperRSP.gridApi?.sizeColumnsToFit();

                this.gridHelperVRT.gridApi.setRowData(this.selectedRow.bankTransactions);
                this.gridHelperVRT.gridApi.sizeColumnsToFit();
            })
        }
    }

    onDetachRappClicked(params) {
        this.rapprochementComptePsService.deleteRapprochement(params.data).subscribe((data) => {
            this.toastr.success('Détachement effectué avec succès', 'Rapprochement automatique');
            this.launchSearch();
        });
    }

    public closeModalRappAutoDetail() {
        this.selectedRow = null;
        this.showRappAutoDetail = false;
        //this.gridHelperVRT.gridApi.destroy();
        this.gridHelperRSP.saveGridStatePrefix();
        this.gridHelperVRT.saveGridStatePrefix();
    }

    exportCsv() { }

    resetGridState() {
        this.gridHelper.resetGridStateRapp();
        this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
    }

    private getColumnRSPDefs() {
        const columnDefs: any[] = [
            {
                headerName: 'N° lot',
                minWidth: 80,
                field: 'numLot',
                cellStyle: this.gridHelper.centerContent(),
                cellRenderer: RappAutoExternalPreviewComponent,
                sortable: true,
                sort: 'desc'
            },
            {
                headerName: 'N° FSE',
                minWidth: 100,
                field: 'numeroFacture',
                cellStyle: this.gridHelper.centerContent(),
                cellRenderer: RappAutoExternalPreviewComponent,
                sortable: true
            },
            {
                headerName: 'Montant AMO',
                minWidth: 100,
                field: 'montantFactureRo',
                cellStyle: this.gridHelper.rightContent(),
                valueFormatter: this.formatCurrency,
                sortable: true
            },
            {
                headerName: 'Montant AMO payé',
                minWidth: 150,
                field: 'montantRo',
                cellStyle: this.gridHelper.rightContent(),
                valueFormatter: (params) => this.gridHelper.formatWithSigne(params.value, params.data.signe),
                sortable: true
            },
            {
                headerName: 'Montant AMC',
                minWidth: 100,
                field: 'montantFactureRc',
                cellStyle: this.gridHelper.rightContent(),
                valueFormatter: (params) => this.gridHelper.formatWithSigne(params.value, params.data.signe),
                sortable: true
            },
            {
                headerName: 'Montant AMC payé',
                minWidth: 150,
                field: 'montantRc',
                cellStyle: this.gridHelper.rightContent(),
                valueFormatter: this.formatCurrency,
                sortable: true
            },
            {
                headerName: 'Libellé organisme',
                minWidth: 130,
                field: 'libelleOrganisme',
                sortable: true
            },
            {
                headerName: 'Date réception',
                minWidth: 130,
                field: 'dateReception',
                cellStyle: this.gridHelper.centerContent(),
                valueFormatter: this.formatDate,
                sortable: true
            },
            {
                headerName: 'Libellé virement 1',
                minWidth: 250,
                field: 'libelleVirement1',
                sortable: true
            },
            {
                headerName: 'Libellé virement 2',
                minWidth: 255,
                field: 'libelleVirement2',
                sortable: true
            },
        ];

        return columnDefs;
    }

    private getColumnVRTDefs() {
        const columnDefs: any[] = [
            {
                headerName: 'Libellé',
                field: 'label',
                width: 660,
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    suppressDoubleClickExpand: true,
                    innerRenderer: 'columnTooltipCellRenderer'
                },
                sortable: true,
                suppressSizeToFit: true
            },
            {
                headerName: 'Montant',
                width: 200,
                field: 'montant',
                cellStyle: this.gridHelper.rightContent(),
                valueFormatter: this.formatCurrencyRapp,
                sortable: true,
                suppressSizeToFit: true
            },
            {
                headerName: 'Date opération',
                width: 200,
                field: 'dateComptable',
                cellStyle: this.gridHelper.centerContent(),
                valueFormatter: this.formatDate,
                sortable: true,
                sort: 'desc',
                suppressSizeToFit: true
            }
        ];

        return columnDefs;
    }

    private getColumnDefs() {
        const columnDefs: any[] = [
            {
                headerName: '',
                field: '',
                minWidth: 50,
                maxWidth: 50,
                checkboxSelection: true,
                cellStyle: this.gridHelper.centerContent(),
            },
            {
                headerName: 'Avis de réception comptable (RSP)',
                headerClass: 'ag-header-group-parent',
                children: [
                    this.getCount('nbRsp'),
                    {
                        headerName: 'Date comptable',
                        field: 'dateReceptionRsp',
                        minwidth: 100,
                        maxWidth: 150,
                        cellStyle: this.gridHelper.centerContent(),
                        onCellClicked: (params) => this.onDetailClicked(params),
                        valueFormatter: this.formatDate,
                        sortable: true,
                    },
                    {
                        headerName: 'Organisme',
                        field: 'organismeLibelle',
                        minwidth: 100,
                        sortable: true,
                        cellStyle: this.gridHelper.centerContent(),
                        onCellClicked: (params) => this.onDetailClicked(params),
                    },
                    {
                        headerName: 'Montant',
                        field: 'montantTotalRsp',
                        minwidth: 150,
                        maxWidth: 200,
                        cellStyle: this.formatCellRspVrt(), //this.gridHelper.rightContent(),
                        valueFormatter: this.formatCurrencyRapp,
                        onCellClicked: (params) => this.onDetailClicked(params),
                        sortable: true,
                    }
                ]
            },
            {
                headerName: 'Virements banque (VRT)',
                headerClass: 'ag-header-group-parent',
                children: [
                    this.getCount('nbTransactions'),
                    {
                        headerName: 'Date rapp.',
                        field: 'dateRapprochement',
                        minwidth: 100,
                        maxWidth: 150,
                        cellStyle: this.gridHelper.centerContent(),
                        valueFormatter: this.formatDate,
                        onCellClicked: (params) => this.onDetailClicked(params),
                        sortable: true,
                        sort: 'desc',
                    },
                    {
                        headerName: 'Montant',
                        field: 'montantTotalTr',
                        minwidth: 150,
                        maxWidth: 200,
                        cellStyle: this.gridHelper.centerContent(),
                        valueFormatter: this.formatCurrencyRapp,
                        onCellClicked: (params) => this.onDetailClicked(params),
                        sortable: true,
                    },
                    {
                        headerName: 'Libellé',
                        field: 'label',
                        minWidth: 150,
                        sortable: true,
                        onCellClicked: (params) => this.onDetailClicked(params),
                    },
                    {
                        headerName: 'Date opération',
                        field: 'dateComptableTr',
                        minWidth: 100,
                        maxWidth: 150,
                        cellStyle: this.formatCellDateOperation(),
                        valueFormatter: this.formatDate,
                        onCellClicked: (params) => this.onDetailClicked(params),
                        sortable: true,
                    }
                ]
            },
            {
                headerName: '',
                field: 'unlink',
                suppressCellSelection: true,
                minwidth: 50,
                maxWidth: 50,
                headerClass: 'ag-header-merge',
                cellRenderer: RappAutoPreviewcomponent,
                onCellClicked: (params) => this.onDetachRappClicked(params),
                lockVisible: true,
                cellStyle: this.gridHelper.centerContent()
            },
            this.getDetailsRapprochement()
        ];

        return columnDefs;
    }
    getDateDerniereSynchro(){
        this.ebicsService.getLastSyncDate(this.getPsId()).subscribe(
            (data) => {
                this.lastSyncDateEbics = data;
            }
        );
    }
    requestIds = [];

    synchronizeEbicsAccount() {
        this.requestIds = [];
        this.totalImportedVrtAlert = null;
        this.transactionService.synchronizeEbicsTransactions(this.getPsId())
            .subscribe((requestIds) => {
                this.requestIds = requestIds;
                this.toastr.success('Synchronisation en cours, votre page sera actualisée une fois la synchronisation terminée.', 'Rapprochement automatique');
                this.timeLeft = 60;
                this.interval = setInterval(() => {
                    if(this.timeLeft > 0) {
                      this.timeLeft--;
                    } else {
                        this.getTotalNewTx();
                    }
                  },1000)
            },
                (error) => {
                    this.toastr.error("Une erreur s'est produite lors de synchronisation des nouvelles transactions", 'Rapprochement automatique');
                }
            );
    }

    syncInprogress(){
        return this.timeLeft > 0;
    }

    synchronizeAccount() {
        this.rappComptePsDataRaw = new RappComptePsDataRaw();
        this.rappComptePsDataRaw.idRappComptePs = this.oxlinUserConnected.idRappComptePs;
        this.rappComptePsDataRaw.dernierRefresh = this.oxlinUserConnected.dernierRefresh;
        this.toastr.success('Synchronisation des transactions en cours', 'Rapprochement automatique');

        this.transactionService.synchronizeOxlinTransactions(this.rappComptePsDataRaw)
            .subscribe((nbrTransactionInsere) => {
                this.updateRappComptePs();

                if (nbrTransactionInsere > 0) {
                    //  localStorage.setItem('careweb_rapp_user', JSON.stringify(rComptePs));
                    //  this.oxlinUserConnected = rComptePs;
                    this.toastr.success(nbrTransactionInsere + ' transactions importées', 'Rapprochement automatique');
                    this.toastr.success('Synchronisation effectuée avec succès', 'Rapprochement automatique');
                    this.launchSearch();
                } else {
                    // TODO Verifier si besoin : localStorage.setItem('careweb_rapp_user', JSON.stringify(rComptePs));
                    // this.oxlinUserConnected = rComptePs;
                    //console.log(JSON.stringify(rComptePs));
                    this.toastr.success('Pas de nouvelles transactions sur vos comptes', 'Rapprochement automatique');
                }
            },
                (error) => {
                    this.toastr.error("Une erreur s'est produite lors de synchronisation des nouvelles transactions", 'Rapprochement automatique');
                }
            );
    }

    formatCellRspVrt() {
        return { textAlign: 'right', borderRight: 'solid 1px #d9dcde', display: 'flex', justifyContent: 'center' };
    }

    formatCellDateOperation() {
        return { textAlign: 'center', borderRight: 'solid 1px #d9dcde', display: 'flex', justifyContent: 'center' };
    }

    getDetailsRapprochement() {
        return {
            headerName: '',
            field: 'options',
            suppressCellSelection: true,
            minwidth: 50,
            maxWidth: 50,
            headerClass: 'ag-header-merge',
            cellRenderer: RappAutoPreviewcomponent,
            onCellClicked: (params) => this.onDetailClicked(params),
            lockVisible: true,
            cellStyle: this.gridHelper.centerContent()
        };
    }

    dissociateRapprochementAuto() {
        return {
            headerName: '',
            field: 'unlink',
            suppressCellSelection: true,
            //width: 30,
            headerClass: 'ag-header-merge',
            suppressSizeToFit: true,
            cellRenderer: RappAutoPreviewcomponent,
            onCellClicked: (params) => this.onDetachRappClicked(params),
            lockVisible: true,
            cellStyle: this.gridHelper.centerContent()
        };
    }

    getCount(field: string) {
        return {
            headerName: 'Nb.',
            field: field,
            suppressCellSelection: true,
            width: 80,
            headerClass: 'ag-header-merge',
            cellRenderer: RappAutoCountComponent,
            onCellClicked: (params) => this.onDetailClicked(params),
            lockVisible: true,
            cellStyle: this.gridHelper.centerContent(),
            sortable: true,
            suppressSizeToFit: true
        };
    }

    formatDate = (params): string => {
        if (params.value != 'MULTI' && params.value != 'PAS DE RSP')
            return this.datePipe.transform(params.value, 'shortDate');
        else return params.value;
    };

    formatCurrencyRapp = (params): string => {
        if (params.data) {
            return this.formatThousands.transform(params.value);
        }
    };

    formatCurrency = (params): string => {
        if (params.data) {
            return this.formatThousands.transform(parseFloat(params.value) / 100);
        }
    };

    updateFilterList(filters) {
        const ctx = this;
        ctx.filterList = [];
        this.initRspMontant();
        this.initVrtMontant();
        Object.keys(filters).forEach((key) => {
            if (key === 'montantRspFilter' || key === 'montantVrtFilter' ) {
                return;
            }
            if (filters[key] !== null && filters[key] !== '') {
                ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
            }
        });
        // Remove duplicated ids
        ctx.filterList = uniqBy(ctx.filterList, 'id');
        // filter null
        ctx.filterList = ctx.filterList.filter((item) => {
            return item.id != null;
        });
    }

    translateFilterLabelValue(key, value) {
        let translatedKey = key;
        let formattedValue = value;
        let formattedKey = key;
        switch (key) {
            case 'label':
                translatedKey = 'Libellé virement contient';
                break;
            case 'organismeLibelle':
                translatedKey = 'Libellé organisme contient';
                break;
            case 'periode':
                translatedKey = 'Période de rapprochement';
                formattedValue = value + ' derniers jours';
                break;
            case 'montantTotalRspExacte':
                translatedKey = 'Montant RSP';
                formattedValue = value + ' €';
                break;
            case 'montantTotalRspFrom':
                translatedKey = 'Montant RSP supérieur ou égal à';
                formattedValue = value + ' €';
                break;
            case 'montantTotalRspTo':
                translatedKey = 'Montant RSP inférieur ou égal à';
                formattedValue = value + ' €';
                break;
            case 'montantTotalTrExacte':
                translatedKey = 'Montant VRT ';
                formattedValue = value + ' €';
                break;
            case 'montantTotalTrFrom':
                translatedKey = 'Montant VRT supérieur ou égal à';
                formattedValue = value + ' €';
                break;
            case 'montantTotalTrTo':
                translatedKey = 'Montant VRT inférieur ou égal à';
                formattedValue = value + ' €';
                break;
            case 'montantDiff':
                translatedKey = 'Montant diff. RSP/VRT';
                formattedValue = value ? 'Oui' : 'Non';
                break;
            case 'idOrganisme':
                translatedKey = 'Code organisme émetteur';
                break;
            case 'libelleVirement1':
                translatedKey = 'Libellé de virement';
                break;
            default:
                break;
        }
        return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
    }

    onKeyDown(event) {
        this.launchSearch();
    }

    removeFilter(id) {
        const ctx = this;
        delete ctx.filters[id];
        let currentFilterList = ctx.filterList;
        const index = findIndex(currentFilterList, (filt) => filt.id === id);
        currentFilterList.splice(index, 1);
        ctx.filterList = currentFilterList;
        this.fillFilterObjectFromTagsOnly();
        ctx.launchSearch();
    }

    fillFilterObjectFromTagsOnly() {
        this.filters = new RappTransactionFilterRaw();
        this.filterList.forEach(filter => {
            this.filters[filter.id] = filter.value2;
        });
    }

    /* ----------------  CONFIRM MODAL -------------- */

    closeModal() {
        this.showConfirmModal = false;
    }

    public showModal(showModal: boolean) {
        this.showConfirmModal = showModal;
    }

    public confirmModal() {
        this.validateSelectedTransactions();
        this.showConfirmModal = false;
    }

    public openModalConfim() {
        var selectedRows = this.gridHelper.gridApi.getSelectedRows();
        var montantDiffSelected = false;

        selectedRows.forEach((row) => {
            if (row.montantTotalRsp != row.montantTotalTr) {
                montantDiffSelected = true;
            }
        });

        if (montantDiffSelected) {
            this.modalMessage =
                "Certains rapprochements automatiques n'ont pas les mêmes montants. Voulez-vous les valider ?";
            this.showConfirmModal = true;
        } else {
            this.validateSelectedTransactions();
            this.showConfirmModal = false;
        }
    }

    ngOnDestroy() {
        console.log("destroy called")
        sessionStorage['rapproAutoFilter'] = JSON.stringify(this.filters);
        this.gridHelper.saveGridStatePrefix();
        this.gridHelperRSP.saveGridStatePrefix();
        this.gridHelperVRT.saveGridStatePrefix();
    }

    onPaginationSizeChange(paginationSize: number): void {
        this.gridHelper.changePaginationSize(paginationSize);
        this.launchSearch();
        this.gridHelper.saveGridStatePrefix();
    }

    private getServerDataSource(): IServerSideDatasource {
        const ctx = this;
        return {
            getRows: function (paramsRows: IServerSideGetRowsParams) {
                const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
                ctx.rapprochementComptePsService
                    .getRapprochementsFilteredList(
                        {
                            page: ctx.gridHelper.gridApi?.paginationGetCurrentPage() || 0,
                            size: ctx.gridHelper.gridApi.paginationGetPageSize(),
                            sorts
                        },
                        ctx.rapprochementDataRaw
                    )
                    .subscribe(
                        (data) => {
                            const rowCount = data.totalElements;
                            ctx.gridHelper.manageNoRowsOverlay(rowCount);
                            paramsRows.success({ "rowData": data.content, "rowCount": rowCount });
                        },
                        () => paramsRows.fail()
                    );
            }
        };
    }

    private updateRappComptePs(): void {
        this.rapprochementComptePsService.getRapprochementComptePsById(this.getPsId())
            .subscribe((data: RappComptePsDataRaw) => {
                localStorage.setItem('careweb_rapp_user', JSON.stringify(data));
                this.oxlinUserConnected = data;
            })
    }

    

    private getTotalNewTx(): void {
        this.totalImportedVrtAlert = null;
        this.ebicsService.getTotalNewTx(this.getPsId(), this.requestIds).then(
            (total) => {
                clearInterval(this.interval);
                this.getDateDerniereSynchro();
                this.launchSearch();
                this.totalImportedVrtAlert = total;
            }

        ).catch((error) => {
            console.log(error)
            clearInterval(this.interval);
        })
    }

    onColumnChanged(params: any): void {
        this.gridHelper.saveGridColumnState();
    }

    onRspPaginationSizeChange(paginationSize: number): void {
        this.gridHelperRSP.changePaginationSize(paginationSize);
        this.gridHelperRSP.gridApi.setRowData(this.selectedRow.rsps);
        this.gridHelperRSP.saveGridStatePrefix();
    }

    onVrtPaginationSizeChange(paginationSize: number): void {
        this.gridHelperVRT.changePaginationSize(paginationSize);
        this.gridHelperVRT.gridApi.setRowData(this.selectedRow.bankTransactions);
        this.gridHelperVRT.saveGridStatePrefix();
    }

    resetRspGridState() {
        this.gridHelperRSP.resetGridState();
        this.gridDataRawRsp.size = this.gridHelperRSP.defaultPaginationPageSize;
    }

    resetVrtGridState() {
        this.gridHelperVRT.resetGridState();
        this.gridDataRawVrt.size = this.gridHelperVRT.defaultPaginationPageSize;
    }

    onChoiceChange($event: any, type: string): void {
        if (type === 'montantTotalRsp') {
            this.filters.montantRspFilter = $event.filterChoice === '' ? null : $event.filterChoice;
        } else {
            this.filters.montantVrtFilter = $event.filterChoice === '' ? null : $event.filterChoice;
        }
    }

    formatRoRc(params, key) {
        let total = params?.signe === 0 ? params?.cumulMontantFacture * -1 : params?.cumulMontantFacture
        let ro = params?.montantRo ? parseFloat(params.montantRo) / 100 : 0
        let rc = params?.montantRc ? parseFloat(params.montantRc) / 100 : 0
        switch (key) {
            case 'montantRc': { return ro + rc * -1 === total || ro * -1 + rc * -1 === total ? (rc * -1).toString() : rc.toString(); break; }
            case 'montantRo': { return rc + ro * -1 === total || ro * -1 + rc * -1 === total ? (ro * -1).toString() : ro.toString(); break; }
        }
    }

    protected readonly isNullOrUndefined = isNullOrUndefined;
}
