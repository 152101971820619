<div class="modal modal-show fade show" id="modalPslogiciel" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header pb-1">
          <h3 class="modal-title">{{title}}</h3>
          <button class="close" name="btn_display_filter" id="hide_Modal" data-dismiss="modal" type="button" title="Fermer"
            (click)="closeModal()"><span aria-hidden="true">×</span></button>
        </div>



        <div class="modal-body pb-0 mt-4" *ngIf="!isDeleteModale">

          <div class="container bg-white" *ngIf="dictRuleCpam && dictRuleCpam.occurrenceVrt && dictRuleCpam.occurrenceOrg">

            <h3>Informations d'analyse</h3>

            <div class="alert alert-info">
              <p>Nous vous proposons ces suggestions de mots clés CPAM <b>"{{dictRuleCpam?.motCleLabelVrt}}"</b> et
                <b>"{{dictRuleCpam?.motCleLabelV1}}"</b> sur la base d'une analyse portant sur les informations
                suivantes
                :
              </p>
              <ul>
                <li>
                  Nombre d'organismes : <b>{{dictRuleCpam?.occurrenceOrg}}</b>
                <li>
                  Nombre de rapprochements : <b>{{dictRuleCpam?.occurrenceVrt}}</b></li>
              </ul>
            </div>

            <h3>Liste des VRT analysés pour établir la suggestion</h3>

            <div class="table-responsive table-fixed-height">
              <table class="table ui-grid-like">
                <thead class="table-header">
                  <tr>
                    <th>Libellé</th>
                    <th>Numéro PS</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let vrt of bankTransactions">
                    <td container="body" containerClass="tooltip-grid"
                      class="overflow-hidden text-truncate rappro-tooltip" placement="bottom"
                      [tooltip]="vrt.analyseLabel">
                      {{vrt.analyseLabel?.substring(0,60)}}<span *ngIf="vrt.analyseLabel.length > 60">[...]</span>
                    </td>
                    <td container="body" containerClass="tooltip-grid"
                      class="overflow-hidden text-truncate rappro-tooltip" placement="bottom">
                      {{vrt.numeroAdeli}}
                    </td>
                    <td>{{vrt.dateComptable | date:'shortDate'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div *ngIf="showConfirmAlert" class="alert alert-warning">
              <p><b>Êtes-vous sûr de vouloir nettoyer les métadonnées ?</b></p>
              Cette action est irréversible, et les métadonnées ne seront plus accessibles.
              <div class="modal-footer">
                <button type="button" id="closeModal" (click)="showConfirmAlert = false" class="btn btn-default">Annuler</button>
                <button type="button" id="submit" (click)="clearMetadata()" class="btn btn-primary">Nettoyer</button>
              </div>
              <button (click)="showConfirmAlert = false" id="hide_alert" type="button" class="close position-absolute" style="top: 10px; right: 10px;" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <button *ngIf="!showConfirmAlert" type="button" (click)="showConfirmAlert = true" id="id_clear" class="btn btn-primary float-right">Nettoyer les métadonnées</button> 
          </div>



          <div class="container bg-white">
            <h3 *ngIf="dictRuleCpam && !dictRuleCpam.enabled">Ajouter la règle</h3>

            <div class="alert alert-danger alert-dismissible fade show position-relative" id="oldRuleKeyLabel" role="alert"
            *ngIf="oldRuleKeyLabel !== ''">
            Le "mot clé de virement" <strong>{{oldRuleKeyLabel}}</strong> est déjà ajouté au dictionnaire.
            <button type="button" id="KeyLabel" class="close position-absolute top-0 end-0 me-2 mt-2" data-dismiss="alert" aria-label="Close"
              (click)="resetOldRuleKeyLabel()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="alert alert-danger alert-dismissible fade show position-relative" id="oldRuleKeyLabelVirement1" role="alert"
            *ngIf="oldRuleKeyLabelVirement1 !== '' ">
            Le "mot clé du Libellé de VRT 1 des RSP" <strong>{{oldRuleKeyLabelVirement1}}</strong> est déjà ajouté au
            dictionnaire.
            <button type="button" id="KeyLabelVirement1" class="close position-absolute top-0 end-0 me-2 mt-2" data-dismiss="alert" aria-label="Close"
              (click)="resetOldRuleKeyLabelVirement()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

            <form [formGroup]="dictRuleCpamForm" class="col-12">
              <div class="row">
                <div class="col-4 col-form-label">Mot clé virement <span style="color:red">*</span> : </div>
                <div class="col-8 form-group">
                  <input type="text" id="label_vrt" class="form-control" formControlName="motCleLabelVrt" required
                    [ngClass]="{ 'is-invalid': isDictRuleFormSubmitted && dictRuleFormFields.motCleLabelVrt.errors }" />
                  <div *ngIf="isDictRuleFormSubmitted && dictRuleFormFields.motCleLabelVrt.errors"
                    class="invalid-feedback">
                    <div>
                      Ce champ est obligatoire
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4 col-form-label"> Mot clé du "Libellé de VRT 1" des RSP <span
                    style="color:red">*</span> : </div>
                <div class="col-8 form-group">
                  <input type="text" id="label_vrt1" class="form-control" formControlName="motCleLabelV1" required
                    [ngClass]="{ 'is-invalid': isDictRuleFormSubmitted && dictRuleFormFields.motCleLabelV1.errors}" />
                  <div *ngIf="isDictRuleFormSubmitted && dictRuleFormFields.motCleLabelV1.errors"
                    class="invalid-feedback">
                    <div>
                      Ce champ est obligatoire
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="modal-body pb-0" *ngIf="isDeleteModale">
          <div class="form-block">
            <fieldset class="container-fluid">
              <div class="row">
                <div class="col-sm-12 col-form-label">Etes-vous sûr de vouloir supprimer la règle ?</div>
              </div>
            </fieldset>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" id="closeModal" (click)="closeModal()" class="btn btn-default">Annuler</button>
          <button type="button" id="submit" (click)="onSubmit()" class="btn btn-primary"
            *ngIf="!isDeleteModale">Enregistrer</button>
          <button type="button" id="submit" (click)="onSubmit()" class="btn btn-primary" *ngIf="isDeleteModale">Supprimer</button>
        </div>

      </div>
    </div>
  </div>

</div>
