<div class="x-large-careweb-container">
    <form [formGroup]="editeurForm">
        <section class="form-content">
            <div class="row form-title">
                <h2 class="col-md-6">
                    Éditeur {{selectedEditeur.raisonSociale}}
                </h2>

                <div class="col-md-6 text-title">
                    <button class="btn btn-default btn-lg" type="button" (click)="goToEditeur()">Retour</button>

                </div>
            </div>
            <div class="form-block">
                <div class="row">
                    <div class="col">
                        <h3 class="title-border">Détails</h3>
                    </div>
                </div>
                <fieldset class="container-fluid">
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Raison sociale</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" readonly name="raisonSociale"
                                formControlName="raisonSociale" onKeyPress="if(this.value.length==50) return false;"
                                onpaste="if(this.value.length==50) return false;" maxlength="50" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Adresse 1</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="adresse1" formControlName="adresse1"
                                onKeyPress="if(this.value.length==50) return false;"
                                onpaste="if(this.value.length==50) return false;" maxlength="50" /></div>
                        <div class="col-sm-2 col-form-label">Adresse 2</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="adresse2" formControlName="adresse2"
                                onKeyPress="if(this.value.length==50) return false;"
                                onpaste="if(this.value.length==50) return false;" maxlength="50" /></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Code postal</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="codePostal" formControlName="codePostal"
                                   cawCodePostal /></div>
                        <div class="col-sm-2 col-form-label">Ville</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="ville" formControlName="ville"
                                onKeyPress="if(this.value.length==50) return false;"
                                onpaste="if(this.value.length==50) return false;" maxlength="50" /></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Téléphone</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="telephone" formControlName="telephone"
                                   phoneNumberDirective />
                        </div>
                        <div class="col-sm-2 col-form-label">Fax</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="fax" formControlName="fax"
                                   phoneNumberDirective />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 col-form-label">Email</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" id="email" name="email"
                                (paste)="$event.preventDefault()" formControlName="email"
                                onKeyPress="if(this.value.length==100) return false;"
                                [ngClass]="{ 'is-invalid': editeurFormSubmitted && editeurFormFields.email.errors }"
                                onpaste="if(this.value.length==100) return false;" maxlength="100" carewebEmail />
                            <div *ngIf="editeurFormSubmitted && editeurFormFields.email.errors"
                                class="invalid-feedback">
                                Le format de l'adresse email n'est pas valide
                            </div>
                        </div>
                        <div class="col-sm-2 col-form-label">Site internet</div>
                        <div class="col-sm-4 form-group">
                            <input type="text" class="form-control" name="url" formControlName="url"
                                onKeyPress="if(this.value.length==100) return false;"
                                onpaste="if(this.value.length==100) return false;" maxlength="100" /></div>
                    </div>
                </fieldset>
                <div class="row grid-top-btn" style="margin-top:0px">
                    <div class="col-md-12 conf-col">
                        <div class="sy-toolbar">
                            <div>
                                <button type="button" class="btn btn-default me-3" (click)="reinitialiserEditeur()">
                                    Annuler
                                </button>
                                <button type="button" class="btn btn-primary" (click)="enregistrerEditeur()">
                                    Enregister
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="form-block">
                <div class="row">
                    <div class="col">
                        <h3 class="title-border">
                            <div class="row">
                                <div class="col-md-10">Liste des logiciels</div>
                                <div class="col-md-2" *ngIf="logicielList.length == 1">{{logicielList.length}} Logiciel
                                </div>
                                <div class="col-md-2" *ngIf="logicielList.length > 1">{{logicielList.length}} Logiciels
                                </div>
                            </div>
                        </h3>
                    </div>
                </div>
                <div class="row grid-top-btn" style="margin-top:0px">
                    <div class="col-md-12 conf-col">
                        <div class="sy-toolbar">
                            <div>
                                <button type="button" class="btn btn-primary" (click)="openModalAddLogiciel()">
                                    Rattacher un logiciel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="table-responsive">
                            <table *ngIf="logicielList.length > 0" class="ui-grid-like">
                                <thead>
                                    <tr>
                                        <th>Libellé</th>
                                        <th>Système d'exploitation</th>
                                        <th>Version</th>
                                        <th style="width:50px"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let logiciel of logicielList">
                                        <td>{{logiciel.libelle}}</td>
                                        <td>{{logiciel.systeme}}</td>
                                        <td>{{logiciel.version}}</td>
                                        <td class="text-center" style="font-size: 1rem">
                                            <a (click)="openModalConfim('logiciel',logiciel)" class="text-gray"
                                                data-placement="top" title="Supprimer le logiciel">
                                                <i class="fa fa-trash gridCellStyle" container="body">
                                                </i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-block">
                <div class="row">
                    <div class="col">
                        <h3 class="title-border">
                            <div class="row">
                                <div class="col-md-10">Liste des contacts</div>
                                <div class="col-md-2" *ngIf="contactsList.length == 1">{{contactsList.length}} contact
                                </div>
                                <div class="col-md-2" *ngIf="contactsList.length > 1 ">{{contactsList.length}} contacts
                                </div>
                            </div>
                        </h3>
                    </div>
                </div>
                <div class="row grid-top-btn" style="margin-top:0px">
                    <div class="col-md-12 conf-col">
                        <div class="sy-toolbar">
                            <div>
                                <button type="button" class="btn btn-primary" (click)="openModalAddContact(null)">
                                    Ajouter un contact
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="table-responsive">
                            <table *ngIf="contactsList.length > 0" class="ui-grid-like">
                                <thead>
                                    <tr>
                                        <th style="width:50px">Civilité</th>
                                        <th style="min-width:100px">Nom</th>
                                        <th style="min-width:100px">Prénom</th>
                                        <th style="min-width:100px">Téléphone</th>
                                        <th style="min-width:100px">Fax</th>
                                        <th style="min-width:100px">Mobile</th>
                                        <th style="min-width:150px">Email</th>
                                        <th style="width:50px"></th>
                                        <th style="width:50px"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let contact of contactsList">
                                        <td>{{contact.civilite.libelleCourt}}</td>
                                        <td>{{contact.nom}}</td>
                                        <td>{{contact.prenom}}</td>
                                        <td>{{formatPhoneNumbers(contact.telephone)}}</td>
                                        <td>{{contact.fax}}</td>
                                        <td>{{contact.mobile}}</td>
                                        <td>{{contact.mail}}</td>
                                        <td class="text-center" style="font-size: 1rem">
                                            <a (click)="openModalAddContact(contact)" class="text-gray"
                                                title="Modifier le contact" container="body" data-placement="top">
                                                <i class="fa fa-edit gridCellStyle">
                                                </i>
                                            </a>
                                        </td>
                                        <td class="text-center" style="font-size: 1rem">
                                            <a (click)="openModalConfim('contact',contact)" class="text-gray"
                                                title="Supprimer le contact" data-placement="top">
                                                <i class="fa fa-trash gridCellStyle" container="body">
                                                </i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </form>
</div>
<!-- Modal logiciel add -->
<div *ngIf="showAddLogicielModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalAddLogiciel" tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 class="modal-title">Rattacher un logiciel</h3>
                        <button class="close" (click)="closeModal()" name="btn_display_filter" type="button"
                            title="Fermer"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body pb-0">
                        <div class="form-block">
                            <ul class="nav nav-tabs">
                                <li class="nav-item"><a (click)="showRattacherLogiciel('existingSoft')"
                                        [ngClass]="{'active': showExistingSoft}" class="nav-link"
                                        data-toggle="tab">Logiciel existant</a></li>
                                <li class="nav-item"><a (click)="showRattacherLogiciel('newSoft')"
                                        [ngClass]="{'active': showNewSoft}" class="nav-link" data-toggle="tab">Nouveau
                                        logiciel</a></li>
                            </ul>

                            <div id="myTabContent" class="tab-content">
                                <div id="existingSoft" [ngClass]="{'show active': showExistingSoft}"
                                    class="tab-pane fade">
                                    <fieldset class="container-fluid">
                                        <ag-grid-angular style="width: 100%;" class="ag-theme-material"
                                            [gridOptions]="gridHelperLogiciel.gridOptions">
                                        </ag-grid-angular>
                                        <careweb-pagination [gridApi]="gridHelperLogiciel.gridApi"></careweb-pagination>
                                    </fieldset>
                                </div>
                            </div>
                        </div>

                        <div id="myTabContent" class="tab-content">
                            <div id="newSoft" [ngClass]="{'show active': showNewSoft}" class="tab-pane fade">
                                <div class="form-block">
                                    <form [formGroup]="logicielForm" (ngSubmit)="ajouterLogiciel()">
                                        <fieldset class="container-fluid">
                                            <div class="row">
                                                <div class="col-sm-3 col-form-label">Libellé *</div>
                                                <div class="col-sm-9 form-group">
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="newLogiciel.libelle" maxlength="50"
                                                        formControlName="libelle" required
                                                        [ngClass]="{ 'is-invalid': logicielFormSubmitted && logicielFormFields.libelle.errors }" />
                                                    <div *ngIf="logicielFormSubmitted && logicielFormFields.libelle.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="logicielFormFields.libelle.errors.required">
                                                            Ce champ est obligatoire</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-3 col-form-label">Système d'exploitation</div>
                                                <div class="col-sm-9 form-group">
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="newLogiciel.systeme" maxlength="30"
                                                        formControlName="systeme" />

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-3 col-form-label">Version</div>
                                                <div class="col-sm-9 form-group">
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="newLogiciel.version" maxlength="10"
                                                        formControlName="version" />

                                                </div>
                                            </div>
                                            <div class="control mb-3 row">
                                                <div class="col-6 mt-3">

                                                </div>
                                                <div class="button-bar col-6">
                                                    <button type="button" (click)="reinitialiserLogicielForm()"
                                                        class="btn btn-default">
                                                        Annuler </button>
                                                    <button type="submit" class="btn btn-primary">
                                                        Ajouter </button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>
</div>
<!-- Modal contact -->
<div *ngIf="showAddContactModal">
    <div class="modal-backdrop"></div>
    <div class="modal modal-show fade show" id="modalAddContact" tabindex="-1">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="inmodal">
                    <div class="modal-header pb-1">
                        <h3 [hidden]="isUpdateContact" class="modal-title">Nouveau contact</h3>
                        <h3 *ngIf="isUpdateContact" class="modal-title">Modification contact</h3>
                        <button class="close" (click)="closeModal()" name="btn_display_filter" type="button"
                            title="Fermer"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body pb-0">
                        <h3 class="title-border mt-0">Éditeur de référence - {{selectedEditeur.raisonSociale}}</h3>
                        <div class="form-block">
                            <form [formGroup]="contactForm" (ngSubmit)="enregistrerContact()">
                                <fieldset class="container-fluid">
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">Civilité *</div>
                                        <div class="col-sm-4 form-group">
                                            <div>
                                                <div style="display:inline-block; margin-right:20px">
                                                    <input type="radio" name="civilite" selected="true" value="Mr"
                                                        formControlName="civilite" />M
                                                </div>
                                                <div style="display:inline-block">
                                                    <input type="radio" name="civilite" value="Mme"
                                                        formControlName="civilite" />Mme
                                                </div>

                                                <div *ngIf="contactFormSubmitted && contactFormFields.civilite.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="contactFormFields.civilite.errors.required">
                                                        Ce champ est obligatoire</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">Nom *</div>
                                        <div class="col-sm-4 form-group">
                                            <input required type="text" class="form-control" name="nom" maxlength="50"
                                                formControlName="nom"
                                                [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormFields.nom.errors }" />
                                            <div *ngIf="contactFormSubmitted && contactFormFields.nom.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="contactFormFields.nom.errors.required">
                                                    Ce champ est obligatoire</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2 col-form-label">Prénom *</div>
                                        <div class="col-sm-4 form-group">
                                            <input required type="text" class="form-control" name="prenom"
                                                maxlength="50" formControlName="prenom"
                                                [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormFields.prenom.errors }" />
                                            <div *ngIf="contactFormSubmitted && contactFormFields.prenom.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="contactFormFields.prenom.errors.required">
                                                    Ce champ est obligatoire</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">Téléphone *</div>
                                        <div class="col-sm-4 form-group">
                                            <input required type="text" class="form-control" name="telephone" formControlName="telephone"
                                                   [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormFields.telephone.errors }"
                                                   phoneNumberDirective />
                                            <div *ngIf="contactFormSubmitted && contactFormFields.telephone.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="contactFormFields.telephone.errors.required ">
                                                    Ce champ est obligatoire</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2 col-form-label">Fax</div>
                                        <div class="col-sm-4 form-group">
                                            <input type="text" class="form-control" name="fax" formControlName="fax"
                                                   phoneNumberDirective />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-2 col-form-label">Mobile</div>
                                        <div class="col-sm-4 form-group">
                                            <input type="text" class="form-control" name="mobile" formControlName="mobile"
                                                   phoneNumberDirective />
                                        </div>
                                        <div class="col-sm-2 col-form-label">Email</div>
                                        <div class="col-sm-4 form-group">
                                            <input type="text" class="form-control" name="emailContact"
                                                id="emailContact" (paste)="$event.preventDefault()" maxlength="100"
                                                [ngClass]="{ 'is-invalid': contactFormSubmitted && contactFormFields.mail.errors }"
                                                formControlName="mail" carewebEmail />
                                            <div *ngIf="contactFormSubmitted && contactFormFields.mail.errors"
                                                class="invalid-feedback">
                                                Le format de l'adresse email n'est pas valide
                                            </div>
                                        </div>
                                    </div>
                                    <div class="control row">
                                        <div class="col-6 mt-3">

                                        </div>
                                        <div class="button-bar col-6">
                                            <button type="button" (click)="reinitialiserContact()"
                                                class="btn btn-default"> Annuler
                                            </button>
                                            <button type="button" type="submit" class="btn btn-primary">
                                                <span *ngIf="!isUpdateContact">Ajouter</span>
                                                <span *ngIf="isUpdateContact">Valider</span>
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal confirmation de suppression -->
<careweb-confirm-modal (confirmModal)="confirmModal()" (showModal)="showModal($event)" [(visible)]="showConfirmModal"
    [text]="modalMessage">
</careweb-confirm-modal>