import { Routes } from '@angular/router';
import { ContactSupportComponent } from '../../pages/contact/contact-support.component';
import { DashboardAdminComponent } from '../../pages/dashboard-admin/dashboard-admin.component';
import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { AjoutEditeurComponent } from '../../pages/editeurs/ajout-editeur/ajout-editeur.component';
import { EditeursAccessGuard } from '../../pages/editeurs/editeurs-access.guard';
import { EditorsComponent } from '../../pages/editeurs/editeurs.component';
import { FicheEditeurComponent } from '../../pages/editeurs/fiche-editeur/fiche-editeur.component';
import { ARLComponent } from '../../pages/flux/arl/arl.component';
import { ComptabiliteComponent } from '../../pages/flux/comptabilite/comptabilite.component';
import { FicheFSEComponent } from '../../pages/flux/fse/fiche-fse/fiche-fse.component';
import { FSEComponent } from '../../pages/flux/fse/fse.component';
import { LoiComponent } from '../../pages/flux/loi/loi.component';
import { FicheLotComponent } from '../../pages/flux/lots/fiche-lot/fiche-lot.component';
import { LotsComponent } from '../../pages/flux/lots/lots.component';
import { RetoursEditablesComponent } from '../../pages/flux/retours-editables/retours-editables.component';
import { FicheRSPComponent } from '../../pages/flux/rsp/fiche-rsp/fiche-rsp.component';
import { RSPComponent } from '../../pages/flux/rsp/rsp.component';
import { HotlineDashboardComponent } from '../../pages/hotline/hotline-dashboard/hotline-dashboard.component';
import { HotlineEditComponent } from '../../pages/hotline/hotline-edit/hotline-edit.component';
import { HotlineParametersComponent } from '../../pages/hotline/hotline-parameters/hotline-parameters.component';
import { HotlineComponent } from '../../pages/hotline/hotline.component';
import { InterventionComponent } from '../../pages/hotline/intervention/intervention.component';
import { IsOctAllowedAccessGuard } from '../../pages/organismes/is-oct-allowed-access.guards';
import { OrganismeIsAuthAccessGuard } from '../../pages/organismes/organisme-is-auth-access.guard';
import { FicheOrganismeComponent } from '../../pages/organismes/organismes/fiche-organisme/fiche-organisme.component';
import { NewOrganismeComponent } from '../../pages/organismes/organismes/new-organisme/new-organisme.component';
import { OrganismesComponent } from '../../pages/organismes/organismes/organismes.component';
import { RegroupementsComponent } from '../../pages/organismes/regroupements/regroupements.component';
import { FicheTypeZeroComponent } from '../../pages/organismes/Type0/fiche-type0/fiche-type0.component';
import { TypeZeroComponent } from '../../pages/organismes/Type0/type0.component';
import { FichePsComponent } from '../../pages/ps/fiche-ps/fiche-ps.component';
import { PsUserComponent } from '../../pages/ps/fiche-ps/ps-user/ps-user.component';
import { PsIsAuthAccessGuard } from '../../pages/ps/ps-is-auth-access.guard';
import { PSComponent } from '../../pages/ps/ps.component';
import { RappAutoComponent } from '../../pages/rapprochement/rapp-auto/rapp-auto.component';
import { RappBankAccountComponent } from '../../pages/rapprochement/rapp-bank-account/rapp-bank-account.component';
import { RappDashboardComponent } from '../../pages/rapprochement/rapp-dashboard/rapp-dashboard.component';
import { RappErrorMessageComponent } from '../../pages/rapprochement/rapp-error-message/rapp-error-message.component';
import { RappManuelComponent } from '../../pages/rapprochement/rapp-manuel/rapp-manuel.component';
import { RappValideComponent } from '../../pages/rapprochement/rapp-valide/rapp-valide.component';
import { UpdatePasswordComponent } from '../../pages/update-password/update-password.component';
import { UserProfileComponent } from '../../pages/user-profile/user-profile.component';
import { FicheUtilisateurOCTComponent } from '../../pages/users/fiche-utilisateurOCT/fiche-userOCT.component';
import { UsersComponent } from '../../pages/users/users.component';
import { serviceDirectoryComponent } from '../../pages/sel/service-directory/service-directory.component';
import { FicheOrgStateComponent } from '../../pages/sel/organization-state/fiche-org-state/fiche-org-state.component';
import { OrganizationStateComponent } from '../../pages/sel/organization-state/organization-state.component';
import { TracabilityComponent } from '../../pages/sel/tracability/tracability.component';

import { MentionsLegalesComponent } from '../../pages/reglementaire/mentions-legales/mentions-legales.component';
import { ConditionsUtilisationComponent } from '../../pages/reglementaire/conditions-utilisation/conditions-utilisation.component';
import { UnknownPageComponent } from '../../pages/unknown-page/unknown-page.component';
import { NouveautesComponent } from '../../pages/nouveautes/nouveautes.component';
import { RappAccessGuard } from '../../pages/rapprochement/rapp-access.guard';
import { SelAccessGuard } from '../../core/guards/sel-access.guard';
import { IsNotAdminSelAccessGuard } from '../../core/guards/is_not_admin_sel-access.guard';
import { IsNotPsGuard } from '../../core/guards/is_not_ps.guard';
import { DictRuleRappComponent } from '../../pages/dict-rule-rapp/dict-rule-rapp.component';
import { LogsRapprochementComponent } from '../../pages/logs-rapprochement/logs-rapprochement.component';
import {RejetComponent} from "../../pages/flux/rsp/rejets-rsp/rejet.component";
import {ReportingComponent} from "../../pages/reporting/reporting.component";
import {MouvementsFinanciersComponent} from "../../pages/flux/mouvements-financiers/mouvements.financiers.component";
import { NotFoundComponent } from '../../pages/not-found/not-found.component';
import { MigrationOctComponent } from '../../pages/migration-oct/migration-oct.component';
import { MigrationOctGuard } from '../../core/guards/migration_oct.guard';
import { CardEbicsAbonnementComponent } from '../../pages/abonnement-ebics/card-ebics-abonnement/card-ebics-abonnement.component';
import {AbonnementEbicsComponent} from "../../pages/abonnement-ebics/abonnement-ebics.component";
import {
	EditAbonnementEbicsComponent
} from "../../pages/abonnement-ebics/edit-abonnement-ebics/edit-abonnement-ebics.component";
import { RappAccessEbicsGuard } from '../../pages/rapprochement/rapp-access-ebics.guard';
import {SuiviEbicsComponents} from "../../pages/suivi-ebics/suivi-ebics.components";
import {AdminContentManagement} from "../../pages/ contenus-plateforme/admin-content-management";
import { SuiviRappComponent } from '../../pages/suivi-rapp/suivi-rapp.component';
import { RappDashboardOrganismComponent } from '../../pages/suivi-rapp/rapp-dashboard-organism/rapp-dashboard-organism.component';

export const AdminLayoutRoutes: Routes = [
	{ path: 'dashboard', component: DashboardComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{ path: 'dashboard/:removeSession', component: DashboardComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{
		path: 'dashboard-admin',
		component: DashboardAdminComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{ path: 'user-profile', component: UserProfileComponent },
	{ path: 'ps', component: PSComponent, canActivate: [ IsNotPsGuard ] },
	{ path: 'add-ps', component: FichePsComponent, canActivate: [ PsIsAuthAccessGuard ] },
	{ path: 'ps-details/:id', component: FichePsComponent, canActivate: [ IsNotPsGuard ] },
	{ path: 'add-ps-user', component: PsUserComponent, canActivate: [ PsIsAuthAccessGuard ] },
	{ path: 'lots', component: LotsComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{ path: 'lots/:id', component: LotsComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{ path: 'lot-details/:id', component: FicheLotComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{ path: 'arl', component: ARLComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{ path: 'fse', component: FSEComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{ path: 'fse-details/:id', component: FicheFSEComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{ path: 'rsp', component: RSPComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{ path: 'rejet', component: RejetComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{ path: 'mouvements-financiers', component: MouvementsFinanciersComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{ path: 'rsp-details/:id', component: FicheRSPComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{ path: 'retours-editables', component: RetoursEditablesComponent, canActivate: [IsNotAdminSelAccessGuard ,IsOctAllowedAccessGuard ] },
	{ path: 'loi', component: LoiComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{ path: 'comptabilite', component: ComptabiliteComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{ path: 'organismes', component: OrganismesComponent, canActivate: [IsNotAdminSelAccessGuard] } /*  canActivate: [ IsOctAllowedAccessGuard ] */,
	{
		path: 'organisme-details/:id/:typeOrganisme',
		component: FicheOrganismeComponent, canActivate: [IsNotAdminSelAccessGuard] /* ,
		canActivate: [ IsOctAllowedAccessGuard ] */
	},
	{
		path: 'new-organisme',
		component: NewOrganismeComponent,
		canActivate: [ OrganismeIsAuthAccessGuard, IsOctAllowedAccessGuard, IsNotAdminSelAccessGuard ]
	},
	{
		path: 'type0',
		component: TypeZeroComponent,
		canActivate: [ OrganismeIsAuthAccessGuard, IsOctAllowedAccessGuard, IsNotAdminSelAccessGuard ]
	},
	{
		path: 'type0-details/:id',
		component: FicheTypeZeroComponent,
		canActivate: [ OrganismeIsAuthAccessGuard, IsOctAllowedAccessGuard, IsNotAdminSelAccessGuard ]
	},
	{
		path: 'new-type0',
		component: FicheTypeZeroComponent,
		canActivate: [ OrganismeIsAuthAccessGuard, IsOctAllowedAccessGuard, IsNotAdminSelAccessGuard ]
	},
	{ path: 'regroupements', component: RegroupementsComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{
		path: 'hotline',
		component: HotlineComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{
		path: 'add_intervention',
		component: InterventionComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{
		path: 'hotline-edit/:id',
		component: HotlineEditComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{
		path: 'hotline-parameters',
		component: HotlineParametersComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{ path: 'ajout-editeur', component: AjoutEditeurComponent, canActivate: [ EditeursAccessGuard, IsNotAdminSelAccessGuard ] },
	{ path: 'editeurs', component: EditorsComponent, canActivate: [ EditeursAccessGuard, IsNotAdminSelAccessGuard ] },
	{ path: 'editeur-details/:id', component: FicheEditeurComponent, canActivate: [ EditeursAccessGuard, IsNotAdminSelAccessGuard ] },
	{
		path: 'users',
		component: UsersComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{
		path: 'user-oct-details/:id',
		component: FicheUtilisateurOCTComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{
		path: 'user-oct-details',
		component: FicheUtilisateurOCTComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{ path: 'rapp-dashboard', component: RappDashboardComponent, canActivate: [ RappAccessGuard, IsNotAdminSelAccessGuard ] },
	{ path: 'rapp-auto', component: RappAutoComponent, canActivate: [ RappAccessGuard, IsNotAdminSelAccessGuard ] },
	{
		path: 'hotline-dashboard',
		component: HotlineDashboardComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{ path: 'update-password', component: UpdatePasswordComponent },
	{ path: 'contact-support', component: ContactSupportComponent },
	{ path: 'rapp-valide', component: RappValideComponent, canActivate: [ RappAccessGuard, IsNotAdminSelAccessGuard ] },
	{ path: 'rapp-manuel', component: RappManuelComponent, canActivate: [ RappAccessGuard, IsNotAdminSelAccessGuard ] },
	{ path: 'rapp-bank-account', component: RappBankAccountComponent, canActivate: [RappAccessGuard,IsNotAdminSelAccessGuard] },
	{ path: 'ebics-account', component: CardEbicsAbonnementComponent, canActivate: [RappAccessEbicsGuard,IsNotAdminSelAccessGuard] },
	{ path: 'rapp-error-message', component: RappErrorMessageComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{ path: 'rapp-error-message', component: RappErrorMessageComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{
		path: 'organization-list',
		component: OrganizationStateComponent,
		canActivate: [ SelAccessGuard ]
	},
	{
		path: 'service-list',
		component: serviceDirectoryComponent,
		canActivate: [ SelAccessGuard ]
	},
	{
		path: 'org-state-details/:label/:organizationNumber',
		component: FicheOrgStateComponent,
		canActivate: [ SelAccessGuard ]
	},
	{
		path: 'tracability-flux-list',
		component: TracabilityComponent,
		canActivate: [ SelAccessGuard ]
	},
	{ path: 'mentions-legales', component: MentionsLegalesComponent },
	{ path: 'conditions-utilisation', component: ConditionsUtilisationComponent },
	{ path: 'unknown-page', component: UnknownPageComponent },
	{ path: 'nouveautes', component: NouveautesComponent, canActivate: [IsNotAdminSelAccessGuard] },
	{
		path: 'suivi-rapp',
		component: SuiviRappComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{
		path: 'perf-rapp',
		component: RappDashboardOrganismComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{
		path: 'dict-rule-rapp',
		component: DictRuleRappComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{
		path: 'logs-rapprochement',
		component: LogsRapprochementComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{
		path: 'migration-oct',
		component: MigrationOctComponent,
		canActivate: [ PsIsAuthAccessGuard, MigrationOctGuard ]
	},
	{
		path: 'abonnements-ebics',
		component: AbonnementEbicsComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{
		path: 'abonnements-ebics/:id',
		component: EditAbonnementEbicsComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{
		path: 'new-abonnement-ebics',
		component: EditAbonnementEbicsComponent,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{
		path : 'reporting',
		component: ReportingComponent

	},
	{
		path: 'suivi-ebics',
		component: SuiviEbicsComponents,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{
		path: 'admin-content',
		component: AdminContentManagement,
		canActivate: [ PsIsAuthAccessGuard ]
	},
	{
		path:'404',
		component:NotFoundComponent
	}
];
