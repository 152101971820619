import { Directive, HostListener, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
    selector: '[cawCodePostal]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => CodePostalDirective), multi: true }
    ]
})
export class CodePostalDirective implements Validator {

    // Longueur maximale du code postal
    private readonly maxLength: number = 5;

    constructor() {}

    validate(control: AbstractControl): ValidationErrors | null {
        const value = control.value as string;
        if (value && (value.length > this.maxLength || !/^\d*$/.test(value))) {
            return { invalidCodePostal: true };
        }
        return null;
    }

    // Limite la saisie à 5 caractères et uniquement des chiffres
    @HostListener('input', ['$event'])
    onInput(event: Event): void {
        const input = (event.target as HTMLInputElement).value;
        const sanitized = this.sanitizeInput(input);
        this.updateInputValue(event.target as HTMLInputElement, sanitized);
    }

    // Gère l'événement de collage (paste)
    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent): void {
        event.preventDefault();
        const clipboardData = event.clipboardData?.getData('text') ?? '';
        const sanitized = this.sanitizeInput(clipboardData);
        this.updateInputValue(event.target as HTMLInputElement, sanitized);
    }

    // Sanitize la valeur : uniquement des chiffres, et limite à la longueur
    private sanitizeInput(value: string): string {
        return value.replace(/[^\d]/g, '').substring(0, this.maxLength);
    }

    private updateInputValue(inputElement: HTMLInputElement, value: string): void {
        inputElement.value = value;
    }
}
