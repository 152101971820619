import { GridSortModel } from './grid-sort-model';
import { Subscription } from 'rxjs';
import { GridApi, ColumnApi } from 'ag-grid-enterprise';

export class AgGridHelper {
	gridOptions;
	gridApi: GridApi;
	gridColumnApi: ColumnApi;
	paginationPageSize: number;
	translateSvcSubscription: Subscription;
	gridStatePrefix: string;
	defaultPaginationPageSize: number;
    isOverlay: boolean;
	constructor(gridStatePrefix: string = '', paginationPageSize: number = 10, defaultPaginationPageSize: number = 10) {
		this.paginationPageSize = paginationPageSize;
		this.defaultPaginationPageSize = defaultPaginationPageSize;
		this.gridStatePrefix = gridStatePrefix;
		if (this.gridApi) {
			this.gridApi.refreshHeader();
		}
	}

	changePaginationSize(pageSize: number) {
		this.gridOptions.cacheBlockSize = pageSize;
		this.gridOptions.paginationPageSize = pageSize;
	}

	isAvailableData() {
		return this.gridApi && this.gridApi.getDisplayedRowCount() > 0;
	}

	get totalRowCount() {
		return this.gridApi
			? this.gridApi.paginationGetRowCount().toLocaleString('en-US', { minimumFractionDigits: 0 })
			: 0;
	}

	get totalRowCountFseDetail() {
		if (this.gridApi) {
			const count = this.gridApi.paginationGetRowCount();
			return count ? count.toLocaleString('en-US', { minimumFractionDigits: 0 }) : '0';
		}
		return '0';
	}
	/**
     * Gère l'affichage du message indiquant qu'il n'y a aucune donnée à présenter.
     * @param dataLength Nombre de lignes présentées dans la grille
     */
	manageNoRowsOverlay(dataLength: number) {
		if (dataLength === 0) {
			this.gridApi.showNoRowsOverlay();
			this.isOverlay = true;
		} else {
			this.gridApi.hideOverlay();
			this.isOverlay = false;
		}
	}

	/**
     * Sauvegarde l'état de la grille.
     */
	saveGridStatePrefix() {
		if (this.gridColumnApi) {
			this.saveGridPaginationSize();
			sessionStorage[this.gridStatePrefix + 'CurrentPage'] = JSON.stringify(
				this.gridApi.paginationGetCurrentPage() + 1
			);
		}
	}

	saveGridColumnState(): void {
		let gridColumnState = null;
		if (this.gridColumnApi && this.gridColumnApi.getColumnState()) {
			gridColumnState = this.gridColumnApi.getColumnState();
		} else if (this.gridOptions && this.gridOptions.columnApi) {
			gridColumnState = this.gridOptions.columnApi.getColumnState();
		}
		sessionStorage[this.gridStatePrefix + 'GridColumnState'] = JSON.stringify(gridColumnState);
	}

	saveGridSort(): void {
		if (this.gridColumnApi) {
			this.gridSortModel();
		}
	}

	saveGridPaginationSize(): void {
		if (this.gridColumnApi && this.gridApi.paginationGetPageSize()) {
			sessionStorage[this.gridStatePrefix + 'PaginationPageSize'] = JSON.stringify(
				this.gridApi.paginationGetPageSize()
			);
		}
	}

	/**
     * Remet l'etat de la grille à l'initial.
     */
	resetGridState() {
		if (sessionStorage[this.gridStatePrefix + 'GridColumnState']) {
			delete sessionStorage[this.gridStatePrefix + 'GridColumnState'];
		}
		if (sessionStorage[this.gridStatePrefix + 'GridSort']) {
			delete sessionStorage[this.gridStatePrefix + 'GridSort'];
		}
		if (sessionStorage[this.gridStatePrefix + 'PaginationPageSize']) {
			delete sessionStorage[this.gridStatePrefix + 'PaginationPageSize'];
		}
		this.gridColumnApi.resetColumnState();
		this.gridApi.paginationGoToPage(0);
		this.gridApi.paginationSetPageSize(this.defaultPaginationPageSize);
		this.gridApi.sizeColumnsToFit();
	}

	resetGridStateRapp() {
		if (sessionStorage[this.gridStatePrefix + 'GridColumnState']) {
			delete sessionStorage[this.gridStatePrefix + 'GridColumnState'];
		}
		if (sessionStorage[this.gridStatePrefix + 'GridSort']) {
			delete sessionStorage[this.gridStatePrefix + 'GridSort'];
		}
		if (sessionStorage[this.gridStatePrefix + 'PaginationPageSize']) {
			delete sessionStorage[this.gridStatePrefix + 'PaginationPageSize'];
		}
		this.gridColumnApi.resetColumnState();
		this.gridApi.paginationGoToPage(0);
		this.gridApi.paginationSetPageSize(this.defaultPaginationPageSize);
	}

	resetGridStateOrganisme() {
		if (sessionStorage[this.gridStatePrefix + 'GridColumnState']) {
			delete sessionStorage[this.gridStatePrefix + 'GridColumnState'];
		}
		if (sessionStorage[this.gridStatePrefix + 'GridSort']) {
			delete sessionStorage[this.gridStatePrefix + 'GridSort'];
		}
		this.gridColumnApi.resetColumnState();
		this.gridApi.paginationGoToPage(0);
		this.gridApi.sizeColumnsToFit();
	}

	/**
     * Restaure la l'état de la grille.
     * @param gridStatePrefix Préfixe donnée à la variable de session
     * @param defaultPaginationPageSize Taille par défaut de la pagination
     */
	restoreGridStatePrefix() {
		if (sessionStorage[this.gridStatePrefix + 'GridColumnState']) {
			this.gridColumnApi?.applyColumnState(
				{state: JSON.parse(sessionStorage[this.gridStatePrefix + 'GridColumnState']), applyOrder: true}
			);
		}
		this.restoreGridSort();

		if (sessionStorage[this.gridStatePrefix + 'CurrentPage']) {
			const currentPageSaved = JSON.parse(sessionStorage[this.gridStatePrefix + 'CurrentPage']);

			this.gridApi?.paginationGoToPage(currentPageSaved);
		}

		this.restorePaginationSize();
	}

	restorePaginationSize() {
		if (sessionStorage[this.gridStatePrefix + 'PaginationPageSize']) {
			const paginationSizeSaved = JSON.parse(sessionStorage[this.gridStatePrefix + 'PaginationPageSize']);

			this.changePaginationSize(paginationSizeSaved);
			this.paginationPageSize = paginationSizeSaved;
		} else {
			this.gridApi?.paginationSetPageSize(this.paginationPageSize);
		}
	}

	restoreGridSort() {
		if (sessionStorage[this.gridStatePrefix + 'GridSort']) {
			const sortModel = JSON.parse(sessionStorage[this.gridStatePrefix + 'GridSort']);
			this.gridOptions.columnApi?.applyColumnState({
				state: [ sortModel ]
			});
		}
	}

	refreshView() {
		this.gridApi.redrawRows();
		this.gridApi.refreshCells();
	}

	getLocaleText() {
		return {
			noRowsToShow: 'Aucun résultat trouvé.',
			loadingOoo: 'Chargement',
		};
	}

	centerContent() {
		return { display: 'flex' , justifyContent : 'center' };
	}

	endContent(): any {
		return { display: 'flex' , justifyContent : 'end' };
	}

	linkContent() {
		return {
			textAlign: 'center',
			cursor: 'pointer',
			color: '#21b8cf'
		};
	}

	rightContent() {
		return { textAlign: 'right' };
	}

	leftContent() {
		return { textAlign: 'left' };
	}

	public gridSortModel(): GridSortModel[] {
		if (this.gridOptions && this.gridOptions.columnApi) {
			let gridSortModel: GridSortModel[] = [];
			const colState = this.gridOptions.columnApi.getColumnState();
			const sortState = colState
				.filter(function(s) {
					return s.sort != null;
				})
				.map(function(s) {
					return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
				});
			gridSortModel = sortState;
			sessionStorage[this.gridStatePrefix + 'GridSort'] = JSON.stringify(gridSortModel);
			this.saveGridColumnState();
			return gridSortModel;
		}
	}

	public applyColumnState(sort) {
		this.gridColumnApi.applyColumnState({
			state: sort
		  });
	}

	isCellTextSelected(): boolean {
		const selection = getSelection();
		return (selection && selection.rangeCount > 0 && selection.getRangeAt(0)) ? !selection.getRangeAt(0).collapsed ? true : false : false;
	}

	setDetailGridsColumnState(): void {
		if (this.gridOptions && this.gridColumnApi && this.gridColumnApi.getColumnState()) {
			this.gridOptions.api.forEachDetailGridInfo((detailGrid) => {
				detailGrid.columnApi.applyColumnState({state: this.gridColumnApi.getColumnState(), applyOrder: true});
			});
		}
	}

	formatWithSigne(montant, signe) {
		if (montant) {
			if (signe === 0) {
				if (parseFloat(montant) / 100 !== 0) {
					return (parseFloat(montant) / 100 * -1).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ') + ' €';
				}
				return '0.00 €';
			}
			else {
				return (parseFloat(montant) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ') + ' €';
			}
		}
		else {
			return '0.00 €';
		}
	}

}
