<careweb-help></careweb-help>
<div class="full-careweb-container">
    <div id="filter" class="filter-container" carewebFilterMinHeight>
        <div class="filter-container-inner" [ngClass]="{ 'show-content': showFilter, 'init-hidden': initHidden }">
            <careweb-accordion-header-buttons [disablesearch]="disableSearch()" (closeFilters)="toggleFilterDisplay()"
                                              (resetFilters)="resetAllFilter()" (launchSearch)="launchSearch()">
            </careweb-accordion-header-buttons>
            <div class="filter-content">
                <mat-accordion displayMode="flat" class="accordion-filter">
                    <!-- Numéro de PS -->
                    <careweb-accordion-group id="numero_ps" title="Numéro de PS" [hidden]="!existGroupNumeroPS()">
                        <input type="text" id="input_num_ps" [(ngModel)]="filters.numeroPs"
                               (keydown.enter)="onKeyDown($event)" onKeyPress="if(this.value.length==9) return false;"
                               pattern="^[0-9]" min="0" step="1" max="999999999"
                               oninput="this.value=this.value.replace(/[^\d]/,'');"
                               onpaste="if(this.value.length==9) return false;" [hidden]="!isAuthorized()" placeholder="000000000" />

                        <!--Afficher Numéro de PS avec ComboBox si l'utilisateur PS-->
                        <ng-select name="numeroPScomboBox" id="numeroPScomboBox" placeholder="Numéro de PS"
                                   [searchable]="false" [(ngModel)]="filters.numeroPScomboBox" appendTo=".filter-container"
                                   [hidden]="isAuthorized()">
                            <ng-option *ngFor="let numeroPScomboBox of psDelegueListFilter"
                                       [value]="numeroPScomboBox.numeroPsDelegue">
                                {{numeroPScomboBox.numeroPsDelegue}}
                            </ng-option>
                        </ng-select>
                    </careweb-accordion-group>
                    <!-- Numéro facture -->
                    <careweb-accordion-group id="numero_facture" title="Numéro de Facture ">
                        <careweb-filter-choice-exacte-plage [id]="'numFacture'"
                                                            [(filterChoiceFrom)]="this.filters.numFactureFrom"
                                                            [(filterChoiceTo)]="this.filters.numFactureTo">
                            <input EXACTE type="text" id="input_numeroFacture_exacte" class="form-control"
                                   [(ngModel)]="this.filters.numFacture" onlyNum="9"  [ngClass]="{'date-range-error': numeroFactureExacteError}"
                                   tooltip="Veuillez saisir au minimum 3 chiffres" triggers="" placement="top" #tooltipExacte="bs-tooltip"
                                   (mouseover) = "numeroFactureExacteError ? showErrorTooltip('Exacte') : null" (keypress)="onNumeroFseFilterChange('Exacte')"
                                   (change)="onNumeroFseFilterChange('Exacte')" (keydown.enter)="onKeyDown($event)" placeholder="000000000" />
                            <input FROM type="text" id="input_numeroFacture_from" class="form-control"
                                   [(ngModel)]="this.filters.numFactureFrom" onlyNum="9" [ngClass]="{'date-range-error': numeroFactureError}"
                                   tooltip="Veuillez saisir un numéro antérieure" triggers="" placement="top" #tooltipFrom="bs-tooltip"
                                   (mouseover) = "numeroFactureError ? showErrorTooltip('From') : null" (keypress)="onNumeroFseFilterChange('From')"
                                   (change)="onNumeroFseFilterChange('From')" (keydown.enter)="onKeyDown($event)"/>
                            <input TO type="text" id="input_to_numeroFacture_To" class="form-control"
                                   [(ngModel)]="this.filters.numFactureTo" onlyNum="9" [ngClass]="{'date-range-error': numeroFactureError}"
                                   tooltip="Veuillez saisir un numéro postérieure" triggers="" placement="bottom" #tooltipTo="bs-tooltip"
                                   (mouseover) = "numeroFactureError ? showErrorTooltip('To') : null" (keypress)="onNumeroFseFilterChange('To')"
                                   (change)="onNumeroFseFilterChange('To')" (keydown.enter)="onKeyDown($event)"/>
                        </careweb-filter-choice-exacte-plage><br>
                    </careweb-accordion-group>
                    <!-- organisme -->
                    <careweb-accordion-group id="libelle_organisme" title="Organisme">
                        <input type="text" id="input_libelle_organisme" [(ngModel)]="filters.organismeLibelle"
                               onKeyPress="if(this.value.length==13) return false;" (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!-- Montant -->
                    <careweb-accordion-group id="montantMf" title="Montant"  >
                        <careweb-filter-choice-exacte-plage [id]="'montantMf'" [(filterChoiceFrom)]="this.filters.montantMfFrom"  [(filterChoiceTo)]="this.filters.montantMfTo"
                                                            (filterChange)="this.onChoiceChange($event, 'montantMf')">
                            <input EXACTE type="text" id="input_exacte_montant" class="form-control" placeholder="0.00"  [(ngModel)]="this.filters.montantMf" (keydown.enter)="onKeyDown($event)"  name="montantExacte" carewebOnlyDouble/>
                            <input FROM type="text" id="input_from_montant" class="form-control" placeholder="0.00" [(ngModel)]="this.filters.montantMfFrom" (keydown.enter)="onKeyDown($event)"  name="montantFrom" carewebOnlyDouble/>
                            <div FROM *ngIf="montantMfMinError"  class="text-danger">Indiquer un montant minimum</div>
                            <input TO type="text" id="input_to_montantRsp" class="form-control" placeholder="0.00" [(ngModel)]="this.filters.montantMfTo"  (keydown.enter)="onKeyDown($event)" name="montantRspTo" carewebOnlyDouble/>
                            <div TO *ngIf="montantMfMaxError"  class="text-danger">Indiquer un montant maximum</div>
                        </careweb-filter-choice-exacte-plage>
                    </careweb-accordion-group>
                    <!-- reeference dette -->
                    <careweb-accordion-group id="reference_dette" title="Référence dette">
                         <input type="text" id="input_reference_dette" [(ngModel)]="filters.referenceDette"
                             (keydown.enter)="onKeyDown($event)" onKeyPress="if(this.value.length==10) return false;"
                             min="0" step="1" max="9999999999"
                             onpaste="if(this.value.length==10) return false;" />
                    </careweb-accordion-group>
                   <!--nature dette -->
                    <careweb-accordion-group id="nature_dette" title="Nature dette">
                        <input type="text"  id="input_nature_dette" placeholder="Nature dette"  [(ngModel)]="filters.natureDette"
                               onKeyPress="if(this.value.length==3) return false;" (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                    <!--nature Operation -->
                    <careweb-accordion-group  id="nature_operation" title="Nature Opération">
                        <input type="text"  id="input_nature_operation" placeholder="Nature Opération" [(ngModel)]="filters.natureOperation"
                               onKeyPress="if(this.value.length==3) return false;" (keydown.enter)="onKeyDown($event)" />
                    </careweb-accordion-group>
                   <!-- jour comptable-->
                   <careweb-accordion-group id="jour_comptable" title="Jour comptable" #dateAccordion>
                       <careweb-date-from-to-filter labelFrom="entre le" [(modelFrom)]="filters.jourComptableFrom"
                                                    [(modelTo)]="filters.jourComptableTo" [(modelExacte)]="filters.jourComptable"
                                                    [maxDate]="null" [isExactePlageChoice]="true" id="jourComptable"
                                                    (filterChange)="this.onChoiceChange($event, 'jourComptable')"
                                                    (dateRangeValidityChange) = "checkDateRangeValidity($event)" #jourComptable (onEnter) = "onKeyDown($event)">
                       </careweb-date-from-to-filter>
                   </careweb-accordion-group>
                   <careweb-accordion-group id="date_reception_partition" title="Date réception partition" >
                    <careweb-date-time-filter labelFrom=" entre le " [(modelStart)]="filters.dateStartReceptionPartition"
                                          [(modelEnd)]="filters.dateEndReceptionPartition" [maxDate]="null" (keydown.enter)="onKeyDown($event)">
                    </careweb-date-time-filter>
                </careweb-accordion-group>
                </mat-accordion>
            </div>
        </div>
    </div>
    <div class="grid-container" id="grid">
        <h2 class="title-border mt-3">Mouvements Financiers
            <careweb-open-help code="help-018"></careweb-open-help>
        </h2>
        <div>
            <section>
                <div class="row grid-top-btn">
                    <div class="col-md-4 filter">
                        <button type="button" name="btn_toggle_filter" id="filter_mouvement" class="btn btn-primary small left-open" [hidden]="showFilter"
                                (click)="toggleFilterDisplay()">
                            <i class="fa fa-sliders"></i>
                            Filtrer
                        </button>
                        <careweb-pluralize *ngIf="totalResultsHidden"  class="filter-count" [messageKey]="'Résultat(s) trouvé(s)'" [count]="gridHelper.totalRowCount">
                        </careweb-pluralize>
                    </div>
                    <div class="col-md-8 conf-col">
                        <div class="careweb-toolbar">
                            <div [tooltip]="gridHelper.isAvailableData() ? 'Exporter les résultats (.xlsx)' : 'Aucune ligne à exporter, veuillez affiner votre recherche'"
                                 placement="top" container="body">
                                <span class="btn btn-action small" [class.disabled]="!gridHelper.isAvailableData()"
                                      name="btn_export_csv" id="export_mouvement" (click)="exportFileXlsxModal()">
								<i class="fa fa-file-excel-o"></i>
							    </span>
                            </div>
                            <careweb-column-selector (itemSelect)="selectColumnEvent($event)" (itemDeselect)="deselectColumnEvent($event)" >
                            </careweb-column-selector>
                            <careweb-pagination-size id="dv_mvt_financ_paging" [pageSizes]="[10, 20,30, 50, 100]"
                                                     [(ngModel)]="gridDataRaw.size" (paginationSizeChange)="onPaginationSizeChange($event)">
                            </careweb-pagination-size>
                            <span class="btn btn-action small" tooltip="Réinitialiser l'affichage" placement="top" container="body"
                                  name="spn_reset_grid" id="resetGridState" (click)="resetGridState()">
                                <i class="fa fa-refresh"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <app-alert [export]="export" [exportState]="exportState" (close)="closeAlert()"></app-alert>
                <div class="row grid-top-filter">
                    <div class="col-md-12">
                        <careweb-filter-label *ngFor="let filter of filterList" (closeFilter)="removeFilter(filter.id)">
                            <span *ngIf="filter" id="filter_label">{{ filter.label}}: {{filter.value }}</span>
                        </careweb-filter-label>
                    </div>
                </div>
                <div class="row highlight-row">
                    <div class="col-md-12 relative">
                        <ag-grid-angular style="width: 100%" class="ag-theme-balham" [gridOptions]="gridHelper.gridOptions">
                        </ag-grid-angular>
                        <careweb-pagination [gridApi]="gridHelper.gridApi"></careweb-pagination>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
