import { IHeaderAngularComp } from 'ag-grid-angular';
import { Component, OnInit, Injectable } from '@angular/core';
import { RowNode } from 'ag-grid-enterprise';
import { Subject } from 'rxjs';

export enum CheckAllState {
    SELECT, UNSELECT, INDETERMINATE
}

export interface AgCheckBoxState {
    id: string;
    state: CheckAllState;
}

@Component({
    selector: 'careweb-ag-header-checkbox-component',
    template: `
        <div class="ag-cell" style="left: 0px; width: 100%; display: flex; justify-content: center;">
            <div class="ag-cell-wrapper">
                <div class="ag-selection-checkbox">
                    <input type="checkbox" id=" case_coche" style="background-color: white ;margin-bottom: 45px;" class="ag-input-wrapper ag-checkbox-input-wrapper"
                    [indeterminate]="checkboxState == checkAllStates.INDETERMINATE"
                    [checked]="checkboxState == checkAllStates.SELECT"
                    (change)="onSelectAllChanged($event)">
                </div>
            </div>
        </div>
    `,
})
export class AgHeaderCheckboxComponent implements IHeaderAngularComp, OnInit {
    params: any;

    checkboxState: CheckAllState = CheckAllState.UNSELECT;
    checkAllStates = CheckAllState;

    agInit(params: any): void {
        this.params = params;
    }

    constructor(private agCheckAllService: AgCheckAllService) {
    }

    // on check/uncheck checkall
    onSelectAllChanged($event: any): void {
        if ($event.target.checked) {
            this.checkboxState = CheckAllState.SELECT;
            this.params.api.getRenderedNodes().map((node: RowNode) => {
                node.setSelected(true);
            });
            this.params.column.colDef.headerName = 'select';
            this.agCheckAllService.toggleCheckAll({id:this.params.id, state: CheckAllState.SELECT});
        } else {
            this.checkboxState = CheckAllState.UNSELECT;
            this.params.api.deselectAll();
            this.params.column.colDef.headerName = 'deselect';
            this.agCheckAllService.toggleCheckAll({id:this.params.id, state: CheckAllState.UNSELECT});
        }

    }

    ngOnInit() {
        this.agCheckAllService.notifyCheckbox$.subscribe((value) => {
            if (value.id == this.params.id) {
                this.checkboxState = value.state;
            }
        });
    }

    refresh(): boolean {
        return false;
    }
}

@Injectable({
    providedIn: 'root'
})
export class AgCheckAllService {
    private checkAllSubject = new Subject<AgCheckBoxState>();

    private notifyCheckboxSubject = new Subject<AgCheckBoxState>();

    checkAll$ = this.checkAllSubject.asObservable();

    notifyCheckbox$ = this.notifyCheckboxSubject.asObservable();

    toggleCheckAll(value: AgCheckBoxState) {
        this.checkAllSubject.next(value);
    }

    notifyCheckBox(value: AgCheckBoxState) {
        this.notifyCheckboxSubject.next(value);
    }

}