import {OrganismeDataRaw} from "./organismeDataRaw";

export class MouvementsFinanciersDataRaw{
    idMouvement: Number;
    organisme : OrganismeDataRaw;
    numeroPs: String;
    numFacture: String;
    numFactureFrom: String;
    numFactureTo: String;
    idOrganisme: Number;
    organismeLibelle: string;
    montantMF: number;
    montantMfFrom: number;
    montantMfTo: number;
    referenceDette: String;
    natureDette: String;
    natureOperation: String;
    soldeDette: String;
    jourComptable: String;
    dateEnregisteremntDette: String;
    dateReceptionPartition: Date;
    jourComptableFrom: Date;
    jourComptableTo: Date;
    signeActe :String;
    fields: string[]
    dateEndReceptionPartition: Date;
	dateStartReceptionPartition: Date;
}