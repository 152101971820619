<div class="x-large-careweb-container">
  <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <section class="form-content">
      <!-- Mes informations -->
      <div class="form-block">
        <!-- Titre -->
        <div class="row">
          <div class="col">
            <h3 class="title-border">Mes informations</h3>
          </div>
        </div>
        <fieldset class="container-fluid" *ngIf="isAdminOrHot() || isAdminSel()">
          <div class="row">
            <div class="col-sm-2 col-form-label">Civilité</div>
            <div class="col-sm-4 form-group">
<!--               <select formControlName="typeCivility" style="width:100%; height:calc(1.5em + .75rem + 2px)"
                class="form-control form-control-input"
                [ngClass]="{ 'is-invalid': submitted && formFields.typeCivility.errors }">
                <option *ngFor="let civility of civiliteArray" [ngValue]="civility">
                  {{civility.viewValue}}
                </option>
              </select> -->
              <input type="text" class="form-control" name="typeCivility" formControlName="typeCivility"
              onkeypress="if(this.value.length == 50) return false;"
              [ngClass]="{ 'is-invalid': submitted && formFields.nom.errors }" readonly/>
              <div *ngIf="submitted && formFields.typeCivility.errors" class="invalid-feedback">
                <div *ngIf="formFields.typeCivility.errors.required">Ce champ est obligatoire</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 col-form-label">Nom</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" name="nom" formControlName="nom"
                onkeypress="if(this.value.length == 50) return false;"
                [ngClass]="{ 'is-invalid': submitted && formFields.nom.errors }" readonly/>
              <div *ngIf="submitted && formFields.nom.errors" class="invalid-feedback">
                <div *ngIf="formFields.nom.errors.required || formFields.nom.errors.whitespaces">
                  Ce champ est obligatoire
                </div>
              </div>
            </div>
            <div class="col-sm-2 col-form-label">Prénom</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" formControlName="prenom"
                onkeypress="if(this.value.length == 50) return false;" readonly/>
            </div>

          </div>
          <div class="row">
            <div class="col-sm-2 col-form-label">Email</div>
            <div class="col-sm-4 form-group">
              <input type="email" class="form-control" formControlName="email"
                onkeypress="if(this.value.length == 255) return false;"
                [ngClass]="{ 'is-invalid': submitted && formFields.email.errors }" readonly />

              <div *ngIf="submitted && formFields.email.errors" class="invalid-feedback">
                <div *ngIf="formFields.email.errors.required || formFields.email.errors.whitespaces">
                  Ce champ est obligatoire
                </div>
                <div *ngIf="formFields.email.errors.pattern">
                  Le format du champ E-mail est invalide
                </div>
              </div>
            </div>
            <div class="col-sm-2 col-form-label">Fonction</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" formControlName="fonction"
                onkeypress="if(this.value.length == 50) return false;" readonly />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 col-form-label">Téléphone</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" formControlName="telephone" min="0" max="99999999999999999"
                onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13 || this.value.length >= 16)? 
                                                      false :
                                                      event.charCode >= 48 && event.charCode <= 57" readonly/>
            </div>
            <div class="col-sm-2 col-form-label">Fax</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" formControlName="fax" min="0" max="99999999999999999" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13 || this.value.length >= 16)? 
                                                      false :
                                                      event.charCode >= 48 && event.charCode <= 57" readonly/>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 col-form-label">Mobile</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" formControlName="mobile" min="0" max="99999999999999999"
                onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13 || this.value.length >= 16)? 
                                                      false :
                                                      event.charCode >= 48 && event.charCode <= 57" readonly/>
            </div>
          </div>
        </fieldset>
        <fieldset class="container-fluid" *ngIf="isPs()">
          <div class="row">
            <div class="col-sm-2 col-form-label">N° ADELI</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" formControlName="numeroAdeli" readonly />
            </div>
            <div class="col-sm-2 col-form-label">Date de dernière transmission</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" name="derniereTransmission" formControlName="derniereTransmission" readonly />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 col-form-label">Type de professionnel de santé</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" formControlName="selectTypePs"
                onkeypress="if(this.value.length == 50) return false;" readonly />
            </div>
            <div class="col-sm-2 col-form-label">Raison sociale</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" formControlName="raisonSociale"
                onkeypress="if(this.value.length == 50) return false;" readonly/>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 col-form-label">Adresse 1</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" formControlName="adresse1"
                onkeypress="if(this.value.length == 50) return false;" readonly/>
            </div>
            <div class="col-sm-2 col-form-label">Adresse 2</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" formControlName="adresse2"
                onkeypress="if(this.value.length == 50) return false;" readonly/>

              <div *ngIf="submitted && formFields.adresse2.errors" class="invalid-feedback">
                <div *ngIf="formFields.adresse2.errors.required || formFields.adresse2.errors.whitespaces">
                  Ce champ est obligatoire
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 col-form-label">Ville</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" formControlName="ville"
                onkeypress="if(this.value.length == 50) return false;" readonly/>
              <div *ngIf="submitted && formFields.ville.errors" class="invalid-feedback">
                <div *ngIf="formFields.ville.errors.required || formFields.ville.errors.whitespaces">
                  Ce champ est obligatoire
                </div>
              </div>
            </div>
            <div class="col-sm-2 col-form-label">Code postal</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" formControlName="codepostal"
                onkeypress="if(this.value.length == 50) return false;" readonly />

              <div *ngIf="submitted && formFields.codepostal.errors" class="invalid-feedback">
                <div *ngIf="formFields.codepostal.errors.required || formFields.codepostal.errors.whitespaces">
                  Ce champ est obligatoire
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 col-form-label">Téléphone</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" formControlName="telephonePs" min="0" max="99999999999999999"
                onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13 || this.value.length >= 16)? 
                                                      false :
                                                      event.charCode >= 48 && event.charCode <= 57" readonly />
            </div>
            <div class="col-sm-2 col-form-label">Fax</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" formControlName="faxPs" min="0" max="99999999999999999"
                onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13 || this.value.length >= 16)? 
                                                      false :
                                                      event.charCode >= 48 && event.charCode <= 57" readonly/>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 col-form-label">Email</div>
            <div class="col-sm-4 form-group">
              <input type="text" class="form-control" formControlName="emailPs" min="0" max="99999999999999999" readonly/>
              <div *ngIf="submitted && formFields.emailPs.errors" class="invalid-feedback">
                <div *ngIf="formFields.emailPs.errors.required || formFields.emailPs.errors.whitespaces">
                  Ce champ est obligatoire
                </div>
                <div *ngIf="formFields.email.errors.pattern">
                  Le format du champ E-mail est invalide
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="form-block" *ngIf="configRappro && rappVisible">
        <!-- Titre -->
        <div class="row">
          <div class="col">
            <h3 class="title-border">Mes Rapprochements bancaires</h3>
          </div>
        </div>
        <div class="row d">
          <div class="col-sm-2 col-form-label">Rappro visible pour l'assistance</div>
          <div class="col-sm-4 form-group">
            <select class="form-select" formControlName="hideRappro" (change)="checkRapproBtn()">
              <option [ngValue]="false">Oui</option>
              <option [ngValue]="true">Non</option>
            </select>
          </div>
            <div *ngIf="listPsAssocie.length != 0 && rappVisible" class="col-sm-2 col-form-label">Rappro visible pour le superviseur</div>
            <div *ngIf="listPsAssocie.length != 0 && rappVisible" class="col-sm-4 form-group">
                <select class="form-select" formControlName="rappVisible" (change)="checkRapproBtn()">
                    <option [ngValue]="true">Oui</option>
                    <option [ngValue]="false">Non</option>
                </select>
            </div>
        </div>
              <div class="text-end">
                  <button type="button" class="btn btn-primary" (click)="saveConfigRappro()" [disabled]="!rapproBtn">Enregistrer</button>
              </div>
      </div>
      <!-- Mes utilisateurs -->
      <div class="form-block" *ngIf="isPs()">
        <!-- Titre -->
        <div class="row">
          <div class="col">
            <h3 class="title-border">Mes utilisateurs</h3>
          </div>
        </div>
        <fieldset class="container-fluid">
          <!-- Tableau -->
          <div class="row">
            <div class="col">
              <div class="table-responsive">
                <table class="ui-grid-like" *ngIf="usersPs.length > 0 || mainUsers.length > 0">
                  <thead>
                    <tr>
                      <th style="width:50px">Civilité</th>
                      <th style="min-width:100px">Nom</th>
                      <th style="min-width:100px">Prénom</th>
                      <th style="min-width:100px">Fonction</th>
                      <th style="min-width:100px">Téléphone</th>
                      <th style="min-width:150px">Email</th>
                      <th class="text-center" style="min-width:50px">Etat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let mainUser of mainUsers">
                      <td>{{formatCivility(mainUser.idCivilite)}}</td>
                      <td>{{mainUser.nom}}</td>
                      <td>{{mainUser.prenom}}</td>
                      <td>{{mainUser.fonction}}</td>
                      <td>{{formatWithoutSeperater(mainUser.telephone)}}</td>
                      <td>{{mainUser.email}}</td>
                      <td class="text-center">
                        <i [ngClass]="(mainUser.actif===true)? 'fa fa-check' :'fa fa-close'"></i>
                      </td>
                    </tr>
                    <tr *ngFor="let user of usersPs">
                      <td>{{formatCivility(user.idCivilite)}}</td>
                      <td>{{user.nom}}</td>
                      <td>{{user.prenom}}</td>
                      <td>{{user.fonction}}</td>
                      <td>{{formatWithoutSeperater(user.telephone)}}</td>
                      <td>{{user.email}}</td>
                      <td class="text-center">
                        <i [ngClass]="(user.actif===true)? 'fa fa-check' :'fa fa-close'"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <!-- Mes PS supervisés -->
      <div class="form-block" *ngIf="isPs()">
        <!-- Titre -->
        <div class="row">
          <div class="col">
            <h3 class="title-border">Mes PS supervisés</h3>
          </div>
        </div>
        <!-- Tableau -->
        <fieldset class="container-fluid">
          <div class="row">
            <div class="col">
              <div class="table-responsive">
                <table class="ui-grid-like" *ngIf="listPsDelegue.length > 0">
                  <thead>
                    <tr>
                      <th style="width:300px">Numéro ps</th>
                      <th style="width:300px">Nom</th>
                      <th style="min-width:100px">Etablissement</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let delegue of listPsDelegue">
                      <td>{{delegue.numeroPsDelegue}}</td>
                      <td>{{delegue.nomPsDelegue}}</td>
                      <td>{{delegue.raisonSocialeEtablissementPsDelegue}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      <!-- Mes PS associés -->
      <div class="form-block" *ngIf="isPs()">
        <!-- Titre -->
        <div class="row">
          <div class="col">
            <h3 class="title-border">Mes PS associés</h3>
          </div>
        </div>
        <!-- Tableau -->
        <fieldset class="container-fluid">
          <div class="row">
            <div class="col">
              <div class="table-responsive">
                <table class="ui-grid-like" *ngIf="listPsAssocie.length > 0">
                  <thead>
                    <tr>
                      <th style="width:300px">Numéro ps</th>
                      <th style="width:300px">Nom</th>
                      <th style="min-width:100px">Etablissement</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let associe of listPsAssocie">
                      <td>{{associe.numeroPsAssocie}}</td>
                      <td>{{associe.nomPsAssocie}}</td>
                      <td>{{associe.raisonSocialeEtablissementPsAssocie}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      <!-- Mes OCT -->
      <div class="form-block">
        <!-- Titre -->
        <div class="row">
          <div class="col">
            <h3 class="title-border">Mes OCT</h3>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col-md-3 btn-oct" *ngFor="let oct of listOCT">
                <button type="button" class="btn btn-primary btn-oct-value" name="oct.value" disabled>
                  {{oct.viewValue}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>
