<careweb-help></careweb-help>
<div class="main-container med-careweb-container"
    style="background-repeat: no-repeat; background-attachment:fixed; background-size:cover;">
    <!-- <form #loiForm="ngForm" class="form-content" (ngSubmit)="submitLoiDownload()"> -->
        <section class="inner-container form-content">
            <!-- Titre H2 -->
            <div class="row form-title">
                <h2 class="col-md-6">
                    Liste d'Opposition Incrémentale
                    <careweb-open-help code="help-004"></careweb-open-help>
                </h2>
            </div>
            <div class="form-block" [hidden]="!existGroupNumeroPS()">
                <!-- Titre -->
                <div class="row">
                    <div class="col">
                        <h3 class="title-border">
                            Choisir un PS dans la liste
                        </h3>
                    </div>
                </div>
                <fieldset class="container-fluid">
                    <div class="row highlight-row">
                        <div class="col-md-12 relative">
                            <ag-grid-angular style="width: 100%;" class="ag-theme-material"
                                [gridOptions]="gridHelper.gridOptions">
                            </ag-grid-angular>
                            <careweb-pagination [gridApi]="gridHelper.gridApi">
                            </careweb-pagination>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="form-block">
                <!-- Titre -->
                <div class="row">
                    <div class="col">
                        <h3 class="title-border">
                            Dernier fichier de LOI
                            <!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
                        </h3>
                    </div>
                </div>
                <fieldset class="container-fluid">
                    <div class="row">
                        <div class="col-sm-6 col-form-label" style="text-align:center"><label>Télécharger le
                                fichier</label></div>
                        <div class="col-sm-6 col-form-label" style="text-align:center"><label *ngIf="isAuthorized()">Envoyer par email</label><label *ngIf="!isAuthorized()">Recevoir par email</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6" style="text-align:center">
                            <div (click)="downloadLoi()" id="telecharger_loi">
                                <i class="fa fa-download fa-4x" style="cursor:pointer;"></i>
                            </div>
                        </div>
                        <div class="col-sm-6" style="text-align:center" >
                            <div (click)="sendRecieveLoi()" id="received_loi">
                                <i class="fa fa-envelope-o fa-4x" style="cursor:pointer;"></i>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="form-block">
                <!-- Titre -->
                <div class="row">
                    <div class="col">
                        <h3 class="title-border">
                            Recevoir le delta des incréments dLOI dans ma boite email
                            <!-- <a href="#" class="btn-popin"><i class="fa fa-info-circle"></i></a> -->
                        </h3>
                    </div>
                </div>
                  <form #loiForm="ngForm" class="form-content" (ngSubmit)="submitLoiDownload()">
                <fieldset class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            Saisir le dernier rang LOI
                        </div>
                    </div>
                    <div class="row" style="margin-top:5px">
                        <div class="col-sm-12 form-label" style="display:inline-flex;">
                                <input type="number" id="rangLoi" class="form-control" name="rangLoi" style="width:200px"
                                    [(ngModel)]="rangLoi" onKeyPress="if(this.value.length==4) return false;"
                                    pattern="^[0-9]" min="0" step="1" max="9999"
                                    oninput="this.value=this.value.replace(/[^\d]/,'');"
                                    onpaste="this.value=this.value.replace(/[^\d]/,'');if(this.value.length==4) return false;"
                                    [required]="true" />&nbsp;
                            <button class="btn btn-primary btn-lg" id="recevoir_mail" type="submit" aria-disabled="true"><i
                                    class="fa fa-download"></i>&nbsp;&nbsp;Recevoir par email</button>
                        </div>
                    </div>
                </fieldset>
            </form>
            </div>
        </section>
<!--     </form> -->
</div>