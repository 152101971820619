<div class="full-careweb-container">
  <div class="grid-container" id="grid">
    <h2 class="title-border mt-3">Modification des contenus
    </h2>
    <div>
      <section>
        <div class="form-block">
          <fieldset class="container-fluid">
            <div class="form-block">
              <!-- Titre -->
              <div class="row">
                <div class="col">
                  <h3 class="title-border">
                    Gestion de la vidéo tutorielle
                  </h3>
                </div>
              </div>
              <fieldset class="container-fluid">
                <form class="form-block"  (ngSubmit)="onSubmit()">
                  <div class="row">
                    <div class="col-sm-2 col-form-label">URL de la vidéo</div>
                    <div class="col-sm-4 form-group required">
                      <input type="text" class="form-control"  id="videoUrl" name="videoUrl" [(ngModel)]="videoUrl" placeholder="https://"
                             [required]="true" #urlVideo="ngModel" (input)="onUrlChange()">
                      <careweb-error-messages  [hidden]="!isAuthorized() " [field]="urlVideo" [touched]="true">
                      </careweb-error-messages>
                      <div *ngIf="isUrlValid === false && videoUrl!='' " class="text-danger">
                        L'URL n'est pas valide.
                      </div>
                    </div>
                  </div>
                  <!--  Visibilité de la vidéo pour tous les PS  -->
                  <div class="row d">
                    <div class="col-sm-2 col-form-label">Visibilité de la vidéo pour tous les PS</div>
                    <div class="col-sm-4 form-group" >
                          <select class="form-select" [(ngModel)]="videoVisibility" (change)="changeInputs($event)" [ngModelOptions]="{standalone: true}">
                            <option value="true">Activer</option>
                            <option value="false">Désactiver</option>
                          </select>
                      </div>
                  </div>
                  <div class="row" style="display:flex; text-align:right">
                    <div class="col-md-12 text-title">
                      <button class="btn btn-primary btn-lg" type="submit" (click)="onSubmit()" [disabled]="!hasChanges || isUrlValid === false">Enregistrer</button>
                    </div>
                  </div>
                </form>
              </fieldset>
            </div>
          </fieldset>
        </div>
      </section>
    </div>
  </div>
</div>
