import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { TypeZeroDataRaw } from '../../../../data/typeZeroDataRaw';
import { AuthService } from '../../../../core/services/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { TypeZeroService } from '../type0.service';
import { ToastrService } from 'ngx-toastr';
import { NumeroEmetteurDataRaw } from '../../../../data/numeroEmetteurDataRaw';
import { VersRgptTzeroTpsDataRaw } from '../../../../data/VersRgptTzeroTpsDataRaw';
import { OctService } from '../../../../core/services/oct.service';
import { OctDataRaw } from '../../../../data/octDataRaw';
import { NormeDataRaw } from '../../../../data/normeDataRaw';
import { VersionDataRaw } from '../../../../data/versionDataRaw';
import { AgGridHelper } from '../../../../components/ag-grid/ag-grid-helper';
import { RegroupementDataRaw } from '../../../../data/regroupementDataRaw';
import { ColDef, IDatasource, IGetRowsParams } from 'ag-grid-enterprise';
import { RegroupementsService } from '../../regroupements/regroupement.service';
import { FicheTypeZeroRattacherComponent } from './fiche-type0-rattacher.component';
import { TypePsDataRaw } from '../../../../data/typePsDataRaw';
import { TypePsService } from '../../../ps/service/typeps.service';
import { AssociationService } from '../association.service';
import { gridConvertSort } from '../../../../components/ag-grid/ag-grid.utils';
import { InputValidators } from '../../../../components/validators/InputValidators';
import { MapEmptyValueToNull } from '../../../../core/utilities/map-empty-value-to-null';

@Component({
	selector: 'careweb-app-fiche-type0',
	templateUrl: './fiche-type0.component.html',
	styleUrls: [ './fiche-type0.component.scss' ]
})
export class FicheTypeZeroComponent implements OnInit {
	typeZero: TypeZeroDataRaw;
	emetteurs = new Array<NumeroEmetteurDataRaw>();
	isFormValid = false;
	submitted = false;
	typeZeroForm: UntypedFormGroup;
	regroupementForm: UntypedFormGroup;
	isNewTypeZero = false;
	isEditTypeZero = false;
	regroupementsList = new Array<VersRgptTzeroTpsDataRaw>();
	showAddAssoModal = false;
	showAddAssoModalOnglet1 = false;
	showAddAssoModalOnglet2 = false;
	addAssocBtnClicked = false;
	isUpdateAssociation = false;
	showConfirmModal = false;
	modalMessage: string;
	initalValues: any;

	octs = new Array<OctDataRaw>();
	normes = new Array<NormeDataRaw>();
	versions = new Array<VersionDataRaw>();
	typePss = new Array<TypePsDataRaw>();
	versRgptTzeroTpsDataRaw: VersRgptTzeroTpsDataRaw;
	selectedAssociation: VersRgptTzeroTpsDataRaw;

	gridHelperRegroupement: AgGridHelper;
	regroupementDataRaw: RegroupementDataRaw;
	paginationSizeReg: number;

	selectedNumEmetteur = new NumeroEmetteurDataRaw();

	constructor(
		private authSvc: AuthService,
		private typeZeroService: TypeZeroService,
		private octSvc: OctService,
		private regroupementsSvc: RegroupementsService,
		private typeServicePsSVC: TypePsService,
		private associationService: AssociationService,
		private activatedRoute: ActivatedRoute,
		private toastr: ToastrService,
		private router: Router,
		private fb: UntypedFormBuilder,
		private inputValidators: InputValidators,
		private mapEmptyValueToNull: MapEmptyValueToNull
	) {
		this.gridHelperRegroupement = new AgGridHelper();
	}

	ngOnInit() {
		const idTypeZero = this.activatedRoute.snapshot.params.id;

		if (!this.isAuthorized()) {
			this.router.navigate([ '/dashboard' ]);
		}

		this.typeZero = new TypeZeroDataRaw();
		this.initForm();

		if (isNullOrUndefined(idTypeZero)) {
			this.isNewTypeZero = true;
			this.isEditTypeZero = false;

			this.typeZeroForm.controls['numDestDyn'].setValue(false);
			this.typeZeroForm.controls['compactage'].setValue(false);
			this.typeZeroForm.controls['cryptage'].setValue(false);
			this.typeZeroForm.controls['identifiantFichier'].setValue('0');
		} else {
			this.isEditTypeZero = true;
			this.isNewTypeZero = false;
			// get selected type 0 by Id
			this.typeZeroService.getTypeZeroById({ idTypeZero: idTypeZero }).subscribe((data) => {
				this.typeZero = data;
				if (!isNullOrUndefined(this.typeZero)) {
					this.fromObjectToForm();
				}
				// get list of associations
				this.getListAssociations();
			},
			err=>{
				if(err.status===400){
					this.router.navigate(['/404'])
				}
			});
		}
		this.fillListNumEmetteur();
	}

	getListAssociations() {
		this.associationService.getRegroupementByTypeZero(this.typeZero.idTypeZero).subscribe((regroupements) => {
			this.regroupementsList = regroupements;
		});
	}

	onSubmit() {
		this.submitted = true;

		if (
			isNullOrUndefined(this.typeZeroForm.controls['longueurEnregistrement'].value) ||
			this.typeZeroForm.controls['longueurEnregistrement'].value === ''
		) {
			// set default value to 128
			this.typeZeroForm.controls['longueurEnregistrement'].setValue(128);
		}

		if (this.typeZeroForm.valid && 
			JSON.stringify(this.mapEmptyValueToNull.emptyValueToNull(this.initalValues)) != JSON.stringify(this.mapEmptyValueToNull.emptyValueToNull(this.typeZeroForm.value))) {
			this.initalValues = this.typeZeroForm.value;
			this.fromFormToObject();
			if (!isNullOrUndefined(this.typeZero)) {
				this.typeZeroService
					.save(this.typeZero)
					.then((returnedType0: TypeZeroDataRaw) => {
						if (!isNullOrUndefined(returnedType0)) {
							if (this.isNewTypeZero) {
								this.toastr.success('Le type 0 a été ajouté avec succès');
								// afficher la page en mode édition
								this.isEditTypeZero = true;
								this.isNewTypeZero = false;
								this.typeZero = returnedType0;
							} else if (this.isEditTypeZero) {
								this.toastr.success('Le type 0 a été mis à jour avec succès');
								// refresh la liste aprés la modification
								this.getListAssociations();
							}
						}
					})
					.catch((err) => {
						this.toastr.error(
							'Une erreur est survenue lors du sauvegarde du type 0',
							'Enregistrer un type 0'
						);
					});
			}
		}
	}

	initForm() {
		this.typeZeroForm = this.fb.group({
			typeEmetteur: new UntypedFormControl('', [Validators.required, this.inputValidators.inputWithSpaces  ]),
			numEmetteur: new UntypedFormControl('', [ Validators.required ]),
			typeDestinataire: new UntypedFormControl('', [Validators.required, this.inputValidators.inputWithSpaces ]),
			numDestDyn: new UntypedFormControl(''),
			numDestinataire: new UntypedFormControl('', [ Validators.required, this.inputValidators.inputWithSpaces  ]),
			application: new UntypedFormControl('', [ Validators.required, this.inputValidators.inputWithSpaces  ]),
			identifiantFichier: new UntypedFormControl('0', [ Validators.required ]),
			compactage: new UntypedFormControl(''),
			cryptage: new UntypedFormControl(''),
			programmeEmetteur: new UntypedFormControl(''),
			programmeDestinataire: new UntypedFormControl(''),
			longueurEnregistrement: new UntypedFormControl('')
		});
	}

	fromFormToObject() {
		this.typeZero.typeEmetteur = this.typeZeroForm.controls['typeEmetteur'].value;

		if (!isNullOrUndefined(this.typeZeroForm.controls['numEmetteur'].value.idEmetteur)) {
			this.typeZero.numeroEmetteur = this.typeZeroForm.controls['numEmetteur'].value;
			this.typeZero.idEmetteur = this.typeZero.numeroEmetteur.idEmetteur;
		}

		this.typeZero.typeDestinataire = this.typeZeroForm.controls['typeDestinataire'].value;
		this.typeZero.numDestinataire = this.typeZeroForm.controls['numDestinataire'].value;
		this.typeZero.numDestDyn = this.typeZeroForm.controls['numDestDyn'].value
			? this.typeZeroForm.controls['numDestDyn'].value
			: false;
		this.typeZero.application = this.typeZeroForm.controls['application'].value;
		this.typeZero.identifiantFichier = this.typeZeroForm.controls['identifiantFichier'].value;
		this.typeZero.longueurEnregistrement = this.typeZeroForm.controls['longueurEnregistrement'].value;
		this.typeZero.compactage = this.typeZeroForm.controls['compactage'].value
			? this.typeZeroForm.controls['compactage'].value
			: false;
		this.typeZero.cryptage = this.typeZeroForm.controls['cryptage'].value
			? this.typeZeroForm.controls['cryptage'].value
			: false;
		this.typeZero.programmeEmetteur = this.typeZeroForm.controls['programmeEmetteur'].value;
		this.typeZero.programmeDestinataire = this.typeZeroForm.controls['programmeDestinataire'].value;
	}

	fromObjectToForm() {
		this.typeZeroForm.controls['typeEmetteur'].setValue(this.typeZero.typeEmetteur);
		this.typeZeroForm.controls['numEmetteur'].setValue(
			this.typeZero.numeroEmetteur != null ? this.typeZero.numeroEmetteur.numEmetteur : null
		);
		this.typeZeroForm.controls['typeDestinataire'].setValue(this.typeZero.typeDestinataire);
		this.typeZeroForm.controls['numDestinataire'].setValue(this.typeZero.numDestinataire);
		this.typeZeroForm.controls['numDestDyn'].setValue(this.typeZero.numDestDyn);
		this.typeZeroForm.controls['application'].setValue(this.typeZero.application);
		this.typeZeroForm.controls['identifiantFichier'].setValue(this.typeZero.identifiantFichier);
		
		this.typeZeroForm.controls['longueurEnregistrement'].setValue(this.typeZero.longueurEnregistrement);
		this.typeZeroForm.controls['compactage'].setValue(this.typeZero.compactage);
		this.typeZeroForm.controls['cryptage'].setValue(this.typeZero.cryptage);
		this.typeZeroForm.controls['programmeEmetteur'].setValue(this.typeZero.programmeEmetteur);
		this.typeZeroForm.controls['programmeDestinataire'].setValue(this.typeZero.programmeDestinataire);
		this.initalValues = this.typeZeroForm.value;
	}

	fillListNumEmetteur() {
		this.typeZeroService.getNumEmetteur().subscribe((data) => {
			if (!isNullOrUndefined(data)) {
				this.emetteurs = data;
			}
		});
	}

	cancel() {
		if (this.isNewTypeZero) {
			this.typeZero = new TypeZeroDataRaw();
			this.fromObjectToForm();
			this.typeZeroForm.controls['numDestDyn'].setValue(false);
			this.typeZeroForm.controls['compactage'].setValue(false);
			this.typeZeroForm.controls['cryptage'].setValue(false);
		} else if (this.isEditTypeZero) {
			this.typeZeroService.getTypeZeroById({ idTypeZero: this.typeZero.idTypeZero }).subscribe((data) => {
				this.typeZero = data;
				if (!isNullOrUndefined(this.typeZero)) {
					this.fromObjectToForm();
				}
			});
		}
		this.isFormValid = false;
		this.submitted = false;
	}

	isAuthorized() {
		return this.authSvc.userAuthorized('HOTLINER') || this.authSvc.userAuthorized('ADMIN');
	}

	get formFields() {
		return this.typeZeroForm.controls;
	}

	get regptFormFields() {
		return this.regroupementForm.controls;
	}

	goToTypeZero() {
		this.router.navigate([ '/type0' ]);
	}

	initRegroupementForm() {
		this.regroupementForm = this.fb.group({
			typeEmetteur: new UntypedFormControl('', [ Validators.required ]),
			numEmetteur: new UntypedFormControl('', [ Validators.required ]),
			typeDestinataire: new UntypedFormControl('', [ Validators.required ]),
			numDestinataire: new UntypedFormControl('', [ Validators.required ]),
			oct: new UntypedFormControl('', [ Validators.required ]),
			norme: new UntypedFormControl('', [ Validators.required ]),
			version: new UntypedFormControl('', [ Validators.required ]),
			typePs: new UntypedFormControl('', [ Validators.required ]),
			regroupement: new UntypedFormControl('')
		});
	}

	openModalAddAssoc(reg?: VersRgptTzeroTpsDataRaw) {
		this.showAddAssoModal = true;
		this.showAddAssoModalOnglet1 = true;
		this.showAddAssoModalOnglet2 = false;
		this.initRegroupementForm();
		this.regroupementDataRaw = new RegroupementDataRaw();

		// Remplir le formulaire de regroupement les 4 premier champs
		this.regroupementForm.controls['typeEmetteur'].setValue(this.typeZero.typeEmetteur);
		if (!isNullOrUndefined(this.typeZero.numeroEmetteur)) {
			this.regroupementForm.controls['numEmetteur'].setValue(this.typeZero.numeroEmetteur.numEmetteur);
		} else if (!isNullOrUndefined(this.typeZeroForm.controls['numEmetteur'].value.idEmetteur)) {
			this.regroupementForm.controls['numEmetteur'].setValue(
				this.typeZeroForm.controls['numEmetteur'].value.numEmetteur
			);
		} else {
			this.regroupementForm.controls['numEmetteur'].setValue(this.typeZeroForm.controls['numEmetteur'].value);
		}
		this.regroupementForm.controls['typeDestinataire'].setValue(this.typeZero.typeDestinataire);
		this.regroupementForm.controls['numDestinataire'].setValue(this.typeZero.numDestinataire);
		// is update
		if (!isNullOrUndefined(reg)) {
			this.isUpdateAssociation = true;
			this.selectedAssociation = new VersRgptTzeroTpsDataRaw();
			this.selectedAssociation.idRgpt = reg.idRgpt;
			this.selectedAssociation.idOct = reg.idOct;
			this.selectedAssociation.idTypePs = reg.idTypePs;
			this.selectedAssociation.idTypeZero = reg.idTypeZero;
			this.selectedAssociation.idVersion = reg.idVersion;
			this.selectedAssociation.duo = reg.duo;
			this.selectedAssociation.idNorme = reg.idNorme;
			this.selectedAssociation.regroupement = reg.regroupement;
			this.selectedAssociation.idRgpt = reg.idRgpt;
			// this.regroupementDataRaw.idRegroupement = reg.idRgpt;
			this.regroupementForm.controls['regroupement'].setValue(this.typeZero.regroupement);

			// Remplir la liste des OCT
			this.getOcts(reg.idOct);
			// Remplir la liste NORME
			this.getNormes(reg.idNorme, reg.idVersion);
			// Remplir la liste de type PS
			this.getTypePss(reg.idTypePs);
		} else {
			this.isUpdateAssociation = false;
			// Remplir la liste des OCT
			this.getOcts();
			// Remplir la liste NORME
			this.getNormes();
			// Remplir la liste de type PS
			this.getTypePss();
		}

		// Grid des regroupements
		this.gridHelperRegroupement.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true
			},
			context: { componentParent: this },
			components: {
				customSelectFilterComponent: this.getSelectFloatingFilterComponent(),
				customInputFilterComponent: this.getInputFloatingFilterComponent()
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'infinite',
			pagination: true,
			cacheBlockSize: 3,
			paginationPageSize: 3,
			infiniteInitialRowCount: 1,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			localeText: this.gridHelperRegroupement.getLocaleText(),
			onGridReady: (params) => this.onGridReady(params)
			// onGridSizeChanged: (params) => this.onGridSizeChanged(params)
		};
		this.paginationSizeReg = this.gridHelperRegroupement.paginationPageSize;
	}

	onGridReady(params) {
		const ctx = this;
		this.gridHelperRegroupement.gridApi = params.api;
		this.gridHelperRegroupement.gridColumnApi = params.columnApi;
		this.gridHelperRegroupement.gridApi.sizeColumnsToFit();

		const dataSource: IDatasource = {
			getRows: function(paramsRows: IGetRowsParams) {
				const sorts = gridConvertSort(ctx.gridHelperRegroupement.gridSortModel(), []);
				ctx.regroupementsSvc
					.getRegroupementFilteredList(
						{
							page: ctx.gridHelperRegroupement.gridApi.paginationGetCurrentPage(),
							size: ctx.gridHelperRegroupement.gridApi.paginationGetPageSize(),
							sorts
						},
						ctx.regroupementDataRaw
					)
					.subscribe(
						(dataRegroupement) => {
							const rowCount = dataRegroupement.totalElements;
							ctx.gridHelperRegroupement.manageNoRowsOverlay(rowCount);
							paramsRows.successCallback(dataRegroupement.content, rowCount);
						},
						() => paramsRows.failCallback()
					);
			}
		};
		this.gridHelperRegroupement.gridApi.setDatasource(dataSource);
		this.gridHelperRegroupement.gridApi.sizeColumnsToFit();
	}

	onGridSizeChanged(params) {
		this.gridHelperRegroupement.gridApi.sizeColumnsToFit();
	}

	closeModal() {
		this.showAddAssoModal = false;
		this.showAddAssoModalOnglet1 = false;
		this.showAddAssoModalOnglet2 = false;
		this.addAssocBtnClicked = false;
	}

	renitialiser() {
		this.addAssocBtnClicked = false;
		this.regroupementDataRaw = new RegroupementDataRaw();
		this.regroupementDataRaw.libelle = null;
		this.regroupementDataRaw.regroupementRc = null;
		this.gridHelperRegroupement.gridApi.setFilterModel(null);
		this.gridHelperRegroupement.gridApi.onFilterChanged();

		// is update
		if (this.isUpdateAssociation) {
			this.regroupementForm.controls['regroupement'].setValue(this.typeZero.regroupement);
			// Remplir la liste des OCT
			this.getOcts(this.selectedAssociation.idOct);
			// Remplir la liste NORME
			this.getNormes(this.selectedAssociation.idNorme, this.selectedAssociation.idVersion);
			// Remplir la liste de type PS
			this.getTypePss(this.selectedAssociation.idTypePs);
		} else {
			this.isUpdateAssociation = false;
			// Remplir la liste des OCT
			this.getOcts();
			// Remplir la liste NORME
			this.getNormes();
			// Remplir la liste de type PS
			this.getTypePss();
		}
		this.closeModal();
	}

	showOnglet(onglet) {
		if (onglet === 'onglet1') {
			this.showAddAssoModalOnglet1 = true;
			this.showAddAssoModalOnglet2 = false;
		} else if (onglet === 'onglet2') {
			this.showAddAssoModalOnglet1 = false;
			this.showAddAssoModalOnglet2 = true;
		}
	}

	getOcts(idOct?) {
		this.octs = new Array<OctDataRaw>();
		this.octSvc.getOctByIdUser(this.authSvc.currentUserValue.idUser).subscribe((data) => {
			if (!isNullOrUndefined(data)) {
				this.octs = data;
				if (!isNullOrUndefined(idOct)) {
					this.regroupementForm.controls['oct'].setValue(idOct);
				} else {
					this.regroupementForm.controls['oct'].setValue(this.octs[0].idOct);
				}
			}
		});
	}

	getNormes(idNorme?, idVersion?) {
		this.normes = new Array<NormeDataRaw>();
		this.associationService.getNormes().subscribe((data) => {
			if (!isNullOrUndefined(data)) {
				this.normes = data;
				if (!isNullOrUndefined(idNorme) && !isNullOrUndefined(idVersion)) {
					this.regroupementForm.controls['norme'].setValue(idNorme);
					this.getVersionsByIdNorme(idNorme, idVersion);
				} else {
					this.regroupementForm.controls['norme'].setValue(this.normes[0].idNorme);
					this.getVersionsByIdNorme(this.normes[0].idNorme);
				}
			}
		});
	}

	getVersionsByIdNorme(idNorme: number, idVersionNorme?: number) {
		this.versions = new Array<VersionDataRaw>();
		this.associationService.getVersionsByNormeId(idNorme).subscribe((data) => {
			if (!isNullOrUndefined(data)) {
				this.versions = data;
				if (!isNullOrUndefined(idVersionNorme)) {
					this.regroupementForm.controls['version'].setValue(idVersionNorme);
				} else {
					this.regroupementForm.controls['version'].setValue(this.versions[0].idVersionNorme);
				}
			}
		});
	}

	onChangeNorme($event) {
		if (!isNullOrUndefined($event)) {
			this.getVersionsByIdNorme($event.idNorme);
		}
	}

	getTypePss(idTPs?) {
		this.typeServicePsSVC.getTypePSList().then((data) => {
			this.typePss = data;
			if (!isNullOrUndefined(idTPs)) {
				this.regroupementForm.controls['typePs'].setValue(idTPs);
			} else {
				this.regroupementForm.controls['typePs'].setValue(this.typePss[0].idTypePs);
			}
		});
	}

	rattacher(params) {
		if (!isNullOrUndefined(params.data)) {
			this.regroupementDataRaw = params.data;
		}
	}

	saveAssociation() {
		this.addAssocBtnClicked = true;
		this.versRgptTzeroTpsDataRaw = new VersRgptTzeroTpsDataRaw();

		if (this.isUpdateAssociation && isNullOrUndefined(this.regroupementDataRaw.idRegroupement)) {
			this.regroupementDataRaw.idRegroupement = this.selectedAssociation.idRgpt;
		}

		if (
			this.regroupementForm.valid &&
			!isNullOrUndefined(this.regroupementDataRaw.idRegroupement) &&
			!isNullOrUndefined(this.typeZero.idTypeZero)
		) {
			if (this.isUpdateAssociation && !isNullOrUndefined(this.selectedAssociation)) {
				// delete old association by all th ids
				this.associationService
					.delete(this.selectedAssociation)
					.then((res) => {
						// then save
						this.fromRegroupementFormToAssociation();
						this.associationService
							.save(this.versRgptTzeroTpsDataRaw)
							.then((data) => {
								if (!isNullOrUndefined(data)) {
									this.toastr.success("L'association a été mis à jour avec succès");
									this.closeModal();
									// refresh list of association
									this.getListAssociations();
								}
							})
							.catch((err) => {
								this.toastr.error(
									"Une erreur est survenue lors de la modification de l'association",
									"Modification d'une association"
								);
							});
					})
					.catch((err) => {
						this.toastr.error(
							"Une erreur est survenue lors de la modification de l'association",
							"Modification d'une association"
						);
					});
			} else {
				this.fromRegroupementFormToAssociation();
				this.associationService
					.save(this.versRgptTzeroTpsDataRaw)
					.then((data) => {
						if (!isNullOrUndefined(data)) {
							this.toastr.success("L'association a été ajoutée avec succès");
							this.closeModal();
							// refresh list of association
							this.getListAssociations();
						}
					})
					.catch((err) => {
						this.toastr.error(
							"Une erreur est survenue lors de l'ajout de l'association",
							"Ajout d'une association"
						);
					});
			}
		}
	}

	fromRegroupementFormToAssociation() {
		this.versRgptTzeroTpsDataRaw.idRgpt = this.regroupementDataRaw.idRegroupement;
		this.versRgptTzeroTpsDataRaw.idTypeZero = this.typeZero.idTypeZero;
		this.versRgptTzeroTpsDataRaw.idTypePs = this.regroupementForm.controls['typePs'].value;
		this.versRgptTzeroTpsDataRaw.idVersion = this.regroupementForm.controls['version'].value;
		this.versRgptTzeroTpsDataRaw.idNorme = this.regroupementForm.controls['norme'].value;
		this.versRgptTzeroTpsDataRaw.idOct = this.regroupementForm.controls['oct'].value;
		this.versRgptTzeroTpsDataRaw.duo = 0;
	}

	public openModalConfim(toDeleteObject: VersRgptTzeroTpsDataRaw) {
		this.showConfirmModal = true;
		this.modalMessage = 'Voulez-vous supprimer cette association ?';
		this.selectedAssociation = toDeleteObject;
	}

	public showModal(showModal: boolean) {
		this.showConfirmModal = showModal;
	}

	public confirmModal() {
		if (!isNullOrUndefined(this.selectedAssociation)) {
			// delete logiciel
			this.associationService
				.delete(this.selectedAssociation)
				.then((ret) => {
					this.showModal(false);
					this.toastr.success("L'association a été supprimée avec succès");

					// refresh associations liste after deleting
					const index: number = this.regroupementsList.indexOf(this.selectedAssociation);
					if (index !== -1) {
						this.regroupementsList.splice(index, 1);
					}
					this.selectedAssociation = new VersRgptTzeroTpsDataRaw();
				})
				.catch((err) => {
					this.showModal(false);
					this.selectedAssociation = new VersRgptTzeroTpsDataRaw();
					this.toastr.error(
						"Une erreur est survenue lors de la suppression de l'association !!",
						"Suppression d'une association"
					);
				});
		}
		this.showModal(false);
	}

	private getColumnDefs(): ColDef[] {
		return [
			{
				headerName: 'Libellé',
				field: 'libelle',
				minWidth: 80,
				lockVisible: true,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponent: 'customInputFilterComponent',
				floatingFilterComponentParams: { suppressFilterButton: true },
				suppressMenu: true,
				sortable: true
			},
			{
				headerName: 'Regroupement RC interdit',
				field: 'brcsi',
				minWidth: 100,
				lockVisible: true,
				cellStyle: this.gridHelperRegroupement.centerContent(),
				cellRenderer: FicheTypeZeroRattacherComponent,
				filter: 'agTextColumnFilter',
				floatingFilter: true,
				floatingFilterComponentParams: { suppressFilterButton: true, debounceMs: 3000 },
				floatingFilterComponent: 'customSelectFilterComponent',
				suppressMenu: true,
				sortable: true
			},
			{
				headerName: '',
				field: 'options',
				maxWidth: 70,
				lockVisible: true,
				cellStyle: this.gridHelperRegroupement.centerContent(),
				onCellClicked: (params) => this.rattacher(params),
				cellRenderer: FicheTypeZeroRattacherComponent,
				cellRendererParams: {
					iconClass: 'fa fa-link'
				}
			}
		];
	}

	getInputFloatingFilterComponent() {
		const ctx = this;
		function InputFloatingFilter() {}

		InputFloatingFilter.prototype.init = function(params) {
			this.eGui = document.createElement('div');
			this.eGui.setAttribute('class', 'ag-input-wrapper');

			this.eGui.innerHTML =
				"<input type='text' class='customFilterInput' style='width:100%; box-shadow: none;' maxlength='50' onKeyPress='if(this.value.length==50) return false;'/>";

			this.currentValue = null;
			this.eFilterInput = this.eGui.querySelector('input');
			this.eFilterInput.style.color = params.color;
			const that = this;
			function onInputChanged() {
				const filterField = params.filterParams.colDef.field;
				if (that.eFilterInput.value === '') {
					// Remove the filter
					delete ctx.regroupementDataRaw[filterField];
					params.parentFilterInstance(function(instance) {
						instance.onFloatingFilterChanged(null, null);
					});
					return;
				}
				that.currentValue = that.eFilterInput.value;
				if (that.eFilterInput.value.length >= 3) {
					if (that.currentValue !== '') {
						ctx.regroupementDataRaw[filterField] = that.eFilterInput.value;
					}
					params.parentFilterInstance(function(instance) {
						instance.onFloatingFilterChanged('equals', that.currentValue);
					});
				}
			}
			this.eFilterInput.addEventListener('input', onInputChanged);
		};

		InputFloatingFilter.prototype.onParentModelChanged = function(parentModel) {
			if (!parentModel) {
				this.eFilterInput.value = '';
				this.currentValue = null;
			} else {
				this.eFilterInput.value = parentModel.filter + '';
				this.currentValue = parentModel.filter;
			}
		};

		InputFloatingFilter.prototype.getGui = function() {
			return this.eGui;
		};

		return InputFloatingFilter;
	}

	getSelectFloatingFilterComponent() {
		const ctx = this;
		function SelectFloatingFilter() {}

		SelectFloatingFilter.prototype.init = function(params) {
			this.eGui = document.createElement('div');
			this.eGui.innerHTML =
				'<select style="width:100%; height: 30px;"/><option value="null">Tous</option><option>Oui</option><option>Non</option></select>';
			this.currentValue = null;
			this.eFilterInput = this.eGui.querySelector('select');
			const that = this;
			function onSelectChanged() {
				if (that.eFilterInput.value === '') {
					// Remove the filter
					params.parentFilterInstance(function(instance) {
						instance.onFloatingFilterChanged(null, null);
					});
					return;
				}

				that.currentValue = that.eFilterInput.value;
				const filterField = params.filterParams.colDef.field;
				if (that.currentValue !== 'null') {
					ctx.regroupementDataRaw[filterField] = that.eFilterInput.value === 'Oui' ? true : false;
				} else {
					delete ctx.regroupementDataRaw[filterField];
				}
				params.parentFilterInstance(function(instance) {
					instance.onFloatingFilterChanged('equals', that.currentValue);
				});
			}
			this.eFilterInput.addEventListener('change', onSelectChanged);
		};

		SelectFloatingFilter.prototype.onParentModelChanged = function(parentModel) {
			if (!parentModel) {
				this.eFilterInput.value = '';
				this.currentValue = null;
			} else {
				this.eFilterInput.value = parentModel.filter + '';
				this.currentValue = parentModel.filter;
			}
		};

		SelectFloatingFilter.prototype.getGui = function() {
			return this.eGui;
		};

		return SelectFloatingFilter;
	}
}
