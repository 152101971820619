<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div class="tab-pane">
        <div class="form-block">
            <fieldset class="container-fluid">
                <div class="row">
                    <div class="col-sm-2 col-form-label">Type</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" formControlName="typeUser" id="type" class="form-control" readonly />
                    </div>
                    <div class="col-sm-2 col-form-label">Groupe</div>
                    <div class="col-sm-4 form-group"><input type="text" id="groupe" class="form-control" readonly
                            formControlName="groupUser" /></div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Civilité</div>
                    <div class="col-sm-4 form-group">
                        <ng-select formControlName="typeCivilite" style="width:100%; height:calc(1.5em + .75rem + 2px);"
                            bindLabel="name" [clearable]="false">
                            <ng-option *ngFor="let civility of civiliteArray" [value]="civility">
                                <span id="{{civility.viewValue}}">{{civility.viewValue}}</span>
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="col-sm-2 col-form-label">Fonction</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" id="fonction" formControlName="fonction"
                            onpaste="if(this.value.length==50) return false;"
                            onKeyPress="if(this.value.length==50) return false;" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Nom</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" id="nom" formControlName="nom"
                            onpaste="if(this.value.length==50) return false;"
                            onKeyPress="if(this.value.length==50) return false;" />
                    </div>
                    <div class="col-sm-2 col-form-label">Prénom</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" id="prenom" formControlName="prenom"
                            onpaste="if(this.value.length==50) return false;"
                            onKeyPress="if(this.value.length==50) return false;" /></div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Téléphone</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" id="telephone" formControlName="telephone"
                               phoneNumberDirective />
                    </div>
                    <div class="col-sm-2 col-form-label">Tél. Mobile</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" id="numMobil" formControlName="mobile"
                               phoneNumberDirective />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Fax</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" id="fax" formControlName="fax"
                               phoneNumberDirective />
                    </div>
                    <div class="col-sm-2 col-form-label">Email *</div>
                    <div class="col-sm-4 form-group">
                        <input type="text" class="form-control" id="email" formControlName="email"
                            onpaste="if(this.value.length==100) return false;"
                            onKeyPress="if(this.value.length==100) return false;"
                            [ngClass]="{ 'is-invalid': submitted && formFields.email.errors }" />
                        <div *ngIf="submitted && formFields.email.errors" class="invalid-feedback">
                            <div *ngIf="formFields.email.errors.required || formFields.email.errors.whitespaces">
                                Ce champ est obligatoire
                            </div>
                            <div *ngIf="formFields.email.errors.pattern">
                                Le format du champ E-mail est invalide
                            </div>
                            <div *ngIf="alreadyExists && !formFields.email.errors.required && !formFields.email.errors.whitespaces && !formFields.email.errors.pattern">Cet email est déjà utilisé</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Actif</div>
                    <div class="col-sm-4 form-group">
                        <ng-select formControlName="actif" style="width:100%; height:calc(1.5em + .75rem + 2px);"
                            bindLabel="name" [clearable]="false">
                            <ng-option id="{{actif.viewValue}}" *ngFor="let actif of actifArray" [value]="actif">
                                <span id="{{actif.viewValue}}"> {{actif.viewValue}}</span>
                            </ng-option>
                        </ng-select>
                    </div>

                    <div class="col-sm-2 col-form-label">Rappro. visible</div>
                    <div class="col-sm-4 form-group">
                        <ng-select formControlName="rapproActif" style="width:100%; height:calc(1.5em + .75rem + 2px);" 
                            bindLabel="name" [clearable]="false">
                            <ng-option  *ngFor="let rapproActif of actifArray" [value]="rapproActif">
                                
                                <span id="{{rapproActif.viewValue}}"> {{rapproActif.viewValue}}</span>
                            </ng-option>
                        </ng-select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-2 col-form-label">Commentaire</div>
                    <div class="col-sm-10 form-group">
                        <textarea class="form-control-input" id="commentaire" rows="3" formControlName="commentaire" rows="3"
                            style="  resize:horizontal;  resize: vertical;" autosize
                            onpaste="if(this.value.length==100) return false;"
                            onKeyPress="if(this.value.length==100) return false;">
                                        </textarea>
                    </div>
                </div>

                <!-- TOOLBAR -->
                <div class="button-bar control">
                    <!-- <button style="float:left" type="button" class="btn btn-primary"
                        (click)="onReinitializePassword()">Réinitialiser le
                        mot de passe</button>&nbsp;&nbsp;&nbsp;&nbsp; -->
                    <button type="button" id="cancel" class="btn btn-default" (click)="reset()"> Annuler </button>
                    <button type="submit" id="save" class="btn btn-primary"> Ajouter </button>
                </div>

            </fieldset>
        </div>
    </div>
</form>