export class OctDataRaw {
	idOct: Number;
	nom: string;
	code: string;
	url: string;

	withIdOct(idOct) {
		this.idOct = idOct;
		return this;
	}

	withNom(nom) {
		this.nom = nom;
		return this;
	}

	withCode(code) {
		this.code = code;
		return this;
	}
}
