import { ColDef, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import {Component, OnInit, OnDestroy, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { findIndex, isEmpty, uniqBy } from 'lodash-es';
import { isNullOrUndefined } from 'util';
import { AgGridHelper } from '../../components/ag-grid/ag-grid-helper';
import { gridConvertSort } from '../../components/ag-grid/ag-grid.utils';
import { ColumnSelectorComponent } from '../../components/ag-grid/grid-column-selector.component';
import { FilterItem } from '../../data/filters/filter-item';
import { UserFilterRaw } from '../../data/filters/userFilterRaw';
import { OctDataRaw } from '../../data/octDataRaw';
import { User } from '../../data/userDataRaw';
import { LotsFilterSvc } from '../flux/lots/lots-filter.service';
import { UserPreviewComponent } from './user-preview.component';
import { UsersService } from './users.service';
import { RouteHistoryService } from '../../shared/route-history.service';
import { GridDataRaw } from '../../data/gridDataRaw';
import { StorageService } from '../../shared/storage-service';
import {ExportModalComponent, ExportMode} from "../export-modal/export-modal.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import { saveAs } from 'file-saver';
import {TransformService} from "../../core/services/transform.service";
import {ExportState, State} from "../../shared/model/storage.model";
import { OctService } from '../../core/services/oct.service';
import {AuthService} from "../../core/services/auth.service";

@Component({
	selector: 'careweb-app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
	params: any;
	initHidden = true;
	showFilter = false;
	gridHelper: AgGridHelper;
	columnSelector: ColumnSelectorComponent;
	filters = new UserFilterRaw();
	filterList: FilterItem[];
	userDataRow: User;
	statesFilter;
	currentOct: OctDataRaw;
	gridDataRaw: GridDataRaw;
	rowCount: number;
	private openedModal: BsModalRef;
	export: boolean = false;
	exportState: ExportState;
	showAlert=false;
	listOctByUserCriteria:OctDataRaw[];
	octUserList: OctDataRaw[];
	currentUser: User;

	constructor(
		private usersService: UsersService,
		private router: Router,
		private filterServiceSvc: LotsFilterSvc,
		private routeHistory: RouteHistoryService,
		private storageService: StorageService,
		private modalService: BsModalService,
		private transformService: TransformService,
		private authSvc: AuthService,
		private octSvc: OctService

	) {
		//Get grid size from session if already stored
		const sessionPageSize = this.storageService.get('usersPaginationPageSize', true);
		this.gridDataRaw = new GridDataRaw();
		this.gridDataRaw.size = !isNullOrUndefined(sessionPageSize) ? sessionPageSize : 10;

		this.gridHelper = new AgGridHelper('users', this.gridDataRaw.size, 10);
		this.userDataRow = new User();
	}

	loadFilterState() {
		this.statesFilter = [
			{ id: null, name: 'Indifférent' },
			{ id: true, name: 'Activé' },
			{ id: false, name: 'Désactivé' }
		];
	}

	ngOnInit() {
		// verify previous url if not details remove session currentPage
		if (this.routeHistory?.getPreviousUrl()?.indexOf('user-oct-details') === -1) {
			sessionStorage['usersCurrentPage'] = null;
		}
		this.currentOct = JSON.parse(localStorage.getItem('octCurrent'));
		// Init filters
		let parsedFilter = JSON.parse(sessionStorage.getItem('userFilter'));
		// Get filter stored in session if exist
		if (!isEmpty(parsedFilter)) {
			this.filters = Object.assign({}, parsedFilter);
		} else {
			this.filterList = [new FilterItem()];
		}
		this.currentUser = this.authSvc.currentUserValue;
		this.gridHelper.gridOptions = {
			columnDefs: this.getColumnDefs(),
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			domLayout: 'autoHeight',
			rowHeight: 50,
			headerHeight: 50,
			rowModelType: 'serverSide',
			suppressServerSideInfiniteScroll: false,
			pagination: true,
			cacheBlockSize: 10,
			maxBlocksInCache: 0,
			infiniteInitialRowCount: 1,
			paginationPageSize: this.gridDataRaw.size,
			paginateChildRows: true,
			suppressScrollOnNewData: true,
			suppressPaginationPanel: true,
			suppressContextMenu: true,
			enableCellTextSelection: true,
			localeText: this.gridHelper.getLocaleText(),
			onRowClicked: (params) => this.onRowClicked(params),
			onGridReady: (params) => this.onGridReady(params),
			 onGridSizeChanged: (params) => this.onGridSizeChanged(params),
			onColumnMoved: (params) => this.onColumnMoved(params)
		};
		this.loadFilterState();
	}

	onGridSizeChanged(params) {
		this.gridHelper.gridApi.sizeColumnsToFit();
	}

	onGridReady(params) {
		const ctx = this;
		this.initFilter();
		this.gridHelper.gridApi = params.api;
		this.gridHelper.gridColumnApi = params.columnApi;
		this.restoreGrid();
		this.setColumnOrder(window.innerWidth);
		let parsedFilter = JSON.parse(sessionStorage.getItem('userFilter'));
		ctx.usersService.getListOctByIdUser(ctx.currentUser?.idUser).subscribe((data) => {
			if (data) {
				ctx.usersService.setCurrentlistOctByUser(data);
				ctx.octUserList = ctx.usersService.getCurrentlistOctByUser;
			}
		});
		if (!isNullOrUndefined(parsedFilter) && !isEmpty(parsedFilter)) {
			this.launchSearch();
		} else {
			const dataSource: IServerSideDatasource = {
				getRows: function (paramsRows: IServerSideGetRowsParams) {
					const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
					ctx.usersService
						.getUsersListByCriteriaByIdOct({
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							idOct: ctx.currentOct.idOct || JSON.parse(localStorage.getItem('octCurrent'))?.idOct || null,
							sorts
						})
						.subscribe(
							(data) => {
								ctx.rowCount = data.totalElements;
								ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
								paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
								const pageN = Number.parseInt(sessionStorage.getItem('usersCurrentPage'));
								if (
									!isNullOrUndefined(pageN) &&
									pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
									pageN > 0
								) {
									ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
								}
								sessionStorage['usersCurrentPage'] = null;
							},
							() => paramsRows.fail()
						);
				}
			};
			this.gridHelper.gridApi.setServerSideDatasource(dataSource);
			this.gridHelper.gridApi.sizeColumnsToFit();
		}
	}
	isOctInUserList(oct): boolean {
		return this.octUserList?.some(userOct => userOct.idOct === oct.idOct);
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		let width = event.target.innerWidth;
		this.setColumnOrder(width);
	}
	setColumnOrder(width:any) {
		if(width < 1280){
			const columnDefs = this.getColumnDefs();
			const optionsColumnIndex = columnDefs.findIndex(column => column.field === 'options');
			if (optionsColumnIndex !== -1) {
				columnDefs.splice(0, 0, columnDefs.splice(optionsColumnIndex, 1)[0]);
				this.gridHelper.gridApi.setColumnDefs(columnDefs);
			}
		}else {
			const originalColumnDefs = this.getColumnDefs();
			// Set the original column order
			this.gridHelper.gridApi.setColumnDefs(originalColumnDefs);
			this.gridHelper.gridApi.sizeColumnsToFit();
		}
	}
	onRowClicked(params) {
		if (!this.gridHelper.isCellTextSelected()) {
			this.router.navigate(['/user-oct-details', params.data.idUser]);
		}
	}

	restoreGrid() {
		this.gridHelper.restoreGridStatePrefix();
		this.gridDataRaw.size = this.gridHelper.paginationPageSize;
	}

	agInit(params: any): void {
		this.params = params;
	}

	resetGridState() {
		this.gridHelper.resetGridState();
		this.gridDataRaw.size = this.gridHelper.defaultPaginationPageSize;
	}

	refresh(): boolean {
		return false;
	}

	private getColumnDefs(): ColDef[] {
		return [
			{
				headerName: 'Civilité',
				field: 'idCivilite',
				minWidth: 100,
				maxWidth: 150,
				lockVisible: true,
				valueFormatter: this.formatCivility
			},
			{
				headerName: 'Nom',
				field: 'nom',
				minWidth: 100,
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'Prénom',
				field: 'prenom',
				minWidth: 100,
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'Fonction',
				field: 'fonction',
				minWidth: 150,
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'Téléphone',
				field: 'telephone',
				minWidth: 50,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent()
			},
			{
				headerName: 'E-mail',
				field: 'email',
				minWidth: 150,
				lockVisible: true,
				sortable: true
			},
			{
				headerName: 'Profil',
				field: 'idTypeUser',
				minWidth: 100,
				maxWidth: 155,
				valueFormatter: this.formatProfile
			},
			{
				headerName: 'Actif',
				field: 'actif',
				minWidth: 50,
				maxWidth: 50,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: UserPreviewComponent,
				sortable: true
			},
			{
				headerName: 'Compte bloqué',
				field: 'blocked',
				minWidth: 100,
				maxWidth: 100,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				cellRenderer: UserPreviewComponent,
				sortable: true
			},
			{
				headerName: '',
				field: 'options',
				minWidth: 50,
				maxWidth: 50,
				lockVisible: true,
				cellStyle: this.gridHelper.centerContent(),
				cellRendererParams: {
					iconClass: 'fa fa-eye',
					redirect: ''
				},
				cellRenderer: UserPreviewComponent
			}
		];
	}

	formatCivility = (params): string => {
		return params.value === 1 ? 'M' : 'Mme';
	};

	toggleFilterDisplay() {
		if (this.initHidden) {
			this.initHidden = false;
			setTimeout(() => {
				this.showFilter = !this.showFilter;
			}, 10);
		} else {
			this.showFilter = !this.showFilter;
		}
	}

	initFilter() {
		this.resetFilter();
		this.updateFilterList(this.filters);
	}

	resetFilter() {
		this.filterServiceSvc.reset();
	}

	onKeyDown(event) {
		this.launchSearch();
	}

	onChangeFilterState(event) {
		this.launchSearch();
	}

	resetAllFilter(): void {
		this.initFilter();
		this.filterServiceSvc.reset();
		this.filters = new UserFilterRaw();
		this.filterServiceSvc.reset();
		this.launchSearch();
	}

	launchSearch(): void {
		const ctx = this;
		
		this.filters.nom = this.filters.nom ? this.filters.nom.trim() : null;
		this.userDataRow.nom = this.filters.nom || null;
		this.filters.prenom = this.filters.prenom ? this.filters.prenom.trim() : null;
		this.userDataRow.prenom = this.filters.prenom || null;
		this.filters.email = this.filters.email ? this.filters.email.trim() : null;
		this.userDataRow.email = this.filters.email;
		this.filters.fonction = this.filters.fonction ? this.filters.fonction.trim() : null;
		this.userDataRow.fonction = this.filters.fonction || null;
		this.userDataRow.actif = this.filters.actif;
		this.userDataRow.idOct = isNullOrUndefined(this.currentOct) ? 1 : this.currentOct.idOct;

		this.updateFilterList(this.filters);
		Object.keys(this.userDataRow).forEach((key) => {
			if (isNullOrUndefined(this.userDataRow[key])) {
				delete this.userDataRow[key];
			}
		});
		const dataSource: IServerSideDatasource = {
			getRows: function (paramsRows: IServerSideGetRowsParams) {
				const sorts = gridConvertSort(ctx.gridHelper.gridSortModel(), []);
				ctx.usersService
					.getUsersByFilterNotPS(
						{
							page: ctx.gridHelper.gridApi.paginationGetCurrentPage() || 0,
							size: ctx.gridHelper.gridApi.paginationGetPageSize(),
							sorts
						},
						ctx.userDataRow
					)
					.subscribe(
						(data) => {
							this.rowData = data;
							ctx.rowCount = data.totalElements;
							ctx.gridHelper.manageNoRowsOverlay(ctx.rowCount);
							paramsRows.success({"rowData": data.content, "rowCount": ctx.rowCount});
							const pageN = Number.parseInt(sessionStorage.getItem('usersCurrentPage'));
							if (
								!isNullOrUndefined(pageN) &&
								pageN != ctx.gridHelper.gridApi.paginationGetCurrentPage() &&
								pageN > 0
							) {
								ctx.gridHelper.gridApi.paginationGoToPage(pageN - 1);
							}
							sessionStorage['usersCurrentPage'] = null;
							if(!isEmpty(ctx.userDataRow.email)){
								ctx.usersService.getAllOctByUserSearchFilters(ctx.userDataRow).subscribe(data=>{
									 ctx.listOctByUserCriteria=data;
									 if(!isNullOrUndefined(ctx.listOctByUserCriteria) && ctx.listOctByUserCriteria?.length==1 && ctx.listOctByUserCriteria[0].idOct!==ctx.currentOct.idOct ){
										
										ctx.showAlert=true;
										
									}
									else if(!isNullOrUndefined(ctx.listOctByUserCriteria) && ctx.listOctByUserCriteria?.length >1 ){
										ctx.showAlert=true;
										
									}else {
										ctx.showAlert=false;
									}
								});
							}
							else{
								ctx.showAlert=false;
							}
							
						},
						() => paramsRows.fail()
					);
			}
		};
		this.gridHelper.gridApi.setServerSideDatasource(dataSource);
		sessionStorage['userFilter'] = JSON.stringify(this.filters);
	}

	updateFilterList(filters) {
		const ctx = this;
		ctx.filterList = [];
		Object.keys(filters).forEach((key) => {
			if (filters[key] !== null && filters[key] !== '') {
				ctx.filterList.push(ctx.translateFilterLabelValue(key, filters[key]));
			}
		});
		// Remove duplicated ids
		ctx.filterList = uniqBy(ctx.filterList, 'id');
		// filter null
		ctx.filterList = ctx.filterList.filter((item) => {
			return item.id != null;
		});
	}

	translateFilterLabelValue(key, value) {
		let translatedKey = key;
		let formattedValue = value;
		let formattedKey = key;
		switch (key) {
			case 'nom':
				translatedKey = 'Nom';
				break;
			case 'prenom':
				translatedKey = 'Prénom';
				break;
			case 'email':
				translatedKey = 'Email';
				break;
			case 'fonction':
				translatedKey = 'Fonction';
				break;
			case 'actif':
				translatedKey = 'Etat';
				formattedValue = this.filters.actif == true ? 'Activé' : 'Désactivé';
				break;
			default:
				break;
		}
		return new FilterItem().withId(formattedKey).withLabel(translatedKey).withValue(formattedValue).withValue2(value);
	}

	removeFilter(id) {
		const ctx = this;
		delete ctx.filters[id];
		let currentFilterList = ctx.filterList;
		const index = findIndex(currentFilterList, (filt) => filt.id === id);
		currentFilterList.splice(index, 1);
		ctx.filterList = currentFilterList;
		this.fillFilterObjectFromTagsOnly();
		ctx.launchSearch();
	}

	fillFilterObjectFromTagsOnly() {
		this.filters = new UserFilterRaw();
		this.filterList.forEach(filter => {
			this.filters[filter.id] = filter.value2;
		});
	}

	ngOnDestroy() {
		sessionStorage['userFilter'] = JSON.stringify(this.filters);
		sessionStorage['usersCurrentPage'] = this.gridHelper.gridApi.paginationGetCurrentPage();
		this.gridHelper.saveGridStatePrefix();
	}

	exportExcel() {
		const exportableColumns = this.gridHelper.gridColumnApi.getAllDisplayedColumns()
			.filter(column => column.getColDef().headerName != "")
			.map(column => column.getColDef().field);

		if (this.rowCount > 0 && this.rowCount <= 1000) {
			this.exportToExcel(exportableColumns);
		} else if (this.rowCount > 1000) {
			this.openedModal = this.modalService.show(ExportModalComponent,  { initialState: {
					nbrElement: this.rowCount
				}, class: 'modal-lg'});
			this.openedModal.content.exportEvent.subscribe((e: any) => {
				if (e.action === ExportMode.EXPORT) {
					// this.exportFileXlsx();
					this.exportToExcel(exportableColumns);
				}
				if (e.action === ExportMode.EMAIL) {
					// this.exportFileXlsx();
					this.exportByEmail(exportableColumns);
				}
			});
		}
	}

	exportToExcel(columns) {
		this.export = true;
		this.exportState = {
			message : "Vous allez exporter <b>" + this.transformService.transform(this.rowCount) + " ligne(s)</b>, veuillez patienter quelques instants.",
			state: State.INPROGRESS
		}
		const sorts = gridConvertSort(this.gridHelper.gridSortModel(),[]);
		this.usersService.exportUsers(
			{
				idOct: this.currentOct.idOct,
				sorts
			},
			this.userDataRow,
			columns
		).subscribe((response) => {
			saveAs(response.file, response.fileName);
			this.exportState = {
				message : "Votre fichier a été téléchargé avec succès.",
				state: State.SUCCESS
			}
		},() => {
			this.exportState = {
				message : "Le téléchargement de votre fichier a rencontré un problème.",
				state: State.FAILED
			}
		})
	}

	exportByEmail(columns) {
		const sorts = gridConvertSort(this.gridHelper.gridSortModel(),[]);
		this.usersService.exportUsersByEmail(
			{
				idOct: this.currentOct.idOct,
				sorts
			},
			this.userDataRow,
			columns
		).subscribe((response) => {

		})
	}
	closeAlert() {
		this.export = false;
	}

	rowGroupCallback(params) {
		return params.node.key;
	}

	onPaginationSizeChange(paginationSize: number): void {
		this.gridHelper.changePaginationSize(paginationSize);
		this.launchSearch();
		this.gridHelper.saveGridStatePrefix();
	}

	onColumnMoved(params: any): void {
		this.gridHelper.saveGridColumnState();
	}

	formatProfile = (params): string => {
		switch(params.value){
			case 1:{return 'Administrateur'; break; }
			case 2:{return 'Utilisateur PS'; break; }
			case 3:{return 'Hotline'; break; }
			case 4:{return 'Administrateur SEL'; break; }
			default:{return ''}
		}
	};
	dismissAlert(): void {
		this.showAlert = false;
	}
	onOctChange(oct:OctDataRaw) {
		this.octSvc.setOctValue(oct);
		localStorage['octCurrent'] = JSON.stringify(oct);
		window.location.reload();
	}
}