import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';

@Component({
	selector: 'careweb-arl-preview',
	template: `
		<span *ngIf="!isRapproche">{{params.value}}</span>
		<div *ngIf="isRapproche"
            container="body" id="link_arl"
			(click)="previewLot()"
		>{{params.value}}</div>
	`,
	styles: [ '.careweb-link {color: #21b8cf!important;}' ]
})
export class ArlLotPreviewComponent implements ICellRendererAngularComp {
	params: any;
	isRapproche: boolean;

	constructor(private router: Router) {}

	agInit(params: any): void {
		this.params = params;
		if (!isNullOrUndefined(this.params.data) && this.params.data.rapprochement) {
			this.isRapproche = true;
		} else {
			this.isRapproche = false;
		}
	}

	refresh(): boolean {
		return false;
	}

	previewLot(): void {
		if (
			this.params.redirect === 'lotDetails' &&
			!isNullOrUndefined(this.params.data) &&
			!isNullOrUndefined(this.params.data.idLot)
		) {
			sessionStorage.previouslyClickedArlId = this.params.data.idArl;
			this.router.navigate([ '/lot-details', this.params.data.idLot ]);
		}
	}
}
